export const isOnlyMaskPresent = id => {
  return id === "______";
};

export const validatePin = value => {
  if (!value || isOnlyMaskPresent(value)) {
    return true;
  }
  return isPinValid(value);
};

const isPinValid = value => {
  const regex = /[0-9]{6}/g;
  return regex.test(value);
};

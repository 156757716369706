import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { blue } from "@material-ui/core/colors";
import { withStyles } from "@material-ui/styles";
import "./MultiSelectFilter.scss";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 120,
    maxWidth: 300
  },
  groupParent: {
    padding: 0,
    minHeight: 45
  },
  groupChild: {
    padding: 0,
    paddingLeft: 10,
    margin: 0,
    height: 37,
    minHeight: 37
  },
  groupSubChild: {
    padding: 0,
    paddingLeft: 20,
    margin: 0,
    height: 30,
    minHeight: 30
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  variant: "menu",
  getContentAnchorEl: null
};

const BlueCheckbox = withStyles({
  root: {
    color: blue[400],
    "&$checked": {
      color: blue[600]
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const BootstrapInput = withStyles(() => ({
  root: {
    "label + &": {
      marginTop: 3
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#ffff",
    border: "1px solid #ced4da !important",
    width: 140,
    fontSize: 14,
    color: "#808080 !important",
    fontWeight: "normal !important",
    paddingRight: "15px !important",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      backgroundColor: "#ffff !important",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    },
    "&:hover": {
      borderColor: "hsl(0, 0%, 70%) !important"
    }
  }
}))(props => <Input {...props} />);

const DashboardCustomFilter = ({
  options,
  onFilter,
  showOnlyChidren = false,
  showOnlySubChidren = false,
  filteredText = "Items filtered.",
  emptyText = "",
  isDashboard = true,
  reverse = false,
  hideMessages = false,
  fullWidth = true,
  hideIcon = false
}) => {
  const classes = useStyles();
  const [isAllCheckboxChecked, setIsAllCheckboxChecked] = React.useState(false);
  const [checkedValues, setCheckedValues] = React.useState(options || []);

  const isAtLeastOneChildChecked =
    checkedValues
      .map(el => el.children)
      .flat()
      .findIndex(el => el.checked) > -1;

  React.useEffect(() => {
    if (isAllCheckboxChecked) checkedValues.forEach(opt => setParentChecked(opt, isAllCheckboxChecked));
  }, [isAllCheckboxChecked]);

  React.useEffect(() => {
    onFilter(checkedValues);
    if (isDashboard) {
      localStorage.setItem("dashboard_filters", JSON.stringify(checkedValues));
    }

    let isAllParentChecked = true;
    let isAllParentUnChecked = true;

    checkedValues.forEach(c => {
      c.checked ? (isAllParentUnChecked = false) : (isAllParentChecked = false);
    });

    if (isAllParentChecked) setIsAllCheckboxChecked(true);
    else if (isAllParentUnChecked || !isAllParentChecked) setIsAllCheckboxChecked(false);
  }, [checkedValues]);

  const findParent = parent => {
    return checkedValues.find(cv => cv.value === parent.value);
  };

  const findChildInParent = (parent, child) => {
    if (!parent) return null;
    return parent.children.find(c => c.value === child.value);
  };

  const findParentIndex = parent => {
    return checkedValues.findIndex(pv => pv.value === parent.value);
  };

  const findChildInParentIndex = (parent, child) => {
    return findParent(parent).children.findIndex(pv => pv.value === child.value);
  };

  const findSubChildInChildInParentIndex = (parent, child, subChild) => {
    return child.subChildren?.findIndex(sc => sc.value === subChild.value);
  };

  const setChildChecked = (parent, child, value) => {
    const parentIndex = findParentIndex(parent);
    const childIndex = findChildInParentIndex(parent, child);

    const children = [...checkedValues[parentIndex].children];
    const state = value ? value.state : !children[childIndex].checked;
    children[childIndex].checked = state;

    if (!value.onlyChild) {
      children[childIndex].subChildren = children[childIndex].subChildren?.map(sc => ({ ...sc, checked: state }));
    }

    const values = [...checkedValues];
    values[parentIndex].children = [...children];

    let isAllChildrenChecked = true;
    let isAllChildrenUnChecked = true;

    values[parentIndex].children.forEach(c => {
      c.checked ? (isAllChildrenUnChecked = false) : (isAllChildrenChecked = false);
    });

    if (isAllChildrenChecked) values[parentIndex].checked = true;
    else if (isAllChildrenUnChecked || !isAllChildrenChecked) {
      values[parentIndex].checked = false;
    }

    setCheckedValues(values);
  };

  const setSubChildChecked = (parent, child, subChild) => {
    if (!subChild) return null;
    const subChildIndex = findSubChildInChildInParentIndex(parent, child, subChild);

    const subChildren = [...child.subChildren];
    subChildren[subChildIndex].checked = !subChildren[subChildIndex].checked;

    let isAllSubChildrenChecked = true;
    let isAllSubChildrenUnChecked = true;

    if (subChildren.some(sc => sc.checked)) {
      isAllSubChildrenUnChecked = false;
    }
    if (subChildren.some(sc => !sc.checked)) {
      isAllSubChildrenChecked = false;
    }

    if (isAllSubChildrenChecked) setChildChecked(parent, child, { state: true, onlyChild: true });
    else if (isAllSubChildrenUnChecked || !isAllSubChildrenChecked) {
      setChildChecked(parent, child, { state: false, onlyChild: true });
    }

    return;
  };

  const setParentChecked = (parent, state) => {
    const parentIndex = findParentIndex(parent);
    const values = [...checkedValues];
    values[parentIndex].children = values[parentIndex].children.map(c => ({
      ...c,
      checked: state,
      subChildren: c.subChildren?.map(sc => ({ ...sc, checked: state }))
    }));
    values[parentIndex].checked = state;

    setCheckedValues(values);
  };

  const setSelectedAll = () => {
    if (isAtLeastOneChildChecked && !isAllCheckboxChecked) setIsAllCheckboxChecked(true);
    else setIsAllCheckboxChecked(!isAllCheckboxChecked);

    if (isAllCheckboxChecked) {
      checkedValues.forEach(opt => setParentChecked(opt, false));
    }
  };

  const isParentChecked = parent => {
    return checkedValues.find(cv => cv.value === parent.value).checked;
  };

  const isChildChecked = (parent, child) => {
    return child.checked;
  };

  const isInParentAtLeastOneChildChecked = parent => {
    return checkedValues.find(cv => cv.value === parent.value).children.findIndex(el => el.checked) > -1;
  };

  const isInParentAtLeastOneSubChildChecked = parent => {
    return parent.children.some(c => c.subChildren?.some(sc => sc.checked));
  };

  const isInChildAtLeastOneSubChildChecked = (parent, child) => {
    if (!child.subChildren) return false;
    return child.subChildren.some(el => el.checked);
  };

  const isChildInParentChecked = (parent, child) => {
    return findParent(parent).children.find(el => el.value === child.value).checked;
  };

  const isSubChildInChildInParentChecked = (parent, child, subChild) => {
    return findChildInParent(findParent(parent), child)?.subChildren?.find(el => el.value === subChild.value).checked;
  };

  const isAtLeastOneSubChildChecked = checkedValues.some(p => isInParentAtLeastOneSubChildChecked(p));

  let filter = (
    <FormControl className={classes.formControl} variant={isDashboard ? "outlined" : "standard"}>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={["Choose filter"]}
        renderValue={selected => selected.join(",")}
        input={<BootstrapInput />}
        MenuProps={MenuProps}
      >
        <MenuItem value={"all"} className={classes.groupParent} onClick={setSelectedAll}>
          <BlueCheckbox
            indeterminate={(isAtLeastOneChildChecked || isAtLeastOneSubChildChecked) && !isAllCheckboxChecked}
            checked={isAtLeastOneChildChecked || isAtLeastOneSubChildChecked || isAllCheckboxChecked}
          />
          <ListItemText primary={"Select all"} />
        </MenuItem>
        {checkedValues.map(v => (
          <div key={v.value}>
            <MenuItem
              value={v.value}
              className={classes.groupParent}
              style={{ display: `${showOnlyChidren || showOnlySubChidren ? "none" : "flex"}` }}
              onClick={() => {
                isParentChecked(v) || isInParentAtLeastOneChildChecked(v)
                  ? setParentChecked(v, false)
                  : setParentChecked(v, true);
              }}
            >
              <BlueCheckbox
                indeterminate={
                  !isParentChecked(v) && (isInParentAtLeastOneChildChecked(v) || isInParentAtLeastOneSubChildChecked(v))
                }
                checked={
                  isParentChecked(v) || isInParentAtLeastOneChildChecked(v) || isInParentAtLeastOneSubChildChecked(v)
                }
              />
              <ListItemText primary={v.label} />
            </MenuItem>
            {v.children.map(c => (
              <div key={c.value}>
                <MenuItem
                  value={c.value}
                  style={{ display: `${showOnlySubChidren ? "none" : "flex"}` }}
                  className={showOnlyChidren ? classes.groupParent : classes.groupChild}
                  onClick={() => {
                    isChildChecked(v, c) || isInChildAtLeastOneSubChildChecked(v, c)
                      ? setChildChecked(v, c, { state: false })
                      : setChildChecked(v, c, { state: true });
                  }}
                >
                  <BlueCheckbox
                    indeterminate={!isChildChecked(v, c) && isInChildAtLeastOneSubChildChecked(v, c)}
                    checked={isChildInParentChecked(v, c) || isInChildAtLeastOneSubChildChecked(v, c)}
                  />
                  <ListItemText primary={c.label} />
                </MenuItem>
                {c.subChildren && c.subChildren.length
                  ? c.subChildren.map(sc => (
                      <MenuItem
                        key={sc.value}
                        value={sc.value}
                        className={
                          showOnlySubChidren
                            ? classes.groupParent
                            : showOnlyChidren
                            ? classes.groupChild
                            : classes.groupSubChild
                        }
                        onClick={() => setSubChildChecked(v, c, sc)}
                      >
                        <BlueCheckbox checked={isSubChildInChildInParentChecked(v, c, sc)} />
                        <ListItemText primary={sc.label} />
                      </MenuItem>
                    ))
                  : null}
              </div>
            ))}
          </div>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <div className={`${isDashboard ? "card" : ""} card-stats`}>
      <div className="db-filter">
        <div
          className="db-filter__row"
          style={{
            display: "flex",
            flexDirection: !reverse ? "row" : "row-reverse",
            justifyContent: "start",
            padding: isDashboard ? "10px 0" : 0,
            width: fullWidth ? 800 : 170
          }}
        >
          {!hideIcon && <i className="mdi mdi-filter db-filter__icon"></i>}
          {filter}
          {!isAllCheckboxChecked && !hideMessages ? (
            <div className="db-filter__filter-details">
              <div
                className="db-filter__column"
                style={{
                  position: isDashboard ? "inherit" : "absolute"
                }}
              >
                {!isDashboard && !checkedValues.filter(v => v.checked).length && emptyText ? emptyText : filteredText}
              </div>
              <div
                className="db-filter__clear"
                style={{
                  position: isDashboard ? "inherit" : "absolute",
                  top: "30px",
                  left: "160px"
                }}
                onClick={() => setIsAllCheckboxChecked(true)}
              >
                Show All
              </div>
            </div>
          ) : (
            !hideMessages && <div style={{ width: 200 }} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DashboardCustomFilter;

/* eslint-disable @typescript-eslint/ban-types */
import React from "react";
import "./SettingsWrapper.scss";
import SettingsTitle from "../../components/Settings/SettingsTitle";
import { Form, Row } from "react-bootstrap";
import Button from "../../components/CustomButton/CustomButton";

type SettingsWrapperProps = {
  handleSubmit;
  title;
  children;
  buttonStyles;
  showDelete?: any;
  handleDelete?: any;
  deleteButtonText?: any;
  hideSubmit?: any;
  submitBtnDisabled?: boolean;
  fullHeight?: boolean;
  onKeyDown?: Function;
  saveBtnId?: any;
};

const SettingsWrapper = ({
  handleSubmit,
  title,
  children,
  buttonStyles,
  showDelete,
  handleDelete,
  deleteButtonText,
  hideSubmit,
  submitBtnDisabled = false,
  fullHeight = false,
  onKeyDown = () => null,
  saveBtnId = null
}: SettingsWrapperProps) => {
  return (
    <>
      <SettingsTitle title={title} />
      <Form noValidate className="text-messages" onSubmit={handleSubmit} onKeyDown={onKeyDown}>
        <div className="main-scroll-wrapper">
          <div className="main-scroll-wrapper__form-wrapper">
            <div className={`inner-wrapper ${fullHeight ? "h-100" : ""} `}>{children}</div>
          </div>
        </div>
        <Row style={buttonStyles}>
          {showDelete && (
            <Button onClick={handleDelete} style={{ marginRight: "20px" }} bsStyle="danger" type="button">
              {deleteButtonText || "Delete Location"}
            </Button>
          )}
          {!hideSubmit && (
            <Button bsStyle="info" id={saveBtnId} fill type="submit" disabled={submitBtnDisabled}>
              Save Changes
            </Button>
          )}
        </Row>
      </Form>
    </>
  );
};

export default SettingsWrapper;

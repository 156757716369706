import React from "react";
import { Modal } from "react-bootstrap";
import "./ThankYouAndVideoModal.scss";
import CustomButton from "components/CustomButton/CustomButton";

const ThankYouAndVideoModal = ({ show, close }) => {
  return (
    <Modal
      dialogClassName="thank-you-and-tour-video-modal"
      backdropClassName="dark-backdrop"
      show={show}
      onHide={() => {
        close();
      }}
    >
      <CustomButton
        className="thank-you-and-tour-video-modal__close-btn"
        simple
        onClick={() => {
          close();
        }}
      >
        <i className="fa fa-times" />
      </CustomButton>
      <div className="content">
        <div className="header">Quick Start Video</div>
        <div className="text">
          <div>Below is our "Quick Start" video that will guide you through the basics.</div>
        </div>
        <div className="iframe-wrapper">
          <div className="iframe-container">
            <iframe
              src="https://www.youtube.com/embed/6q0ewtqNZKI"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ThankYouAndVideoModal;

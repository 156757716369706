import { SET_DASHBOARD_STATS } from "../actions/aggregate.actions";

export function aggregate(state = {}, action) {
  switch (action.type) {
    case SET_DASHBOARD_STATS:
      return { ...state, dashboardStats: action.payload };
    default:
      return state;
  }
}

import { firebase, db } from "../firebase";
import { useState, useEffect } from "react";

export function useAuth() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  async function getDBUserByUid(uid) {
    return await db
      .collection("users")
      .doc(uid)
      .get();
  }

  function mapInputToUser(firebaseUser, dbUser, claims) {
    const payload = {
      displayName: firebaseUser.displayName,
      photoUrl: firebaseUser.photoURL,
      uid: firebaseUser.uid,
      firstName: dbUser.firstName,
      lastName: dbUser.lastName,
      email: dbUser.email,
      businessId: dbUser.businessId,
      accountId: dbUser.accountId,
      settings: dbUser.settings,
      claims
    };
    return payload;
  }

  useEffect(() => {
    firebase.auth().onIdTokenChanged(async function(firebaseUser) {
      if (firebaseUser) {
        if (
          window.location.hash &&
          window.location.hash.includes("accept-invite") &&
          !window.location.hash.includes("submitted")
        ) {
          firebase.auth().signOut();
          setLoading(true);
          setUser(null);
          return [null, true];
        }
        const dbUser = await getDBUserByUid(firebaseUser.uid);
        const tokenResult = await firebaseUser.getIdTokenResult();
        if (dbUser.data()) {
          const user = mapInputToUser(firebaseUser, dbUser.data(), tokenResult.claims);
          setUser(user);
          setLoading(false);
        } else {
          setUser(null);
          setLoading(false);
        }
      } else {
        setUser(null);
        setLoading(false);
      }
    });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.has("authToken")) {
      const token = params.get("authToken");
      firebase
        .auth()
        .signInWithCustomToken(token)
        .then(() => {
          window.location.href = "/";
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, []);

  return [user, loading];
}

import { db } from "../../firebase";
import moment from "moment-timezone";
import { collections } from "services";
import { VisitStatus } from "services/visits/visits.service";

export function getReservations(payload) {
  const { businessId, accountId, date, timezone } = payload;
  const start = moment
    .tz(date, timezone)
    .startOf("day")
    .toDate();
  const end = moment
    .tz(date, timezone)
    .endOf("day")
    .toDate();
  return db
    .collection(collections.reservations)
    .where("businessId", "==", businessId)
    .where("accountId", "==", accountId)
    .where("reservationTime", ">=", start)
    .where("reservationTime", "<", end)
    .where("status", "in", [VisitStatus.reserved, VisitStatus.seated, VisitStatus.waiting, VisitStatus.walkout]);
}

export function createReservation(reservation) {
  return db
    .collection(collections.reservations)
    .add(reservation)
    .then(r => {
      return db
        .collection(collections.reservations)
        .doc(r.id)
        .get();
    });
}

export function updateReservation(id, reservation) {
  return db
    .collection(collections.reservations)
    .doc(id)
    .set(reservation, { merge: true });
}

export async function deleteReservation(reservation) {
  return db
    .collection(collections.reservations)
    .doc(reservation.id)
    .set({ ...reservation.data(), status: VisitStatus.deleted });
}

export function checkInReservation(reservation) {
  return db
    .collection(collections.reservations)
    .doc(reservation.id)
    .set({ ...reservation.data(), status: VisitStatus.waiting, checkInTime: new Date() });
}

export function serveReservation(reservation) {
  return db
    .collection(collections.reservations)
    .doc(reservation.id)
    .set({ ...reservation.data(), status: VisitStatus.seated, seatedTime: new Date() });
}

export function walkoutReservation(reservation) {
  return db
    .collection(collections.reservations)
    .doc(reservation.id)
    .set({ ...reservation.data(), status: VisitStatus.walkout });
}

export function resetReservation(reservation) {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const { seatedTime, checkInTime, ...payload } = reservation.data();
  return db
    .collection(collections.reservations)
    .doc(reservation.id)
    .set({ ...payload, status: VisitStatus.reserved });
}

export function addOrUpdateReservationNote(reservation, note) {
  const payload = reservation.data();
  return db
    .collection(collections.reservations)
    .doc(reservation.id)
    .set({ ...payload, note });
}

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Button } from "react-bootstrap";
// used to make this component's props into classes
import cx from "classnames";
// used for making the props of this component to bool

type CustomButtonProps = {
  fill?: boolean;
  simple?: boolean;
  pullRight?: boolean;
  block?: boolean;
  wd?: boolean;
  round?: boolean;
  icon?: boolean;
  neutral?: boolean;
  twitter?: boolean;
  facebook?: boolean;
  google?: boolean;
  linkedin?: boolean;
  pinterest?: boolean;
  youtube?: boolean;
  tumblr?: boolean;
  github?: boolean;
  behance?: boolean;
  dribbble?: boolean;
  reddit?: boolean;
  stumbleupon?: boolean;
  bsStyle?: any;
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

const CustomButton = (props: CustomButtonProps) => {
  const {
    fill,
    simple,
    pullRight,
    block,
    wd,
    round,
    icon,
    neutral,
    twitter,
    facebook,
    google,
    linkedin,
    pinterest,
    youtube,
    tumblr,
    github,
    behance,
    dribbble,
    reddit,
    stumbleupon,
    className,
    ...rest
  } = props;

  const btnClasses = cx({
    "btn-fill": fill,
    "btn-simple": simple,
    "pull-right": pullRight,
    "btn-block": block,
    "btn-wd": wd,
    "btn-round": round,
    "btn-icon": icon,
    "btn-neutral": neutral,
    "btn-social btn-twitter": twitter,
    "btn-social btn-facebook": facebook,
    "btn-social btn-google": google,
    "btn-social btn-linkedin": linkedin,
    "btn-social btn-pinterest": pinterest,
    "btn-social btn-youtube": youtube,
    "btn-social btn-tumblr": tumblr,
    "btn-social btn-github": github,
    "btn-social btn-behance": behance,
    "btn-social btn-dribbble": dribbble,
    "btn-social btn-reddit": reddit,
    "btn-social btn-stumbleupon": stumbleupon
  });

  return <Button className={`${btnClasses} ${className ?? ""}`} {...rest} />;
};
export default CustomButton;

import { useEffect, useState } from "react";
import "./Square.scss";
import Button from "components/CustomButton/CustomButton";
import { ControlLabel, FormGroup } from "react-bootstrap";
import SettingsInput from "components/Settings/SettingsInput";
import { reservationPossibleValues } from "constants/time";
import { partySizeSuitableDuration } from "constants/time";
import { updateSquareInfo } from "services/square/square.service";
import toastr from "toastr";
import CustomButton from "components/CustomButton/CustomButton";

export const peopleCounts = [
  {
    value: 1,
    text: "1 person"
  },
  {
    value: 2,
    text: "2 people"
  },
  {
    value: 3,
    text: "3 people"
  },
  {
    value: 4,
    text: "4 people"
  },
  {
    value: 5,
    text: "5 people"
  },
  {
    value: 6,
    text: "6 people"
  },
  {
    value: 7,
    text: "7 people"
  },
  {
    value: 8,
    text: "8 people"
  },
  {
    value: 9,
    text: "9 people"
  },
  {
    value: 10,
    text: "10 people"
  },
  {
    value: 12,
    text: "12 people"
  }
];

export const minDurationOptions = reservationPossibleValues.map(pv => ({
  text: Math.floor(pv / 60) > 0 ? Math.floor(pv / 60) + "h " + (pv % 60 > 0 ? (pv % 60) + "m" : "") : pv + "m",
  value: pv
}));

export const simultaneousCounts = Array(11)
  .fill(0)
  .map((_, idx) => ({ value: idx, text: "" + idx + `${idx === 1 ? " table" : " tables"}` }));

export const DEFAULT_RESOURCE_COUNT = 6;

const SquareReservationSetup = ({
  goToNextStep,
  maxResourcesCount,
  minPartySize,
  isInModal = true,
  shouldSubmit = false,
  shoudReturnResorces = false,
  setShouldSubmit,
  businessId = null,
  maxPartySizeChanged,
  reserveWithGoogle = false,
  isLastStep = false,
  ...props
}) => {
  const [form, setForm] = useState({
    resourcesCount: maxResourcesCount || DEFAULT_RESOURCE_COUNT,
    minPartySize: minPartySize || 1
  });
  const [resources, setResources] = useState([]);
  useEffect(() => {
    if (maxResourcesCount) {
      setForm({ ...form, resourcesCount: maxResourcesCount });
    }
    setResources([]);
  }, [maxResourcesCount]);

  const changeMaxPartySize = event => {
    if (maxPartySizeChanged) {
      maxPartySizeChanged(Number(event.target.value));
    }
    setForm({ ...form, [event.target.name]: Number(event.target.value) });
  };

  const changeMinPartySize = event => {
    setForm({ ...form, [event.target.name]: Number(event.target.value) });
  };

  const updateResources = (rsrcs, count = 0) => {
    const resources = Array(Number(count))
      .fill(0)
      .map((_, idx, arr) => {
        if ((idx + 1) % 2 === 0) return null;
        if (idx + 2 < form.minPartySize) return null;
        const name = `${idx + 2} top`;
        const resource = rsrcs.find(r => r.maxPartySize === idx + 1 || r.maxPartySize === idx + 2);

        const maxPartySize = idx === arr.length - 1 ? idx + 1 : idx + 2;
        return resource
          ? { ...resource, maxPartySize }
          : {
              name,
              minDuration: partySizeSuitableDuration[idx + 1],
              maxReservations: 0,
              maxPartySize
            };
      })
      .filter(r => r);
    setResources(resources);
  };

  useEffect(() => {
    if (form.resourcesCount) {
      updateResources((resources?.length ? resources : props.resources) || [], form.resourcesCount);
    }
  }, [form.resourcesCount, form.minPartySize]);

  useEffect(() => {
    if (props.resources?.length) {
      const newResourcesCount = Math.max(...props.resources.map(r => r.maxPartySize));
      updateResources(props.resources, Math.ceil(newResourcesCount));
      setForm({ ...form, resourcesCount: newResourcesCount });
    }
  }, [props.resources]);

  const nextStep = async (withLoading = false) => {
    const configs = {
      squareReservations: { resources: resources.filter(r => r.maxReservations) },
      minPartySize: form.minPartySize,
      selfBooking: isInModal,
      reserveWithGoogle,
      businessId: businessId
    };
    if (withLoading && !isInModal) {
      await updateSquareInfo(configs);
    } else {
      updateSquareInfo(configs);
    }
    const data = shoudReturnResorces ? configs.squareReservations.resources : { takeReservations: true };
    goToNextStep(data);
  };

  const nextStepWithLoading = async () => {
    props.setLoading(true);
    try {
      await nextStep(true);
    } catch (e) {
      console.log(e);
      toastr.error("Something went wrong");
    }
    props.setLoading(false);
  };

  const skipStep = () => {
    goToNextStep({ skipped: true, final: true, takeReservations: false });
  };

  const changeResource = (name, value, idx) => {
    const tempResources = [...resources];
    tempResources[idx] = { ...tempResources[idx], [name]: Number(value) };
    setResources(tempResources);
  };

  useEffect(() => {
    if (shouldSubmit) {
      nextStepWithLoading();
      setShouldSubmit(false);
    }
  }, [shouldSubmit]);

  const getMinRange = () => {
    return peopleCounts.filter(p => {
      if (p.value <= form.resourcesCount) {
        return p;
      }
    });
  };

  const getMaxRange = () => {
    return peopleCounts.filter(p => {
      if (p.value >= form.minPartySize) {
        return p;
      }
    });
  };

  return (
    <div className={`${isInModal ? "square-modal " : "h-100 d-flex"}`}>
      {isInModal && <div className="square-modal__header">Table Capacity</div>}
      <div className={`${isInModal ? "square-modal__body" : "mb-4"}`}>
        {isInModal && (
          <CustomButton
            className="square-modal__close-btn"
            simple
            onClick={() => {
              skipStep();
            }}
          >
            <i className="fa fa-times" />
          </CustomButton>
        )}
        <div className="square-modal__select">
          <FormGroup className="square-modal__input-group">
            <div style={{ flexDirection: "row", width: "100%" }}>
              <ControlLabel className={"settings-label"}>Min party size</ControlLabel>
              <ControlLabel className={"settings-subtitle"}>
                The minimum party size that can be booked online.
              </ControlLabel>
            </div>
            <SettingsInput
              form={form}
              name={"minPartySize"}
              type={"select"}
              options={getMinRange()}
              enabled={true}
              style={{ marginBottom: 0, fontSize: isInModal ? 14 : 15, borderColor: "#979797" }}
              value={form.minPartySize}
              register={null}
              onChangeValue={changeMinPartySize}
            />
          </FormGroup>
        </div>
        <div className="square-modal__select">
          <FormGroup className="square-modal__input-group">
            <div style={{ flexDirection: "row", width: "100%" }}>
              <ControlLabel className={"settings-label"}>Max party size</ControlLabel>
              <ControlLabel className={"settings-subtitle"}>The max party size that can be booked online.</ControlLabel>
            </div>
            <SettingsInput
              form={form}
              name={"resourcesCount"}
              type={"select"}
              options={getMaxRange()}
              enabled={true}
              style={{ marginBottom: 0, fontSize: isInModal ? 14 : 15, borderColor: "#979797" }}
              value={form.resourcesCount}
              register={null}
              onChangeValue={changeMaxPartySize}
            />
          </FormGroup>
        </div>
        <p className="square-modal__body-text">
          <ControlLabel className={"settings-label"}>Capacity by tables sizes</ControlLabel>
          <ControlLabel className={"settings-subtitle"}>
            Only allocate the capacity you want guest to be able to book online or from Google
          </ControlLabel>
        </p>
        <div className="square-modal__body-container">
          {!!resources?.length &&
            resources?.map((r, idx) => {
              return (
                <FormGroup
                  key={idx}
                  style={{
                    fontSize: isInModal ? 15 : 17
                  }}
                  className="square-modal__input-resources-group"
                >
                  <div className="square-modal__select flex">
                    <ControlLabel className="square-modal__select__label">{r?.name}:</ControlLabel>
                    <SettingsInput
                      name={"maxReservations"}
                      type={"select"}
                      options={simultaneousCounts}
                      enabled={true}
                      style={{ fontSize: isInModal ? 14 : 15 }}
                      className="square-modal__select__input"
                      value={r?.maxReservations}
                      register={null}
                      onChangeValue={e => changeResource("maxReservations", e.target.value, idx)}
                      hideLabel
                    />
                  </div>
                  <div className="square-modal__select flex">
                    <ControlLabel className="square-modal__select__label">Turn time:</ControlLabel>
                    <SettingsInput
                      name={"minDuration"}
                      type={"select"}
                      options={minDurationOptions}
                      enabled={true}
                      className="square-modal__select__input"
                      style={{
                        fontSize: isInModal ? 14 : 15
                      }}
                      value={r?.minDuration}
                      register={null}
                      onChangeValue={e => changeResource("minDuration", e.target.value, idx)}
                      hideLabel
                    />
                  </div>
                </FormGroup>
              );
            })}
        </div>
      </div>
      {isInModal ? (
        <>
          <p style={{ margin: isInModal ? "0 0 30px 0" : "auto 0 30px 0" }} className="square-modal__note">
            <strong>IMPORTANT</strong>: Only allocate the capacity you want guest to be able to book online or from
            Google.
          </p>

          <div className="square-modal__footer square-modal__footer--right">
            <Button
              onClick={skipStep}
              bsStyle="info"
              className="square-modal__button square-modal__button--left"
              pullRight
              type="button"
            >
              {isLastStep ? "Cancel" : "Skip"}
            </Button>
            <Button onClick={nextStep} bsStyle="info" className="square-modal__button btn-fill" pullRight type="button">
              {isLastStep ? "Finish" : "Next"}
            </Button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SquareReservationSetup;

import { SET_REGIONS } from "../actions/regions.actions";

export function regions(state = {}, action) {
  switch (action.type) {
    case SET_REGIONS:
      return { ...state, regions: action.regions };
    default:
      return state;
  }
}

import { DateTime, Interval } from "luxon";

export const getDurations = (start, end = 480) => {
  if (start === -1) {
    return [
      {
        value: -1,
        label: "All day"
      }
    ];
  }
  const step = 15;

  let hour = 0;
  let minutes = 0;
  let totalMinutes = start;
  let arr = [];

  while (totalMinutes <= end) {
    hour = Math.floor(totalMinutes / 60);
    minutes = totalMinutes - hour * 60;
    arr.push({
      value: totalMinutes,
      label: ("" + (hour ? hour + "h" : "") + (minutes ? " " + minutes + "m" : "")).trim()
    });
    totalMinutes += step;
  }
  return arr;
};

export const filterResourcesForNewReservation = (
  timezone,
  resourcesWithReservations,
  resources,
  date,
  selectedResource,
  duration,
  reservationFromForm
) => {
  const seletedTime = DateTime.fromJSDate(date).setZone(timezone);
  // const selectedResourceIdx = resourcesWithReservations.findIndex(r => r.id === selectedResource.id);
  const selectedDuration = duration === "-1" ? 0 : Number(duration);
  const selectedInterval = Interval.fromDateTimes(seletedTime, seletedTime.plus({ minutes: selectedDuration }));
  const newResourcesWithReservations = resourcesWithReservations
    .map(rwr => {
      return {
        ...rwr,
        children: rwr.children.map(child => {
          return child.map(r => {
            const reservTime = DateTime.fromJSDate(r.reservationTime.toDate()).setZone(timezone);
            const isAllDay = r.duration === -1;
            const reservEnd = reservTime.plus({ minutes: isAllDay ? 0 : r.duration });
            const interval = Interval.fromDateTimes(reservTime, reservEnd);
            const isSelectedDateInIntervalB = interval.contains(seletedTime);
            const isSelectedDateInInvervalA = selectedInterval.contains(reservTime);
            return {
              ...child,
              intersectionExist:
                reservTime.equals(seletedTime) || isSelectedDateInIntervalB || isSelectedDateInInvervalA || isAllDay
            };
          });
        })
      };
    })
    .filter(rwr => {
      const isEditing = rwr.id === reservationFromForm?.resourceId ?? false;
      const intersectionExists = rwr.children.reduce((prevExists, currChild) => {
        return prevExists + Number(currChild.some(r => r.intersectionExist));
      }, 0);
      // console.log(rwr.name, intersectionExists);
      return intersectionExists < rwr.children.length || (intersectionExists === rwr.children.length && isEditing);
    });
  return newResourcesWithReservations;
};

export const makeResourcesWithResertions = (resources, reservations, timezone) => {
  const rsrc = [...resources.map(r => ({ ...r, children: Array(r.maxReservations).fill([]) }))];

  const reservs = reservations
    .map(r => ({ id: r.id, ...r.data() }))
    .sort(
      (a, b) =>
        a.resourceId?.localeCompare(b.resourceId) ||
        DateTime.fromJSDate(a.reservationTime.toDate()).diff(DateTime.fromJSDate(b.reservationTime.toDate()))
    );

  return rsrc.map(rsr => ({
    ...rsr,
    children: rsr.children.map(() => {
      const children = [];
      let nearestReservationIdx = reservs.findIndex(r => {
        return r.resourceId === rsr.id;
      });
      while (nearestReservationIdx !== -1) {
        const prevReserv = reservs.splice(nearestReservationIdx, 1)[0];
        children.push(prevReserv);

        const isPreviousAllDay = prevReserv.duration === -1;
        const previousRStart = prevReserv.reservationTime.toDate();
        const previousRStartDateTime = DateTime.fromJSDate(previousRStart).setZone(timezone);
        const previousRInterval = Interval.fromDateTimes(
          previousRStartDateTime,
          previousRStartDateTime.plus({ minutes: isPreviousAllDay ? 0 : Number(prevReserv.duration) })
        );
        nearestReservationIdx = reservs.findIndex(r => {
          const rStartDT = DateTime.fromJSDate(r.reservationTime.toDate()).setZone(timezone);
          return (
            r.resourceId === rsr.id &&
            ((!previousRInterval.contains(rStartDT) &&
              rStartDT.startOf("hour") > previousRStartDateTime.startOf("hour") &&
              !isPreviousAllDay) ||
              (isPreviousAllDay && children.length === 0))
          );
        });
      }
      return children;
    })
  }));
};

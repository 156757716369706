import { firebase } from "../../firebase";

export async function contactSupportForUpgrade() {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/support`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify({ message: "I would like to upgrade to Waitly Professional." })
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.log("contactSupportForUpgrade response: ", response);
      }
      return;
    })
    .catch(error => {
      console.error("error triggering contactSupportForUpgrade function", error);
      throw error;
    });
}

import React from "react";
import "./footer.scss";
import { useMediaQuery } from "hooks/useMediaQuery";

const Footer = props => {
  const isTablet = useMediaQuery("(max-width: 1300px)");

  if (isTablet) {
    return <></>;
  }

  return (
    <footer className={"footer footer__nopadding"} style={{ backgroundColor: "#FFFFFF" }}>
      <div className={"container" + (props.fluid !== undefined ? "-fluid" : "")}>
        <nav className="pull-left">
          <ul>
            <li>
              <a href="https://apps.apple.com/app/apple-store/id1437729300?pt=1717824&ct=app.waitly&mt=8">
                Get the App
              </a>
            </li>
            <li>
              <a href="https://www.waitly.com/blog">Blog</a>
            </li>
          </ul>
        </nav>
        <p className="copyright pull-right">
          &copy; {1900 + new Date().getYear()} <strong>Arisota Software LLC</strong>, Waitly is a registered trademark
          of Arisota Software LLC{" "}
        </p>
      </div>
    </footer>
  );
};
export default Footer;

export const SET_SHOULD_UPDATE_USERS = "SET_SHOULD_UPDATE_USERS";
export const SET_SHOULD_UPDATE_INVITES = "SET_SHOULD_UPDATE_INVITES";

export const setShouldUpdateUsers = shouldUpdateUsers => {
  return {
    type: SET_SHOULD_UPDATE_USERS,
    shouldUpdateUsers
  };
};

export const setShouldUpdateInvites = shouldUpdateInvites => {
  return {
    type: SET_SHOULD_UPDATE_INVITES,
    shouldUpdateInvites
  };
};

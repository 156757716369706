import React, { useState } from "react";
import { Form, Row, Col, Modal, HelpBlock, FormGroup } from "react-bootstrap";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import "../AddGuest/AddGuests.scss";
import { useForm } from "react-hook-form";

function AddNoteModal(props) {
  const { showModal, handleFormClose, noteForm } = props;
  const { register, handleSubmit, errors } = useForm();
  const emptyForm = {
    note: ""
  };

  const [form, setForm] = useState({
    note: noteForm && noteForm.data() ? noteForm.data().note : ""
  });

  const onSubmit = data => {
    setForm(emptyForm);
    handleFormClose({ ...data });
  };

  const closeModal = () => {
    setForm(emptyForm);
    handleFormClose();
  };

  return (
    <Modal dialogClassName="add-guest-modal" show={showModal} onHide={closeModal}>
      <Card
        content={
          <Form noValidate className="add-guest" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="add-guest__title" md={12}>
                Add Note
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.name && "error") || null}>
                  <textarea
                    name="note"
                    className="form-control text-messages-block__textarea w-100"
                    value={props.textareaValue}
                    placeholder="Note"
                    maxLength={50}
                    autoFocus
                    defaultValue={form.note}
                    ref={register({ required: false, maxLength: 120 })}
                  ></textarea>

                  {errors.name && errors.name.type === "maxLength" && (
                    <HelpBlock>Notes can only be 120 characters</HelpBlock>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="add-guest__actions">
                  <Button onClick={closeModal} className="add-guest__cancel" bsStyle="info" pullRight type="button">
                    Cancel
                  </Button>
                  <Button bsStyle="info" pullRight fill type="submit">
                    Save Note
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      ></Card>
    </Modal>
  );
}

export default AddNoteModal;

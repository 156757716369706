import React from "react";

export const MoreIcon = ({ disabled, isMobile }: { disabled: boolean; isMobile?: boolean }) => {
  const styles = {
    width: isMobile ? 7 : 10,
    height: isMobile ? 7 : 10,
    borderRadius: "50%",
    backgroundColor: disabled ? "#ccc" : "#555"
  };

  return (
    <div>
      <div
        style={{
          ...styles
        }}
      />
      <div
        style={{
          ...styles,
          marginTop: isMobile ? 3.5 : 5,
          marginBottom: isMobile ? 3.5 : 5
        }}
      />
      <div
        style={{
          ...styles
        }}
      />
    </div>
  );
};

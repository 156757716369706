import SettingsWrapper from "common/ScrollableWrapper/SettingsWrapper";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SettingsInput from "./SettingsInput";
import toastr from "toastr";
import { getAccountById, uploadAppearanceLogo } from "services/account/account.service";
import { updateAppearance } from "services/account/account.service";
import { deleteLogoFromStorage } from "services/account/account.service";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { Link } from "react-router-dom";
const logoMaxWidth = 1000;
const logoMaxHeight = 500;
export const defaultHighlightBackgroundColor = "#002bb7";
export const defaultHighlightFontColor = "#FFF";
export const defaultFontColor = "#333";

const AppearanceSettings = ({ user, plan }) => {
  const [isAccountFree, setIsAccountFree] = useState(!plan || plan === "free");
  const { handleSubmit, errors } = useForm();
  const [form, setForm] = useState({
    logoUrl: "",
    fontColor: defaultFontColor,
    highlightFontColor: defaultHighlightFontColor,
    highlightBackgroundColor: defaultHighlightBackgroundColor
  });
  const [loading, setLoading] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const fetchAccount = async () => {
    if (!user) return;
    if (isAccountFree) {
      setForm({
        fontColor: defaultFontColor,
        highlightBackgroundColor: defaultHighlightBackgroundColor,
        highlightFontColor: defaultHighlightFontColor,
        logoUrl: ""
      });

      return;
    }

    setLoading(true);
    const { settings } = await (await getAccountById(user.accountId)).data();
    if (settings && settings.appearance)
      setForm({
        fontColor: settings.appearance.fontColor || defaultFontColor,
        highlightBackgroundColor: settings.appearance.highlightBackgroundColor || defaultHighlightBackgroundColor,
        highlightFontColor: settings.appearance.highlightFontColor || defaultHighlightFontColor,
        logoUrl: settings.appearance.logoUrl || ""
      });
    setLoading(false);
  };

  useEffect(() => {}, [form.logoUrl]);

  const uploadLogo = async logo => {
    try {
      if (user) {
        setLogoLoading(true);
        const image = new Image();
        var objectUrl = window.URL.createObjectURL(logo);
        image.src = objectUrl;
        image.onload = async function() {
          const { width, height } = this;
          if (width <= logoMaxWidth && height <= logoMaxHeight) {
            const newLogoUrl = await uploadAppearanceLogo(user.accountId, logo);
            setForm({ ...form, logoUrl: newLogoUrl });
            await updateAppearance(user.accountId, { logoUrl: newLogoUrl });
          } else {
            toastr.error(`Image width shoud be less than ${logoMaxHeight}px and height less than ${logoMaxWidth}px`);
          }

          setLogoLoading(false);
        };
      }
    } catch (e) {
      toastr.error(`Something went wrong`);
    }
  };

  useEffect(() => {
    fetchAccount();
  }, [user.accountId, plan]);

  useEffect(() => {
    setIsAccountFree(!plan || plan === "free");
  }, [plan]);

  const onSubmit = async data => {
    if (loading || !user || !data) return;
    try {
      setLoading(true);
      await updateAppearance(user.accountId, form);
      setLoading(false);
      toastr.success(`Settings updated`);
    } catch (e) {
      setLoading(false);
      toastr.error(`Something went wrong`);
    }
  };

  const onChangeFormField = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const hideDeleteAlert = () => {
    setShowDeleteAlert(false);
  };

  const clearImage = async () => {
    try {
      hideDeleteAlert();
      if (user) {
        setLogoLoading(true);
        await deleteLogoFromStorage(form.logoUrl, user.accountId);
        setForm({ ...form, logoUrl: "" });
        setLogoLoading(false);
      }
    } catch (e) {
      toastr.error(e.message || `Something went wrong`);
    }
  };

  return (
    <SettingsWrapper
      title={"Appearance"}
      handleSubmit={handleSubmit(onSubmit)}
      buttonStyles={{ maxWidth: 600 }}
      submitBtnDisabled={loading || isAccountFree}
    >
      {showDeleteAlert && (
        <SweetAlert
          showCancel
          title={"Delete Logo?"}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideDeleteAlert}
          onConfirm={clearImage}
        >
          Are you sure you want to delete your logo?
        </SweetAlert>
      )}
      {isAccountFree && (
        <div className="settings-subtitle self-check-wrapper" style={{ margin: "0 0 25px 15px" }}>
          Appearance is a Premium feature.
          <Link to="/admin/upgrade" style={{ fontWeight: "700" }}>
            Upgrade now
          </Link>
        </div>
      )}
      <Row>
        <Col md={9}>
          <SettingsInput
            enabled={!isAccountFree}
            form={form}
            value={form.logoUrl}
            subtitle={"Customers will see your logo on self check-in and waitlist screens"}
            name={"logoUrl"}
            type={"image-dropzone"}
            allowEdit={user ? !isAccountFree : false}
            label={"Logo"}
            onImageUpload={uploadLogo}
            clearImage={() => setShowDeleteAlert(true)}
            imageLoading={logoLoading}
          />
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <SettingsInput
            form={form}
            value={form.fontColor}
            enabled={!isAccountFree}
            allowEdit={user ? !isAccountFree : false}
            name={"fontColor"}
            type={"color"}
            errors={errors}
            label={"Font Color"}
            onChangeValue={onChangeFormField}
          />
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <SettingsInput
            form={form}
            value={form.highlightBackgroundColor}
            enabled={!isAccountFree}
            allowEdit={user ? !isAccountFree : false}
            name={"highlightBackgroundColor"}
            type={"color"}
            label={"Highlight Color"}
            onChangeValue={onChangeFormField}
          />
        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <SettingsInput
            form={form}
            value={form.highlightFontColor}
            enabled={!isAccountFree}
            allowEdit={user ? !isAccountFree : false}
            name={"highlightFontColor"}
            type={"color"}
            label={"Highlight Font Color"}
            onChangeValue={onChangeFormField}
          />
        </Col>
      </Row>
    </SettingsWrapper>
  );
};

export default AppearanceSettings;

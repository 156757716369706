import { firebase } from "../../firebase";

type BodyResponseItemType = {
  name: string;
  phone: string;
  email: string;
  firstVisit: Date | string;
  lastVisit: Date | string;
  totalVisits: number;
};

export async function getCustomersData({
  limit,
  page,
  orderBy,
  search
}: {
  limit: number;
  page: number;
  orderBy: { column: string; direction: "asc" | "desc" };
  search: string;
}) {
  const currentUser = firebase.auth().currentUser;
  const token = await currentUser?.getIdToken(true);

  const url = `app/customers?limit=${limit}&page=${page}&orderBy=${orderBy.column}&orderDirection=${orderBy.direction}&search=${search}`;

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<{
        items: BodyResponseItemType[];
        total: number;
        page: number;
        limit: number;
        totalPages: number;
      }>;
    })
    .catch(error => {
      console.error("error triggering getCustomersData function", error);
      throw error;
    });
}

export async function exportCustomersData() {
  const currentUser = firebase.auth().currentUser;
  const token = await currentUser?.getIdToken(true);

  const url = `/app/customers-export`;

  return fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<{ url: string }>;
    })
    .catch(error => {
      console.error("error triggering exportCustomersData function", error);
      throw error;
    });
}

export const getPreferenceColorByIndex = (index: number) => {
  switch (index) {
    case 0:
      return "#027BFE";
    case 1:
      return "#8464FF";
    case 2:
      return "#19B50A";
    case 3:
      return "#EA5E1D";
    case 4:
      return "#022BB9";
    case 5:
      return "#D12314";
    case 6:
      return "#F3689E";
    case 7:
      return "#E2C11F";
    case 8:
      return "#17C0D0";
    case 9:
      return "#23EB03";
    case 10:
      return "#EB44A5";
    case 11:
      return "#969493";
    default:
      return "";
  }
};

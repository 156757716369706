export enum WIDGET_TYPE {
  RESERVATION = "resButton",
  WAITLIST = "waitlist",
  RESERVATION_FORM = "resForm"
}

export enum WIDGET_MODE {
  TAB = "tab",
  OVERLAY = "overlay"
}

export enum WIDGET_STYLE {
  RECTANGLE = "rectangle",
  BEVELED = "beveled",
  ROUND = "round",
  CUSTOM = "custom"
}

export const WidgetTypeOptions = [
  {
    value: WIDGET_TYPE.RESERVATION_FORM,
    text: "Reservation Form"
  },
  {
    value: WIDGET_TYPE.RESERVATION,
    text: "Reservation Button"
  },
  {
    value: WIDGET_TYPE.WAITLIST,
    text: "Waitlist Button"
  }
];

export const WidgetModeOptions = [
  {
    value: WIDGET_MODE.OVERLAY,
    text: "Open in overlay"
  },
  {
    value: WIDGET_MODE.TAB,
    text: "Open in new tab"
  }
];

export const WidgetStyleOptions = [
  {
    value: WIDGET_STYLE.RECTANGLE,
    text: "Rectangle"
  },
  {
    value: WIDGET_STYLE.BEVELED,
    text: "Beveled"
  },
  {
    value: WIDGET_STYLE.ROUND,
    text: "Round"
  },
  {
    value: WIDGET_STYLE.CUSTOM,
    text: "I'll add my own style"
  }
];

export const WIDGET_STYLE_CSS = {
  [WIDGET_STYLE.RECTANGLE]: {
    borderRadius: "0px"
  },
  [WIDGET_STYLE.BEVELED]: {
    borderRadius: "5px"
  },
  [WIDGET_STYLE.ROUND]: {
    borderRadius: "24px"
  }
};

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Form, FormGroup, ControlLabel, HelpBlock } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "./RegisterPage.scss";
import BeatLoader from "react-spinners/BeatLoader";

import { forgotPassword } from "../../services/auth/signup.js";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import { logEvent } from "services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "constants/firebase";

var errorMessages = [];

errorMessages["auth/user-not-found"] = "No account with that email address.";

function ForgotPasswordPage() {
  var [cardHidden, setCardHidden] = useState(true);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setCardHidden(false);
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Grid>
      <Row>
        <Col>
          <ForgotPasswordForm hidden={cardHidden} />
        </Col>
      </Row>
    </Grid>
  );
}

function ForgotPasswordForm({ hidden }) {
  var [showConfirmation, setShowConfirmation] = useState(false);
  // errorMessages["auth/no-user-found"] = "No account with that email address.";
  const { register, handleSubmit, errors, watch, triggerValidation, formState } = useForm();
  const [authError, setAuthError] = useState(null);
  const [loading, setLoading] = useState(false);

  const email = watch("email");

  const onSubmit = async () => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await forgotPassword(email);
      setLoading(false);
      logEvent(FIREBASE_EVENTS.forgot_password);
      setShowConfirmation(true);
    } catch (error) {
      setLoading(false);
      setAuthError(errorMessages[error.code] || "There was a problem sending the forgot password email");
    }
  };

  return (
    <Form hidden={hidden} noValidate onSubmit={handleSubmit(onSubmit)} className="register-modal extra-padding">
      <Card
        hidden={hidden}
        textCenter
        title="Forgot password"
        content={
          showConfirmation ? (
            <p style={{ textAlign: "center", fontSize: "14px", color: "#009900" }}>
              We sent you an email. Please click the link in the email and follow instructions to reset your password.
            </p>
          ) : (
            <div>
              <p style={{ textAlign: "center", fontSize: "12px" }}>
                Enter your email address and we’ll send you a link to create a new password.
              </p>
              <FormGroup validationState={(!!errors.email && "error") || null}>
                <ControlLabel>EMAIL</ControlLabel>
                <input
                  name="email"
                  className="form-control"
                  type="email"
                  placeholder="Email"
                  onChange={() => {
                    formState.touched.repeatEmail
                      ? triggerValidation(["email", "repeatEmail"])
                      : triggerValidation("email");
                  }}
                  ref={register({
                    required: true,
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
                    }
                  })}
                />
                {errors.email && errors.email.type === "required" && <HelpBlock>Email is required</HelpBlock>}
                {errors.email && errors.email.type === "pattern" && <HelpBlock>Please enter a valid email</HelpBlock>}
                {errors.email && errors.email.type === "emailsNotEqual" && <HelpBlock>Emails do not match</HelpBlock>}
              </FormGroup>

              {authError && <small className="text-danger">{authError}</small>}
            </div>
          )
        }
        legend={
          !showConfirmation && (
            <div>
              <Button type="submit" bsStyle="primary" fill wd>
                {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Send email</span>}
              </Button>
              <p style={{ marginTop: "20px" }}>
                <a href="/#/auth/login-page">Back to Sign in</a>
              </p>
            </div>
          )
        }
        ftTextCenter
      />
    </Form>
  );
}

export default ForgotPasswordPage;

import { firebase } from "../../firebase";

export async function getSquareInfo() {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/square/info`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.log("response.status", response.status);
        if (response.status === 401 || response.status === 403) {
          return {};
        }
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getSquareInfo function", error);
      throw error;
    });
}

export async function updateSquareInfo(data) {
  const currentUser = firebase.auth().currentUser;
  const token = await currentUser.getIdToken(true);
  return fetch(`app/square/info`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.log("updateSquareInfo response: ", response);
      }
      return response;
    })
    .catch(error => {
      console.error("error triggering updateSquareInfo function", error);
      throw error;
    });
}

export async function disconnectSquare() {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/square/disconnect`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.log("disconnectSquare response: ", response);
        throw "error";
      }
    })
    .catch(error => {
      console.error("error triggering disconnectSquare function", error);
      throw error;
    });
}

export async function createSnippet(data) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/square/snippet/create`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(async response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.log("createSnippet response: ", response);
        const result = await response.json();
        throw result;
      }
    })
    .catch(error => {
      console.error("error triggering createSnippet function", error);
      throw error;
    });
}

export async function editSnippet(id, data) {
  const currentUser = firebase.auth().currentUser;
  const token = await currentUser.getIdToken(true);
  return fetch(`app/square/snippet/update/${id}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(async response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.log("editSnippet response: ", response);
        const result = await response.json();
        throw result;
      }
    })
    .catch(error => {
      console.error("error triggering editSnippet function", error);
      throw error;
    });
}

export async function deleteSnippet(id) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/square/snippet/delete/${id}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(async response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.log("deleteSnippet response: ", response);
        const result = await response.json();
        throw result;
      }
    })
    .catch(error => {
      console.error("error triggering deleteSnippet function", error);
      throw error;
    });
}

export async function getRedirectToSquareUrls() {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/square/redirect-urls`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.log("getRedirectToSquareUrls response: ", response);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getRedirectToSquareUrls function", error);
      throw error;
    });
}

export async function getRedirectToSquareSubscriptionUrls() {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/square/subsription-redirect-urls`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.log("getRedirectToSquareUrls response: ", response);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getRedirectToSquareUrls function", error);
      throw error;
    });
}

import { db } from "../../firebase";
import { Observable } from "rxjs";
import moment from "moment";
import { firebase } from "../../firebase";

export function getTodaysMessages(businessId, accountId) {
  return new Observable(subscriber => {
    db.collection("messages")
      .where("businessId", "==", businessId)
      .where("accountId", "==", accountId)
      .where(
        "timestamp",
        ">=",
        moment()
          .startOf("day")
          .toDate()
      )
      .where("read", "==", false)
      .where("direction", "==", "inbound")
      .orderBy("timestamp", "asc")
      .onSnapshot(result => {
        subscriber.next(result.docs);
      });
  });
}

export function getMessagesForChat(accountId, referenceId) {
  return db
    .collection("messages")
    .where("accountId", "==", accountId)
    .where("referenceId", "==", referenceId)
    .orderBy("timestamp", "asc");
}

export function markMessagesAsRead(messages) {
  if (!messages) {
    return;
  }
  messages.forEach(message => {
    if (message.data().direction === "inbound" && !message.data().read) {
      db.collection("messages")
        .doc(message.id)
        .set({ read: true }, { merge: true });
    }
  });
}

export async function sendEmail(emails, message) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);

  return fetch(`app/send-email`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify({ emails, message })
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        console.log("sendEmail response: ", response);
      }
      return;
    })
    .catch(error => {
      console.error("error triggering sendEmail function", error);
      throw error;
    });
}

import React, { useState } from "react";
import { Business } from "types/business";
import { Col, ControlLabel, FormGroup, Row, Modal, HelpBlock, Form } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton";
import { useForm } from "react-hook-form";
import Autocomplete from "react-google-autocomplete";
import { environment } from "configs/environment";
import { addOrUpdateBussinessAddress } from "services/businesses/businesses.service";
import toastr from "toastr";
import CustomButton from "components/CustomButton/CustomButton";
import "./AddLocationForGoogleModal.scss";

const AddLocationForGoogleModal = ({
  show,
  onHide,
  business,
  onSubmit,
  type
}: {
  show: boolean;
  onHide: (type: "reserve-with-google" | "get-started") => void;
  business: Business;
  onSubmit: (type: "reserve-with-google" | "get-started") => void;
  type: "reserve-with-google" | "get-started";
}) => {
  const { register, handleSubmit, errors, setError, clearError } = useForm();
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [address, setAddress] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const onAutoComplete = place => {
    // "street_address" - address1
    // "locality" - city
    // "administrative_area_level_1" - state
    // "postal_code" - postal
    const { address_components, formatted_address, geometry } = place;
    if (!address_components) return;
    if (formatted_address) setAutoCompleteValue(formatted_address);

    const address1 = address_components.find(el => el.types.includes("street_address"));
    const street_number = address_components.find(el => el.types.includes("street_number"));
    const route = address_components.find(el => el.types.includes("route"));
    const city = address_components.find(el => el.types.includes("locality"));
    const state = address_components.find(el => el.types.includes("administrative_area_level_1"));
    const postal = address_components.find(el => el.types.includes("postal_code"));
    const country = address_components.find(el => el.types.includes("country"));

    const geo = {
      lat: geometry.location?.lat?.(),
      lng: geometry.location?.lng?.()
    };

    const data = {
      address1:
        address1?.long_name || ((street_number?.long_name || "") + " " + (route?.short_name || "")).trim() || "",
      address2: address?.address2 || "",
      country: country?.short_name || "",
      city: city?.long_name || "",
      state: state?.short_name || state?.long_name || "",
      postal: postal?.long_name || "",
      geo
    };

    if (!data || !data.address1 || !data.city || !data.state || !data.postal || !data.country || !data.geo?.lat) {
      setError("address", "required");
      setIsAddressValid(false);
      setAddress(null);
      return;
    } else {
      clearError("address");
      setAddress(data);
      setIsAddressValid(true);
    }
  };

  // Also prevent form submiting when press "Enter", because pressing also trigger onAutocomplete
  // and form submitting 2 times
  // it doesn't affect on other components
  const checkKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const onSaveClick = async () => {
    setLoading(true);
    try {
      const res = await addOrUpdateBussinessAddress(business.businessId, address);
      if (res.success) {
        onSubmit(type);
      } else {
        throw new Error(res.error || "Something went wrong");
      }
    } catch (e) {
      toastr.error((e as any).message, "Address didn't updated");
    }
    setLoading(false);
  };

  return (
    <Modal dialogClassName="google-address-modal" show={show} onHide={() => onHide(type)}>
      <CustomButton className="google-address-modal__close-btn" simple onClick={() => onHide(type)}>
        <i className="fa fa-times" />
      </CustomButton>
      <div className="d-flex flex-column align-items-center justify-content-center pl-2 pr-2">
        <div className="h3">{type === "get-started" ? "Let's add your address" : "We need your address"}</div>
        <div className="h5 text-center">
          {type === "get-started"
            ? "We need your address to show some maps in reservation confirmations as well as set up with Google."
            : "This let's Google know your business is using Waitly."}
        </div>
        <Form noValidate className="add-guest" onSubmit={handleSubmit(() => onSubmit(type))} onKeyDown={checkKeyDown}>
          <Row>
            <Col md={12}>
              <FormGroup validationState={(!!errors.address && "error") || null}>
                <ControlLabel>Address</ControlLabel>
                <div className="settings-input-wrapper">
                  <Autocomplete
                    apiKey={environment.googleMapsKey}
                    onPlaceSelected={onAutoComplete}
                    className="form-control"
                    language="en"
                    options={{
                      strictBounds: true,
                      componentRestrictions: {
                        country: ["us", "ca"]
                      },
                      types: ["address"],
                      fields: ["address_components", "formatted_address", "geometry"]
                    }}
                    onChange={(e: any) => setAutoCompleteValue(e.target.value)}
                    value={autoCompleteValue}
                    disabled={loading}
                    placeholder="Enter address"
                  />
                  {/* need this input without display for correct react-hook-form validation */}
                  <input
                    name="address"
                    type="text"
                    onChange={() => null}
                    value={isAddressValid ? "address" : ""}
                    style={{ display: "none" }}
                    ref={register({ required: true })}
                  />
                </div>
                {address && (
                  <div>
                    {address.address1 && <div>{address.address1}</div>}
                    {address.address2 && <div>{address.address2}</div>}
                    {address.city && address.state && address.postal && (
                      <div>
                        {address.city}, {address.state} {address.postal}
                      </div>
                    )}
                  </div>
                )}
                {errors.address && (errors.address as any).type === "required" && (
                  <HelpBlock>Address is required</HelpBlock>
                )}
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="d-flex gap-2 justify-content-end pb-2 pr-2 mt-3 buttons-wrapper">
        <Button type="button" bsStyle="info" onClick={() => onHide(type)}>
          Cancel
        </Button>
        <Button type="button" fill bsStyle="info" onClick={onSaveClick}>
          {type === "get-started" ? "Next" : "Save"}
        </Button>
      </div>
      {type === "reserve-with-google" && (
        <div className="pb-2 pl-2 pr-2">It make take Google up to 48 hours to make reservations available.</div>
      )}
    </Modal>
  );
};

export default AddLocationForGoogleModal;

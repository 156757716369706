import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import "./Chat.scss";
import Button from "components/CustomButton/CustomButton";
import SendIcon from "@material-ui/icons/Send";
import Fade from "react-reveal/Fade";
import ScrollToBottom from "react-scroll-to-bottom";
import { logEvent } from "../../services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "../../constants/firebase";
import WarningIcon from "@material-ui/icons/Warning";
import { getMessagesForChat } from "services/messages/messages.service";
import ChatMessageAge from "./ChatMessageAge";
import SweetAlert from "react-bootstrap-sweetalert";
import { chatMessagesKeywords, upgradeInChatMessage, upgradeInChatMessageSecond } from "constants/messages";
import { isGracePeriodAfterNow } from "constants/verification";
import { PHONE_CONFIG_STATUSES } from "constants/verification";
import { VERIFICATION_MODES } from "constants/verification";
import { useSelector } from "react-redux";
import Filter from "bad-words";

function ChatModal(props) {
  const divRef = useRef(null);
  const { chat, handleChatClose, sendMessage, error, type, accountId, plan } = props;
  const { verificationMode } = useSelector(state => state.config || {});
  const { account } = useSelector(state => state.account || {});
  const [showQuickResponses, setShowQuickResponses] = useState(false);
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState("");
  const [messageError, setMessageError] = useState("");
  const [currentMessages, setCurrentMessages] = useState([]);
  const [verifyAlert, setVerifyAlert] = useState(false);
  const [upgradeMessage, setUpgradeMessage] = useState(null);
  const quickResponses = ["OK", "Yes", "No", "Thank you"];
  const closeModal = () => {
    handleChatClose();
  };

  useEffect(() => {
    const unsubscribe = getMessagesForChat(accountId, chat.id).onSnapshot(result => {
      setMessages(result.docs);
      if (plan === "free" && hasInBoundMessages(result.docs)) {
        setUpgradeMessage(upgradeInChatMessage(chat.data().name));
      }
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  const hasInBoundMessages = messages => {
    for (let message of messages) {
      console.log("mesages", message);
      if (message.data().direction === "inbound") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const formattedMessages = formatMessages(messages);
    setCurrentMessages(formattedMessages);
    if (
      formattedMessages &&
      formattedMessages.length &&
      formattedMessages[formattedMessages.length - 1].direction === "inbound" &&
      plan !== "free"
    ) {
      setShowQuickResponses(true);
    }
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [messages]);

  const formatMessages = messages => {
    return (messages || [])
      .filter(message => message.data().referenceId === chat.id)
      .map(message => {
        return {
          text: message.data().message,
          status: message.data().status,
          direction: message.data().direction,
          date: message.data().timestamp.toDate()
        };
      });
  };

  const displayCustomMessage = () => {
    setShowQuickResponses(false);
  };

  const sendMessageClick = (message, isQuickResponse) => {
    if (plan === "free") {
      setUpgradeMessage(upgradeInChatMessageSecond(chat.data().name));
      return;
    }
    if (messageError || !message) {
      return;
    }
    if (isQuickResponse) {
      setShowQuickResponses(false);
      logEvent(FIREBASE_EVENTS.message_quick_response);
    } else {
      logEvent(FIREBASE_EVENTS.message_custom);
    }
    sendMessage(chat.id, message, type);
    setCurrentMessages([
      ...currentMessages,
      {
        text: message,
        status: "sent",
        direction: "outbound",
        date: new Date()
      }
    ]);
    setMessage("");
    if (divRef.current) {
      divRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const messageChanged = message => {
    const filter = new Filter();
    setMessageError("");
    if (/[^0-9a-zA-Z ,.':/!?@#$%^&*()-]/.test(message)) {
      setMessageError("Message should contain only alphanumeric characters");
    } else if (
      new RegExp(
        "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
      ).test(message)
    ) {
      setMessageError("Message cannot contain URL");
    } else if (message.length > 160) {
      setMessageError("Message length should not exceed 160 characters");
    } else if (filter.isProfane(message)) {
      setMessageError("Message contains profane language");
    }
    setMessage(message);
  };

  const isLastMessageInbound = () => {
    return (
      currentMessages && currentMessages.length && currentMessages[currentMessages.length - 1].direction === "inbound"
    );
  };

  const hideConfirmAlert = () => {
    setUpgradeMessage(null);
  };

  const onGoToUpgrade = () => {
    hideConfirmAlert();
    let url = document.URL.split("#")[0] + "#/admin/upgrade";
    window.location.replace(url);
  };

  const onReturnInboundMessageText = messageText => {
    if (plan !== "free") {
      return messageText;
    } else if (
      plan === "free" &&
      chatMessagesKeywords.find(keyword => keyword.toLocaleLowerCase() === messageText.toLowerCase())
    ) {
      return messageText;
    } else {
      let result = "";
      messageText.split("").forEach(key => {
        if (key === " ") {
          result += "   ";
        } else {
          result += "\u25A0";
        }
      });
      return result;
    }
  };

  const onCheckIfUpgradeNeed = () => {
    if (plan === "free") {
      setUpgradeMessage(upgradeInChatMessageSecond(chat.data().name));
    }
  };

  useEffect(() => {
    if (
      account?.plan !== "free" &&
      !(
        account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.VERIFIED ||
        account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.SUBMITTED ||
        account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.PENDING
      ) &&
      verificationMode === VERIFICATION_MODES.ON &&
      !isGracePeriodAfterNow(account?.phoneConfig) &&
      !document.URL.includes("action=verify")
    ) {
      setVerifyAlert(true);
    }
  }, []);

  const hideVerifyAlert = () => {
    setVerifyAlert(false);
    closeModal();
  };

  const showVerifyBusinessModal = () => {
    setVerifyAlert(false);
    window.location.replace("#" + document.URL.split("#")[1] + "?action=verify");
  };

  return (
    <>
      {verifyAlert && (
        <SweetAlert
          showCancel
          title={"Business Verification Required"}
          cancelBtnText="Cancel"
          confirmBtnText="Verify Now"
          onCancel={hideVerifyAlert}
          onConfirm={showVerifyBusinessModal}
        >
          In order to send sms messages, you'll need to verify your business.
        </SweetAlert>
      )}
      <Modal dialogClassName="add-guest-modal" show={!!chat && !verifyAlert} onHide={closeModal}>
        {upgradeMessage && (
          <SweetAlert
            showCancel
            title={upgradeMessage.title}
            cancelBtnText="Cancel"
            confirmBtnText="Upgrade"
            onCancel={hideConfirmAlert}
            onConfirm={onGoToUpgrade}
          >
            {upgradeMessage.message}
          </SweetAlert>
        )}
        <div className="chat">
          <div className="chat__header">To: {chat.data().name}</div>
          <ScrollToBottom className="chat__body">
            {currentMessages.map((message, index) => {
              return message.direction === "inbound" ? (
                <Fade key={index}>
                  <div className="chat__message chat__message--from">
                    <div className="chat__message-text chat__message-text--from">
                      {onReturnInboundMessageText(message.text)}
                    </div>
                    <ChatMessageAge date={message.date} />
                  </div>
                </Fade>
              ) : (
                <Fade key={index}>
                  <div className="chat__message chat__message--to">
                    <div className="chat__message-text chat__message-text--to">{message.text}</div>
                    {message.status !== "undelivered" && <ChatMessageAge date={message.date} />}
                    {message.status === "undelivered" && <div className="chat__error">Undelivered</div>}
                  </div>
                </Fade>
              );
            })}
          </ScrollToBottom>
          <div className="footer">
            {!showQuickResponses && !error ? (
              <div className="custom-message">
                <input
                  className="form-control custom-message__input"
                  name="name"
                  type="text"
                  placeholder="Text Message"
                  autoFocus
                  value={message}
                  onClick={onCheckIfUpgradeNeed}
                  onChange={e => messageChanged(e.target.value)}
                />
                <SendIcon disabled={messageError} onClick={sendMessageClick.bind(this, message, false)} />
              </div>
            ) : isLastMessageInbound() && !error ? (
              <div className="quick-responses">
                <div className="quick-responses__title">Quick Responses</div>
                <div className="quick-responses__list">
                  {quickResponses.map((qr, i) => {
                    return (
                      <div key={i} className="quick-responses__item" onClick={sendMessageClick.bind(this, qr, true)}>
                        {qr}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : error ? (
              <div className="quick-responses">
                <div className="flex">
                  <WarningIcon className="warning-icon" />
                  <div className="quick-responses__title">{error.title}</div>
                </div>
                <div className="error-message">{error.message}</div>
              </div>
            ) : null}
            {messageError ? <div className="chat__error">{messageError}</div> : null}
            <div className="footer__actions">
              {showQuickResponses && !error ? (
                <div className="footer__custom-message" onClick={displayCustomMessage.bind(this)}>
                  custom message
                </div>
              ) : null}

              <Button bsStyle="primary" onClick={closeModal.bind(this)}>
                Close
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ChatModal;

import React, { useEffect, useState } from "react";
import SettingsTitle from "./SettingsTitle";
import RegularTable from "../../views/RegularTable";
import {
  HEADERS,
  ACTIONS,
  INVITE_DATE_HEADER,
  INVITE_DATE_FORMAT
} from "../../constants/tables/invites-settings-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteMessage, resendMessage } from "../../constants/messages";
import toastr from "toastr";
import BeatLoader from "react-spinners/BeatLoader";
import Button from "../CustomButton/CustomButton";
import * as _ from "lodash";
import AddUser from "components/AddUser/AddUser";
import moment from "moment";
import { getPendingInvites, resendInvite, deleteInvite } from "../../services/invites/invites.service";
import { ROlES } from "../../constants/settings";
import { useDispatch, useSelector } from "react-redux";
import { setShouldUpdateInvites } from "store/actions/users.actions";

let inviteForDelete = null;
let inviteForResend = null;

const InvitesSettings = ({ business }) => {
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showResendMessage, setShowResendMessage] = useState(false);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [invites, setInvites] = useState([]);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [orderBy, setOrderBy] = useState({ column: "name", direction: "asc" });
  const shouldUpdateInvites = useSelector(state => state.users.shouldUpdateInvites);
  const dispatch = useDispatch();

  const deleteInviteMessage = deleteMessage("invite");
  const resendInviteMessage = resendMessage("invite");

  const hideInfoAlert = () => {
    setShowDeleteMessage(false);
    setShowResendMessage(false);
  };

  useEffect(() => {
    onGetInvites(business.accountId);
  }, [business]);

  useEffect(() => {
    if (shouldUpdateInvites) {
      onGetInvites(business.accountId);
      dispatch(setShouldUpdateInvites(false));
    }
  }, [dispatch, shouldUpdateInvites]);

  const onGetInvites = async accountId => {
    setInviteLoading(true);
    try {
      const invites = await getPendingInvites(accountId);
      invites.length &&
        invites.sort((a, b) =>
          a.lastName.toLowerCase() > b.lastName.toLowerCase()
            ? 1
            : b.lastName.toLowerCase() > a.lastName.toLowerCase()
            ? -1
            : 0
        );
      setInvites(invites);
    } catch (error) {
      console.error(error);
    }
    setInviteLoading(false);
  };

  const onShowDeleteInviteMessage = invite => {
    inviteForDelete = invites.find(i => i.email === invite.email);
    setShowDeleteMessage(true);
  };

  const onShowResendInviteMessage = invite => {
    inviteForResend = invites.find(i => i.email === invite.email);
    setShowResendMessage(true);
  };

  const onDeleteInvite = async () => {
    setModalLoading(true);
    try {
      await deleteInvite(inviteForDelete.id);
      await onGetInvites(business.accountId);
    } catch (error) {
      setShowDeleteMessage(false);
      toastr.error(error.message);
      setModalLoading(false);
      return;
    }
    setShowDeleteMessage(false);
    setModalLoading(false);
    toastr.success("Invite deleted!");
  };

  const onResendInvite = async () => {
    setModalLoading(true);
    const data = {
      inviteId: inviteForResend.id,
      email: inviteForResend.email,
      accountId: business.accountId
    };
    try {
      await resendInvite(data);
    } catch (error) {
      setShowResendMessage(false);
      toastr.error(error.message);
      setModalLoading(false);
      return;
    }
    setModalLoading(false);
    setShowResendMessage(false);
    toastr.success("Invite sent");
  };

  const onCloseModal = () => {
    setShowInviteUserModal(false);
  };

  const onGetInvitesForTable = () => {
    let sortBy = orderBy.column.split(" ");
    if (invites.length > 0) {
      const values = _.chain(
        invites.map(invite => {
          return {
            name: `${invite.firstName} ${invite.lastName}`,
            email: invite.email,
            inviteDate: moment(invite.createDate.toDate()).format(INVITE_DATE_FORMAT),
            role: ROlES.find(role => role.role === invite.role)?.name || "User"
          };
        })
      )
        .sortBy(invite => invite[sortBy[1] || sortBy[0]])
        .value();
      if (orderBy.column === INVITE_DATE_HEADER)
        values.sort(
          (a, b) =>
            moment(b.inviteDate, INVITE_DATE_FORMAT).toDate() - moment(a.inviteDate, INVITE_DATE_FORMAT).toDate()
        );
      return orderBy.direction === "desc" ? values.reverse() : values;
    } else {
      return [];
    }
  };

  const onShowInviteUserModal = () => {
    setShowInviteUserModal(true);
  };

  const onRequestSort = (event, column) => {
    if (column === orderBy.column) {
      setOrderBy({ column, direction: orderBy.direction === "asc" ? "desc" : "asc" });
    } else {
      setOrderBy({ column, direction: "desc" });
    }
  };

  return (
    <>
      {showResendMessage && (
        <SweetAlert
          showCancel
          title={resendInviteMessage.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideInfoAlert}
          onConfirm={onResendInvite}
        >
          {resendInviteMessage.message}
          {modalLoading && <BeatLoader margin={2.5} size={10} color={"#016BFF"} />}
        </SweetAlert>
      )}
      {showDeleteMessage && (
        <SweetAlert
          showCancel
          title={deleteInviteMessage.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideInfoAlert}
          onConfirm={onDeleteInvite}
        >
          {deleteInviteMessage.message}
          {modalLoading && <BeatLoader margin={2.5} size={10} color={"#016BFF"} />}
        </SweetAlert>
      )}
      {showInviteUserModal && (
        <AddUser
          showModal={showInviteUserModal}
          closeModal={onCloseModal}
          onInviteSubmit={() => onGetInvites(business.accountId)}
        />
      )}
      <SettingsTitle title={"User Invites"} />
      <Button
        className="invite-guest-button"
        bsStyle="info"
        onClick={onShowInviteUserModal}
        pullRight
        fill
        type="button"
      >
        Invite User
      </Button>
      {!inviteLoading ? (
        <RegularTable
          rows={onGetInvitesForTable()}
          deleteAction={onShowDeleteInviteMessage}
          resendAction={onShowResendInviteMessage}
          tableHeaders={HEADERS}
          actions={ACTIONS}
          showSorting={true}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          hideAction={() => null}
          emptyRowsMessage="There are no pending invites."
        />
      ) : (
        <div className="loader-wrapper">
          <BeatLoader margin={2.5} size={10} color={"#016BFF"} />
        </div>
      )}
    </>
  );
};

export default InvitesSettings;

import React from "react";
import { Row, Col, HelpBlock, FormGroup, ControlLabel } from "react-bootstrap";
import InputMask from "react-input-mask";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  DEFAULT_CUSTOMIZABLE_FIELDS_ORDER,
  DEFAULT_CUSTOMIZABLE_INPUT_FIELDS
} from "constants/customizable-input-fields";
import { Business } from "types/business";
import {
  CustomizableInputFieldOption,
  CustomizableInputFieldType,
  CustomizableInputFieldsKeys
} from "types/customizable-input-fields";
import { NestDataObject } from "react-hook-form";
import { EMAIL_PATTERN } from "constants/patterns";

export const getInputFields = (business: Business, type: "waitlist" | "reservations" | "selfCheckIn") => {
  let fieldOrder = DEFAULT_CUSTOMIZABLE_FIELDS_ORDER;
  let fields = DEFAULT_CUSTOMIZABLE_INPUT_FIELDS;
  if (business?.settings?.fields?.fieldOrder) {
    const { fieldOrder: _fieldOrder, ..._fields } = business.settings.fields;
    (fieldOrder = _fieldOrder), (fields = _fields);
  }

  const parsedFieldOrder = (fieldOrder.map(field => field.split(",")) as CustomizableInputFieldsKeys[][])
    .map(field =>
      field
        .filter(
          f =>
            fields[f]?.enabled &&
            !!fields[f]?.[type] &&
            (fields[f]?.[type] === CustomizableInputFieldOption.REQUIRED ||
              fields[f]?.[type] === CustomizableInputFieldOption.OPTIONAL) &&
            (f === CustomizableInputFieldType.SERVICE ? !!business?.settings?.services?.length : true)
        )
        .map(f => ({
          name: f,
          validation: fields[f]?.[type]
        }))
    )
    .filter(field => field.length);

  return parsedFieldOrder;
};

const formatPhone = phone => {
  if (isOnlyMaskPresent(phone)) {
    return "";
  }

  return phone?.replace(/[-_()\s]/gi, "").slice(0, 12);
};

const isOnlyMaskPresent = phone => {
  return phone === "+1 (___) ___-____";
};

const validatePhoneNumber = value => {
  if (!value || isOnlyMaskPresent(value)) {
    return true;
  }

  const phoneValue = formatPhone(value);
  const phone = parsePhoneNumberFromString(phoneValue || "");
  return !!phone && phone.isValid() && (phone.country === "US" || phone.country === "CA");
};

const formFunctions: (props: {
  errors;
  register;
  form;
  business: Business;
  setForm;
  isLastField?: boolean;
}) => {
  [key in CustomizableInputFieldsKeys]?: (validation?: CustomizableInputFieldOption) => React.ReactElement;
} = ({ errors, register, form, business, setForm, isLastField }) => ({
  name: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup validationState={(!!errors.name && "error") || null}>
        <ControlLabel>NAME</ControlLabel>
        <input
          name="name"
          className="form-control"
          type="text"
          placeholder="Enter name"
          autoFocus
          defaultValue={form.name}
          enterKeyHint={isLastField ? "done" : "next"}
          ref={register({ required: validation === CustomizableInputFieldOption.REQUIRED, maxLength: 30 })}
        />
        {errors.name && errors.name.type === "required" && <HelpBlock>Name is required</HelpBlock>}
        {errors.name && errors.name.type === "maxLength" && (
          <HelpBlock>Name length should be less than 30 characters</HelpBlock>
        )}
      </FormGroup>
    );
  },
  orderNumber: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup validationState={(!!errors.orderNumber && "error") || null}>
        <ControlLabel>ORDER NO.</ControlLabel>
        <input
          name="orderNumber"
          className="form-control"
          type="text"
          placeholder="Enter Order no."
          inputMode="numeric"
          enterKeyHint={isLastField ? "done" : "next"}
          defaultValue={form.orderNumber}
          ref={register({ required: validation === CustomizableInputFieldOption.REQUIRED, maxLength: 30 })}
        />
        {errors.orderNumber && errors.orderNumber.type === "maxLength" && (
          <HelpBlock>Order no. length should be less than 30 characters</HelpBlock>
        )}
        {errors.orderNumber?.type === "required" && <HelpBlock>Order no. is required</HelpBlock>}
      </FormGroup>
    );
  },
  partySize: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup validationState={(!!errors.partySize && "error") || null}>
        <ControlLabel>PARTY SIZE</ControlLabel>
        <input
          className="form-control"
          name="partySize"
          type="number"
          inputMode="numeric"
          enterKeyHint={isLastField ? "done" : "next"}
          placeholder="Enter size"
          defaultValue={form.partySize}
          ref={register({ min: 1, max: 999, required: validation === CustomizableInputFieldOption.REQUIRED })}
          onChange={e => setForm(prev => ({ ...prev, partySize: Number(e.target.value) }))}
        />
        {errors.partySize && errors.partySize.type === "min" && (
          <HelpBlock>Party size should be an integer greater 0</HelpBlock>
        )}
        {errors.partySize && errors.partySize.type === "max" && (
          <HelpBlock>Max 3 digits allowed for party size</HelpBlock>
        )}
        {errors.partySize && errors.partySize.type === "required" && <HelpBlock>Party size is required</HelpBlock>}
      </FormGroup>
    );
  },
  quotedTime: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup validationState={(!!errors.quotedTime && "error") || null}>
        <ControlLabel>ESTIMATED WAIT TIME</ControlLabel>
        <input
          name="quotedTime"
          className="form-control"
          type="number"
          inputMode="numeric"
          enterKeyHint={isLastField ? "done" : "next"}
          placeholder="Enter minutes"
          defaultValue={form.quotedTime}
          ref={register({ required: validation === CustomizableInputFieldOption.REQUIRED, max: 99 })}
        />
        {errors.quotedTime && errors.quotedTime.type === "required" && (
          <HelpBlock>Estimated Wait is required</HelpBlock>
        )}
        {errors.quotedTime && errors.quotedTime.type === "max" && (
          <HelpBlock>Max 2 digits allowed for Estimated Wait</HelpBlock>
        )}
      </FormGroup>
    );
  },
  phoneNumber: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup validationState={(!!errors.phone && "error") || null}>
        <ControlLabel>PHONE NUMBER</ControlLabel>
        <InputMask
          name="phone"
          className="form-control"
          type="text"
          placeholder="Enter phone"
          defaultValue={form.phone}
          inputMode="numeric"
          enterKeyHint={isLastField ? "done" : "next"}
          ref={register({
            required: validation === CustomizableInputFieldOption.REQUIRED,
            validate: value => validatePhoneNumber(value)
          })}
          mask="+1 (999) 999-9999"
        />
        {errors.phone?.type === "validate" && <HelpBlock>Invalid phone number</HelpBlock>}
        {errors.phone?.type === "required" && <HelpBlock>Phone number is required</HelpBlock>}
      </FormGroup>
    );
  },
  service: (validation?: CustomizableInputFieldOption) => {
    if (business.settings && business.settings.services && business.settings.services.length) {
      return (
        <FormGroup validationState={(!!errors.service && "error") || null}>
          <ControlLabel>SERVICE</ControlLabel>
          <select
            className="form-control"
            name="service"
            ref={register({ required: validation === CustomizableInputFieldOption.REQUIRED })}
            defaultValue={form.service || ""}
          >
            <option value="">Select Service</option>
            {business.settings.services.map((service, index) => {
              return (
                <option key={index} value={service.name}>
                  {service.name}
                </option>
              );
            })}
          </select>
          {errors.service?.type === "required" && <HelpBlock>Service is required</HelpBlock>}
        </FormGroup>
      );
    } else {
      return <></>;
    }
  },
  email: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup validationState={(!!errors.email && "error") || null}>
        <ControlLabel>EMAIL</ControlLabel>
        <input
          name="email"
          className="form-control"
          type="email"
          placeholder="Enter email"
          defaultValue={form.email}
          enterKeyHint={isLastField ? "done" : "next"}
          ref={register({
            required: validation === CustomizableInputFieldOption.REQUIRED,
            pattern: {
              value: EMAIL_PATTERN,
              message: "Please enter a valid email"
            }
          })}
        />
        {errors.email?.type === "required" && <HelpBlock>Email is required</HelpBlock>}
        {errors.email?.type === "pattern" && <HelpBlock>{errors.email.message}</HelpBlock>}
      </FormGroup>
    );
  },
  note: (validation?: CustomizableInputFieldOption) => {
    return (
      <FormGroup validationState={(!!errors.note && "error") || null}>
        <ControlLabel>NOTES</ControlLabel>
        <textarea
          name="note"
          className="form-control"
          placeholder="Enter notes"
          defaultValue={form.note}
          ref={register({ required: validation === CustomizableInputFieldOption.REQUIRED })}
        />
        {errors.note?.type === "required" && <HelpBlock>Note is required</HelpBlock>}
      </FormGroup>
    );
  }
});

export const BuildCusmomizableInputsForm = <T,>({
  business,
  form,
  register,
  errors,
  setForm,
  type,
  alwaysNextBtn
}: {
  business: Business;
  form: T;
  register;
  errors: NestDataObject<T>;
  setForm: React.Dispatch<React.SetStateAction<T>>;
  type: "waitlist" | "reservations" | "selfCheckIn";
  alwaysNextBtn?: boolean;
}) => {
  const fields = getInputFields(business, type);
  return (
    <>
      {fields.map((field, idx) => {
        const subFields = field;
        const isLastField = alwaysNextBtn ? false : idx === fields.length - 1;
        if (subFields.length === 2) {
          return (
            <Row key={"waitlist-field-" + idx}>
              <Col md={6}>
                {formFunctions({ form, register, errors, business, setForm })?.[subFields[0].name]?.(
                  subFields[0].validation
                )}
              </Col>
              <Col md={6}>
                {formFunctions({ form, register, errors, business, setForm, isLastField })?.[subFields[1].name]?.(
                  subFields[1].validation
                )}
              </Col>
            </Row>
          );
        } else {
          return (
            <Row key={"waitlist-field-" + idx}>
              <Col md={12}>
                {formFunctions({ form, register, errors, business, setForm, isLastField })?.[subFields[0].name]?.(
                  subFields[0].validation
                )}
              </Col>
            </Row>
          );
        }
      })}
    </>
  );
};

import React, { useState } from "react";
import { FormGroup } from "react-bootstrap";
import toastr from "toastr";
import { updateBusinessSettings } from "../../services/profile/profile.service";
import Button from "../CustomButton/CustomButton";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import RegularTable from "../../views/RegularTable";
import { ACTIONS, HEADERS } from "../../constants/tables/categories-settings-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteMessage } from "../../constants/messages";
import AddCategory from "../AddCategory/AddCategory";
import SettingsTitle from "./SettingsTitle";

const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  }
}));

const CategorySettings = ({ settings, businessId, ...props }) => {
  const classes = useStyles();
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const deleteCategoryMessage = deleteMessage("preference");

  const hideInfoAlert = () => {
    setShowDeleteMessage(false);
  };

  const onShowCategoryModal = event => {
    if (settings && settings.services && settings.services.length === 6) {
      setAnchorEl(event.currentTarget);
      return;
    }
    setShowCategoryModal(true);
  };

  const onEditCategory = category => {
    setSelectedCategory(category);
    setShowCategoryModal(true);
  };

  const onShowDeleteCategoryMessage = category => {
    setSelectedCategory(category);
    setShowDeleteMessage(true);
  };

  const onDeleteCategory = async () => {
    setShowDeleteMessage(false);
    let newServices = [...settings.services];
    let filteredServices = newServices.filter(service => {
      return service.name !== selectedCategory.name;
    });

    await updateBusinessSettings(businessId, {
      services: filteredServices
    });
    toastr.success("Settings updated");
    setSelectedCategory(null);
  };

  const onCloseModal = () => {
    setSelectedCategory(null);
    setShowCategoryModal(false);
  };

  const onHideDeleteAction = () => {
    return false;
  };

  const onGetCategoriesForTable = () => {
    if (settings.services && settings.services.length > 0) {
      return settings.services.map(setting => {
        return {
          name: setting.name,
          welcomeText: setting.welcomeText,
          notificationText: setting.notificationText
        };
      });
    } else {
      return [];
    }
  };

  return (
    <>
      {showDeleteMessage && (
        <SweetAlert
          showCancel
          title={deleteCategoryMessage.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideInfoAlert}
          onConfirm={onDeleteCategory}
        >
          {deleteCategoryMessage.message}
        </SweetAlert>
      )}
      {showCategoryModal && (
        <AddCategory
          businessId={businessId}
          showAddCategoryModal={showCategoryModal}
          closeModal={onCloseModal}
          services={settings.services || []}
          categoryForEdit={selectedCategory}
        />
      )}

      <SettingsTitle title={`Guest Preferences ${props.businessName ? " - " + props.businessName : ""}`} />
      <Button className="invite-guest-button" onClick={onShowCategoryModal} bsStyle="info" pullRight fill type="button">
        Add Preference
      </Button>
      <div style={{ maxWidth: "100%" }}>
        <FormGroup className="flex settings-form-group" style={{ alignItems: "center", maxWidth: "98%" }}>
          <Popover
            id={anchorEl ? "simple-popover" : undefined}
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => {
              setAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
          >
            <Typography className={classes.typography}>You can only have 6 categories</Typography>
          </Popover>
        </FormGroup>
      </div>
      {settings.services && settings.services.length > 0 ? (
        <RegularTable
          rows={onGetCategoriesForTable()}
          editAction={onEditCategory}
          deleteAction={onShowDeleteCategoryMessage}
          tableHeaders={HEADERS}
          actions={ACTIONS}
          hideAction={onHideDeleteAction}
        />
      ) : (
        <div className="waitlist-empty">You currently have no preferences set up.</div>
      )}
    </>
  );
};

export default CategorySettings;

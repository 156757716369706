import React, { useEffect, useRef, useState } from "react";
import "./DailyReservationsTable.scss";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import momentPlugin from "@fullcalendar/moment-timezone"; // needed for dayClick
import moment from "moment-timezone";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { DateTime } from "luxon";

const licenseKey = "0703169169-fcs-1713021308";

function DailyReservationsTimeline({ scrollTime, setScrollTime, ...props }) {
  const { reservations, filter, handleEdit, date, resourcesWithReservations } = props;
  const [timelineEvents, setTimelineEvents] = useState([]);
  const [timelineResources, setTimelineResources] = useState([]);
  const timelineRef = useRef(null);
  const [showNoResourcesModal, setShowNoResourcesModal] = useState(false);

  moment.tz.setDefault(props.timezone);

  useEffect(() => {
    setScrollTime(DateTime.now().setZone(props.timezone));
  }, []);

  const handleTimelineEventClick = async eventInfo => {
    if (props.disabled) return;
    const reservation = reservations.find(r => r.id === eventInfo.event.id);
    await handleEdit(reservation);
    setScrollTime(DateTime.fromJSDate(eventInfo.date).setZone(props.timezone));
  };

  const handleTimelineDateClick = async eventInfo => {
    if (props.disabled) return;
    let resourceId = eventInfo.resource.id;
    const seperatorIndex = resourceId.indexOf("_");
    if (seperatorIndex > -1 && resourceId) {
      resourceId = resourceId.slice(0, seperatorIndex);
    }
    await props.addReservation(eventInfo.date, resourceId);
    setScrollTime(DateTime.fromJSDate(eventInfo.date).setZone(props.timezone));
  };

  useEffect(() => {
    if (!resourcesWithReservations || !resourcesWithReservations.length) return;

    const events = resourcesWithReservations.map(resource => {
      if (!resource.children || !resource.children.length) return;

      let isInGroup = false;
      if (resource.children.length > 1) {
        isInGroup = true;
      }
      return resource.children.map((child, idx) => {
        return child.map(r => {
          const reservationStart = r.reservationTime.toDate();
          const reservEvent = {
            id: r.id,
            title: r.name,
            resourceId: r.resourceId,
            start: reservationStart,
            end:
              r.duration === -1
                ? moment(reservationStart)
                    .add(1, "day")
                    .toDate()
                : moment(reservationStart)
                    .add(r.duration, "minutes")
                    .toDate(),
            allDay: r.duration === -1
          };

          if (isInGroup) {
            reservEvent.resourceId = `${reservEvent.resourceId}_${idx + 1}`;
          }

          return reservEvent;
        });
      });
    });

    setTimelineEvents(events.flat(2));
  }, [resourcesWithReservations]);

  useEffect(() => {
    const resources = filter?.resources?.map((r, idx) => {
      const resource = {
        id: r.id,
        title: r.name,
        order: idx
      };
      let index = 0;
      if (r.maxReservations && r.maxReservations > 1) {
        resource.children = Array(r.maxReservations)
          .fill(null)
          .map((_, idx) => ({
            id: `${r.id}_${++index}`,
            title: `${r.name} ${idx + 1}`,
            order: idx + 1
          }));
      }

      return resource;
    });
    setTimelineResources(resources);
  }, [filter.resources]);

  useEffect(() => {
    if (scrollTime) {
      const calendarApi = timelineRef.current.getApi();
      calendarApi?.scrollToTime(scrollTime.toFormat("HH:mm:ss"));
    }
  }, [scrollTime]);

  useEffect(() => {
    if (date && timelineRef.current && moment(date).isValid()) {
      const calendarApi = timelineRef.current.getApi();
      calendarApi?.gotoDate(moment(date).format("YYYY-MM-DD"));
    }
  }, [date]);

  useEffect(() => {
    if (!props.resources || !props.resources.length) setShowNoResourcesModal(true);
  }, [props.resources]);

  return (
    <div className="reservation-table-content">
      <div className="reservations-schedule">
        {showNoResourcesModal && (
          <SweetAlert
            showCancel
            title={"Warning"}
            cancelBtnText="Cancel"
            confirmBtnText="Show me"
            onCancel={() => setShowNoResourcesModal(false)}
            onConfirm={() => props.history.push("/admin/settings?page=reservations")}
          >
            Timeline view requires Bookable resources to be configured.
          </SweetAlert>
        )}
        <div className="hour-reservations fixed-height">
          <FullCalendar
            resourceOrder={"order"}
            timeZone={props.timezone}
            schedulerLicenseKey={licenseKey}
            plugins={[momentPlugin, resourceTimelinePlugin, interactionPlugin]}
            initialView="resourceTimeline"
            resourceAreaHeaderContent="Resources"
            nowIndicator={true}
            ref={timelineRef}
            now={new Date()}
            slotDuration={"00:15:00"}
            events={timelineEvents}
            headerToolbar={false}
            eventClick={handleTimelineEventClick}
            dateClick={handleTimelineDateClick}
            resources={timelineResources}
            initialDate={moment(date).isValid() ? moment(date).format("YYYY-MM-DD") : new Date()}
            scrollTimeReset={false}
            scrollTime={null}
          />
        </div>
      </div>
    </div>
  );
}

export default DailyReservationsTimeline;

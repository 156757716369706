import { SNIPPET_TYPES } from "constants/square";
import React from "react";
import { Modal } from "react-bootstrap";
import "./Square.scss";
import SquareAppearance from "./SquareAppearance";

function SquareAppearanceEditModal(props) {
  const { handleAppearanceModalClose, config, squareInfo } = props;
  const { isEdit, snippet, type } = config;
  const closeModal = () => {
    handleAppearanceModalClose();
  };

  const saveSnippet = async payload => {
    if (!squareInfo?.squareOnlineSites) {
      handleAppearanceModalClose({ snippet: payload.snippet });
    }
    handleAppearanceModalClose({ snippet: payload.snippet, isEdit });
  };

  return (
    <Modal dialogClassName="square" show={!!config} onHide={closeModal}>
      <div className="square__content">
        <SquareAppearance
          placement="settings"
          snippet={snippet}
          type={type || snippet?.type || SNIPPET_TYPES.WAITLIST}
          squareInfo={squareInfo}
          saveSnippet={saveSnippet}
          dismissSetup={closeModal}
        />
      </div>
    </Modal>
  );
}

export default SquareAppearanceEditModal;

export const SET_WAITING_VISITORS = "SET_WAITING_VISITORS";
export const SET_ALL_VISITORS = "SET_ALL_VISITORS";
export const SET_HISTORY_VISITORS = "SET_HISTORY_VISITORS";
export const SET_MESSAGES = "SET_MESSAGES";

export function setWaitingVisitors(payload) {
  return {
    type: SET_WAITING_VISITORS,
    payload
  };
}

export function setAllVisitors(payload) {
  return {
    type: SET_ALL_VISITORS,
    payload
  };
}

export function setHistoryVisitors(payload) {
  return {
    type: SET_HISTORY_VISITORS,
    payload
  };
}

export function setMessages(payload) {
  return {
    type: SET_MESSAGES,
    payload
  };
}

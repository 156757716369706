export const BUSINESS_TYPES = [
  "Restaurant",
  "Bar",
  "Barber Shop",
  "Bowling Alley",
  "DMV",
  "Hair Salon",
  "Medical Practice",
  "Nail Salon",
  "Night Club",
  "Place of Worship",
  "Tattoo Parlor",
  "Retail",
  "Permanent Jewelry",
  "Other"
];

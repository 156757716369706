import React, { useState, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { useAuth } from "./hooks/useAuth";

import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "assets/css/demo.css";
import "assets/css/pe-icon-7-stroke.css";
import AuthLayout from "layouts/Auth";
import AdminLayout from "layouts/Admin.jsx";
import rootReducer from "./store/reducers";
import { Provider } from "react-redux";
import { createStore } from "redux";
import firebase from "firebase/compat/app";
import "firebase/analytics";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import LoadingPage from "views/Pages/LoadingPage";
import ReactPixel from "react-facebook-pixel";
import { environment } from "./configs/environment";
import "./index.scss";
import { FirstLoginContext } from "context/FirstLoginContext";

const analytics = firebase.analytics;

export const stripePromise = loadStripe(environment.stripeKey);

const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const handleHashChange = async () => {
  analytics().setCurrentScreen(window.location.hash);
  ReactPixel.pageView();
  analytics().logEvent("screen_view");
};

handleHashChange();
window.addEventListener("hashchange", handleHashChange);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
      <App />
    </Provider>
  </Elements>
);

function App() {
  const [user, loading] = useAuth();
  const [initialHash, setInitalHash] = useState();
  const [firstLogin, setFirstLogin] = useState(false);

  useEffect(() => {
    setInitalHash(window.location.hash);
  }, []);
  if (loading) {
    return <LoadingPage></LoadingPage>;
  }
  return user ? (
    <HashRouter>
      <Switch>
        <Route
          path="/admin"
          render={props => (
            <FirstLoginContext.Provider
              value={{
                firstLogin,
                setFirstLogin
              }}
            >
              <AdminLayout user={user} {...props} initialHash={initialHash} firstLogin={firstLogin} />
            </FirstLoginContext.Provider>
          )}
        />
        <Redirect from="/" to="/admin/waitlist" />
      </Switch>
    </HashRouter>
  ) : (
    <HashRouter>
      <Switch>
        <Route
          path="/auth"
          render={props => (
            <FirstLoginContext.Provider
              value={{
                firstLogin,
                setFirstLogin
              }}
            >
              <AuthLayout {...props} initialHash={initialHash} />
            </FirstLoginContext.Provider>
          )}
        />
        <Redirect from="/" to="/auth/login-page" />
      </Switch>
    </HashRouter>
  );
}

import React, { useState } from "react";
import RegularTable from "views/RegularTable";
import { HEADERS, ACTIONS } from "../../constants/tables/snippets-settings-table";
import { setSquareInfo } from "store/actions/square.actions";
import Button from "../CustomButton/CustomButton";
import SquareAppearanceEditModal from "components/Square/SquareAppearanceEditModal";
import "./Settings.scss";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { deleteSnippet } from "services/square/square.service";
import { CheckInButton } from "../Square/SquareButtonPreview";
import toastr from "toastr";
import { createSnippet } from "services/square/square.service";
import { editSnippet } from "services/square/square.service";
import { useDispatch } from "react-redux";
import { getSquareInfo } from "services/square/square.service";
import SquareSnipppetTypeModal from "./SquareSnippetTypeModal";
import { PREMIUM_TO_PRO, SNIPPET_TYPES } from "../../constants/square";
import SquareReservationSetup from "components/Square/SquareReservationSetup";
import { Modal } from "react-bootstrap";
import { useEffect } from "react";
import { getBusinessResources } from "services/businesses/businesses.service";
import { BeatLoader } from "react-spinners";
import { upgradePremiumToPro } from "services/profile/profile.service";
import UpgradeSquare from "components/Upgrade/UpgradeSquare";
import SquareHeader from "components/Square/SquareHeader";
import StripeUpgradeErrorContent from "./StripeUpgradeErrorContent";

export const STRIPE_UPGRADE_ERROR = {
  WRONG_BILLING_ADDRESS: 406,
  ORIGIN_IOS: 405
};

const SquareSnippetsSettings = ({ squareInfo, plan, business }) => {
  const { snippets } = squareInfo;
  const [editSnippetModalConfig, setEditSnippetModalConfig] = useState(null);
  const [selectedSnippetToDelete, setSelectedSnippetToDelete] = useState(null);
  const [snippetTypeModal, setSnippetTypeModal] = useState(false);
  const [setupResourcesModal, setSetupResourcesModal] = useState(false);
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [upgradeErrorModal, setUpgradeErrorModal] = useState(false);
  const [snippetType, setSnippetType] = useState(null);
  const [snippetTypeError, setSnippetTypeError] = useState("");
  const [resourcesLoading, setResourcesLoading] = useState(true);
  const [resources, setResources] = useState(null);
  const [maxResourcesCount, setMaxResourcesCount] = useState(null);
  const dispatch = useDispatch();
  const getRows = () => {
    if (snippets.length > 0) {
      return snippets.map(snippet => {
        const isReservation = snippet.type === SNIPPET_TYPES.RESERVATION;
        return {
          siteTitle: snippet.appearance.onlineSite,
          domain: () => {
            const site = squareInfo.squareOnlineSites?.find(v => v.id === snippet.siteId);

            return (
              <a href={`https://${site?.domain}`} target="blank">
                {site?.domain}
              </a>
            );
          },
          preview: () => {
            return <CheckInButton appearance={snippet.appearance} isReservation={isReservation} />;
          }
        };
      });
    } else {
      return [];
    }
  };

  const fetchSquareResources = async () => {
    setResourcesLoading(true);
    try {
      const businessId = business?.business?.businessId;
      if (!businessId) throw new Error("Bussiness id is required");
      const reslt = await getBusinessResources(businessId);
      if (!reslt || !reslt.docs) throw new Error("Something went wrong, when fetch resources");
      const resorcs = reslt?.docs.map(r => r.data()).filter(r => r.onlineCapacity);
      const maxRsrcCount = Math.max(...resorcs.map(r => r.maxPartySize), 0) || 0;
      if (maxRsrcCount) {
        setMaxResourcesCount(maxRsrcCount);
        setResources(resorcs);
      } else {
        setResources(null);
        setMaxResourcesCount(null);
      }
    } catch (e) {
      console.error(e);
      setResources(null);
      setMaxResourcesCount(null);
    }
    setResourcesLoading(false);
  };

  useEffect(() => {
    if (!setupResourcesModal) return;
    fetchSquareResources();
  }, [setupResourcesModal]);

  const reloadData = async () => {
    const squareInfoUpdated = await getSquareInfo();
    dispatch(setSquareInfo(squareInfoUpdated));
  };

  const onEdit = async snippet => {
    setEditSnippetModalConfig({
      isEdit: true,
      snippet: snippets.find(s => s.appearance.onlineSite === snippet.siteTitle)
    });
  };

  const onDelete = snippet => {
    setSelectedSnippetToDelete(snippet);
  };

  const deleteSnippetConfirm = async () => {
    try {
      const snippet = snippets.find(s => s.appearance.onlineSite === selectedSnippetToDelete.siteTitle);
      toastr.success("Snippet delete in progres...");
      setSelectedSnippetToDelete(null);
      await deleteSnippet(snippet.id);
      await reloadData();
      toastr.success("Snippet was successfully deleted");
    } catch (err) {
      console.log(err);
      toastr.error(err.error || "There was an issue during snippet delete, please contact support");
    }
  };

  const showAddSnippetModal = () => {
    setEditSnippetModalConfig({
      isEdit: false,
      type: snippetType
    });
  };

  const handleAppearanceModalClose = async data => {
    if (data) {
      const { isEdit, snippet } = data;
      if (isEdit) {
        try {
          toastr.success("Snippet update in progress...");
          const id = editSnippetModalConfig.snippet.id;

          setEditSnippetModalConfig(null);
          await editSnippet(id, { snippet });
          await reloadData();
          toastr.success("Snippet was successfully updated");
        } catch (err) {
          console.log("update err", err);
          toastr.error(err.error || "There was an issue during snippet update, please contact support");
        }
      } else {
        try {
          toastr.success("Snippet create in progress...");
          setEditSnippetModalConfig(null);
          await createSnippet({ snippet });
          await reloadData();
          toastr.success("Snippet was successfully created");
        } catch (err) {
          toastr.error(err.error || "There was an issue during snippet create, please contact support");
        }
      }
    }
    setEditSnippetModalConfig(null);
  };

  const addSnippetDisabled = () => {
    return squareInfo.squareOnlineSites.length === squareInfo.snippets.length;
  };

  const showSelectSnippetTypeModal = () => {
    setSnippetTypeModal(true);
    setSnippetType(null);
  };

  const showSetupResourcesModel = () => {
    setSetupResourcesModal(true);
  };

  const onNextSetupResourcesModel = () => {
    onCloseSetupResourcesModal();
    showAddSnippetModal();
  };

  const onCloseSnippetTypeModal = () => {
    setSnippetTypeModal(false);
  };

  const onCloseSetupResourcesModal = () => {
    setSetupResourcesModal(false);
  };

  const isActiveSquareType = type => {
    return snippetType === type;
  };

  const setActiveSquareType = type => {
    setSnippetTypeError("");
    setSnippetType(type);
  };

  const showUpgradeModal = () => {
    setUpgradeModal(true);
  };

  const onCloseUpgradeModal = () => {
    setUpgradeModal(false);
  };

  const showUpgradeErrorModal = (code = true) => {
    setUpgradeErrorModal(code);
  };

  const onCloseUpgradeErrorModal = () => {
    setUpgradeErrorModal(false);
  };

  const onSquareToPremiunUpgrade = async () => {
    const result = await upgradePremiumToPro();
    onCloseUpgradeModal();
    if (result) {
      if (result.upgraded) {
        showSetupResourcesModel();
        return;
      }
      if (result.error) {
        const code = result.code;
        if (code === STRIPE_UPGRADE_ERROR.ORIGIN_IOS || code === STRIPE_UPGRADE_ERROR.WRONG_BILLING_ADDRESS) {
          showUpgradeErrorModal(code);
          return;
        }
        toastr.error(result.error);
        return;
      }
    } else {
      toastr.error("Something went wrong");
    }
  };

  const onSubmitSquareType = async () => {
    if (!snippetType) return setSnippetTypeError("Snippet type is required");
    if (plan === "free") return setSnippetTypeError("Adding Self check-in to your website is a premium feature");
    if (snippetType === SNIPPET_TYPES.RESERVATION) {
      if (plan === "premium") {
        // upgrade flow should be here

        showUpgradeModal();
        onCloseSnippetTypeModal();
        return;
      }
      // RESERVATION && plan === 'pro' || plan === 'enterprise'
      onCloseSnippetTypeModal();
      showSetupResourcesModel();
      return;
    }
    // WAITLIST && plan > 'free'
    onCloseSnippetTypeModal();
    showAddSnippetModal();
  };

  return (
    <div className="square-snippets-settings">
      {!!upgradeErrorModal && (
        <Modal dialogClassName="square" show={!!upgradeErrorModal} onHide={onCloseUpgradeErrorModal}>
          <div className="square__content">
            <SquareHeader>Online Reservations</SquareHeader>
            <StripeUpgradeErrorContent type={upgradeErrorModal} closeModal={onCloseUpgradeErrorModal} />
          </div>
        </Modal>
      )}

      {upgradeModal && (
        <Modal dialogClassName="square" show={upgradeModal} onHide={onCloseUpgradeModal}>
          <div className="square__content">
            <SquareHeader>Upgrade account to Waitly Professional</SquareHeader>
            <UpgradeSquare
              isProfessional={true}
              isFirstUpgrade={false}
              skipUpgrade={onCloseUpgradeModal}
              onUpgrade={onSquareToPremiunUpgrade}
              upgradeType={PREMIUM_TO_PRO}
            />
          </div>
        </Modal>
      )}

      {setupResourcesModal && (
        <Modal dialogClassName="square" show={setupResourcesModal} onHide={onCloseSetupResourcesModal}>
          <div className="square__content">
            <div className="step-wrapper">
              {resourcesLoading ? (
                <div className="loader-wrapper">
                  <BeatLoader margin={2.5} size={15} color={"#016BFF"} />
                </div>
              ) : (
                <SquareReservationSetup
                  businessId={business?.business?.businessId}
                  goToNextStep={onNextSetupResourcesModel}
                  maxResourcesCount={maxResourcesCount}
                  resources={resources}
                />
              )}
            </div>
          </div>
        </Modal>
      )}

      {snippetTypeModal && (
        <SquareSnipppetTypeModal
          showModal={snippetTypeModal}
          closeModal={onCloseSnippetTypeModal}
          isActive={isActiveSquareType}
          setActiveType={setActiveSquareType}
          onSubmit={onSubmitSquareType}
          error={snippetTypeError}
        />
      )}

      {selectedSnippetToDelete && (
        <SweetAlert
          showCancel
          title="Are you sure?"
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={() => setSelectedSnippetToDelete(null)}
          onConfirm={deleteSnippetConfirm}
        >
          Are you sure you want to delete this snippet?
        </SweetAlert>
      )}
      {editSnippetModalConfig && (
        <SquareAppearanceEditModal
          handleAppearanceModalClose={handleAppearanceModalClose}
          squareInfo={squareInfo}
          config={editSnippetModalConfig}
        />
      )}
      <div className="square-online-snippets">
        <div className="square-online-snippets__label">
          <label
            className={`settings-title settings-title--without-border ${
              plan === "free" ? "settings-title--disabled" : ""
            }`}
          >
            Square Online Site Snippets
          </label>
          {plan === "free" && (
            <div className="square-online-snippets__upgrade settings-subtitle self-check-wrapper">
              Adding Self check-in to your website is a premium feature
              <a
                style={{ fontWeight: "700" }}
                href={document.URL.split("#")[0] + "#/admin/upgrade"}
                rel="noopener noreferrer"
              >
                Upgrade now
              </a>
            </div>
          )}
        </div>
        <Button
          className="square-snippets-settings__add-button pull-right"
          bsStyle="info"
          disabled={addSnippetDisabled() || plan === "free"}
          onClick={showSelectSnippetTypeModal}
          pullRight
          type="button"
        >
          Add check-in button to my site
        </Button>
      </div>

      {plan !== "free" ? (
        <div>
          {snippets && snippets.length ? (
            <RegularTable
              rows={getRows()}
              editAction={onEdit}
              deleteAction={onDelete}
              tableHeaders={HEADERS}
              actions={ACTIONS}
              showSorting={false}
              hideAction={() => false}
            />
          ) : (
            <div className="square-snippets-settings__empty-table">There are no snippets associated with sites</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SquareSnippetsSettings;

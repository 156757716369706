import { firebase } from "../../firebase";
import { environment } from "configs/environment";

const authErrorMessages = {
  "auth/network-request-failed": "Unable to connect, check your internet connection.",
  "auth/email-already-in-use": "Email Address is already used.",
  "auth/invalid-email": "Please enter a valid email address.",
  "auth/weak-password": "Password must be at least 6 characters."
};

async function saveUserToDatabase(token, user) {
  user.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return fetch(`app/signUp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(user)
  });
}

async function reauthenticateUser(currentUser, user) {
  var credential = firebase.auth.EmailAuthProvider.credential(user.email, user.password);
  await currentUser.reauthenticateWithCredential(credential);
}

function logErrorToAnalytics(error) {
  if (authErrorMessages[error.code]) {
    firebase.analytics().logEvent("sign_up_error", error);
  }
}

export async function forgotPassword(email) {
  const actionItems = {
    url: `${environment.baseUrl}/#/auth/login-page`
  };
  return firebase.auth().sendPasswordResetEmail(email, actionItems);
}

export async function signUp(user) {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(user.email.toLowerCase().trim(), user.password)
    .then(async () => {
      const currentUser = firebase.auth().currentUser;
      const token = await currentUser.getIdToken(true);
      const response = await saveUserToDatabase(token, user);
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        currentUser.delete();
        throw new Error(response.statusText);
      }
      await reauthenticateUser(currentUser, user);
      return response;
    })
    .catch(error => {
      logErrorToAnalytics(error);
      return {
        ok: false,
        error: authErrorMessages[error.code] || "Unknown error occurred. Please try again later."
      };
    });
}

export async function acceptUserInvitation(user) {
  return firebase
    .auth()
    .createUserWithEmailAndPassword(user.email.toLowerCase().trim(), user.password)
    .then(async () => {
      const currentUser = firebase.auth().currentUser;

      const token = await currentUser.getIdToken(true);
      const response = await signUpByUserInvitation(token, user);
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        currentUser.delete();
        throw new Error(response.statusText);
      }
      await reauthenticateUser(currentUser, user);
      return response;
    })
    .catch(error => {
      logErrorToAnalytics(error);
      return {
        ok: false,
        error: authErrorMessages[error.code] || "Unknown error occurred. Please try again later."
      };
    });
}

export async function createUser(user) {
  const currentUser = firebase.auth().currentUser;
  const token = await currentUser.getIdToken(true);
  const response = await processUserCreate(token, { ...user });
  if (!response.ok || (response.status > 200 && response.status < 600)) {
    throw new Error((await response.json()).error);
  }
  return response;
}

async function signUpByUserInvitation(token, user) {
  return fetch(`app/users/invite/accept`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(user)
  });
}

async function processUserCreate(token, user) {
  return fetch(`app/users/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(user)
  });
}

import React from "react";
import "./WaitlyFeatures.scss";
import customTextsImage from "../../assets/img/icon-custom-text.svg";
import syncImage from "../../assets/img/icon-devices.svg";
import waitTimeImage from "../../assets/img/icon-wait-time.svg";
import squareImage from "../../assets/img/icon-building.svg";
import browserImage from "../../assets/img/icon-browser.svg";

function WaitlyFeatures({ type }) {
  switch (type) {
    case "addCustomers":
      return (
        <div className="waitly-features col-12 col-sm-12">
          <img className="waitly-features--img" src={waitTimeImage} />
          <div className="waitly-features--info">
            <strong>Easily Add Customers</strong> <br />
            Add customers to your waitlist quickly while managing time and customer's expectations.
          </div>
        </div>
      );
    case "notifyGuests":
      return (
        <div className="waitly-features col-12 col-sm-12">
          <img className="waitly-features--img" src={customTextsImage} />
          <div className="waitly-features--info">
            <strong>Notify Guests</strong> <br />
            Send your guests text messages letting them know when it's their turn.
          </div>
        </div>
      );
    case "syncBetweenDevices":
      return (
        <div className="waitly-features col-12 col-sm-12">
          <img className="waitly-features--img" src={syncImage} />
          <div className="waitly-features--info">
            <strong>Sync Between Devices</strong> <br />
            Use any Apple® iOS devices or a computer with a mondern browser, we'll keep your list in sync across all
            your devices.
          </div>
        </div>
      );
    case "squareLocations":
      return (
        <div className="waitly-features col-12 col-sm-12">
          <img className="waitly-features--img" src={squareImage} />
          <div className="waitly-features--info">
            <strong>Sync all of Your Square Locations</strong> <br />
            Have multiple locations? Take reservations and walk ins for each location. We'll keep all of your Square
            locations up to date with Waitly
          </div>
        </div>
      );
    case "addSquareCustomers":
      return (
        <div className="waitly-features col-12 col-sm-12">
          <img className="waitly-features--img" src={waitTimeImage} />
          <div className="waitly-features--info">
            <strong>Easily Add Your Customers to Square</strong> <br />
            When adding a reservation or guest to the waitlist automatically add them to your Square customer database
          </div>
        </div>
      );
    case "squareOnline":
      return (
        <div className="waitly-features col-12 col-sm-12">
          <img className="waitly-features--img" src={browserImage} />
          <div className="waitly-features--info">
            <strong>Link your Square Online Site</strong> <br />
            Allow customers to check into your waitlist from your Square Online website
          </div>
        </div>
      );
    default:
      return <></>;
  }
}

export default WaitlyFeatures;

import React from "react";
import { STRIPE_UPGRADE_ERROR } from "./SquareSnippetsSettings";
import { Button } from "react-bootstrap";
import TickIcon from "assets/img/checkbox-marked-circle.svg?react";
import { contactSupportForUpgrade } from "services/support/support.service";
import toastr from "toastr";

const StripeUpgradeErrorContent = ({ closeModal, type }) => {
  const [step, setStep] = React.useState(0);
  const [loading, setLoading] = React.useState(false);

  let content = <>Something went wrong</>;

  if (type === STRIPE_UPGRADE_ERROR.WRONG_BILLING_ADDRESS) {
    content = (
      <>
        Online Reservations requires Waitly Professional. Billing details are setup under another user on this account.
        Please log in as that user to upgrade.
      </>
    );
  }

  if (type === STRIPE_UPGRADE_ERROR.ORIGIN_IOS) {
    if (step === 0) {
      content = <> Online Reservations requires Waitly Professional. Contact Support to upgrade your account.</>;
    } else {
      content = (
        <> A ticket has been submitted to our support team. They will reach to you about upgrading your account.</>
      );
    }
  }

  const handleContactSupport = async () => {
    try {
      setLoading(true);
      await contactSupportForUpgrade();
      setStep(step + 1);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      closeModal();
      toastr.error("Something went wrong, try again later");
    }
  };

  return (
    <>
      <div style={{ padding: "0 20px 20px 20px", fontSize: 22 }}>{content}</div>
      <div style={{ width: "50%", margin: "0 auto", textAlign: "center" }}>
        {type === STRIPE_UPGRADE_ERROR.ORIGIN_IOS && step !== 0 && <TickIcon />}
        <button
          type="button"
          className="upgrade-payment__button"
          disabled={loading}
          onClick={() =>
            type === STRIPE_UPGRADE_ERROR.ORIGIN_IOS && step === 0 ? handleContactSupport() : closeModal()
          }
        >
          {type === STRIPE_UPGRADE_ERROR.ORIGIN_IOS && step === 0 ? "Contact Support" : "Ok"}
        </button>
        {type === STRIPE_UPGRADE_ERROR.ORIGIN_IOS && step === 0 && (
          <Button bsStyle="info" onClick={closeModal} className="upgrade-payment__skip-button" pullRight type="button">
            Skip For Now
          </Button>
        )}
      </div>
    </>
  );
};

export default StripeUpgradeErrorContent;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, ControlLabel, Form, FormGroup, HelpBlock, Modal, Row } from "react-bootstrap";
import Card from "../Card/Card";
import Button from "../CustomButton/CustomButton";
import BeatLoader from "react-spinners/BeatLoader";
import { moveUsersMessage } from "../../constants/messages";
import { getUsersByBusinessId } from "../../services/users/users.service";
import { useSelector } from "react-redux";
import "./MoveUsers.scss";

const MoveUsers = ({ closeModal, moveUsers, showMoveUsersModal, loading }) => {
  const { register, handleSubmit, errors } = useForm();
  const businessSelector = useSelector(state => state.business);
  const { business, businesses } = businessSelector;

  const [users, setUsers] = useState([]);

  const [form, setForm] = useState({
    targetBusinessId: ""
  });

  useEffect(() => {
    onGetUsers();
    // eslint-disable-next-line
  }, []);

  const onGetUsers = async () => {
    const users = await getUsersByBusinessId(business.businessId);
    setUsers(users);
  };

  const onSubmit = () => {
    moveUsers(form);
    closeModal();
  };

  const onChangeFormField = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  return (
    <Modal enforceFocus={false} dialogClassName="add-guest-modal" show={showMoveUsersModal} onHide={closeModal}>
      <Card
        content={
          <Form noValidate className="add-guest" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="add-guest__title" md={12}>
                Move Users
              </Col>
            </Row>
            <Row>
              <Col md={12}>{moveUsersMessage.title}</Col>
            </Row>
            <Row>
              <Col md={12} className="move-users-wrapper">
                {users.map((user, index) => (
                  <div className="users-for-move" key={index}>{`${user.firstName} ${user.lastName}`}</div>
                ))}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.targetBusinessId && "error") || null}>
                  <ControlLabel>Move Users to:</ControlLabel>
                  <select
                    className="form-control"
                    disabled={loading}
                    name="targetBusinessId"
                    onChange={onChangeFormField}
                    ref={register({ required: true })}
                    value={form.targetBusinessId || ""}
                  >
                    {businesses.map(
                      (location, index) =>
                        location.businessId !== businesses.businessId && (
                          <option key={index} value={location.businessId}>
                            {location.name}
                          </option>
                        )
                    )}
                  </select>
                  {errors.targetBusinessId && errors.targetBusinessId.type === "required" && (
                    <HelpBlock>Location to move is required</HelpBlock>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="add-guest__actions">
                  <Button
                    onClick={closeModal}
                    className="add-guest__cancel"
                    bsStyle="info"
                    pullRight
                    disabled={loading}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button disabled={loading} bsStyle="info" pullRight fill type="submit">
                    {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Move</span>}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      />
    </Modal>
  );
};

export default MoveUsers;

import { firebase } from "../../firebase";
import { environment } from "../../configs/environment";

export async function sendHelpEmail(message) {
  return firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(async function(idToken) {
      let url = `${environment.apiEndpoint}sendEmail`;
      return fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          Accepts: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ message })
      })
        .then(response => {
          if (!response.ok || (response.status > 200 && response.status < 600)) {
            throw new Error(response.statusText);
          }
          return response;
        })
        .catch(error => {
          console.error("error help email function", error);
          return { ok: false };
        });
    })
    .catch(function(error) {
      console.error("error getting auth token", error);
      return { ok: false };
    });
}

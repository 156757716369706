import React from "react";
import { USER_INFO_TYPES } from "../../../constants/user-info";
import { Col, Row } from "react-bootstrap";
import Button from "../../CustomButton/CustomButton";
import AddPersonIcon from "@material-ui/icons/PersonAdd";
import MailIcon from "@material-ui/icons/MailOutlineOutlined";

const ChooseTypePage = ({ isActive, setActiveType, error, closeModal, onSubmit }) => {
  return (
    <div className="add-guest">
      <Row>
        <Col className="add-guest__title" md={12}>
          Add User
        </Col>
      </Row>
      <Row className="add-guest__responsive-container">
        <Col
          className={`add-guest__box ${isActive(USER_INFO_TYPES.INVITE_USER) && "active"}`}
          onClick={() => setActiveType(USER_INFO_TYPES.INVITE_USER)}
        >
          <div className="add-guest__title">Invite User</div>
          <span>Send an invite and let them set their own password</span>
          <MailIcon />
        </Col>
        <Col
          className={`add-guest__box ${isActive(USER_INFO_TYPES.CREATE_USER) && "active"}`}
          onClick={() => setActiveType(USER_INFO_TYPES.CREATE_USER)}
        >
          <div className="add-guest__title">Create User</div>
          <span>I’ll create the user and set a password.</span>
          <AddPersonIcon />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="add-guest__actions">
            {error && <p className="add-guest__error">{error}</p>}
            <Button onClick={closeModal} className="add-guest__cancel" bsStyle="info" pullRight type="button">
              Cancel
            </Button>
            <Button bsStyle="info" pullRight fill type="button" onClick={onSubmit}>
              <span>Next</span>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ChooseTypePage;

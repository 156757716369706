import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import "./DashboardTable.scss";
import * as _ from "lodash";
import { TableSortLabel } from "@material-ui/core";

function DashboardTable(props) {
  const useStyles = makeStyles({
    table: {
      minWidth: 650
    },
    tableHeaderCell: {
      fontSize: "14px",
      color: "#565656",
      fontWeight: "700"
    },
    tableCell: {
      fontSize: "20px",
      fontWeight: "100"
    }
  });

  const getAverageWait = (servedArray, category) => {
    let array = [...servedArray];
    if (category) {
      array = array.filter(el => el.category === category);
    }
    let count = 0;
    let minutes = 0;
    array.forEach(el => {
      minutes += el.minutesWaited;
      count++;
    });
    if (count) {
      return Math.round(minutes / count);
    }
    return count;
  };

  const { dashboardStats, businesses } = props;
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    if (businesses && businesses.length > 0) {
      const settings = props.businesses[0].settings;
      if (settings && settings.services && settings.services.length) {
        setCategories(settings.services.map(c => c.name));
      }
    }
    // eslint-disable-next-line
  }, [businesses]);
  const classes = useStyles();
  const [stats, setStats] = useState([]);
  const [orderBy, setOrderBy] = useState({ column: "waitlist", direction: "asc" });

  useEffect(() => {
    function mapDashboardStats(dashboardStats) {
      const groupedStats = _.chain(dashboardStats.map(ds => ds.data()))
        .sortBy(v => v.intervalTime)
        .groupBy("businessId")
        .value();
      let result = [];
      _.forEach(businesses, business => {
        const value = groupedStats[business.businessId];
        if (value) {
          const payload = {
            id: business.businessId,
            waitlist: business.name,
            waiting: value[0].waiting >= 0 ? value[0].waiting : 0,
            onlineWaiting: value[0].onlineWaiting >= 0 ? value[0].onlineWaiting : 0,
            served: value[0].served >= 0 ? value[0].served : 0,
            walkedOut: value[0].walkedout >= 0 ? value[0].walkedout : 0,
            averageWait: getAverageWait(value[0].last30MinutesServed),
            reservationsServed: value[0].reservationsServed >= 0 ? value[0].reservationsServed : 0,
            trend: value[0].trend.all
          };
          (business.settings.services || []).forEach(category => {
            payload[`waiting-${category.name}`] =
              value[0].waitingByCategory[category.name] >= 0 ? value[0].waitingByCategory[category.name] : 0;
          });
          result.push(payload);
        } else {
          const payload = {
            id: business.businessId,
            waitlist: business.name,
            waiting: 0,
            onlineWaiting: 0,
            served: 0,
            walkedOut: 0,
            averageWait: 0,
            reservationsServed: 0,
            trend: ""
          };
          (business.settings.services || []).forEach(category => {
            payload[`waiting-${category.name}`] = 0;
          });
          result.push(payload);
        }
      });
      result = _.sortBy(result, r => r[orderBy.column]);
      if (orderBy.direction === "desc") {
        result = result.reverse();
      }
      compareDashboardStats(result);
      return result;
    }

    function compareDashboardStats(newStats) {
      newStats.forEach(newStat => {
        const oldStat = stats.find(s => s.id === newStat.id);
        if (oldStat) {
          _.forOwn(oldStat, (value, key) => {
            if (value !== newStat[key]) {
              updateValueInTable(newStat.id, key, newStat[key] > value);
            }
          });
        }
      });
    }
    setStats(mapDashboardStats(dashboardStats));
    // eslint-disable-next-line
  }, [dashboardStats, orderBy, businesses]);

  function getArrowByTrend(trend) {
    switch (trend) {
      case "up":
        return "mdi-arrow-up";
      case "down":
        return "mdi-arrow-down";
      default:
        return "mdi-window-minimize";
    }
  }

  function updateValueInTable(businessId, key, inreased) {
    if (key.indexOf("trend") !== -1) {
      return;
    }

    const elements = document.getElementsByClassName(`${businessId}-${key}`);
    if (elements[0]) {
      if (elements[0].children[0] && elements[0].children.length) {
        if (inreased) {
          elements[0].classList.add("dashboard-table__cell--transition-red");
        } else {
          elements[0].classList.add("dashboard-table__cell--transition-green");
        }
      } else {
        elements[0].classList.add("dashboard-table__cell--transition-green");
      }
      setTimeout(() => {
        const elements = document.getElementsByClassName(`${businessId}-${key}`);
        if (elements && elements.length) {
          elements[0].classList.remove("dashboard-table__cell--transition-green");
          elements[0].classList.remove("dashboard-table__cell--transition-red");
        }
      }, 1000);
    }
  }

  function onRequestSort(event, column) {
    if (column === orderBy.column) {
      setOrderBy({ column, direction: orderBy.direction === "asc" ? "desc" : "asc" });
    } else {
      setOrderBy({ column, direction: "desc" });
    }
  }

  const createSortHandler = column => event => {
    onRequestSort(event, column);
  };

  return (
    <TableContainer component={Paper}>
      <Table className={`${classes.table} dashboard-table`} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHeaderCell} style={{ width: "220px" }}>
              <TableSortLabel
                active={orderBy.column === "waitlist"}
                direction={orderBy.direction}
                onClick={createSortHandler("waitlist")}
              >
                WAITLIST
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">
              <TableSortLabel
                active={orderBy.column === "onlineWaiting"}
                direction={orderBy.direction}
                onClick={createSortHandler("onlineWaiting")}
              >
                WAITING
                <br />
                ONLINE
              </TableSortLabel>
            </TableCell>
            {categories.map((category, i) => {
              return (
                <TableCell key={i} className={classes.tableHeaderCell} align="right">
                  <TableSortLabel
                    active={orderBy.column === "waiting-" + category}
                    direction={orderBy.direction}
                    onClick={createSortHandler("waiting-" + category)}
                  >
                    WAITING <br />
                    {category.toUpperCase()}
                  </TableSortLabel>
                </TableCell>
              );
            })}
            <TableCell className={classes.tableHeaderCell} align="right">
              <TableSortLabel
                active={orderBy.column === "waiting"}
                direction={orderBy.direction}
                onClick={createSortHandler("waiting")}
              >
                TOTAL
                <br />
                WAITING
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">
              <TableSortLabel
                active={orderBy.column === "averageWait"}
                direction={orderBy.direction}
                onClick={createSortHandler("averageWait")}
              >
                AVG WAIT
              </TableSortLabel>
            </TableCell>
            <TableCell className={classes.tableHeaderCell} align="right">
              <TableSortLabel
                active={orderBy.column === "served"}
                direction={orderBy.direction}
                onClick={createSortHandler("served")}
              >
                SERVED
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHeaderCell} align="right">
              <TableSortLabel
                active={orderBy.column === "reservationsServed"}
                direction={orderBy.direction}
                onClick={createSortHandler("reservationsServed")}
              >
                RESERVATIONS SERVED
              </TableSortLabel>
            </TableCell>

            <TableCell className={classes.tableHeaderCell} align="right">
              <TableSortLabel
                active={orderBy.column === "walkedOut"}
                direction={orderBy.direction}
                onClick={createSortHandler("walkedOut")}
              >
                NO SHOWS
              </TableSortLabel>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stats.map((row, index) => (
            <TableRow key={index}>
              <TableCell className={classes.tableCell} style={{ width: "220px" }} component="th" scope="row">
                <span className={`dashboard-table__cell ${row.id}-waitlist`}>{row.waitlist}</span>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <span className={`dashboard-table__cell ${row.id}-onlineWaiting`}>{row.onlineWaiting}</span>
              </TableCell>
              {categories.map((category, i) => {
                return (
                  <TableCell key={i} className={classes.tableCell} align="right">
                    <span className={`dashboard-table__cell ${row.id}-waiting-${category}`}>
                      {row[`waiting-${category}`]}
                    </span>
                  </TableCell>
                );
              })}
              <TableCell className={classes.tableCell} align="right">
                <span className={`dashboard-table__cell ${row.id}-waiting`}>{row.waiting}</span>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <span className={`dashboard-table__cell ${row.id}-averageWait`}>
                  <i className={`mdi ${getArrowByTrend(row.trend)} ${row.id}-averageArrow`}></i>
                  {row.averageWait} mins
                </span>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <span className={`dashboard-table__cell ${row.id}-served`}>{row.served}</span>
              </TableCell>

              <TableCell className={classes.tableCell} align="right">
                <span className={`dashboard-table__cell ${row.id}-reservationsServed`}>{row.reservationsServed}</span>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <span className={`dashboard-table__cell ${row.id}-walkedOut`}>{row.walkedOut}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default DashboardTable;

import React, { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton";
import SquareHeader from "components/Square/SquareHeader";
import CustomInput from "common/CustomInput/CustomInput";
import { EMAIL_PATTERN } from "constants/patterns";
import { useForm } from "react-hook-form";
import toastr from "toastr";
import { sendEmail } from "services/messages/messages.service";
import "./WebsiteInstructionsModal.scss";

const WebsiteInstructionsModal = ({ show, close, snippetContent }) => {
  const [loading, setLoading] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);

  const [email, setEmail] = useState("");

  const form = useForm();
  const { handleSubmit, triggerValidation, errors } = form;

  const onSubmit = async data => {
    setLoading(true);
    setIsEmailChanged(false);
    await sendEmail(
      data.email,
      `Here is code snippet which can be used to enable Waitly button on your website: \n\n <code>${snippetContent}</code>`
    );
    toastr.success(`Instructions email sent`);
    setLoading(false);
  };

  const handleSnippetContentClick = async () => {
    navigator.clipboard.writeText(snippetContent).then(function() {
      toastr.success(`Copied To Clipboard`);
    });
  };

  return (
    <Modal dialogClassName="square" show={show} onHide={close}>
      <SquareHeader style={{ paddingBottom: 0 }}>Website Instructions</SquareHeader>
      <div className="square__content">
        <div className="step-wrapper website-instrunctions-content">
          <p>Copy the HTML below to include in your website.</p>
          <Form
            noValidate
            className="d-flex justify-content-around align-items-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <span>To:</span>
            <CustomInput
              form={form}
              name="email"
              placeholder="Email"
              disabled={loading}
              type="email"
              pattern={{
                value: EMAIL_PATTERN,
                message: "Please enter a valid email"
              }}
              onChange={e => {
                setEmail(e.target.value);
                setIsEmailChanged(true);
                triggerValidation("email");
              }}
            />
            <Button
              bsStyle="info"
              className="square-modal__button btn-fill btn-email"
              type="submit"
              disabled={!email || !!errors.email || !isEmailChanged}
            >
              Email to my website admin
            </Button>
          </Form>

          <div onClick={handleSnippetContentClick}>
            <textarea disabled={true} readOnly={true} value={snippetContent} />
          </div>

          <Button onClick={close} bsStyle="info" className="square-modal__button btn-fill btn-done" type="button">
            Done
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WebsiteInstructionsModal;

import React from "react";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";

function CustomIconButton(props) {
  return (
    <Fab onClick={props.handleClick} color="secondary" style={{ marginTop: "5px" }}>
      <AddIcon></AddIcon>
    </Fab>
  );
}

export default CustomIconButton;

import UpgradeSquare from "components/Upgrade/UpgradeSquare";
import React, { useState } from "react";
import { useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { useSelector } from "react-redux";
import { config } from "store/reducers/config.reducers";
import SquareHeader from "./SquareHeader";

function SquareActivate(props) {
  const { goToNextStep, configs, squareInfo, exitPoint } = props;
  const accountSelector = useSelector(state => state.account);
  const [sweetAlertConfig, setSweetAlertConfig] = useState(null);
  const { account } = accountSelector;
  const [isFirstUpgrade, setIsFirstUpgrade] = useState(false);
  const isProfessional = configs.takeReservations || configs.plan === "pro";
  useEffect(() => {
    if ((account.squareLinked || account.customerSource === "square") && !account.originalTransactionId) {
      setIsFirstUpgrade(true);
    }
  }, [account]);

  const skipUpgrade = () => {
    config.skipActivate = true;
    if (configs.addCheckins || configs.takeReservations || configs.squareSyncLocations) {
      let message = "You had opted to allow check-ins from your square online site. This requires waitly Premium.";
      if (config.takeReservations) {
        message = "You had opted to add booking reservations to your website. This requires Waitly Professioal.";
      } else if (config.squareSyncLocations) {
        message = `You had opted sync your ${squareInfo.length} locations. This requires waitly Professional.`;
      }
      setSweetAlertConfig({
        title: "Are you sure?",
        message: message
      });
    } else {
      goToNextStep();
    }
  };

  const closeModal = () => {
    setSweetAlertConfig(null);
  };

  return (
    <div className="square-activate">
      {sweetAlertConfig && (
        <SweetAlert
          showCancel={true}
          title={sweetAlertConfig.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={closeModal}
          onConfirm={() => goToNextStep({ skipActivate: true })}
        >
          {sweetAlertConfig.message}
        </SweetAlert>
      )}
      <SquareHeader>
        {isFirstUpgrade
          ? `Activate 30 day free trial of Waitly ${isProfessional ? "Professional" : "Premium"}`
          : `Upgrade to Waitly ${isProfessional ? "Professional" : "Premium"}`}
      </SquareHeader>
      {isProfessional ? (
        <div className="upgrade-subtitle">Waitly Professional is required to take reservations online.</div>
      ) : (
        <div className="upgrade-subtitle">Waitly Premium is required for website self check in feature</div>
      )}
      <UpgradeSquare skipUpgrade={skipUpgrade} isProfessional={isProfessional} exitPoint={exitPoint}></UpgradeSquare>
    </div>
  );
}

export default SquareActivate;

import { SET_CONFIG } from "../actions/config.actions";

export function config(state = {}, action) {
  switch (action.type) {
    case SET_CONFIG:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

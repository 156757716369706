import React, { useState } from "react";
import "./SquareAppearance.scss";
import { ControlLabel } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton";
import SquareColorPicker from "./SquareColorPicker";
import SettingsInput from "components/Settings/SettingsInput";
import SquareButtonPreview from "./SquareButtonPreview";
import { updateSquareInfo } from "services/square/square.service";
import toastr from "toastr";
import { setSquareInfo } from "store/actions/square.actions";
import { useDispatch } from "react-redux";
import { BeatLoader } from "react-spinners";
import { getSquareInfo } from "services/square/square.service";
import { SNIPPET_TYPES } from "../../constants/square";

const styles = [
  {
    name: "Rectangle",
    css: {
      borderRadius: "0px"
    }
  },
  {
    name: "Beveled",
    css: {
      borderRadius: "5px"
    }
  },
  {
    name: "Round",
    css: {
      borderRadius: "24px"
    }
  }
];

function SquareAppearance(props) {
  const { dismissSetup, saveSnippet, squareInfo, placement, snippet, configs } = props;
  const type = (props.type ?? snippet?.type) || SNIPPET_TYPES.WAITLIST;
  const isReservation = configs?.takeReservations || type === SNIPPET_TYPES.RESERVATION || false;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const defaultConfig = {
    fontColor: "#fff",
    buttonColor: "#000",
    onlineSite: getFirstAvailableSiteOption(),
    style: styles[0],
    shadow: true
  };
  const [appearance, setAppearance] = useState(snippet ? snippet.appearance : defaultConfig);

  const getSnippetContent = () => {
    return `
      <style type="text/css">
        .waitly-square-link {
          cursor: pointer;
          font-weight: 700;
          letter-spacing: normal;
          font-family: Roboto, "Helvetica Neue", Arial, sans-serif;
          text-decoration: none;
          position: fixed;
          bottom: 35px;
          right: 35px;
          height: 48px;
          line-height: 48px;
          font-size: 19px;
          padding: 0px 10px 0px 10px;
          color: ${appearance.fontColor};
          background: ${appearance.buttonColor};
          border-radius: ${appearance.style.css.borderRadius};
          box-shadow: ${appearance.shadow ? "rgba(0, 0, 0, 0.24) 0px 3px 8px" : "none"};
        }
      </style>
      <script type="text/javascript">
        window.onload = function () {
          let a = document.createElement("a");
          a.innerHTML = "${isReservation ? "Make a Reservation" : "Add me to Waitlist"}";
          a.href = "${squareInfo.waitlistUrl}${isReservation ? "/book" : ""}";
          a.classList.add('waitly-square-link')
          document.body.appendChild(a);
        }
      </script>
    `;
  };

  const applyToSite = async () => {
    setLoading(true);
    const site = squareInfo.squareOnlineSites.find(site => site.siteTitle === appearance.onlineSite);
    try {
      await updateSquareInfo({
        snippet: {
          content: getSnippetContent(),
          appearance,
          siteId: site.id,
          domain: site.domain,
          type: isReservation ? "reservation" : "waitlist"
        }
      });
      await reloadData();
    } catch (err) {
      console.log(err);
      toastr.error("There was an issue adding your snippet. Please try again.");
      setLoading(false);
    }
    setLoading(false);
    saveSnippet({ siteDomain: site.domain });
  };

  const reloadData = async () => {
    const squareInfoUpdated = await getSquareInfo();
    dispatch(setSquareInfo(squareInfoUpdated));
  };

  const saveSnippetClick = async () => {
    saveSnippet({
      snippet: {
        content: getSnippetContent(),
        appearance,
        siteId: squareInfo?.squareOnlineSites?.find(site => site.siteTitle === appearance.onlineSite)?.id || null,
        type: isReservation ? "reservation" : "waitlist"
      }
    });
  };

  const isSiteOptionDisabled = siteId => {
    const sitesWithSnippets = squareInfo.snippets.map(s => s.siteId);
    const filteredSites = snippet ? sitesWithSnippets.filter(s => s !== snippet.siteId) : sitesWithSnippets;
    return filteredSites.indexOf(siteId) !== -1;
  };

  function getFirstAvailableSiteOption() {
    if (snippet) {
      return snippet.appearance.onlineSite;
    }
    for (const site of squareInfo.squareOnlineSites || []) {
      const existingSnippets = squareInfo.snippets || [];
      if (existingSnippets.some(es => es.siteId === site.id)) {
        continue;
      } else {
        return site.siteTitle;
      }
    }
  }

  return (
    <div className="square-modal">
      <div className="square-modal__header">Button Appearance</div>
      <div className="square-modal__body">
        <p className="square-appearance__text">
          Configure what you would like the button to look like on your website.
        </p>
        <div className="square-appearance-item">
          <div className="square-appearance-item__column square-appearance-item__column--title">
            <ControlLabel className="settings-label">Font Color</ControlLabel>
          </div>
          <SquareColorPicker
            color={appearance.fontColor}
            changeColor={fontColor => setAppearance({ ...appearance, fontColor })}
          ></SquareColorPicker>
        </div>
        <div className="square-appearance-item">
          <div className="square-appearance-item__column square-appearance-item__column--title">
            <ControlLabel className="settings-label">Button Color</ControlLabel>
          </div>
          <SquareColorPicker
            color={appearance.buttonColor}
            changeColor={buttonColor => setAppearance({ ...appearance, buttonColor })}
          ></SquareColorPicker>
        </div>
        {squareInfo?.squareOnlineSites?.length && (
          <div className="square-appearance-item">
            <div className="square-appearance-item__column square-appearance-item__column--title">
              <ControlLabel className="settings-label">Online Site</ControlLabel>
            </div>
            <select
              className="form-control square-appearance-item__select"
              placeholder="Online Site"
              name="onlineSite"
              onChange={event => setAppearance({ ...appearance, onlineSite: event.target.value })}
              defaultValue={appearance.onlineSite || ""}
            >
              {squareInfo.squareOnlineSites.map((site, index) => {
                return (
                  <option key={index} disabled={isSiteOptionDisabled(site.id)} value={site.siteTitle}>
                    {site.siteTitle} ({site.domain})
                  </option>
                );
              })}
            </select>
          </div>
        )}
        <div className="square-appearance-item">
          <div className="square-appearance-item__column square-appearance-item__column--title">
            <ControlLabel className="settings-label">Style</ControlLabel>
          </div>
          <select
            className="form-control square-appearance-item__select"
            placeholder="Style"
            name="style"
            onChange={event => setAppearance({ ...appearance, style: styles.find(s => s.name === event.target.value) })}
            defaultValue={appearance.style.name || ""}
          >
            {styles.map((style, index) => {
              return (
                <option key={index} value={style.name}>
                  {style.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="square-appearance-item">
          <div className="square-appearance-item__column square-appearance-item__column--title">
            <ControlLabel className="settings-label">Shadow</ControlLabel>
          </div>
          <SettingsInput
            name={"shadow"}
            type={"switch"}
            enabled={true}
            style={{ marginBottom: 0 }}
            value={appearance.shadow}
            hideLabel={true}
            onChangeValue={event => {
              setAppearance({ ...appearance, shadow: event.target.checked });
            }}
          />
        </div>
        <div className="square-appearance-item">
          <div className="square-appearance-item__column square-appearance-item__column--title preview"></div>
          <SquareButtonPreview appearance={appearance} isReservation={isReservation} />
        </div>
      </div>
      <div className="square-modal__footer square-modal__footer--right">
        {placement === "setupFlow" ? (
          <>
            <Button
              onClick={saveSnippet}
              bsStyle="info"
              className="square-modal__button square-modal__button--left"
              pullRight
              type="button"
            >
              Skip
            </Button>
            <Button
              onClick={applyToSite}
              bsStyle="info"
              disabled={loading}
              className="square-modal__button btn-fill"
              pullRight
              type="button"
            >
              {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Apply to Site</span>}
            </Button>
          </>
        ) : (
          <>
            <Button
              onClick={dismissSetup}
              bsStyle="info"
              className="square-modal__button square-modal__button--left"
              pullRight
              type="button"
            >
              Cancel
            </Button>

            <Button
              onClick={saveSnippetClick}
              bsStyle="info"
              className="square-modal__button btn-fill"
              pullRight
              type="button"
            >
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default SquareAppearance;

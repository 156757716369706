import { useHistory } from "react-router-dom";
import React, { useState } from "react";
import { Nav, NavDropdown, MenuItem } from "react-bootstrap";
import { firebase } from "../../firebase";
import { logEvent } from "services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "constants/firebase";
import AddLocation from "../AddLocation/AddLocation";
import { canAddLocations, hideSettings } from "../../configs/access";
import AddUser from "components/AddUser/AddUser.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { account } from "store/reducers/account.reducers";

function HeaderLinks(props) {
  let history = useHistory();
  let [showAddLocationModal, setShowAddLocationModal] = useState(false);
  let [showAddUserModal, setShowAddUserModal] = useState(false);
  const [showPromptAddUserModal, setShowPromptAddUserModal] = useState(false);

  function handleLogOut() {
    firebase.auth().signOut();
    logEvent(FIREBASE_EVENTS.sign_out);
    localStorage.removeItem("dashboard_filters");
  }

  function goToProfile() {
    history.push("/admin/profile");
  }

  function goToSettings() {
    history.push("/admin/settings");
  }

  const onCheckIfToShowAddUser = () => {
    return (
      (props.plan === "enterprise" || props.plan === "pro" || props.plan === "premium") &&
      props.user.claims.role === "admin"
    );
  };

  const onShowAddLocationModal = () => {
    setShowAddLocationModal(true);
  };
  const onShowAddUserModal = () => {
    setShowAddUserModal(true);
  };

  const onCloseAddLocationModal = businessId => {
    if (businessId && !businessId.target) {
      if (onCheckIfToShowAddUser()) {
        setShowPromptAddUserModal(true);
      }
    }
    setShowAddLocationModal(false);
    props.setNewBusinessId && props.setNewBusinessId(businessId);
  };

  const onCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  const hidePromptAddUserModal = () => {
    setShowPromptAddUserModal(false);
  };
  const confirmPromptAddUserModal = () => {
    setShowPromptAddUserModal(false);
    onShowAddUserModal();
  };

  const onCheckIfToShowAddBusinessLocation = () => {
    return canAddLocations(props.account, props.user, props.businesses) && onCheckForAvailableRoute();
  };

  const onCheckForAvailableRoute = () => {
    let currentLocation = props.history.location.pathname;
    const routesToDisplayBusinessSelector = ["reservations", "waitlist", "settings", "history"];
    return routesToDisplayBusinessSelector.some(route => currentLocation.includes(route));
  };

  return (
    <div>
      {showPromptAddUserModal && (
        <SweetAlert
          showCancel
          title={"New location created"}
          cancelBtnText="Skip for now"
          confirmBtnText="Yes"
          onCancel={hidePromptAddUserModal}
          onConfirm={confirmPromptAddUserModal}
        >
          Do you want to create a login for this location?
        </SweetAlert>
      )}
      {showAddLocationModal && (
        <AddLocation
          businesses={props.businesses}
          showAddLocationModal={showAddLocationModal}
          closeModal={onCloseAddLocationModal}
        />
      )}
      {showAddUserModal && <AddUser showModal={showAddUserModal} closeModal={onCloseAddUserModal} />}

      <Nav pullRight>
        <NavDropdown
          eventKey={4}
          title={
            <div>
              <i className="fa fa-list" />
              <p className="hidden-md hidden-lg">
                More
                <b className="caret" />
              </p>
            </div>
          }
          noCaret
          id="basic-nav-dropdown-3"
          bsClass="dropdown-with-icons dropdown"
        >
          {props.user.claims.role !== "user" && (
            <MenuItem eventKey={4.5}>
              <div onClick={goToProfile}>
                <i className="pe-7s-user" /> Profile
              </div>
            </MenuItem>
          )}

          {onCheckIfToShowAddBusinessLocation() && (
            <MenuItem eventKey={4.5}>
              <div onClick={onShowAddLocationModal}>
                <i className="pe-7s-map-2" />
                Add Location
              </div>
            </MenuItem>
          )}
          {onCheckIfToShowAddUser() && (
            <MenuItem eventKey={4.5}>
              <div onClick={onShowAddUserModal}>
                <i className="pe-7s-add-user" />
                Add User
              </div>
            </MenuItem>
          )}

          {!hideSettings(props.user, account) && (
            <MenuItem eventKey={4.5}>
              <div onClick={goToSettings}>
                <i className="pe-7s-config" /> Settings
              </div>
            </MenuItem>
          )}
          <MenuItem eventKey={4.5}>
            <div className="text-danger" onClick={handleLogOut}>
              <i className="pe-7s-close-circle" /> Log out
            </div>
          </MenuItem>
        </NavDropdown>
      </Nav>
    </div>
  );
}

export default HeaderLinks;

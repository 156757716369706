import React from "react";
import "../../layouts/Admin.scss";
import "./Upgrade.scss";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { withStyles } from "@material-ui/core/styles";

function UpgradeSwitch(props) {
  const { changeBillingType, isAnnual } = props;
  const CustomSwitch = withStyles(theme => ({
    root: {
      width: 62,
      height: 26,
      padding: 0,
      margin: 0
    },
    switchBase: {
      padding: 1,
      "&$checked": {
        transform: "translateX(36px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "rgb(79, 128, 239)",
          opacity: 1,
          border: "none"
        }
      },
      "&$focusVisible $thumb": {
        color: "rgb(79, 128, 239)",
        border: "6px solid #fff"
      }
    },
    thumb: {
      width: 24,
      height: 24
    },
    track: {
      borderRadius: 26 / 2,
      backgroundColor: "rgb(79, 128, 239)",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  }))(Switch);

  return <FormControlLabel control={<CustomSwitch onChange={changeBillingType} checked={isAnnual}></CustomSwitch>} />;
}

export default UpgradeSwitch;

export const DEFAULT_RESERVATION_DURATION = 15;
export const DEFAULT_HOURS = [
  {
    begin: "00:00",
    end: "23:30"
  },
  {
    begin: "00:00",
    end: "23:30"
  },
  {
    begin: "00:00",
    end: "23:30"
  },
  {
    begin: "00:00",
    end: "23:30"
  },
  {
    begin: "00:00",
    end: "23:30"
  },
  {
    begin: "00:00",
    end: "23:30"
  },
  {
    begin: "00:00",
    end: "23:30"
  }
];

export const DEFAUL_TIMEZONE = "America/New_York";

export const RESERVATIONS_TYPES = {
  RESERVATIONS: "reservations",
  APPOINTMENTS: "appointments"
};

import React, { useEffect, useState } from "react";
import { Col, ControlLabel, Form, FormGroup, HelpBlock, Modal, Row } from "react-bootstrap";
import Card from "../Card/Card";
import Button from "../CustomButton/CustomButton";
import { useForm } from "react-hook-form";
import { addNewBusiness } from "../../services/businesses/businesses.service";
import BeatLoader from "react-spinners/BeatLoader";
import Autocomplete from "react-google-autocomplete";
import { environment } from "configs/environment";
import { useSelector } from "react-redux";

const AddLocation = ({ showAddLocationModal, closeModal, businesses }) => {
  const { register, handleSubmit, errors, setError, clearError } = useForm();
  const { account } = useSelector(state => state.account || {});
  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    locationName: "",
    address: null,
    copySettingsFrom: null,
    externalId: ""
  });

  const [isAddressValid, setIsAddressValid] = useState(false);
  const [address, setAddress] = useState(null);

  const onChangeFormField = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const onSubmit = async () => {
    setLoading(true);
    const newBusinessId = await addNewBusiness(form);
    setLoading(false);
    closeModal(newBusinessId);
  };
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const onAutoComplete = place => {
    // "street_address" - address1
    // "locality" - city
    // "administrative_area_level_1" - state
    // "postal_code" - postal
    const { address_components, formatted_address, geometry } = place;
    if (!address_components) return;
    if (formatted_address) setAutoCompleteValue(formatted_address);

    const address1 = address_components.find(el => el.types.includes("street_address"));
    const street_number = address_components.find(el => el.types.includes("street_number"));
    const route = address_components.find(el => el.types.includes("route"));
    const city = address_components.find(el => el.types.includes("locality"));
    const state = address_components.find(el => el.types.includes("administrative_area_level_1"));
    const postal = address_components.find(el => el.types.includes("postal_code"));
    const country = address_components.find(el => el.types.includes("country"));

    const geo = {
      lat: geometry.location?.lat?.(),
      lng: geometry.location?.lng?.()
    };

    const data = {
      address1:
        address1?.long_name || ((street_number?.long_name || "") + " " + (route?.short_name || "")).trim() || "",
      address2: form.address2 || "",
      country: country?.short_name || "",
      city: city?.long_name || "",
      state: state?.short_name || state?.long_name || "",
      postal: postal?.long_name || "",
      geo
    };

    if (!data || !data.address1 || !data.city || !data.state || !data.postal || !data.country || !data.geo?.lat) {
      setError("address", "required");
      setIsAddressValid(false);
      setAddress(null);
      return;
    } else {
      clearError("address");
      setAddress(data);
      setIsAddressValid(true);
    }
  };

  // Also prevent form submiting when press "Enter", because pressing also trigger onAutocomplete
  // and form submitting 2 times
  // it doesn't affect on other components
  const checkKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    setForm({ ...form, address });
  }, [address]);

  return (
    <Modal dialogClassName="add-guest-modal" show={showAddLocationModal} onHide={closeModal}>
      <Card
        content={
          <Form noValidate className="add-guest" onSubmit={handleSubmit(onSubmit)} onKeyDown={checkKeyDown}>
            <Row>
              <Col className="add-guest__title" md={12}>
                Add Location
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.locationName && "error") || null}>
                  <ControlLabel>LOCATION NAME</ControlLabel>
                  <input
                    name="locationName"
                    className="form-control"
                    type="text"
                    placeholder="Enter location name"
                    onChange={onChangeFormField}
                    defaultValue={form.name}
                    ref={register({ required: true })}
                  />
                  {errors.locationName && errors.locationName.type === "required" && (
                    <HelpBlock>Name is required</HelpBlock>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.address && "error") || null}>
                  <ControlLabel>Address</ControlLabel>
                  <div className="settings-input-wrapper">
                    <Autocomplete
                      apiKey={environment.googleMapsKey}
                      onPlaceSelected={onAutoComplete}
                      className="form-control"
                      language="en"
                      options={{
                        strictBounds: true,
                        componentRestrictions: {
                          country: ["us", "ca"]
                        },
                        types: ["address"],
                        fields: ["address_components", "formatted_address", "geometry"]
                      }}
                      onChange={e => setAutoCompleteValue(e.target.value)}
                      value={autoCompleteValue}
                      disabled={loading}
                    />
                    {/* need this input without display for correct react-hook-form validation */}
                    <input
                      name="address"
                      type="text"
                      onChange={() => null}
                      value={isAddressValid ? "address" : ""}
                      style={{ display: "none" }}
                      ref={register({ required: true })}
                    />
                  </div>
                  {form.address && (
                    <div>
                      {form.address.address1 && <div>{form.address.address1}</div>}
                      {form.address.address2 && <div>{form.address.address2}</div>}
                      {form.address.city && form.address.state && form.address.postal && (
                        <div>
                          {form.address.city}, {form.address.state} {form.address.postal}
                        </div>
                      )}
                    </div>
                  )}
                  {errors.address && errors.address.type === "required" && <HelpBlock>Address is required</HelpBlock>}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.copySettingsFrom && "error") || null}>
                  <ControlLabel>COPY SETTINGS FROM</ControlLabel>
                  <select
                    name="copySettingsFrom"
                    className="form-control"
                    onChange={onChangeFormField}
                    ref={register({ required: true })}
                    defaultValue={form.copySettingsFrom || ""}
                  >
                    <option value="">Select business</option>
                    {businesses.map((business, index) => {
                      return (
                        <option key={index} value={business.businessId}>
                          {business.name}
                        </option>
                      );
                    })}
                  </select>
                  {errors.copySettingsFrom && errors.copySettingsFrom.type === "required" && (
                    <HelpBlock>Copy business is required</HelpBlock>
                  )}
                </FormGroup>
              </Col>
            </Row>
            {account.id === "xj5spEZSQb2wd0nFRKY6" && (
              <Row>
                <Col md={12}>
                  <FormGroup validationState={(!!errors.externalId && "error") || null}>
                    <ControlLabel>EXTERNAL ID</ControlLabel>
                    <input
                      name="externalId"
                      className="form-control"
                      type="text"
                      onChange={onChangeFormField}
                      placeholder="Enter external id"
                      defaultValue={form.externalId}
                      ref={register()}
                    />
                    {errors.externalId && errors.externalId.type === "required" && (
                      <HelpBlock>External id is required</HelpBlock>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={12}>
                <div className="add-guest__actions">
                  <Button onClick={closeModal} className="add-guest__cancel" bsStyle="info" pullRight type="button">
                    Cancel
                  </Button>
                  <Button bsStyle="info" pullRight fill type="submit">
                    {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Add</span>}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      />
    </Modal>
  );
};

export default AddLocation;

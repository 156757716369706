import React, { useEffect } from "react";
import "./Square.scss";
import Button from "components/CustomButton/CustomButton";
import { getRedirectToSquareSubscriptionUrls, getRedirectToSquareUrls } from "services/square/square.service";
import { useState } from "react";
import { useLocation } from "react-router-dom";

function SquareError(props) {
  const { handleModalClose, squareSetupModalConfig } = props;
  const [url, setUrl] = useState("");
  const location = useLocation();

  useEffect(() => {
    const asyncFn = async () => {
      const isSquareSubscription = location.search.includes("squareSubscription=true");
      const urls = isSquareSubscription ? await getRedirectToSquareSubscriptionUrls() : await getRedirectToSquareUrls();
      setUrl(location.pathname === "/admin/waitlist" ? urls.signin : urls.link);
    };
    asyncFn();
  }, []);
  const skipClicked = () => {
    handleModalClose(false);
  };

  return (
    <div className="square-modal">
      <div className="square-modal__header">Unable to connect your Square account</div>
      {squareSetupModalConfig.error === "duplicateLink" ? (
        <div className="square-modal__body">
          We're sorry. This square account is already linked to another Waitly account.
          <br />
          <br />
          First disconnect Waitly from the {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href="https://squareup.com/dashboard/apps/my-applications" target="_blank">
            Square Dashboard
          </a>{" "}
          then Click Try Again.
        </div>
      ) : squareSetupModalConfig.error === "denied" ? (
        <div className="square-modal__body">
          Your Waitly user does not have administrative privileges. Please log in with an account that is an
          adminstrator.
          <br />
        </div>
      ) : (
        <div className="square-modal__body">
          We were not able to connect you Square account. Please try again.
          <br />
        </div>
      )}
      <div className="square-modal__footer">
        {squareSetupModalConfig.error !== "denied" && (
          <Button
            bsStyle="info"
            onClick={skipClicked}
            className="square-modal__button square-modal__button--left"
            pullRight
            type="button"
          >
            Skip
          </Button>
        )}
        {squareSetupModalConfig.error === "denied" ? (
          <Button
            bsStyle="info"
            onClick={skipClicked}
            className="square-modal__button btn-fill"
            pullRight
            type="button"
          >
            OK
          </Button>
        ) : (
          <Button bsStyle="info" href={url} className="square-modal__button btn-fill" pullRight type="button">
            Try Again
          </Button>
        )}
      </div>
    </div>
  );
}

export default SquareError;

export const kebabize = (str: string): string => {
  return str
    .split("")
    .map((letter, idx) => {
      return letter.toUpperCase() === letter ? `${idx !== 0 ? "-" : ""}${letter.toLowerCase()}` : letter;
    })
    .join("");
};

export const createCssString = (cssObject: { [key: string]: string }): string => {
  let cssString = "";
  for (const property in cssObject) {
    if (Reflect.has(cssObject, property)) {
      const kebabCaseProperty = kebabize(property);
      cssString += `${kebabCaseProperty}: ${cssObject[property]}; `;
    }
  }
  return cssString.trim();
};

import { SET_BUSINESS, SET_BUSINESSES } from "../actions/business.actions";

export function business(state = {}, action) {
  switch (action.type) {
    case SET_BUSINESS:
      return { ...state, business: action.payload };
    case SET_BUSINESSES:
      return {...state, businesses: action.businesses};
    default:
      return state;
  }
}

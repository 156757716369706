import { Grid, Row, Col } from "react-bootstrap";
import CustomButton from "components/CustomButton/CustomButton";
// PJ
import signUpPhonePJImage from "../../assets/img/pj__sign_up_phone.webp";
import signUpPhoneFlatPJImage from "../../assets/img/pj__sign_up_phone_flat.webp";
import discountPJImage from "../../assets/img/pj__discount.webp";
// Restaurant
import feedbackRestaurantImage from "../../assets/img/restaurant__ipad_hands.png";
import starsRestaurantImage from "../../assets/img/stars.png";
import signUpIpadRestaurantImage from "../../assets/img/restaurant__sign_up_ipad.webp";
import section1RestaurantImage from "../../assets/img/restaurant__sign_up_section1.webp";
import section2RestaurantImage from "../../assets/img/restaurant__sign_up_section2.webp";
import section3RestaurantImage from "../../assets/img/restaurant__sign_up_section3.webp";
import reserveSectionRestaurantImage from "../../assets/img/restaurant__website.webp";
import reserveSectionRestraurantGoogleImage from "../../assets/img/restaurant__google-side-by-side.png";
import AppStoreIcon from "../../assets/img/bug-app-store-black.svg?react";
import rwgLogo from "../../assets/img/rwg-logo.png";
import { logEvent } from "services/firebase/firebase.service";

export const RegisterPagePJContent = () => {
  return (
    <div className="pj-content">
      <h2>Still juggling with pen and paper for your waitlist?</h2>
      <Grid>
        <Row>
          <Col md={4} xs={12}>
            <img src={signUpPhonePJImage} className="pj-phone-img" />
            <img src={signUpPhoneFlatPJImage} className="pj-phone-img pj-phone-img__flat" />
          </Col>
          <Col md={8} xs={12} className="pj-content__pop-up-text ">
            <strong>Meet Waitly PJ :</strong> The game-changer in the world of permanent jewelry pop-ups.
            <span>
              Tailored just for professionals like you, it takes your client management to a whole new level. And if
              you're a Square user, you can capture customer data and send it to your Square Dashboard!
            </span>
          </Col>
        </Row>
      </Grid>
      <div className="pj-content__list">
        <ul>
          <li>Clients can add themselves to your waitlist directly from their phone by scanning your QR code</li>
          <li>Manage your waitlist from your phone or iPad.</li>
          <li>Text clients when it's their turn</li>
          <li>Adds a layer of professionalism</li>
          <li>Integrates with Square for added benefit</li>
        </ul>
      </div>

      <Grid>
        <Row>
          <Col md={6} xs={12} className="pj-discount-text">
            Experience the ease of Waitly PJ! Dive into our one month free trial now. And if you're a{" "}
            <strong>Permanent Jewelry Bestie student, we'll take 50% off your first 3 months!</strong> Check your
            private student group for the exclusive discount code.
          </Col>
          <Col md={6} xs={12} className="pj-discount-img-wrapper">
            <img src={discountPJImage} />
          </Col>
        </Row>
      </Grid>
    </div>
  );
};

export const RegisterPageRestaurantContent = ({ openModal }) => {
  //fire open sign up modal with paramerter of customerSource
  const FreeOffer = (
    <div className="restaurant-free-offer">
      <div className="restaurant-free-offer__header">
        <strong>Free</strong> for 1 month, then <strong>50%</strong> off for 3 months
      </div>

      <ul>
        <li>
          <div>
            <span>Free for 1 month</span>
            <span className="under-dots" />
            <span className="price">$99/month</span>
          </div>
        </li>
        <li>
          <div>
            <span>50% off next 3 months</span>
            <span className="under-dots" />
            <span className="price">$150 value</span>
          </div>
        </li>
        <li>
          <div>
            <span>Zoom call to ensure perfect setup</span>
            <span className="under-dots" />
            <span className="price">$149 value</span>
          </div>
        </li>
        <li>
          <span>Easy to use</span>
        </li>
        <li>
          <span>Best price for value!</span>
        </li>
      </ul>
      <div className="restaurant-open-modal-btn-wrapper">
        <CustomButton fill round onClick={openModal} className="restaurant-open-modal-btn">
          Get Started Today!
        </CustomButton>
      </div>
    </div>
  );

  return (
    <div className="restaurant-content">
      <h2>Best Reservations Platform for Restaurants</h2>
      <div className="restaurant-content__pop-up">
        <div className="restaurant-content__pop-up-text flex flex-column">
          <span>Meet Waitly: The game-changer in the world of waitlist and reservations management.</span>
          <div>
            <CustomButton fill round onClick={openModal} className="restaurant-open-modal-btn">
              Try Waitly Pro FREE
            </CustomButton>
          </div>
        </div>
        <div className="restaurant-ipad-img-wrapper">
          <img src={signUpIpadRestaurantImage} className="restaurant-ipad-img" />
        </div>
      </div>
      <div className="restaurant-content__list">
        <ul>
          <li>Save 60% over similar platforms</li>
          <li>Reduce staffing needs with Waitlist Self Check-in</li>
          <li>More bookings with Reserve with Google</li>
          <li>Reduce customer frustration</li>
          <li>Reduce your wait times</li>
        </ul>
      </div>
      {FreeOffer}
      <hr />
      {/* reserve section start */}
      <div className="restaurant-reserve-section">
        <div className="restaurant-reserve-section__website">
          <div className="restaurant-reserve-section__website__header">Let your customers reserve on your website</div>
          <div className="restaurant-reserve-section__website__image">
            <img src={reserveSectionRestaurantImage} />
          </div>
        </div>
        <div className="restaurant-reserve-section__google">
          <div className="restaurant-reserve-section__google__header">
            <div className="restaurant-reserve-section__website__header">Or reserve directly from Google</div>
          </div>
          <div className="restaurant-reserve-section__google__image">
            <img src={reserveSectionRestraurantGoogleImage} />
          </div>
          <div className="restaurant-reserve-section__google__reserve-logo">
            <img src={rwgLogo} />
          </div>
        </div>
      </div>
      {/* reserve section end */}
      {/*feedback start*/}
      <div className="restaurant-feedback-img-wrapper-mobile">
        <img src={feedbackRestaurantImage} className="restaurant-feedback-img-mobile" />
      </div>
      <div className="restaurant-feedback">
        <div className="restaurant-feedback__wrapper">
          <div className="restaurant-feedback-img-wrapper">
            <img src={feedbackRestaurantImage} className="restaurant-feedback-img" />
          </div>
          <div className="restaurant-feedback__container">
            <div className="restaurant-feedback__stars">
              <img src={starsRestaurantImage} className="stars-restaurant-feedback-img" />
            </div>
            <div className="restaurant-feedback__quot">
              "Waitly customizable app improved our busy restaurant by transforming waitlists from a hassle to a breeze,
              providing clarity to both staff and guests. Highly recommend!"
            </div>

            <div className="restaurant-feedback__name">- Doug, Whitefish Lake Restaurant</div>

            <div className="restaurant-feedback__text">
              Over 600 restaurants trust Waitly with their customer experience!
            </div>

            <div>
              <CustomButton fill round onClick={openModal} className="restaurant-open-modal-btn">
                Streamline Your Waitlist
              </CustomButton>
            </div>
          </div>
        </div>
      </div>
      {/*feedback end*/}
      {/* #1 start */}
      <div className="restaurant-sections restaurant-sections__1">
        <div>
          <img src={section1RestaurantImage} className="restaurant-sections__1__image" />
        </div>
        <div className="restaurant-sections__content">
          <span className="restaurant-sections__number">1</span>
          <span className="restaurant-sections__text">Guests get added to the waitlist</span>
        </div>
      </div>
      {/* #1 end */}
      {/* #2 start */}
      <div className="restaurant-sections restaurant-sections__2">
        <div className="restaurant-sections__content">
          <span className="restaurant-sections__number">2</span>
          <span className="restaurant-sections__text">They see their place in line</span>
        </div>
        <div>
          <img src={section2RestaurantImage} className="restaurant-sections__2__image" />
        </div>
      </div>
      {/* #2 end */}
      {/* #3 start */}
      <div className="restaurant-sections restaurant-sections__3">
        <div>
          <img src={section3RestaurantImage} className="restaurant-sections__3__image" />
        </div>
        <div className="restaurant-sections__content">
          <span className="restaurant-sections__number">3</span>
          <span className="restaurant-sections__text">Then receive a text when their table is ready</span>
        </div>
      </div>
      {/* #3 end */}
      <hr />
      <div>
        <div className="app-store-btn-wrapper">
          <a
            href="https://apps.apple.com/app/apple-store/id1437729300?pt=1717824&ct=RestaurantLanding&mt=8"
            target="_blank"
          >
            <AppStoreIcon className="app-store-btn" />
          </a>
        </div>
        <div className="limited-time-offer">Limited Time Offer!</div>
        {FreeOffer}
        <div className="streamline-text">
          <span>Streamline Your Restaurant Reservations Today!</span>
        </div>
      </div>
    </div>
  );
};

export const USER_INFO_TYPES = {
  INVITE_USER: "INVITE_USER",
  CREATE_USER: "CREATE_USER",
  UPDATE_USER: "UPDATE_USER"
};

export const USER_INFO_TEXT_VARIABLES = {
  HEADER: {
    [USER_INFO_TYPES.CREATE_USER]: "Create User",
    [USER_INFO_TYPES.INVITE_USER]: "Invite User",
    [USER_INFO_TYPES.UPDATE_USER]: "Update User"
  },
  SUCCESS_BTN_TEXT: {
    [USER_INFO_TYPES.CREATE_USER]: "Create User",
    [USER_INFO_TYPES.INVITE_USER]: "Send Invite",
    [USER_INFO_TYPES.UPDATE_USER]: "Update"
  },
  CANCEL_BTN_TEXT: {
    [USER_INFO_TYPES.CREATE_USER]: "Back",
    [USER_INFO_TYPES.INVITE_USER]: "Back",
    [USER_INFO_TYPES.UPDATE_USER]: "Cancel"
  }
};

export const ADD_USER_PAGES = {
  CHOOSE_TYPE_PAGE: "CHOOSE_TYPE_PAGE",
  INVITE_USER_PAGE: "INVITE_USER_PAGE",
  CREATE_USER_PAGE: "CREATE_USER_PAGE"
};

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Card from "../Card/Card";
import UserInfo from "../UserInfo/UserInfo";
import { USER_INFO_TYPES, ADD_USER_PAGES } from "../../constants/user-info";
import ChooseTypePage from "./pages/ChooseTypePage";

const AddUser = ({ showModal, closeModal }) => {
  const [activeType, setActiveType] = useState("");
  const [page, setPage] = useState(ADD_USER_PAGES.CHOOSE_TYPE_PAGE);
  const [error, setError] = useState("");

  const isActive = type => {
    return Boolean(type === activeType);
  };

  const submitChooseType = () => {
    if (!activeType) {
      return setError("Please, choose type of adding user");
    }
    setPage(
      activeType === USER_INFO_TYPES.CREATE_USER ? ADD_USER_PAGES.CREATE_USER_PAGE : ADD_USER_PAGES.INVITE_USER_PAGE
    );
  };

  const getPage = () => {
    switch (page) {
      case ADD_USER_PAGES.CHOOSE_TYPE_PAGE:
        return (
          <ChooseTypePage
            error={error}
            closeModal={closeModal}
            isActive={isActive}
            setActiveType={setActiveType}
            onSubmit={submitChooseType}
          />
        );
      case ADD_USER_PAGES.CREATE_USER_PAGE:
        return (
          <UserInfo
            type={USER_INFO_TYPES.CREATE_USER}
            goBack={() => setPage(ADD_USER_PAGES.CHOOSE_TYPE_PAGE)}
            closeModal={closeModal}
          />
        );
      case ADD_USER_PAGES.INVITE_USER_PAGE:
        return (
          <UserInfo
            type={USER_INFO_TYPES.INVITE_USER}
            goBack={() => setPage(ADD_USER_PAGES.CHOOSE_TYPE_PAGE)}
            closeModal={closeModal}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal dialogClassName="add-guest-modal add-guest-modal__user" show={showModal} onHide={closeModal}>
      <Card content={getPage()} />
    </Modal>
  );
};

export default AddUser;

/* eslint-disable react/no-string-refs */
import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// this is used to create scrollbars on windows devices like the ones from apple devices
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// react component that creates notifications (like some alerts with messages)
import NotificationSystem from "react-notification-system";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import { connect } from "react-redux";
// dinamically create dashboard routes
import routes from "../routes";

// style for notifications
import { style } from "variables/Variables";
import { getGuestsListHistory } from "services/visits/visits.service";
import { getGuestsList } from "services/visits/visits.service";
import { VisitStatus } from "services/visits/visits.service";
import { getDashboardStats, getBusinessStats } from "services/aggregates/aggregates.service";
import { setWaitingVisitors, setHistoryVisitors, setAllVisitors, setMessages } from "../store/actions/visit.actions";

import { setDashboardStats } from "../store/actions/aggregate.actions";
import { setBusiness, setBusinesses } from "../store/actions/business.actions";
import { setPrices } from "../store/actions/prices.actions";
import { setRegions } from "../store/actions/regions.actions";
import { setAccount } from "../store/actions/account.actions";
import { setConfig } from "../store/actions/config.actions";
import { setSquareInfo } from "../store/actions/square.actions";
import firebase from "firebase/compat/app";
import "firebase/analytics";
import { getTodaysMessages } from "services/messages/messages.service";
import { getBusinessById } from "services/businesses/businesses.service";

import SweetAlert from "react-bootstrap-sweetalert";
import { closeToLimitNotification } from "constants/messages";
import { checkIfCloseToLimit, getAccountById, subscribeToRegions } from "services/account/account.service";
import { loadBusinessesByAccountId } from "services/businesses/businesses.service";
import { getPrices } from "services/profile/profile.service";
import { isNotEnterprise } from "services/plans/plans.helper";
import { displayDashboard, displayCharts, displayCustomers } from "configs/access";
import { getSquareInfo } from "services/square/square.service";
import VerifyBusinessModal from "../components/VerifyBusinessModal/VerifyBusinessModal";
import VerifyMobileNumberModal from "../components/VerifyMobileNumberModal/VerifyMobileNumberModal";
import { fetchAndActivate, getRemoteConfig, getAll } from "firebase/remote-config";
import { PHONE_CONFIG_STATUSES } from "constants/verification";
import ReactPixel from "react-facebook-pixel";
import { environment } from "configs/environment";
import LoadingPage from "views/Pages/LoadingPage";
import ThankYouAndVideoModal from "components/ThankYouAndVideoModal/ThankYouAndVideoModal";
import { logEvent } from "services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "constants/firebase";
import FreeTrialTourModal from "components/FreeTrialTourModal/FreeTrialTourModal";

var ps;

class Dashboard extends Component {
  constructor(props) {
    super(props);
    ReactPixel.init(environment.metaPixel, { em: props.user.email }, { autoConfig: true, debug: true });
    firebase.analytics().setUserId(props.user.uid);
    this.state = {
      initialHash: props.initialHash,
      _notificationSystem: null,
      color: "black",
      hasImage: true,
      navbar: false,
      showInfoAlert: false,
      showVerifyModal: false,
      showVerifyMobileModal: "off",
      modalDisplayed: false,
      mini: false,
      onShowInfoModal: false,
      freeTrialTourModal: false,
      showThankYouAndVideo: false,
      fixedClasses: "dropdown show-dropdown open",
      plan: ""
    };
  }

  subscribeObject = {};

  shouldDisplayMobileVerification() {
    return (
      this.props.account?.plan &&
      this.props.account.plan !== "free" &&
      this.props.account?.phoneConfig?.brandId &&
      this.props.account?.phoneConfig?.brandStatus === "UNVERIFIED"
    );
  }

  shouldDisplayVerificationModal(e) {
    return (
      this.props.account?.plan &&
      this.props.account.plan !== "free" &&
      !(
        this.props.account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.VERIFIED ||
        this.props.account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.SUBMITTED ||
        this.props.account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.PENDING
      ) &&
      !this.props.account?.phoneConfig?.brandId &&
      !this.props.location.pathname.includes("settings") &&
      !this.state.modalDisplayed &&
      !this.alreadyVerified()
    );
  }
  componentDidMount() {
    (async () => {
      await this.subscribeToFirebaseChanges();
      const remoteConfig = getRemoteConfig();
      remoteConfig.settings.minimumFetchIntervalMillis = 10000;
      await fetchAndActivate(remoteConfig);

      const remoteValues = getAll(remoteConfig);

      const remoteValuesKeys = Object.keys(remoteValues);
      const parsedRemoteValues = {};

      remoteValuesKeys.forEach(k => {
        parsedRemoteValues[k] = remoteValues[k]._value;
      });

      this.props.setConfig(parsedRemoteValues);
      if (this.shouldDisplayMobileVerification() || params.action === "promptMobile") {
        this.showVerifyMobileModal("prompt");
      }
    })();
    const params = new Proxy(new URLSearchParams(this.props.location.search), {
      get: (searchParams, prop) => searchParams.get(prop)
    });
    const showVerifyModal = params.action === "verify";
    const showFreeTrialTourModal = params.action === "freeTrial" && params.source === "tour";

    if (showVerifyModal && this.shouldDisplayVerificationModal(window)) {
      this.showVerifyModal();
      if (params.source === "tour") {
        logEvent(FIREBASE_EVENTS.intro_tour_registration);
      }
    } else if (showFreeTrialTourModal) {
      this.showFreeTrialTourModal();
    }

    if (!document.URL.includes(this.props.initialHash) && this.props.initialHash) {
      const IHwithQ = "?" + (this.props.initialHash.split("?")[1] ?? "");
      this.hideConfirmAlert();
      let url =
        document.URL.split("#")[0] +
        (this.props.initialHash.includes("auth")
          ? "#/admin/waitlist" + (IHwithQ.length > 1 ? IHwithQ : "")
          : this.props.initialHash);
      window.location.replace(url);
      const showVerifyModal = url.includes("action=verify");
      if (showVerifyModal) {
        this.showVerifyModal();
      }
    }
    this.setState({ _notificationSystem: this.refs.notificationSystem });
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  componentDidUpdate(e) {
    if (navigator.platform.indexOf("Win") > -1) {
      setTimeout(() => {
        ps.update();
      }, 350);
    }
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
    if (
      window.innerWidth < 993 &&
      e.history.action === "PUSH" &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }

    if (this.shouldDisplayVerificationModal(e)) {
      window.location.replace("#" + this.props.location.pathname + "?action=verify");
      // eslint-disable-next-line
      this.setState({ modalDisplayed: true });
    }

    if (e.location.search !== this.props.location.search) {
      const params = new Proxy(new URLSearchParams(this.props.location.search), {
        get: (searchParams, prop) => searchParams.get(prop)
      });
      const showVerifyModal = params.action === "verify";
      const showTourVideoModal = params.source === "tour" && params.action === "video" && !showVerifyModal;
      const showFreeTrialTourModal =
        params.source === "tour" && params.action === "freeTrial" && !showVerifyModal && !showTourVideoModal;
      if (showVerifyModal) {
        if (
          this.alreadyVerified() ||
          this.props.account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.SUBMITTED ||
          this.props.account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.PENDING
        ) {
          this.showAlreadyVerified();
        } else {
          this.showVerifyModal();
        }
      } else if (showTourVideoModal) {
        this.showThankYouAndVideo();
      } else if (showFreeTrialTourModal) {
        logEvent(FIREBASE_EVENTS.show_trial_offers);
        this.showFreeTrialTourModal();
      }
    }
  }

  UNSAFE_componentWillMount() {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
    if (window.localStorage.getItem("mini") === "true") {
      this.setState({ mini: true });
      document.body.classList.add("sidebar-mini");
    }
  }

  stripHash(value) {
    if (value) {
      return value.substr(value.indexOf("#"));
    }
    return value;
  }

  alreadyVerified() {
    const status = this.props.account?.phoneConfig?.status;
    return (
      status === PHONE_CONFIG_STATUSES.VERIFIED ||
      status === PHONE_CONFIG_STATUSES.SUBMITTED ||
      status === PHONE_CONFIG_STATUSES.PENDING
    );
  }

  onSetSubscriptionObject = businessId => {
    if (businessId) {
      this.subscribeObject.getGuestsList = getGuestsList(businessId, this.props.user.accountId).subscribe(result => {
        const waitingVisitors = result.filter(
          d => d.data().status === VisitStatus.waiting || d.data().status === VisitStatus.reserved
        );
        this.props.setWaitingVisitors(waitingVisitors);
        this.props.setAllVisitors(result);
      });
      this.subscribeObject.getGuestsListHistory = getGuestsListHistory(businessId, this.props.user.accountId).subscribe(
        historyVisitors => {
          this.props.setHistoryVisitors(historyVisitors);
        }
      );
      this.subscribeObject.getTodaysMessages = getTodaysMessages(businessId, this.props.user.accountId).subscribe(
        messages => {
          this.props.setMessages(messages);
        }
      );
    }
  };

  async subscribeToFirebaseChanges() {
    this.onSetSubscriptionObject(this.props.user.businessId);
    getBusinessById(this.props.user.businessId).then(business => {
      this.props.setBusiness({ businessId: business.id, ...business.data() });
    });

    const a = await getAccountById(this.props.user.accountId);
    this.props.setAccount({ id: a.id, ...a.data() });
    getPrices()
      .then(prices => {
        if (this.props.firstLogin && prices.introTour) {
          this.props.history.replace(this.props.location.pathname + "?action=freeTrial&source=tour");
          logEvent(FIREBASE_EVENTS.intro_tour_activate);
        }
        this.props.setPrices(prices);
      })
      .catch(err => {
        console.log("error getting prices", err);
      });
    getSquareInfo().then(squareInfo => {
      this.props.setSquareInfo(squareInfo);
    });
    this.setState({
      plan: a.data().plan
    });
    if (isNotEnterprise(a.data().plan)) {
      checkIfCloseToLimit(this.props.user.accountId).subscribe(account => {
        this.props.setAccount({ id: account.id, ...account });
        this.setState({
          plan: account.plan
        });
        if (account.visitCount === 75 && account.plan === "free") {
          this.setState({
            showInfoAlert: true
          });
        }
      });
    }
    if (
      this.props.user.claims.role === "admin" ||
      this.props.user.claims.role === "area-manager" ||
      this.props.user.claims.role === "regional-manager" ||
      this.props.user.claims.role === "analyst"
    ) {
      getDashboardStats(this.props.user).subscribe(dashboardStats => {
        this.props.setDashboardStats(dashboardStats);
      });
    } else {
      getBusinessStats(this.props.user).subscribe(dashboardStats => {
        this.props.setDashboardStats(dashboardStats);
      });
    }

    let subscriber;
    subscribeToRegions(this.props.user.accountId).subscribe(regions => {
      const data = regions
        .map(doc => ({
          ...doc.data(),
          id: doc.id
        }))
        .sort((a, b) =>
          a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
        );
      this.props.setRegions(data);
      if (subscriber) {
        subscriber.unsubscribe();
      }

      subscriber = loadBusinessesByAccountId(this.props.user, data).subscribe(this.businesssCallback);
    });
  }

  showFreeTrialTourModal = () => {
    logEvent(FIREBASE_EVENTS.intro_tour_free_trial);
    this.setState({
      freeTrialTourModal: true
    });
  };

  showThankYouAndVideo = () => {
    this.setState({
      showThankYouAndVideo: true
    });
  };

  businesssCallback = businesses => {
    const data = businesses
      .map(doc => {
        const result = {
          ...doc.data(),
          businessId: doc.id
        };
        if (this.props.regions) {
          const region = this.props.regions.find(r => r.id === doc.data().regionId);
          if (region) {
            result["parentRegion"] = region.parentRegion;
          }
        }

        return result;
      })
      .sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
      );
    this.props.setBusinesses(data);
    if (this.props.business) {
      this.props.setBusiness(data.find(d => d.businessId === this.props.business.businessId));
    }
  };

  unSubscribeToFirebaseChanges = businessId => {
    this.subscribeObject.getGuestsList.unsubscribe();
    this.subscribeObject.getGuestsListHistory.unsubscribe();
    this.subscribeObject.getTodaysMessages.unsubscribe();
    this.onSetSubscriptionObject(businessId);
  };
  // function that shows/hides notifications - it was put here, because the wrapper div has to be outside the main-panel class div
  handleNotificationClick = position => {
    var color = Math.floor(Math.random() * 4 + 1);
    var level;
    switch (color) {
      case 1:
        level = "success";
        break;
      case 2:
        level = "warning";
        break;
      case 3:
        level = "error";
        break;
      case 4:
        level = "info";
        break;
      default:
        break;
    }
    this.state._notificationSystem.addNotification({
      title: <span data-notify="icon" className="pe-7s-gift" />,
      message: (
        <div>
          Welcome to <b>Light Bootstrap Dashboard</b> - a beautiful freebie for every web developer.
        </div>
      ),
      level: level,
      position: position,
      autoDismiss: 15
    });
  };
  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleHasImage = hasImage => {
    this.setState({ hasImage: hasImage });
  };
  handleNavbarClick = navbar => {
    this.setState({ navbar: navbar });
  };
  handleMiniClick = () => {
    const { mini } = this.state;
    this.setState({ mini: !mini });
    if (mini) {
      window.localStorage.setItem("mini", "false");
      document.body.classList.remove("sidebar-mini");
    } else {
      window.localStorage.setItem("mini", "true");
      document.body.classList.add("sidebar-mini");
    }
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === "dropdown") {
      this.setState({ fixedClasses: "dropdown show-dropdown open" });
    } else {
      this.setState({ fixedClasses: "dropdown" });
    }
  };
  hasReservations = () => {
    if (this.props.business && this.props.business.settings.enableReservations) {
      return true;
    }
    return false;
  };

  onGoToUpgrade = () => {
    this.hideConfirmAlert();
    let url = document.URL.split("#")[0] + "#/admin/upgrade";
    window.location.replace(url);
  };
  hideConfirmAlert = () => {
    this.setState({
      showInfoAlert: false
    });
  };
  closeVerifyModal = showVerify => {
    const params = new URLSearchParams(this.props.location.search);
    const isTour = params.get("source") === "tour";

    let sv = false;
    if (showVerify && !isTour) {
      sv = true;
    }
    this.setState({
      showVerifyModal: false,
      showVerifyConfirmation: sv
    });
    this.props.history.replace(
      this.props.location.pathname +
        this.props.location.search
          .replace("action=verify&", "")
          .replace("&action=verify", "")
          .replace("action=verify", "")
    );
    if (isTour) {
      logEvent(FIREBASE_EVENTS.intro_tour_quick_start);
      this.showThankYouAndVideo();
    }
  };
  closeVerifyMobileModal = showVerify => {
    let sv = false;
    if (showVerify) {
      sv = true;
    }
    this.setState({
      showVerifyMobileModal: "off",
      showVerifyConfirmation: sv
    });
    this.props.history.replace(
      this.props.location.pathname +
        this.props.location.search
          .replace("&action=verifyMobile", "")
          .replace("action=verifyMobile", "")
          .replace("&action=promptMobile", "")
          .replace("action=promptMobile", "")
    );
  };
  closeThankYouAndVideo = () => {
    this.props.history.replace(
      this.props.location.pathname +
        this.props.location.search
          .replace("&action=video", "")
          .replace("&source=tour", "")
          .replace("?action=video", "")
          .replace("?source=tour", "")
    );
    this.setState({ showThankYouAndVideo: false });
  };

  closeFreeTrialTourModal = () => {
    this.props.history.replace(
      this.props.location.pathname +
        this.props.location.search
          .replace("&action=freeTrial", "")
          .replace("&source=tour", "")
          .replace("?action=freeTrial", "")
          .replace("?source=tour", "")
    );
    this.setState({ freeTrialTourModal: false });
    this.showThankYouAndVideo();
  };

  onFreeTrialWithoutCardSuccess = () => {
    this.props.history.replace(this.props.location.pathname + "?success=pro&source=tour");
    this.setState({ freeTrialTourModal: false });
  };

  showVerifyConfirmation = () => {
    this.setState({
      showVerifyConfirmation: true
    });
  };
  hideVerifyConfirmation = () => {
    this.setState({
      showVerifyConfirmation: false
    });
  };
  showVerifyModal = () => {
    if (this.alreadyVerified()) {
      this.showAlreadyVerified();
    } else {
      this.setState({
        showVerifyModal: true
      });
    }
  };
  showVerifyMobileModal = value => {
    this.setState({
      showVerifyMobileModal: value
    });
  };
  showAlreadyVerified = () => {
    this.setState({
      showAlreadyVerified: true
    });
  };
  hideAlreadyVerified = () => {
    this.setState({
      showAlreadyVerified: false
    });
    this.props.history.replace(
      this.props.location.pathname +
        this.props.location.search.replace("&action=verify", "").replace("action=verify", "")
    );
  };
  verifyMobileCallback = () => {
    this.showVerifyMobileModal("verify");
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (this.props.location.pathname === "/admin/dashboard" && !displayDashboard(this.state.plan, this.props.user)) {
        return <Redirect key={this.props.location.pathname} from="/admin/dashboard" to="/admin/waitlist" />;
      }
      if (this.props.location.pathname === "/admin/charts" && !displayCharts(this.state.plan, this.props.user)) {
        return <Redirect key={this.props.location.pathname} from="/admin/charts" to="/admin/waitlist" />;
      }
      if (
        this.props.location.pathname === "/admin/customers" &&
        !displayCustomers(this.state.plan, this.props.user, this.props.account)
      ) {
        return <Redirect key={this.props.location.pathname} from="/admin/customers" to="/admin/waitlist" />;
      }
      if (this.props.location.pathname === "/admin/dashboard" && !displayDashboard(this.state.plan, this.props.user)) {
        return <Redirect key={this.props.location.pathname} from="/admin/dashboard" to="/admin/waitlist" />;
      }
      if (this.props.location.pathname === "/admin/reservations" && !this.hasReservations()) {
        return <Redirect key={this.props.location.pathname} from="/admin/reservations" to="/admin/waitlist" />;
      }
      if (
        this.props.location.pathname === "/admin/settings" &&
        (this.props.user.claims.role === "user" ||
          (this.props.account?.settings?.adminsOnly && this.props.user.claims.role !== "admin"))
      ) {
        return <Redirect key={this.props.location.pathname} from="/admin/settings" to="/admin/waitlist" />;
      }
      if (this.props.location.pathname === "/admin/profile" && this.props.user.claims.role === "user") {
        return <Redirect key={this.props.location.pathname} from="/admin/profile" to="/admin/waitlist" />;
      }
      if (this.props.location.pathname === prop.layout + prop.path) {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={routeProps => (
              <prop.component
                plan={this.state.plan}
                user={this.props.user}
                {...routeProps}
                handleClick={this.handleNotificationClick}
              />
            )}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    if (!this.props.business) {
      return (
        <div ref="mainPanel">
          <LoadingPage />
        </div>
      );
    }
    return (
      <div className="wrapper hidden-x-overflow">
        {this.state.showThankYouAndVideo && (
          <ThankYouAndVideoModal
            close={() => this.closeThankYouAndVideo()}
            user={this.props.user}
            show={this.state.showThankYouAndVideo}
          />
        )}
        {this.state.freeTrialTourModal && (
          <FreeTrialTourModal
            close={() => this.closeFreeTrialTourModal()}
            user={this.props.user}
            show={this.state.freeTrialTourModal}
            onFreeTrialSuccess={() => this.onFreeTrialWithoutCardSuccess()}
          />
        )}
        {this.state.showInfoAlert && (
          <SweetAlert
            showCancel
            cancelBtnText="Cancel"
            confirmBtnText="Upgrade"
            onCancel={this.hideConfirmAlert}
            title={closeToLimitNotification.title}
            onConfirm={this.onGoToUpgrade}
          >
            {closeToLimitNotification.message}
          </SweetAlert>
        )}
        {this.state.showVerifyModal && (
          <VerifyBusinessModal
            close={this.closeVerifyModal}
            user={this.props.user}
            show={this.state.showVerifyModal}
            verifyMobileCallback={this.verifyMobileCallback}
          />
        )}
        {this.state.showVerifyMobileModal !== "off" && (
          <VerifyMobileNumberModal
            close={this.closeVerifyMobileModal}
            user={this.props.user}
            show={this.state.showVerifyMobileModal}
            mode={this.state.showVerifyMobileModal}
          />
        )}
        {this.state.showAlreadyVerified && (
          <SweetAlert
            type="success"
            confirmBtnText={"OK"}
            title={this.alreadyVerified() ? `Already Registered` : "Info already submitted"}
            onConfirm={this.hideAlreadyVerified}
          >
            <p style={{ fontSize: "20px", color: "#575757" }}>
              {this.alreadyVerified()
                ? "Your business has already been registered."
                : `Your business information has already been submitted for review.`}
            </p>
          </SweetAlert>
        )}
        {this.state.showVerifyConfirmation && (
          <SweetAlert type="success" confirmBtnText={"OK"} title={"Thank you!"} onConfirm={this.hideVerifyConfirmation}>
            <p style={{ fontSize: "20px", color: "#575757" }}>
              Your business information has been submitted for review. You will recieve an email when once your business
              has been registered.
            </p>
          </SweetAlert>
        )}
        <NotificationSystem ref="notificationSystem" style={style} />
        <Sidebar
          {...this.props}
          image={this.state.image}
          color={this.state.color}
          hasImage={this.state.hasImage}
          mini={this.state.mini}
          plan={this.state.plan}
        />
        <div
          className={
            "main-panel" + (this.props.location.pathname === "/maps/full-screen-maps" ? " main-panel-maps" : "")
          }
          ref="mainPanel"
        >
          <AdminNavbar
            onResetSubscriptions={this.unSubscribeToFirebaseChanges}
            {...this.props}
            plan={this.state.plan}
            handleMiniClick={this.handleMiniClick}
            navbar={this.state.navbar}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          {/* <FixedPlugin
            handleImageClick={this.handleImageClick}
            handleColorClick={this.handleColorClick}
            handleHasImage={this.handleHasImage}
            handleNavbarClick={this.handleNavbarClick}
            handleMiniClick={this.handleMiniClick}
            bgColor={this.state["color"]}
            bgImage={this.state["image"]}
            mini={this.state["mini"]}
            handleFixedClick={this.handleFixedClick}
            fixedClasses={this.state.fixedClasses}
          /> */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { business, regions, account, square, config } = state;

  const props = {};
  if (regions) {
    props.regions = regions.regions;
  }
  if (business) {
    props.business = business.business;
    props.businesses = business.businesses;
  }
  if (account) {
    props.account = account.account;
  }
  if (square) {
    props.squareInfo = square.squareInfo;
  }
  if (config) {
    props.config = config;
  }
  return props;
};

export default connect(mapStateToProps, {
  setWaitingVisitors,
  setAllVisitors,
  setHistoryVisitors,
  setDashboardStats,
  setMessages,
  setBusinesses,
  setBusiness,
  setRegions,
  setAccount,
  setSquareInfo,
  setConfig,
  setPrices
})(Dashboard);

import firebase from "firebase/compat/app";
import "firebase/compat/analytics";
const analytics = firebase.analytics;

export function logEvent(name, props) {
  if (props) {
    analytics().logEvent(name, props);
  } else {
    analytics().logEvent(name);
  }
}

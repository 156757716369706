import { Observable } from "rxjs";
import { collections } from "services";
import { db, firebase } from "../../firebase";

export async function addNewBusiness(business) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/locations`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(business)
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering addNewBusiness function", error);
      throw error;
    });
}

export async function deleteBusiness(businessId, moveUsersBusinessId) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/locations/delete?id=${businessId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(moveUsersBusinessId)
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering deleteBusiness function", error);
      throw error;
    });
}

export function loadBusinessesByAccountId(user, regions) {
  return new Observable(subscriber => {
    const removeListener = db
      .collection(collections.businesses)
      .where("accountId", "==", user.accountId)
      .onSnapshot(result => {
        if (user.claims.role === "admin" || user.claims.role === "analyst") {
          subscriber.next(result.docs);
        } else {
          const reg = getUserRegions(result.docs, user, regions);
          subscriber.next(result.docs.filter(b => reg.includes(b.data().regionId)));
        }
      });
    return function unsubscribe() {
      removeListener();
    };
  });
}

function getUserRegions(businesses, user, regions) {
  const business = businesses.find(b => {
    return b.id === user.businessId;
  });
  if (user.claims.role === "regional-manager" || user.claims.role === "analyst") {
    const parent = findParentRegion(business.data().regionId, regions);
    if (parent) {
      return regions.filter(r => parent === r.parentRegion).map(r => r.id);
    } else {
      return [business.data().regionId];
    }
  } else {
    return [business.data().regionId];
  }
}

function findParentRegion(regionId, regions) {
  const parent = regions.find(r => {
    return r.id === regionId;
  });

  return parent ? parent.parentRegion : undefined;
}

export function getDashboardBusinessesByAccountId(accountId) {
  return db
    .collection(collections.businesses)
    .where("accountId", "==", accountId)
    .get();
}

export function getBusinessById(id) {
  return db
    .collection(collections.businesses)
    .doc(id)
    .get();
}

export function getBusinessSettings(id) {
  return db
    .collection(collections.businesses)
    .doc(id)
    .get();
}

export function getBusinessResources(id) {
  return db
    .collection(collections.businesses)
    .doc(id)
    .collection("resources")
    .get();
}

export function deleteBusinessResource(businessId, resourceId) {
  return db
    .collection(collections.businesses)
    .doc(businessId)
    .collection("resources")
    .doc(resourceId)
    .delete();
}

export function updateBusinessResource(businessId, resourceId, resource) {
  if (resource && !resource.notificationText) {
    resource.notificationText = firebase.firestore.FieldValue.delete();
  }
  return db
    .collection(collections.businesses)
    .doc(businessId)
    .collection("resources")
    .doc(resourceId)
    .set(resource, { merge: true });
}

export function addBusinessResource(businessId, resource) {
  return db
    .collection(collections.businesses)
    .doc(businessId)
    .collection("resources")
    .add(resource);
}

export async function updateBussinessReservationsType(businessId, reservationsType) {
  const bRef = await db.collection(collections.businesses).doc(businessId);

  const b = (await bRef.get()).data();

  await bRef.set({ settings: { ...b.settings, reservationsType } }, { merge: true });
}

export async function addOrUpdateBussinessAddress(businessId, address) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/address`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify({ businessId, location: address })
  })
    .then(async response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error((await response.json()).error);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering addOrUpdateBussinessAddress function", error);
      throw error;
    });
}

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect, useContext } from "react";
import { Grid, Row, Col, Form, FormGroup, ControlLabel, HelpBlock, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

import "./RegisterPage.scss";
import BeatLoader from "react-spinners/BeatLoader";

import { signUp } from "../../services/auth/signup.js";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import { BUSINESS_TYPES } from "constants/business-types";
import { logEvent } from "services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "constants/firebase";
import waitlySquareImage from "../../assets/img/square-waitly.webp";
import signUpPJImage from "../../assets/img/pj__sign_up.webp";

import { Subject } from "rxjs";
import WaitlyFeatures from "components/WaitlyFeatures/WaitlyFeatures";
import ReactPixel from "react-facebook-pixel";
import { environment } from "configs/environment";
import cx from "classnames";
import { REGISTER_SOURCES } from "constants/register-sources";
import CustomButton from "components/CustomButton/CustomButton";
import { RegisterPagePJContent, RegisterPageRestaurantContent } from "views/Pages/RegisterPage.components";
import { useMediaQuery } from "hooks/useMediaQuery";
import { FirstLoginContext } from "context/FirstLoginContext";

function RegisterPage(props) {
  const [cardHidden, setCardHidden] = useState(true);
  const [source, setSource] = useState<string | null>("");
  const isMobile = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    let timeout = setTimeout(() => {
      setCardHidden(false);
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    let params = new URLSearchParams(props.location.search);

    setSource(params.get("source"));

    const destroy$ = new Subject();
    return () => {
      destroy$.next(true);
      destroy$.complete();
    };
  }, [props.location.search]);

  useEffect(() => {
    if (source === REGISTER_SOURCES.RESTAURANT) {
      logEvent(FIREBASE_EVENTS.landing_page_view, { customerSource: "restaurant" });
    } else if (source === REGISTER_SOURCES.PJ) {
      logEvent(FIREBASE_EVENTS.landing_page_view, { customerSource: "pj" });
    }
    if (source === REGISTER_SOURCES.RESTAURANT && isMobile) {
      document.body.style.overflowX = "hidden";
    } else {
      document.body.style.overflowX = "unset";
    }
  }, [source, isMobile]);

  return (
    <Grid>
      <Row>
        <Col>
          <RegisterForm source={source} hidden={cardHidden} />
        </Col>
      </Row>
    </Grid>
  );
}

function RegisterForm({ hidden, source }) {
  const { register, handleSubmit, errors, triggerValidation, formState } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, errors: errors2 } = useForm();
  const [showRegisterFormModal, setShowRegisterFormModal] = useState(false);
  const [authError, setAuthError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onSecondStep, setOnSecondStep] = useState(false);
  const [form, setForm] = useState({
    fullName: "",
    email: "",
    password: "",
    businessName: "",
    businessType: ""
  });
  const { setFirstLogin } = useContext(FirstLoginContext);

  useEffect(() => {
    if (form.businessType) {
      return;
    }
    if (source === REGISTER_SOURCES.RESTAURANT) {
      setForm({ ...form, businessType: BUSINESS_TYPES[0] });
    }
  }, [source]);

  const hasAllFields = form => {
    return (
      form.email &&
      form.email !== "" &&
      form.password &&
      form.password !== "" &&
      form.businessName &&
      form.businessName !== "" &&
      form.fullName &&
      form.fullName !== "" &&
      form.businessType &&
      form.businessType !== ""
    );
  };

  const onHandleSignUp = async frm => {
    if (loading) {
      return;
    }
    setLoading(true);
    const separatedName = onSeparateUserName(frm.fullName);
    const user = {
      firstName: separatedName[0],
      ...(separatedName[1] && { lastName: separatedName[1] }),
      email: frm.email.toLowerCase().trim(),
      password: frm.password,
      businessName: frm.businessName,
      businessType: frm.businessType
    };
    if (source !== "") {
      user.source = source;
    }
    setFirstLogin(true);
    const signupResult = await signUp(user);
    if (!signupResult.ok) {
      setAuthError(
        (signupResult as {
          ok: boolean;
          error: any;
        }).error
      );
      setForm({ ...form, businessName: "" });
      setOnSecondStep(false);
      setLoading(false);
    } else {
      const result = await (signupResult as Response).json();
      if (result.squareURL) {
        window.location.href = result.squareURL;
      }
      ReactPixel.init(environment.metaPixel, { em: user.email } as any, { autoConfig: true, debug: true });
      ReactPixel.pageView();
      ReactPixel.track("CompleteRegistration");
      logEvent(FIREBASE_EVENTS.sign_up, { businessType: user.businessType, customerSource: source });
      setLoading(false);
    }
  };

  const onSubmit = async data => {
    let mergedForm = { ...form, ...data };
    setForm(mergedForm);
    if (hasAllFields(mergedForm)) {
      onHandleSignUp(mergedForm);
    } else {
      logEvent(FIREBASE_EVENTS.landing_page_show_signup_step2, { customerSource: source });
      setOnSecondStep(true);
    }
  };

  const onSeparateUserName = fullName => {
    return fullName.split(" ");
  };

  const openRestaurantModal = () => {
    logEvent(FIREBASE_EVENTS.landing_page_show_signup_step1, { customerSource: "restaurant" });
    setShowRegisterFormModal(true);
  };

  const getTopContentBasedOnSource = source => {
    switch (source) {
      case REGISTER_SOURCES.PJ:
        return <RegisterPagePJContent />;
      case REGISTER_SOURCES.RESTAURANT:
        return <RegisterPageRestaurantContent openModal={() => openRestaurantModal()} />;

      default:
        return (
          <>
            <h2>Waitlist and Reservations for your business</h2>
            <h4>Create your free business account</h4>
          </>
        );
    }
  };

  const getLeftContentBasedOnSource = source => {
    switch (source) {
      case REGISTER_SOURCES.SQUARE:
      case REGISTER_SOURCES.SQUARE_WAITLY:
      case REGISTER_SOURCES.SQUARE_SUBSCRIPTION:
        return (
          <>
            <div className="features-section__list-item col-12 col-sm-12">
              <img className="features-section__list-item--img" src={waitlySquareImage} />
            </div>
            <WaitlyFeatures type="addSquareCustomers" />
            <WaitlyFeatures type="squareOnline" />
            <WaitlyFeatures type="squareLocations" />
          </>
        );
      case REGISTER_SOURCES.PJ:
        return (
          <>
            <div>
              <img src={signUpPJImage} className="pj-img" />
            </div>
          </>
        );
      case REGISTER_SOURCES.RESTAURANT:
        return <></>;
      default:
        return (
          <>
            <WaitlyFeatures type="addCustomers" />
            <WaitlyFeatures type="notifyGuests" />
            <WaitlyFeatures type="syncBetweenDevices" />
          </>
        );
    }
  };

  const submitBtnClasses = cx({
    "pj-btn": source === REGISTER_SOURCES.PJ
  });

  const forms = (
    <>
      {!onSecondStep ? (
        <Form
          id="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          className={cx({
            "register-modal": true,
            "register-modal__restaurant": source === REGISTER_SOURCES.RESTAURANT
          })}
        >
          <Card
            hidden={hidden}
            textCenter
            title={source === REGISTER_SOURCES.RESTAURANT ? "Try Free for 1 Month" : "Let's get started"}
            content={
              <>
                <div>
                  <FormGroup validationState={(!!errors.fullName && "error") || null}>
                    <ControlLabel>Full Name</ControlLabel>
                    <input
                      name="fullName"
                      className="form-control"
                      type="text"
                      id="fullNameId"
                      defaultValue={form.fullName}
                      placeholder="Full Name"
                      ref={register({
                        required: "Full Name is required",
                        maxLength: {
                          value: 80,
                          message: "Full Name length should be less than 80 characters"
                        }
                      })}
                    />
                    {errors.fullName && <HelpBlock>{(errors.fullName as any)?.message}</HelpBlock>}
                  </FormGroup>
                  <FormGroup validationState={(!!errors.email && "error") || null}>
                    <ControlLabel>Email</ControlLabel>
                    <input
                      name="email"
                      className="form-control"
                      type="email"
                      defaultValue={form.email}
                      placeholder="Email"
                      onChange={() => {
                        formState.touched.repeatEmail
                          ? triggerValidation(["email", "repeatEmail"])
                          : triggerValidation("email");
                      }}
                      ref={register({
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i,
                          message: "Please enter a valid email address"
                        }
                      })}
                    />
                    {errors.email && <HelpBlock>{(errors.email as any)?.message}</HelpBlock>}
                  </FormGroup>
                  <FormGroup validationState={(!!errors.password && "error") || null}>
                    <ControlLabel>Password</ControlLabel>
                    <input
                      name="password"
                      className="form-control"
                      type="password"
                      placeholder="Password"
                      defaultValue={form.password}
                      ref={register({
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password is must be at least 8 characters"
                        }
                      })}
                    />
                    {errors.password && <HelpBlock>{(errors.password as any)?.message}</HelpBlock>}
                  </FormGroup>
                </div>
                {authError && <small className="text-danger">{authError}</small>}
              </>
            }
            legend={
              <Button type="submit" bsStyle="primary" fill wd className={submitBtnClasses}>
                {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Create Account</span>}
              </Button>
            }
            ftTextCenter
          />
          <div className="signup__terms-container">
            <ControlLabel className="signup__terms-label">
              By signing up you agree to our
              <a
                className="signup__terms-link"
                href="https://www.waitly.com/terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of service
              </a>
              {" and"}
              <a
                className="signup__terms-link"
                href="https://www.waitly.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>
            </ControlLabel>
          </div>
        </Form>
      ) : (
        <Form
          id="form2"
          noValidate
          onSubmit={handleSubmit2(onSubmit)}
          className={cx({
            "register-modal": true,
            "register-modal__restaurant": source === REGISTER_SOURCES.RESTAURANT
          })}
        >
          <Card
            hidden={hidden}
            textCenter
            title={source === REGISTER_SOURCES.RESTAURANT ? "Let's register your business" : "One Last Step"}
            content={
              <>
                <p className="subheader-message">
                  Tell us your business name. We'll use this to personalize your text messages.
                </p>
                <div>
                  <input type="hidden" name="fullName" defaultValue={form.fullName} ref={register2()} />
                  <input type="hidden" name="email" defaultValue={form.email} ref={register2()} />
                  <input type="hidden" name="password" defaultValue={form.password} ref={register2()} />
                  <FormGroup validationState={(!!errors2.businessName && "error") || null}>
                    <ControlLabel>Business Name</ControlLabel>
                    <input
                      id="businessNameId"
                      name="businessName"
                      className="form-control"
                      type="text"
                      placeholder="Business Name"
                      ref={register2({
                        required: "Business Name is required",
                        maxLength: {
                          value: 40,
                          message: "Business Name length should be less than 40 characters"
                        }
                      })}
                    />
                    {errors2.businessName && <HelpBlock>{(errors2.businessName as any)?.message}</HelpBlock>}
                  </FormGroup>
                  <FormGroup validationState={(!!errors2.businessType && "error") || null}>
                    <ControlLabel>Type of Business</ControlLabel>
                    <select
                      className="form-control"
                      name="businessType"
                      defaultValue={form.businessType}
                      ref={register2({ required: "Type of Business is required" })}
                    >
                      {source === "pj" ? (
                        <option value="Permanent Jewelry">Permanent Jewelry</option>
                      ) : (
                        <>
                          <option value="">Select Type of Business</option>
                          {BUSINESS_TYPES.map((type, index) => {
                            return (
                              <option key={index} value={type}>
                                {type}
                              </option>
                            );
                          })}
                        </>
                      )}
                    </select>
                    {errors2.businessType && <HelpBlock>{(errors2.businessType as any)?.message}</HelpBlock>}
                  </FormGroup>
                </div>
                {authError && <small className="text-danger">{authError}</small>}
              </>
            }
            legend={
              <Button type="submit" bsStyle="primary" fill wd className={submitBtnClasses}>
                {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Finalize Account</span>}
              </Button>
            }
            ftTextCenter
          />
          <div className="signup__terms-container">
            <ControlLabel className="signup__terms-label">
              By signing up you agree to our
              <a
                className="signup__terms-link"
                href="https://www.waitly.com/terms/"
                target="_blank"
                rel="noopener noreferrer"
              >
                terms of service
              </a>
              {" and"}
              <a
                className="signup__terms-link"
                href="https://www.waitly.com/privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                privacy policy
              </a>
            </ControlLabel>
          </div>
        </Form>
      )}
    </>
  );

  const getRightContentBasedOnSource = source => {
    switch (source) {
      case REGISTER_SOURCES.RESTAURANT:
        return <></>;
      default:
        return forms;
    }
  };

  return (
    <>
      <Modal
        dialogClassName="sign-up-modal-wrapper"
        backdropClassName="dark-backdrop"
        show={showRegisterFormModal}
        onHide={() => {
          setShowRegisterFormModal(false);
        }}
      >
        <CustomButton
          className="sign-up-modal-wrapper__close-btn"
          simple
          onClick={() => {
            setShowRegisterFormModal(false);
          }}
        >
          <i className="fa fa-times" />
        </CustomButton>
        {forms}
      </Modal>
      <div style={{ paddingTop: "100px", textAlign: "center" }}>
        <Grid>
          <Row className="show-grid sign-up-header">{getTopContentBasedOnSource(source)}</Row>
          <Row className="features-section">
            <Col md={6} xs={12}>
              {getLeftContentBasedOnSource(source)}
            </Col>
            <Col md={6} xs={12}>
              {getRightContentBasedOnSource(source)}
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
}

export default RegisterPage;

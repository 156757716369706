import { collections } from "services";
import { db, firebase } from "../../firebase";

export async function getPendingInvites(accountId) {
  try {
    const invites = await db
      .collection(collections.inviteUser)
      .where("accountId", "==", accountId)
      .get();
    return invites.docs.map(doc => ({ id: doc.id, ...doc.data() })).filter(invite => invite.expired !== true);
  } catch (error) {
    return { error };
  }
}

export async function resendInvite(data) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/users/resend-invite`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(async response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error((await response.json()).error);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering inviteUser function", error);
      throw error;
    });
}

export async function deleteInvite(inviteId) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/users/invite?id=${inviteId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering deleteInvite function", error);
      throw error;
    });
}

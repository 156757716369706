import moment from "moment";

export const getTimeDifferenceInSeconds = (date, date2) => {
  const notifiedMoment = moment(date);
  let diffSeconds = moment().diff(notifiedMoment, "seconds");
  if (date2) {
    diffSeconds = moment(date2).diff(notifiedMoment, "seconds");
  }

  return `${Math.floor(diffSeconds / 60)}:${diffSeconds % 60 < 10 ? `0${diffSeconds % 60}` : diffSeconds % 60}`;
};

export const getTimeDifferenceInMinutes = (date, date2) => {
  const notifiedMoment = moment(date);
  let diffSeconds = moment().diff(notifiedMoment, "seconds");
  if (date2) {
    diffSeconds = moment(date2).diff(notifiedMoment, "seconds");
  }

  return `${Math.floor(diffSeconds / 60)}`;
};

export const notifyErrorAlert = {
  title: "Cannot notify",
  message: "The customer could not be notified"
};
export const noPhoneNumAlert = {
  title: "No phone number was specified"
};
export const noEmailAlert = {
  title: "No email address was specified"
};
export const spamAlert = {
  title: "Cannot notify",
  message: "As a courtesy to this customer, please wait at least one minute before notifying again"
};

export const autoNofications = {
  title: "Automatic Notifications",
  message: "This guest has already been notified. Additional notifications will be handled automatically."
};

export const optOut = {
  title: "Guest Opted Out",
  message:
    'This guest has replied "STOP" to opt out of receiving any further text messages. You must contact this guest through other means.'
};

export const invalidNumber = {
  title: "Invalid Phone Number",
  message:
    "The phone number provided is not a valid number or cannot receive text messages.  Update the phone number or try to contact this guest though other means."
};

export const closeToLimitNotification = {
  title: "Close to your limit",
  message: "You are currently at 75 of 100 guests. To prevent running out of text messages upgrade to Waitly Premium."
};

export const limitReachedNotification = {
  title: "Limit reached!",
  message:
    "To continue using the texting features, please upgrade to Waitly Premium. You can still use the waitlist without text messages."
};

export const deleteMessage = (item, name) => {
  return {
    title: `Delete ${item}?`,
    message: `Are you sure you want to delete ${name ?? "this " + item}?`
  };
};

export const resendMessage = item => {
  return {
    title: `Resend ${item}?`,
    message: `Are you sure you want to resend this ${item}?`
  };
};

export const upgradeInChatMessage = name => {
  return {
    title: `${name} is trying to reach you.`,
    message: "Upgrade to Waitlist to see incoming messages from guests."
  };
};

export const upgradeInChatMessageSecond = name => {
  return {
    title: `Need to text to ${name}?`,
    message: "Upgrade to Waitly Premium to enable two way messaging."
  };
};

export const moveUsersMessage = {
  title: "In order to delete this location the following users need to be moved tp a different location."
};

export const notifyGuestAlert = { title: "Send SMS", message: "Do you want to notify this guest?" };
export const notifyGuestEmailAlert = { title: "Send Email", message: "Do you want to notify this guest?" };
export const notifyPartyAlert = { title: "Send SMS", message: "Do you want to notify this guest?" };

export const chatMessagesKeywords = ["Start", "Stop", "Unstop"];

import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Col, ControlLabel, Form, FormGroup, Grid, HelpBlock, Row } from "react-bootstrap";
import { getUserInvitation } from "../../services/users/users.service";
import Card from "../../components/Card/Card";
import Button from "../../components/CustomButton/CustomButton";
import { useForm } from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";
import { acceptUserInvitation } from "../../services/auth/signup";
import { logEvent } from "../../services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "../../constants/firebase";

const AcceptInvite = props => {
  let [cardHidden, setCardHidden] = useState(true);

  const [showError, setShowError] = useState(false);
  const [userInvitation, setUserInvitation] = useState({});

  const userInvitationId = props.location.search.slice(props.location.search.lastIndexOf("=") + 1);

  useEffect(() => {
    onGetUserInvitation();
    // eslint-disable-next-line
  }, []);

  const onGetUserInvitation = async () => {
    try {
      const userInvitation = await getUserInvitation(userInvitationId);
      if (userInvitation.expired) {
        setShowError(true);
      } else {
        setUserInvitation(userInvitation);
      }
    } catch (error) {
      setShowError(true);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      setCardHidden(false);
    }, 700);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Grid>
      <Row>
        <Col md={4} sm={6} mdOffset={4} smOffset={3}>
          <AcceptInvitation
            hidden={cardHidden}
            userInvitationId={userInvitationId}
            showError={showError}
            userInvitation={userInvitation}
          />
        </Col>
      </Row>
    </Grid>
  );
};

function AcceptInvitation({ hidden, userInvitationId, showError, userInvitation }) {
  const { register, handleSubmit, errors, watch } = useForm();
  const password = useRef({});
  const history = useHistory();
  password.current = watch("password", "");

  const [loading, setLoading] = useState(false);

  const [authError, setAuthError] = useState(null);

  const onSubmit = async data => {
    setLoading(true);
    const authData = {
      password: data.password,
      userInvitationId,
      ...userInvitation
    };
    history.replace("accept-invite?submitted=true");
    const signupResult = await acceptUserInvitation(authData);

    if (!signupResult.ok) {
      setAuthError(signupResult.error);
      setLoading(false);
    } else {
      logEvent(FIREBASE_EVENTS.sign_up, { businessType: data.businessType });
    }
    setLoading(false);
  };

  return (
    <Form hidden={hidden} noValidate onSubmit={handleSubmit(onSubmit)} className="register-modal extra-padding">
      <Card
        hidden={hidden}
        textCenter
        title={showError ? "Invalid link" : "Create Password"}
        content={
          showError ? (
            <p style={{ textAlign: "center", fontSize: "14px", color: "#009900" }}>Sorry this link is not available.</p>
          ) : (
            <div>
              <p style={{ textAlign: "center", fontSize: "12px" }}>
                You've been invited to {userInvitation.accountName} Waitly account. Create a password to complete
                invitation.
              </p>
              <FormGroup validationState={(!!errors.password && "error") || null}>
                <ControlLabel>PASSWORD</ControlLabel>
                <input
                  name="password"
                  className="form-control"
                  type="password"
                  placeholder="Password"
                  ref={register({
                    required: true,
                    minLength: 8
                  })}
                />
                {errors.password && errors.password.type === "required" && <HelpBlock>Password is required</HelpBlock>}
                {errors.password && errors.password.type === "minLength" && (
                  <HelpBlock>Password is must be at least 8 characters</HelpBlock>
                )}
              </FormGroup>
              <FormGroup validationState={(!!errors.confirmPassword && "error") || null}>
                <ControlLabel>RE-ENTER PASSWORD</ControlLabel>
                <input
                  name="confirmPassword"
                  className="form-control"
                  type="password"
                  placeholder="Re-enter password"
                  ref={register({
                    required: true,
                    validate: value => {
                      return value === password.current;
                    }
                  })}
                />
                {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                  <HelpBlock>Re-entering password is required</HelpBlock>
                )}
                {errors.confirmPassword && errors.confirmPassword.type === "validate" && (
                  <HelpBlock>Passwords should match</HelpBlock>
                )}
              </FormGroup>
              {authError && <small className="text-danger">{authError}</small>}
            </div>
          )
        }
        legend={
          !showError && (
            <div>
              <Button type="submit" bsStyle="primary" fill wd>
                {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Login</span>}
              </Button>
              <p style={{ marginTop: "20px" }}>
                <a href="/#/auth/forgot-password">Forgot Password?</a>
              </p>
            </div>
          )
        }
        ftTextCenter
      />
      <div className="signup__terms-container">
        <ControlLabel className="signup__terms-label">
          By signing in, you agree to our
          <a
            className="signup__terms-link"
            href="https://www.waitly.com/terms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            terms of service
          </a>
          {" and"}
          <a
            className="signup__terms-link"
            href="https://www.waitly.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>
        </ControlLabel>
      </div>
    </Form>
  );
}

export default AcceptInvite;

import { SettingsTabContext } from "context/SettingsTabsContext";
import React from "react";
import MenuIcon from "@material-ui/icons/ArrowDropDownCircle";

type SettingsTitleProps = {
  title: any;
  config?: any;
};

const SettingsTitle = ({ title, config }: SettingsTitleProps) => {
  const { isBtnVisible, onBtnClick } = React.useContext(SettingsTabContext);

  const { withoutBorder } = config || {};
  const extraClasses = withoutBorder ? "settings-title--without-border" : null;
  return (
    <div className={`settings-title ${extraClasses} flex`}>
      {title}
      {isBtnVisible && (
        <div className="settings-mobile-tab-btn">
          <MenuIcon onClick={onBtnClick} />
        </div>
      )}
    </div>
  );
};

export default SettingsTitle;

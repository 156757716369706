import React, { useState, useEffect, useRef } from "react";
import "./DailyReservations.scss";
import moment from "moment-timezone";
import Button from "components/CustomButton/CustomButton";
import ReservationRow from "./ReservationRow";
import { DEFAULT_RESERVATION_DURATION, DEFAULT_HOURS } from "constants/reservation";
import { markMessagesAsRead } from "../../services/messages/messages.service";
import ChatModal from "../Chat/Chat";
import { NotifyGuestType } from "constants/visits";
import { logEvent } from "../../services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "../../constants/firebase";
import { invalidNumber, optOut } from "../../constants/messages";
import { DateTime } from "luxon";
import { getHours } from "./timeFunctions";

function DailyReservationsSchedule(props) {
  const {
    accountId,
    account,
    businessSettings,
    filter,
    resources,
    handleReservationModalShow,
    handleEdit,
    handleCheckIn,
    handleServe,
    handleNotify,
    handleNotifyAgain,
    handleDelete,
    handleReset,
    handleWalkout,
    addNote,
    sendMessage,
    disabled
  } = props;
  const [hours, setHours] = useState(null);
  const [chat, setChat] = useState(null);
  const [infoAlert, setInfoAlert] = useState(null);
  const scrollToRef = ref => {
    const div = document.getElementById("scheduleArea");
    if (ref.current) {
      div.scrollTo(0, ref.current.offsetTop);
    } else {
      div.scrollTo(0, 0);
    }
  };
  const myRef = useRef(null);

  moment.tz.setDefault(businessSettings.timezone);

  useEffect(() => {
    console.log("scrolling");
    setTimeout(() => {
      scrollToRef(myRef);
    }, 500);
  }, []);

  useEffect(() => {
    const resrs =
      resources && filter.resources ? resources.filter(r => filter.resources.some(rs => rs.name === r.name)) : [];
    const hours = getHours(props.hours || DEFAULT_HOURS, resrs, businessSettings);
    setHours(hours);
  }, [props.hours, resources, filter, businessSettings]);

  function getReservationsForHour(hour) {
    const result = [];
    if (!props.reservations.length) {
      return result;
    }
    props.reservations.forEach(reservation => {
      let resTime = DateTime.fromJSDate(reservation.data().reservationTime.toDate(), {
        zone: businessSettings.timezone
      });
      let startTime = DateTime.fromFormat(hour, "h:mm a", { zone: businessSettings.timezone });
      startTime = resTime.set({ hour: startTime.hour, minute: startTime.minute });
      let endTime = startTime.plus({ minutes: 15 });
      if (endTime && resTime >= startTime && resTime < endTime) {
        result.push(reservation);
      }
    });
    return result;
  }

  const openChatModal = reservation => {
    logEvent(FIREBASE_EVENTS.message_view);
    if (reservation.data().errorCode) {
      let message =
        reservation.data().errorCode === "spam" || reservation.data().errorCode === "invalid-number"
          ? invalidNumber
          : reservation.data().errorCode === "opt-out"
          ? optOut
          : {};
      setInfoAlert(message);
    }
    setChat(reservation);
    markMessagesAsRead(props.messages);
  };

  function addReservation(hour) {
    handleReservationModalShow(hour);
  }

  function isToday() {
    return moment(filter.currentDate).format("YYYY/MM/DD") === moment().format("YYYY/MM/DD");
  }

  const handleChatClose = () => {
    setInfoAlert(null);
    setChat(null);
  };

  function isCurrentHour(hour) {
    const time = moment(hour, "h:mm a").toDate();
    const timeDiff = moment().diff(time, "minutes");
    return timeDiff > 0 && timeDiff < DEFAULT_RESERVATION_DURATION;
  }

  const maxCountOfReservation =
    filter.resources && filter.resources.length
      ? filter.resources.reduce((sum, curr) => {
          return sum + curr.maxReservations || 0;
        }, 0)
      : 0;

  return (
    <div className="reservations-schedule">
      {chat && (
        <ChatModal
          accountId={accountId}
          sendMessage={sendMessage}
          chat={chat}
          error={infoAlert}
          plan={account.plan}
          type={NotifyGuestType.reservations}
          handleChatClose={handleChatClose}
        />
      )}
      <div className="reservations-schedule__header">
        <div className="reservations-schedule__col" style={{ width: "74px" }}>
          Time
        </div>
        <div className="reservations-schedule__col">Name</div>
        <div className="reservations-schedule__col reservations-schedule__col--center">Resource</div>
        <div className="reservations-schedule__col">Actions</div>
      </div>
      <div className="reservations-schedule__body" id="scheduleArea">
        {hours &&
          hours.length &&
          hours[filter.currentDate.getDay()].map((hour, i) => {
            const reservations = getReservationsForHour(hour);
            const isCurrentour = isToday() && isCurrentHour(hour);
            return (
              <div key={i} className="reservations-hour">
                {isCurrentour ? <div ref={myRef} /> : null}
                <div className={`reservations-hour__top ${isCurrentour ? "reservations-hour__top--red" : ""}`}>
                  <div className="reservations-hour__time">
                    {hour + " " + (reservations.length ? `(${reservations.length} of ${maxCountOfReservation})` : "")}
                  </div>
                  <div className="reservations-hour__line" />
                </div>
                <div className="hour-reservations">
                  {reservations.map((reservation, index) => (
                    <ReservationRow
                      disabled={disabled}
                      businessSettings={businessSettings}
                      account={account}
                      key={index}
                      index={index}
                      reservation={reservation}
                      openChatModal={openChatModal}
                      addNote={addNote}
                      handleEdit={handleEdit}
                      handleDelete={handleDelete}
                      handleWalkout={handleWalkout}
                      handleReset={handleReset}
                      handleCheckIn={handleCheckIn}
                      handleServe={handleServe}
                      handleNotify={handleNotify}
                      handleNotifyAgain={handleNotifyAgain}
                    ></ReservationRow>
                  ))}
                </div>

                <div className="reservations-hour__add-reservation">
                  <Button
                    disabled={(filter.resources ? reservations.length >= maxCountOfReservation : false) || disabled}
                    bsStyle="info"
                    pullRight
                    type="button"
                    onClick={() => addReservation(hour)}
                  >
                    Add Reservation
                  </Button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default DailyReservationsSchedule;

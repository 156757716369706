export const HEADERS = [{ name: "name" }, { name: "locations" }, { name: "actions" }];

export const ACTIONS = [
  {
    name: "edit",
    className: "mdi mdi-pencil",
    color: "#016BFF"
  },
  {
    name: "delete",
    className: "mdi mdi-delete-outline",
    color: "#6F6F6F"
  }
];

import { Grid, Col, Row, Modal, Form, FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import RegularTable from "views/RegularTable";
import "./Customers.scss";
import CustomButton from "components/CustomButton/CustomButton";
import toastr from "toastr";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { exportCustomersData, getCustomersData } from "services/customers/customers.service";
import { DateTime } from "luxon";
import { formatPhonePretty } from "helpers/phone.helper";
import { firebase } from "../firebase";
import { useDebounce } from "hooks/useDebounce";
import { useAfterMountEffect } from "hooks/useAfterMountEffect";
import cx from "classnames";
import { useMediaQuery } from "hooks/useMediaQuery";

const DEBOUNCE_TIME_MS = 200;

const Customers = props => {
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [loadingRows, setLoadingRows] = useState<boolean>(false);
  const [loadingAuth, setLoadingAuth] = useState<boolean>(false);
  const [successPasswordCheck, setSuccessPasswordCheck] = useState<boolean>(false);
  const [loadingExport, setLoadingExport] = useState<boolean>(false);
  const [fileUploaded, setFileUploaded] = useState<string>("");
  const [limit, setLimit] = useState<number>(25);
  const [page, setPage] = useState<number>(1);
  const debouncedPage = useDebounce<number>(page, DEBOUNCE_TIME_MS);
  const [orderBy, setOrderBy] = useState<{ column: string; direction: "asc" | "desc" }>({
    column: "last visit",
    direction: "desc"
  });
  const [search, setSearch] = useState<string>("");
  const [totalPages, setTotalPages] = useState<number>(1);
  const [rows, setRows] = useState<
    {
      name: string;
      phone: string;
      email: string;
      ["customer since"]: string;
      ["last visit"]: string;
      ["number of visits"]: number;
    }[]
  >([]);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const getRows = async ({ withoutSearch }: { withoutSearch?: boolean } = {}) => {
    try {
      setLoadingRows(true);
      const data = await getCustomersData({ limit, page, orderBy, search: withoutSearch ? "" : search });
      setRows(
        data.items?.map(item => ({
          name: item.name,
          phone: formatPhonePretty(item.phone),
          email: item.email,
          ["customer since"]: DateTime.fromJSDate(new Date(item.firstVisit)).toFormat("MM/dd/yyyy"),
          ["last visit"]: DateTime.fromJSDate(new Date(item.lastVisit)).toFormat("MM/dd/yyyy"),
          ["number of visits"]: item.totalVisits
        })) || []
      );
      setTotalPages(data.totalPages || 1);
    } catch (err) {
      toastr.error("Error getting customers data.");
      console.log(" getRows ~ err", err);
    } finally {
      setLoadingRows(false);
    }
  };

  useEffect(() => {
    getRows();
  }, []);

  const handleExportClick = () => {
    setShowExportModal(true);
  };

  const loadExportFile = async () => {
    try {
      setLoadingExport(true);
      const { url } = await exportCustomersData();
      setFileUploaded(url);
      setLoadingExport(false);
    } catch (err) {
      toastr.error("Error exporting customers data.");
      console.log(" loadExportFile ~ err", err);
    } finally {
      setLoadingExport(false);
    }
  };

  const handleLogin = async event => {
    event.preventDefault();
    setLoadingAuth(true);
    const email = props.user.email;
    const password = event.target.elements[0].value;
    if (email && password) {
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password);
        setSuccessPasswordCheck(true);
        setShowExportModal(false);
        loadExportFile();
      } catch (error) {
        setSuccessPasswordCheck(false);
        console.log("handle check login error: ", error);
        toastr.error("The password is invalid.");
      } finally {
        setLoadingAuth(false);
      }
    }
  };

  useAfterMountEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getRows();
    }
  }, [limit]);

  useAfterMountEffect(() => {
    getRows();
  }, [debouncedPage]);

  useAfterMountEffect(() => {
    getRows();
  }, [orderBy]);

  const onRequestSort = (event, column) => {
    if (column === orderBy.column) {
      setOrderBy({ column, direction: orderBy.direction === "asc" ? "desc" : "asc" });
    } else {
      setOrderBy({ column, direction: "desc" });
    }
  };

  const handleSearch = ({ withoutSearch }: { withoutSearch?: boolean } = {}) => {
    if (page !== 1) {
      setPage(1);
    } else {
      getRows({ withoutSearch });
    }
  };

  const exportText = (
    <>
      {successPasswordCheck && fileUploaded && !loadingAuth && !loadingExport && (
        <a
          href={fileUploaded}
          target="_blank"
          className={cx({
            "mr-2": !isMobile,
            "d-block mt-1": isMobile
          })}
        >
          Download customers.csv
        </a>
      )}
      {loadingExport && (
        <span
          className={cx({
            "mr-2": !isMobile,
            "d-block mt-1": isMobile
          })}
        >
          Export in progress...
        </span>
      )}
    </>
  );

  return (
    <>
      <Modal
        dialogClassName="add-guest-modal"
        show={showExportModal}
        onHide={() => {
          setShowExportModal(false);
        }}
      >
        <div className="customers-modal-content">
          <span className="customers-modal-content__header">Confirm your password</span>
          <span className="customers-modal-content__subheader">For the security, please confirm your password.</span>
          <Form onSubmit={handleLogin}>
            <FormGroup>
              <ControlLabel>Password</ControlLabel>
              <FormControl placeholder="Password" type="password" autoComplete="off" required />
            </FormGroup>
            <CustomButton type="submit" bsStyle="primary" fill wd>
              {loadingAuth ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Continue</span>}
            </CustomButton>
          </Form>
          <CustomButton bsStyle="primary" className="mt-2" simple wd onClick={() => setShowExportModal(false)}>
            Cancel
          </CustomButton>
        </div>
      </Modal>
      <div className="main-content">
        <Grid fluid>
          <Row>
            <Col md={12} className="customers-wrapper">
              <div className="flex w-100">
                <div className="w-100 search-wrapper">
                  <input
                    className="form-control"
                    placeholder="Search"
                    disabled={loadingRows}
                    value={search}
                    onChange={e => {
                      if (!loadingRows) setSearch(e.target.value);
                    }}
                    onKeyDown={e => {
                      if (e.key === "Enter" && !loadingRows) {
                        handleSearch();
                      }
                    }}
                  />
                  <i
                    className={cx({
                      "mdi mdi-magnify": true,
                      disabled: loadingRows
                    })}
                    onClick={() => {
                      if (!loadingRows) handleSearch();
                    }}
                  />
                  {!!search && (
                    <span
                      onClick={() => {
                        if (!loadingRows) {
                          setSearch("");
                          handleSearch({ withoutSearch: true });
                        }
                      }}
                      className={cx({
                        "clear-search": true,
                        disabled: loadingRows
                      })}
                    >
                      Clear Search
                    </span>
                  )}
                </div>
                <div className="w-100 text-right">
                  {!isMobile && exportText}
                  <CustomButton
                    className="btn-primary"
                    fill
                    disabled={!rows.length || loadingRows || loadingAuth || loadingExport || successPasswordCheck}
                    onClick={handleExportClick}
                  >
                    {loadingExport ? <BeatLoader margin={2.5} size={10} color={"#026FFF"} /> : "Export"}
                  </CustomButton>
                </div>
              </div>
              {isMobile && <div className="text-right">{exportText}</div>}
              <hr
                className={cx({
                  "mt-1": isMobile
                })}
              />
              {loadingRows && (
                <div className="loading-wrapper">
                  <BeatLoader margin={2.5} size={20} color={"#026FFF"} />
                </div>
              )}
              <>
                <RegularTable
                  emptyRowsMessage="No customers yet."
                  actions={[]}
                  hideActions
                  tableHeaders={
                    isMobile
                      ? ["name", "phone", "number of visits"]
                      : ["name", "phone", "email", "customer since", "last visit", "number of visits"]
                  }
                  showSorting={h => !["phone", "email"].some(header => header === h)}
                  orderBy={orderBy}
                  onRequestSort={onRequestSort}
                  rows={
                    isMobile
                      ? rows.map(row => ({
                          name: row.name,
                          phone: row.phone,
                          ["number of visits"]: row["number of visits"]
                        }))
                      : rows
                  }
                  overrides={{
                    root: {
                      overflowX: "hidden",
                      paddingTop: "8px"
                    },
                    head: isMobile
                      ? {
                          fontSize: "12px",
                          paddingLeft: "10px",
                          paddingRight: "10px"
                        }
                      : {},
                    body: isMobile
                      ? {
                          fontSize: "14px",
                          padding: "8px"
                        }
                      : {}
                  }}
                />
              </>

              <div className="customers-pagination text-right pb-2">
                <input
                  disabled={loadingRows}
                  min={1}
                  type="number"
                  className="form-control"
                  value={page}
                  onChange={e => {
                    const value = Number(e.target.value);
                    if (value > 0 && value <= totalPages) {
                      setPage(value);
                    }
                  }}
                />
                <span className="mr-2 block">of {totalPages}</span>
                <select
                  className="form-control"
                  value={limit}
                  onChange={e => setLimit(Number(e.target.value))}
                  disabled={loadingRows}
                >
                  {[5, 25, 50, 100, 250].map(value => (
                    <option key={value} value={value}>
                      {value} per page
                    </option>
                  ))}
                </select>
              </div>
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
};

export default Customers;

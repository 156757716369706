/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import waitlyLogo from "../../assets/img/waitly_blue_logo.svg";
import PulseLoader from "react-spinners/PulseLoader";

function LoadingPage() {
  return (
    <div>
      <div className="wrapper">
        <div className="content" style={{ textAlign: "center" }}>
          <div className="center">
            <img src={waitlyLogo} style={{ height: "45px" }} alt="Waitly Logo" />
            <div style={{ marginTop: "25px" }}>
              <PulseLoader margin={2} size={15} color={"#032ab6"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoadingPage;

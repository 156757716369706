import React, { useState } from "react";
import { Col, ControlLabel, Form, FormGroup, HelpBlock, Modal, Row } from "react-bootstrap";
import Card from "../Card/Card";
import Button from "../CustomButton/CustomButton";
import { useForm } from "react-hook-form";
import BeatLoader from "react-spinners/BeatLoader";
import SettingsInput from "../Settings/SettingsInput";
import { updateBusinessSettings } from "../../services/profile/profile.service";
import toastr from "toastr";

const AddCategory = ({ showAddCategoryModal, categoryForEdit, businessId, closeModal, services }) => {
  const { register, handleSubmit, errors } = useForm();

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: categoryForEdit ? categoryForEdit.name : "",
    addWelcomeText: categoryForEdit && !!categoryForEdit.welcomeText,
    welcomeText: (categoryForEdit && categoryForEdit.welcomeText) || "",
    addNotificationText: categoryForEdit && !!categoryForEdit.notificationText,
    notificationText: (categoryForEdit && categoryForEdit.notificationText) || ""
  });

  const onChangeFormField = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const toggleChecked = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const handleTextareaChange = key => event => {
    let value;
    if (typeof event === "string") {
      value = event;
    } else {
      value = event.target.value;
    }
    setForm({ ...form, [key]: value });
  };

  const onSubmit = async () => {
    setLoading(true);
    let newServices = [...services];
    const newCategory = {
      name: form.name,
      ...(form.addWelcomeText && form.welcomeText && { welcomeText: form.welcomeText }),
      ...(form.addNotificationText && form.notificationText && { notificationText: form.notificationText })
    };
    if (categoryForEdit) {
      newServices = newServices.map(service => {
        return service.name === categoryForEdit.name ? newCategory : service;
      });
    } else {
      newServices.push(newCategory);
    }
    await updateBusinessSettings(businessId, {
      services: newServices
    });
    setLoading(false);
    closeModal();
    toastr.success(`Settings updated`);
  };

  return (
    <Modal dialogClassName="add-guest-modal" show={showAddCategoryModal} onHide={closeModal}>
      <Card
        content={
          <Form noValidate className="add-guest" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="add-guest__title" md={12}>
                {categoryForEdit ? "Edit Preference" : "Add Preference"}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.name && "error") || null}>
                  <ControlLabel style={{ fontSize: 15 }}>PREFERENCE NAME</ControlLabel>
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    placeholder="Enter preference name"
                    onChange={onChangeFormField}
                    defaultValue={form.name}
                    ref={register({ required: true })}
                  />
                  {errors.name && errors.name.type === "required" && <HelpBlock>Name is required</HelpBlock>}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup style={{ margin: 0 }}>
                  <ControlLabel style={{ float: "left", marginTop: 17, fontSize: 15 }}>
                    CUSTOM WELCOME TEXT
                  </ControlLabel>
                  <SettingsInput
                    form={form}
                    name={"addWelcomeText"}
                    type={"switch"}
                    enabled={true}
                    style={{ marginBottom: 0 }}
                    value={form.addWelcomeText}
                    register={null}
                    onChangeValue={toggleChecked}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel style={{ marginTop: 17, fontSize: 15, color: !form.addWelcomeText && "#CAC9C9" }}>
                    WELCOME TEXT
                  </ControlLabel>
                  <SettingsInput
                    form={form}
                    name={"welcomeText"}
                    type={"textarea"}
                    errors={errors}
                    hideLabel={true}
                    enabled={form.addWelcomeText}
                    value={form.welcomeText}
                    link={true}
                    register={register}
                    onChangeValue={handleTextareaChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup style={{ margin: 0 }}>
                  <ControlLabel style={{ float: "left", marginTop: 17, fontSize: 15 }}>
                    CUSTOM NOTIFICATION TEXT
                  </ControlLabel>
                  <SettingsInput
                    form={form}
                    name={"addNotificationText"}
                    type={"switch"}
                    enabled={true}
                    style={{ marginBottom: 0 }}
                    value={form.addNotificationText}
                    register={null}
                    onChangeValue={toggleChecked}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel style={{ marginTop: 17, fontSize: 15, color: !form.addNotificationText && "#CAC9C9" }}>
                    NOTIFICATION TEXT
                  </ControlLabel>
                  <SettingsInput
                    form={form}
                    name={"notificationText"}
                    type={"textarea"}
                    errors={errors}
                    hideLabel={true}
                    enabled={form.addNotificationText}
                    value={form.notificationText}
                    link={true}
                    register={register}
                    onChangeValue={handleTextareaChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="add-guest__actions">
                  <Button onClick={closeModal} className="add-guest__cancel" bsStyle="info" pullRight type="button">
                    Cancel
                  </Button>
                  <Button bsStyle="info" pullRight fill type="submit">
                    {loading ? (
                      <BeatLoader margin={2.5} size={10} color={"#fff"} />
                    ) : (
                      <span>{categoryForEdit ? "Save" : "Add"}</span>
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      />
    </Modal>
  );
};

export default AddCategory;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, FormGroup, ControlLabel, HelpBlock, Form, Button } from "react-bootstrap";
import "./Profile.scss";
import { updateProfile } from "services/profile/profile.service";
import { getBusinessById } from "services/businesses/businesses.service";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import BeatLoader from "react-spinners/BeatLoader";

function ProfileFrom(props) {
  const { user } = props;
  const { register, handleSubmit, errors } = useForm();
  const form = {
    firstName: user.firstName,
    lastName: user.lastName
  };
  const [loading, setLoading] = useState(false);

  const onSubmit = async data => {
    if (loading) {
      return;
    }
    setLoading(true);
    const business = await getBusinessById(user.businessId);
    try {
      await updateProfile({ ...data, businessName: business.data().name });
      setLoading(false);
      toastr.success(`Profile successfully updated`);
    } catch (err) {
      setLoading(false);
      toastr.error(`There was an issue during profile update`);
    }
  };
  return (
    <Form noValidate className="profile-form" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={12}>
          <FormGroup>
            <h3>Update profile</h3>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup>
            <ControlLabel>Email</ControlLabel>
            <div>{user.email}</div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup validationState={(!!errors.firstName && "error") || null}>
            <ControlLabel>First name</ControlLabel>
            <input
              name="firstName"
              className="form-control"
              type="text"
              placeholder="Enter first name"
              defaultValue={form.firstName}
              ref={register({ required: true, maxLength: 30 })}
            />
            {errors.firstName && errors.firstName.type === "required" && <HelpBlock>First name is required</HelpBlock>}
            {errors.firstName && errors.firstName.type === "maxLength" && (
              <HelpBlock>First name length should be less than 30 characters</HelpBlock>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup validationState={(!!errors.lastName && "error") || null}>
            <ControlLabel>Last name</ControlLabel>
            <input
              name="lastName"
              className="form-control"
              type="text"
              placeholder="Enter last name"
              defaultValue={form.lastName}
              ref={register({ required: true, maxLength: 30 })}
            />
            {errors.lastName && errors.lastName.type === "required" && <HelpBlock>Last name is required</HelpBlock>}
            {errors.lastName && errors.lastName.type === "maxLength" && (
              <HelpBlock>Last name length should be less than 30 characters</HelpBlock>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup>
            <div className="profile-form__button-wrapper">
              <Button className="profile-form__button-profile btn-fill" bsStyle="primary" type="submit">
                {loading ? <BeatLoader margin={2.5} size={10} color={"#447df7"} /> : <span>Update</span>}
              </Button>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}

export default ProfileFrom;

import React, { useState } from "react";
import "./SquareColorPicker.scss";
import { SketchPicker } from "react-color";
import "./SquareColorPicker.scss";
import cx from "classnames";

function SquareColorPicker(props: {
  changeColor: (color: string) => void;
  color: string;
  disabled?: boolean;
  position?: "top" | "bottom";
}) {
  const { changeColor, color, disabled = false, position = "bottom" } = props;
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleCloseColorPicker = () => {
    setDisplayColorPicker(false);
  };

  return (
    <div className="color-picker">
      <div
        className="color-picker__color-box"
        style={{ background: color }}
        onClick={() => setDisplayColorPicker(!disabled && true)}
      ></div>
      <div className="color-picker__select">
        {displayColorPicker ? (
          <div className={cx({ "color-picker__item": true, "color-picker__item__top": position === "top" })}>
            <div className={"color-picker__cover"} onClick={handleCloseColorPicker} />
            <SketchPicker color={color} onChange={c => changeColor(c.hex)} />
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default SquareColorPicker;

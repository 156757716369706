import React, { useState } from "react";
import "./DailyReservationsTable.scss";
import ReservationRow from "./ReservationRow";
import { markMessagesAsRead } from "../../services/messages/messages.service";
import ChatModal from "../Chat/Chat";
import { NotifyGuestType } from "constants/visits";
import { logEvent } from "../../services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "../../constants/firebase";
import { invalidNumber, optOut } from "../../constants/messages";
import { useMediaQuery } from "hooks/useMediaQuery";

function DailyReservationsSchedule(props) {
  const {
    accountId,
    account,
    reservations,
    handleEdit,
    handleCheckIn,
    handleServe,
    handleNotify,
    handleNotifyAgain,
    handleDelete,
    handleReset,
    handleWalkout,
    addNote,
    sendMessage,
    disabled
  } = props;
  const [chat, setChat] = useState(null);
  const [infoAlert, setInfoAlert] = useState<any>(null);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const openChatModal = reservation => {
    logEvent(FIREBASE_EVENTS.message_view);
    if (reservation.data().errorCode) {
      let message =
        reservation.data().errorCode === "spam" || reservation.data().errorCode === "invalid-number"
          ? invalidNumber
          : reservation.data().errorCode === "opt-out"
          ? optOut
          : {};
      setInfoAlert(message);
    }
    setChat(reservation);
    markMessagesAsRead(props.messages);
  };

  const handleChatClose = () => {
    setInfoAlert(null);
    setChat(null);
  };

  return (
    <div className="reservation-table-content">
      <div className="reservations-schedule">
        {chat && (
          <ChatModal
            accountId={accountId}
            sendMessage={sendMessage}
            chat={chat}
            error={infoAlert}
            plan={account.plan}
            type={NotifyGuestType.reservations}
            handleChatClose={handleChatClose}
          />
        )}
        {!isMobile && (
          <div className="reservations-schedule__header">
            <div className="reservations-schedule__col" style={{ width: "12%" }}>
              Time
            </div>
            <div className="reservations-schedule__col" style={{ width: "30%" }}>
              Name
            </div>
            <div className="reservations-schedule__col reservations-schedule__col--center" style={{ width: "30%" }}>
              Resource
            </div>
            <div className="reservations-schedule__col" style={{ width: "10%" }}>
              Party Size
            </div>
            <div className="reservations-schedule__col" style={{ width: "18%", textAlign: "end" }}>
              Actions
            </div>
          </div>
        )}
        <div className="hour-reservations fixed-height">
          {reservations.map((reservation, index) => (
            <div key={index} style={{ borderBottom: "solid 1px #DDD" }}>
              <ReservationRow
                disabled={disabled}
                index={index}
                account={account}
                isMobile={isMobile}
                businessSettings={props.businessSettings}
                reservation={reservation}
                openChatModal={openChatModal}
                addNote={addNote}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                handleWalkout={handleWalkout}
                handleReset={handleReset}
                handleCheckIn={handleCheckIn}
                handleServe={handleServe}
                handleNotify={handleNotify}
                handleNotifyAgain={handleNotifyAgain}
              />
            </div>
          ))}
          {reservations && reservations.length === 0 && (
            <div className="waitlist-empty">There are currently no reservations.</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default DailyReservationsSchedule;

import React from "react";
import SettingTab from "./SettingsTab";

const SettingSectionTab = ({ section, setActive, isActive }) => {
  return (
    <>
      <div className="settings-tab-title">{section.name}</div>
      {section.children.map((child, idx) => {
        return <SettingTab key={idx} setting={child} setActive={setActive} isActive={isActive} />;
      })}
    </>
  );
};

export default SettingSectionTab;

import SettingsWrapper from "common/ScrollableWrapper/SettingsWrapper";
import React, { useCallback, useEffect } from "react";
import RegularTable from "views/RegularTable";
import { Controller, UseFormReturn, useForm } from "react-hook-form7";
import SettingsInput from "components/Settings/SettingsInput";
import { Modal } from "react-bootstrap";
import CustomButton from "components/CustomButton/CustomButton";
import toastr from "toastr";

import { updateBusinessSettings } from "services/profile/profile.service";
import {
  CustomizableInputFieldType,
  CustomizableInputFields,
  CustomizableInputFieldsKeys
} from "types/customizable-input-fields";
import {
  AVAILABLE_OPTIONS_FOR_CUSTOMIZABLE_INPUT_FIELDS,
  CUSTOMIZABLE_INPUT_FIELDS_LIST,
  DEFAULT_CUSTOMIZABLE_INPUT_FIELDS
} from "constants/customizable-input-fields";
import { BusinessPlan, BusinessSettings } from "types/business";
import Card from "../Card/Card";

const InputFieldSettingsSelect = ({
  type,
  fieldForEdit,
  form
}: {
  type: "waitlist" | "reservations" | "selfCheckIn";
  fieldForEdit: { key: CustomizableInputFieldsKeys };
  form: UseFormReturn<CustomizableInputFields, any, undefined>;
}) => {
  const getData = useCallback(
    (
      fieldForEdit: { key: CustomizableInputFieldsKeys },
      form: UseFormReturn<CustomizableInputFields, any, undefined>
    ) => {
      return {
        options:
          AVAILABLE_OPTIONS_FOR_CUSTOMIZABLE_INPUT_FIELDS[fieldForEdit.key]?.[type]?.map(option => ({
            value: option as string,
            text: (option as string).charAt(0).toUpperCase() + (option as string).slice(1)
          })) || [],
        value: form.watch(fieldForEdit.key)?.[type],
        label: type === "waitlist" ? "Waitlist" : type === "reservations" ? "Reservations" : "Self Check-In"
      };
    },
    [type]
  );

  const { value, options, label } = getData(fieldForEdit, form);

  if (!value || !options.length) {
    return <></>;
  }

  return (
    <Controller
      name={(fieldForEdit.key + "." + type) as any}
      control={form.control}
      render={() => (
        <SettingsInput
          name={fieldForEdit.key + "." + type}
          className="flex flex-column mt-0"
          enabled={true}
          onChangeValue={event => {
            form.setValue((fieldForEdit.key + "." + type) as any, event.target.value);
          }}
          label={label}
          type="select"
          value={value}
          options={options}
          removeDefaultStyle
        />
      )}
    />
  );
};

const InputFieldSettingsModal = ({
  show,
  onHide,
  saveClicked,
  fieldForEdit,
  form
}: {
  show;
  onHide;
  saveClicked;
  fieldForEdit?: { key: CustomizableInputFieldsKeys; name: string };
  form: UseFormReturn<CustomizableInputFields, any, undefined>;
}) => {
  if (!fieldForEdit) {
    return <></>;
  }
  return (
    <Modal dialogClassName="add-guest-modal" show={show} onHide={onHide}>
      <Card
        textCenter={true}
        title={"Edit " + fieldForEdit.name}
        content={
          <div className="pb-4 pl-4 pr-4 pt-4 d-flex flex-column justify-items-center align-items-center">
            <InputFieldSettingsSelect type="waitlist" form={form} fieldForEdit={fieldForEdit} />
            <InputFieldSettingsSelect type="reservations" form={form} fieldForEdit={fieldForEdit} />
            <InputFieldSettingsSelect type="selfCheckIn" form={form} fieldForEdit={fieldForEdit} />
            <CustomButton bsStyle="info" fill={true} onClick={saveClicked} className="mt-2 align-self-end">
              Save
            </CustomButton>
          </div>
        }
      />
    </Modal>
  );
};

type InputFieldsSettingsProps = {
  plan: BusinessPlan;
  businessId: string;
  settings: BusinessSettings;
  businessName?: string;
};

export const InputFieldsSettings = ({ plan: _plan, businessId, settings, businessName }: InputFieldsSettingsProps) => {
  const [showModal, setShowModal] = React.useState(false);
  const [fieldForEdit, setFieldForEdit] = React.useState();

  const getDefaultValues = () => {
    const { fieldOrder: _fieldOrder, ...fields } = settings?.fields || {};
    if (Object.keys(fields).length) {
      return fields;
    }
    return DEFAULT_CUSTOMIZABLE_INPUT_FIELDS;
  };

  const form = useForm<CustomizableInputFields>({
    defaultValues: getDefaultValues()
  });

  useEffect(() => {
    form.reset(getDefaultValues());
  }, [businessId]);

  const rows = CUSTOMIZABLE_INPUT_FIELDS_LIST.map((row, index) => ({
    active: () => {
      return (
        <React.Fragment key={index}>
          <Controller
            name={(row.name + ".enabled") as any}
            control={form.control}
            render={() => (
              <SettingsInput
                name={row.name + ".enabled"}
                type={"switch"}
                enabled={row.name !== CustomizableInputFieldType.NAME}
                className="mb-0 mt-0 mr-0"
                value={!!form.watch((row.name + ".enabled") as any)}
                hideLabel={true}
                onChangeValue={event => {
                  form.setValue((row.name + ".enabled") as any, !!event.target.checked);
                }}
              />
            )}
          />
        </React.Fragment>
      );
    },
    name: row.label,
    key: row.name,
    waitlist: form.getValues(`${row.name}.enabled`) ? form.getValues(`${row.name}.waitlist`) : "",
    reservations: form.getValues(`${row.name}.enabled`) ? form.getValues(`${row.name}.reservations`) : "",
    "Self check-in": form.getValues(`${row.name}.enabled`) ? form.getValues(`${row.name}.selfCheckIn`) : ""
  }));

  const handleSubmit = async data => {
    await updateBusinessSettings(businessId, { fields: { ...data } });
    toastr.success(`Settings updated`);
  };

  const checkIfNeedToHideAction = (name, item: typeof rows[number]) => {
    return item.key === CustomizableInputFieldType.NAME || !form.watch(`${item.key}.enabled`);
  };

  const checkIfNeedToHideColumn = (item: typeof rows[number], _key: string) => {
    const key = _key as keyof typeof rows[number];
    return key === "key";
  };

  return (
    <>
      <InputFieldSettingsModal
        show={showModal}
        saveClicked={() => {
          handleSubmit(form.getValues());
          setShowModal(false);
        }}
        onHide={() => setShowModal(false)}
        fieldForEdit={fieldForEdit}
        form={form}
      />
      <SettingsWrapper
        title={`Input Fields ${businessName ? " - " + businessName : ""}`}
        handleSubmit={form.handleSubmit(handleSubmit)}
        buttonStyles={{ maxWidth: 600 }}
      >
        <RegularTable
          rows={rows}
          editAction={item => {
            setFieldForEdit(item);
            setShowModal(true);
          }}
          tableHeaders={["active", "field", "waitlist", "reservations", "Self check-in", "edit"]}
          actions={[
            {
              name: "edit",
              className: "mdi mdi-pencil",
              color: "#016BFF"
            }
          ]}
          emptyRowsMessage={"No input fields found."}
          hideAction={checkIfNeedToHideAction}
          hideColumn={checkIfNeedToHideColumn}
        />
      </SettingsWrapper>
    </>
  );
};

import { collections } from "services";
import { db, firebase } from "../../firebase";
import { Observable } from "rxjs";
import { toBase64 } from "helpers/file.helper";

export async function getAccountById(accountId) {
  return await db
    .collection(collections.accounts)
    .doc(accountId)
    .get();
}

export const checkIfCloseToLimit = accountId => {
  return new Observable(subscriber => {
    db.collection(collections.accounts)
      .doc(accountId)
      .onSnapshot(result => {
        subscriber.next({ id: result.id, ...result.data() });
      });
  });
};

export const subscribeToRegions = accountId => {
  return new Observable(subscriber => {
    db.collection(collections.accounts)
      .doc(accountId)
      .collection(collections.regions)
      .onSnapshot(result => {
        subscriber.next(result.docs);
      });
  });
};

export async function getRegions(areas) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/regions/get?areas=${areas}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getUserRole function", error);
      throw error;
    });
}

export async function addRegion(region) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/regions/new`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(region)
  })
    .then(response => {
      if (!response.ok || (response.status > 201 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return;
    })
    .catch(error => {
      console.error("error triggering getUserRole function", error);
      throw error;
    });
}

export async function deleteRegion(regionId) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/regions/delete?id=${regionId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering deleteUser function", error);
      throw error;
    });
}

export async function editRegion(region) {
  console.log("regionservice edit region", region);
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/regions/edit?id=${region.id}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(region)
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return;
    })
    .catch(error => {
      console.error("error triggering editUser function", error);
      throw error;
    });
}

export async function updateAccount(settings) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/account`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify({ settings })
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error updating account", error);
      throw error;
    });
}

export async function updateAccountName({ accountId, name }) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/account?id=${accountId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify({ name })
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error updating account", error);
      throw error;
    });
}

export async function updateAppearance(accountId, appearance) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/account/appearance?id=${accountId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify({ appearance })
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error updating account", error);
      throw error;
    });
}

export async function uploadAppearanceLogo(accountId, file) {
  const b64file = await toBase64(file);
  const name = "" + accountId + file.type.replace("image/", ".");
  try {
    const ref = firebase.storage().ref();
    await ref.child(name).putString(b64file, "data_url");
    const url = await ref.child(name).getDownloadURL();
    return url;
  } catch (e) {
    console.log(e);
  }
}

export async function deleteLogoFromStorage(logoURL, accountId) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);

  const sIndex = logoURL.indexOf(accountId);
  const eIndex = logoURL.lastIndexOf("?");
  const parsedLogoURL = logoURL.slice(sIndex, eIndex);

  return fetch(`app/account/appearance/logo?logoURL=${parsedLogoURL}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error delete appearance logo", error);
      throw error;
    });
}

export async function verifyAccount(data) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  const payload = splitFullNameIntoFirstAndLast(data);
  return fetch(`app/registerBusiness`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(payload)
  })
    .then(response => {
      if (!response.ok || (response.status > 202 && response.status < 600)) {
        throw new Error(response.status);
      }
      return Promise.resolve();
    })
    .catch(error => {
      console.error("error verifyAccount", error);
      throw error;
    });
}

export async function sendSmsPIN(data) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  console.log(data);
  return fetch(`app/brand/sendOpt`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify({ mobilePhone: data.phone })
  })
    .then(response => {
      if (!response.ok || (response.status > 202 && response.status < 600)) {
        throw new Error(response.status);
      }
      return Promise.resolve();
    })
    .catch(error => {
      console.error("error verifyAccount", error);
      throw error;
    });
}

export async function verifySmsPIN(data) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/brand/sendOpt`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok || (response.status > 202 && response.status < 600)) {
        throw new Error(response.status);
      }
      return Promise.resolve();
    })
    .catch(error => {
      throw error;
    });
}

function splitFullNameIntoFirstAndLast(data) {
  const fullName = data.fullName;
  const firstName = fullName.substring(0, fullName.indexOf(" "));
  const lastName = fullName.substring(fullName.indexOf(" ") + 1);
  data.firstName = firstName;
  data.lastName = lastName;
  delete data.fullName;
  return data;
}

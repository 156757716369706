import React, { useEffect } from "react";
import "./Square.scss";
import Button from "components/CustomButton/CustomButton";
import { logEvent } from "services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "../../constants/firebase";

function SquareWelcome(props) {
  const { goToNextStep, newAccount } = props;
  useEffect(() => {
    logEvent(FIREBASE_EVENTS.square_start_setup);
  }, []);
  return (
    <div className="square-modal">
      <div className="square-modal__header">
        {newAccount ? "Welcome to Waitly!" : "Thank you for linking your Square account"}
      </div>
      {newAccount ? (
        <div className="square-modal__body">
          Waitly is a waitlist and reservation system to help you stay organized and in contact with your customers.
          <br />
          <br />
          There are just a few more steps to finish linking your Square account.
        </div>
      ) : (
        <div className="square-modal__body">There are just a few more steps to finish the setup.</div>
      )}

      <div className="square-modal__footer">
        <Button
          onClick={goToNextStep}
          bsStyle="info"
          className="square-welcome__button btn-fill"
          pullRight
          type="button"
        >
          Let’s Get Started
        </Button>
      </div>
    </div>
  );
}

export default SquareWelcome;

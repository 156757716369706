import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../../layouts/Admin.scss";
import "./Upgrade.scss";
import UpgradeSwitch from "./UpgradeSwitch";
import { isUpgradable } from "configs/access";
import PriceBox from "components/PriceBox/Pricebox";
import PriceHelper from "helpers/PriceHelper";
import { BeatLoader } from "react-spinners";
import { environment } from "configs/environment";

function Upgrade() {
  const [interval, setInterval] = useState("monthly");
  const accountSelector = useSelector(state => state.account);
  const pricesSelector = useSelector(state => state.prices);
  const [isLoadingAccount, setIsLoadingAccount] = useState(true);
  const [isSquareSubscription, setIsSquareSubscription] = useState(false);
  const { prices } = pricesSelector;
  const { account } = accountSelector;
  const ph = new PriceHelper(prices);

  useEffect(() => {
    if (!isUpgradable(account)) {
      goToWaitlist();
    }
  }, [account]);

  const isAnnual = () => {
    return interval === "annually";
  };

  const changeBillingType = event => {
    setInterval(event.target.checked ? "annually" : "monthly");
  };

  const goToWaitlist = () => {
    let url = document.URL.split("#")[0] + "#/admin/waitlist";
    window.location.replace(url);
  };

  const hideForPromo = () => {
    return !window.location.hash.includes("promo");
  };

  const getGreetingVergiage = () => {
    if (account && account.customerSource === "pj" && account.plan === "free") {
      if (ph.hasDiscount("premium", interval)) {
        return "Hello Permanent Jewelry Customer";
      }
    }
    if (account && account.customerSource === "square") {
      return "Hello Square Customer";
    }
  };

  const hasGreeting = () => {
    return getGreetingVergiage() !== undefined;
  };

  const getOfferVerbiage = () => {
    if (ph.isTrialEligible()) {
      return `One month free${
        ph.hasDiscount("pro", interval) ? ", then " + ph.getTerm("pro", interval) : ""
      } of Waitly Pro`;
    } else {
      if (ph.hasDiscount("pro", interval)) {
        return `${
          ph.hasDiscount("pro", interval)
            ? `Waitly Pro is ${ph.getTerm("pro", interval)}!`
            : "Only pay for what you need"
        }`;
      } else if (account && account.plan === "premium") {
        return "Upgrade to Pro";
      } else {
        return `${
          ph.hasDiscount("pro", interval)
            ? `Waitly Pro is ${ph.getTerm("pro", interval)}!`
            : "Only pay for what you need"
        }`;
      }
    }
  };

  const squareLink = `https://squareup.com/app-subscriptions/plans?client_id=${environment?.squareClientId}`;

  useEffect(() => {
    if (accountSelector && accountSelector.account) {
      setIsLoadingAccount(false);
    }
    const isSquareSub = accountSelector?.account?.customerSource === "squareSubscription";
    if (isSquareSub) {
      if (!isSquareSubscription) {
        setIsSquareSubscription(true);
        window.open(squareLink, "_blank");
      }
    }
  }, [accountSelector]);

  return (
    <div className="upgrade-wrapper">
      {isLoadingAccount && (
        <div id="pricing" className="pricing-section">
          <div className="text-center pricing-section__container pt-4">
            <BeatLoader margin={2.5} size={15} color={"#447df7"} />
          </div>
        </div>
      )}
      {!isLoadingAccount && isSquareSubscription && (
        <div id="pricing" className="pricing-section">
          <div className="text-center pricing-section__container pt-4">
            <h2 className="section-title">
              Subscriptions are managed through{" "}
              <a href={squareLink} target="_blank">
                Square
              </a>
              .
            </h2>
          </div>
        </div>
      )}
      {!isLoadingAccount && !isSquareSubscription && (
        <div id="pricing" className="pricing-section">
          <div className="text-center pricing-section__container">
            {hasGreeting() && (
              <h2 className="section-title">
                {getGreetingVergiage()} <br />
              </h2>
            )}
            <h3 className="section-title">
              {getOfferVerbiage()} <br />
            </h3>
            <div className="billing">
              <div className="billing__plan">
                <div className={`billing__plan-text ${!isAnnual() && "billing__plan-text--selected"}`}>
                  Bill Monthly
                </div>
              </div>

              <UpgradeSwitch changeBillingType={changeBillingType} isAnnual={isAnnual()}></UpgradeSwitch>
              <div className="billing__plan">
                <div className={`billing__plan-text ${isAnnual() && "billing__plan-text--selected"}`}>
                  Bill Annually
                </div>
                <div className="billing__plan-description">Save with Annual billing!</div>
              </div>
            </div>
            {account && account.origin === "ios" && account.autoPay && (
              <div>
                <p>
                  <br />
                  <strong>
                    You currently subscribe though Apple Subscriptions. If you would like to upgrade you'll need to do
                    through through Apple.{" "}
                    <a href="https://support.apple.com/en-us/HT204939" target="new">
                      (learn more)
                    </a>
                  </strong>{" "}
                  <br />
                </p>
              </div>
            )}
            <div className="pricing-section__boxes">
              {account &&
                (account.plan === "premium" || account.plan === "free" || account.plan === "pro") &&
                account.autoPay === false &&
                hideForPromo() && <PriceBox interval={interval} account={account} plan={"premium"} prices={prices} />}
              {account && (account.customerSource !== "pj" || account.plan == "premium") && (
                <PriceBox interval={interval} account={account} plan={"pro"} prices={prices} />
              )}
              {account && account.customerSource !== "pj" && hideForPromo() && (
                <>
                  <PriceBox interval={interval} account={account} plan={"enterprise"} />
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Upgrade;

import React, { useState, useRef } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import Card from "../Card/Card";
import Button from "components/CustomButton/CustomButton";
import { DateTime } from "luxon";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import momentPlugin from "@fullcalendar/moment-timezone"; // needed for dayClick
import "./SpecificDateCalendar.scss";
import SpecificDateForm from "./SpecificDateForm";
import { collections } from "services";
import { useEffect } from "react";
import { db } from "firebase.js";

const SpecificDateCalendar = ({ showSpecificDateModal, closeModal, business }) => {
  let unsubscribe = null;
  const calendarRef = useRef();
  const [showDateForm, setShowDateForm] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);

  const onSelect = selectionInfo => {
    setSelectedEvent(getEventForDate(selectionInfo.start));
    setSelectedDate(selectionInfo.start);
    setShowDateForm(true);
  };

  const eventClicked = e => {
    setSelectedEvent(getEventForDate(e.event.start));
    setSelectedDate(e.event.start);
    setShowDateForm(true);
  };

  const closeDateForm = () => {
    setSelectedDate(null);
    setShowDateForm(false);
  };

  useEffect(() => {
    if (unsubscribe) {
      unsubscribe();
    }
    unsubscribe = db
      .collection(collections.businesses)
      .doc(business.businessId)
      .collection(collections.shifts)
      .where(
        "date",
        ">=",
        DateTime.now()
          .startOf("day")
          .toJSDate()
      )
      .where("accountId", "==", business.accountId)
      .onSnapshot(snapshot => {
        const events = [];
        snapshot.forEach(doc => {
          let e = { id: doc.id, ...doc.data() };
          events.push(createEvent(e));
        });
        setEvents(events);
      });
    return () => {
      unsubscribe();
    };
    //fetch hours and specific dates
  }, [business.id]);

  const createEvent = data => {
    if (data.closed) {
      return {
        title: "No Reservations",
        start: DateTime.fromJSDate(data.date.toDate()).toISODate(),
        event: data,
        color: "#dc3545"
      };
    } else {
      const start = DateTime.fromFormat(data.begin, "HH:mm");
      const end = DateTime.fromFormat(data.end, "HH:mm");
      return {
        title: `${start.toFormat("h:mma")} - ${end.toFormat("h:mma")}`,
        start: DateTime.fromJSDate(data.date.toDate()).toISODate(),
        event: data
      };
    }
  };

  const getEventForDate = date => {
    return events.find(e => {
      return (
        DateTime.fromJSDate(date)
          .startOf("day")
          .toISO() ===
        DateTime.fromISO(e.start)
          .startOf("day")
          .toISO()
      );
    });
  };

  const shouldAllowSelection = e => {
    let startDay = DateTime.now()
      .startOf("day")
      .toJSDate();
    if (e.start.getTime() >= startDay.getTime() && e.end.getTime() / 1000 - e.start.getTime() / 1000 <= 86400) {
      return true;
    }
  };

  return (
    <>
      <Modal dialogClassName="specific-date-modal" show={showSpecificDateModal} onHide={closeModal}>
        <Card
          title="Select date to add specific hours to"
          content={
            <>
              <Row>
                <Col>
                  <FullCalendar
                    ref={calendarRef}
                    height={600}
                    themeSystem="bootstrap"
                    editable={false}
                    droppable={false}
                    plugins={[dayGridPlugin, interactionPlugin, momentPlugin]}
                    initialView="dayGridMonth"
                    selectable={true}
                    eventStartEditable={true}
                    eventClick={eventClicked}
                    dayCellDidMount={e => {
                      if (e.isPast) {
                        e.el.classList.add("fc-disabled");
                      }
                    }}
                    select={onSelect}
                    selectAllow={shouldAllowSelection}
                    eventAllow={shouldAllowSelection}
                    events={events}
                    visibleRange={{
                      start: "2022-11-22",
                      end: "2023-03-25"
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="specific-date-footer__cancel">
                    <Button onClick={closeModal} bsStyle="info" pullRight fill type="submit">
                      Done
                    </Button>
                  </div>
                </Col>
              </Row>
            </>
          }
        ></Card>
      </Modal>
      {showDateForm && (
        <SpecificDateForm
          onHide={closeDateForm}
          business={business}
          date={selectedDate}
          selectedEvent={selectedEvent}
          showDateForm={showDateForm}
        ></SpecificDateForm>
      )}
    </>
  );
};

export default SpecificDateCalendar;

export const isOnlyMaskPresent = id => {
  return id === "__-_______" || id == "______-_";
};

export const validateIdentifier = value => {
  if (!value || isOnlyMaskPresent(value)) {
    return true;
  }
  return isValidCanadianCorporationNumber(value) || isValidEIN(value);
};

const isValidCanadianCorporationNumber = value => {
  const regex = /[0-9]{6}-[0-9]{1}/g;
  return regex.test(value);
};

const isValidEIN = value => {
  const regex = /[0-9]{2}-[0-9]{7}/g;
  return regex.test(value);
};

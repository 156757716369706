import React from "react";
import "./SquareButtonPreview.scss";

function SquareButtonPreview(props) {
  return (
    <div className="square-button-preview">
      <div className="square-button-preview__title">Preview</div>
      <CheckInButton appearance={props.appearance} isReservation={props.isReservation} />
    </div>
  );
}

export function CheckInButton(props) {
  const { appearance, isReservation } = props;
  const onClick = e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    console.log("event", event);
  };
  const getStyles = () => ({
    background: appearance.buttonColor,
    color: appearance.fontColor,
    boxShadow: appearance.shadow ? `rgba(0, 0, 0, 0.24) 0px 3px 8px` : "none",
    ...appearance.style.css
  });
  return (
    <button className="square-button-preview__button" disabled={true} onClick={onClick} style={getStyles()}>
      {isReservation ? "Make a Reservation" : "Add me to Waitlist"}
    </button>
  );
}

export default SquareButtonPreview;

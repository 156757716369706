import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core";
import { ToggleButtonGroup, ToggleButton } from "@material-ui/lab";
import { InsertInvitation, ViewList } from "@material-ui/icons";
import "./DailyReservations.scss";
import DailyReservationsCalendar from "./DailyReservationsCalendar";
import MultiSelectFilter from "components/MultiSelectFilter/MultiSelectFilter";
import TimeLineIcon from "assets/img/icon-chart-gantt.svg?react";
import { useMediaQuery } from "hooks/useMediaQuery";

function DailyReservationsHeader(props) {
  const { filter, setFilter, resources, changeViewStyle, viewStyle, loading, setLoading } = props;
  const [categories, setCategories] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const StyledToggleButtonGroup = withStyles({
    root: {
      height: "40px",
      paddingLeft: "20px"
    }
  })(ToggleButtonGroup);

  useEffect(() => {
    if (resources) {
      const categories = resources.map(r => {
        return {
          value: r.name,
          label: r.name,
          minDuration: r.minDuration
        };
      });
      setCategories(categories);
    }
  }, [resources]);

  const changeFilter = (type, event) => {
    if (type === "resources") {
      event = resources.filter(r => event.some(v => v.value === r.name));
    }
    setFilter({ ...filter, [type]: event });
  };

  const onFilter = options => {
    const choosenFilters = options
      .filter(opt => opt.checked)
      .map(opt => ({ value: opt.value, label: opt.label, minDuration: opt.minDuration }));
    if (choosenFilters.length && choosenFilters.some(c => c.minDuration === -1) && viewStyle !== "timeline") {
      changeViewStyle(null, "list");
    }
    changeFilter("resources", choosenFilters);
  };

  React.useEffect(() => {
    if (!categories || !categories.length) return undefined;
    setLoading(true);
    const filterOptions = [];
    categories.forEach(a => {
      filterOptions.push({
        ...a,
        children: [],
        checked: true
      });
    });

    setFilterOptions(filterOptions);
    onFilter(filterOptions);
    setLoading(false);
  }, [categories]);

  const displayCalendarView =
    filter.resources && filter.resources.length ? !filter.resources.some(r => r.minDuration === -1) : true;

  return (
    <div className="reservations-header">
      <div className="reservations-header__calendar">
        <DailyReservationsCalendar
          currentDate={filter.currentDate}
          changeDate={changeFilter.bind(this, "currentDate")}
        ></DailyReservationsCalendar>
      </div>

      {!isMobile && (
        <>
          <div>
            <StyledToggleButtonGroup
              exclusive
              value={viewStyle}
              onChange={(_, val) => {
                changeViewStyle(null, val ?? viewStyle);
              }}
            >
              {displayCalendarView && (
                <ToggleButton value="calendar">
                  <InsertInvitation />
                </ToggleButton>
              )}
              <ToggleButton value="list">
                <ViewList />
              </ToggleButton>
              <ToggleButton value="timeline">
                <TimeLineIcon />
              </ToggleButton>
            </StyledToggleButtonGroup>
          </div>

          <div className="reservations-header__title">{"Reservations"}</div>
          <div className="reservations-header__category">
            {categories.length && !loading ? (
              <MultiSelectFilter
                onFilter={onFilter}
                options={filterOptions}
                filteredText="Resources filtered."
                emptyText="Please, select at least 1 resource"
                isDashboard={false}
                reverse
              />
            ) : (
              <div style={{ width: 390 }} />
            )}
          </div>
        </>
      )}
    </div>
  );
}

export default DailyReservationsHeader;

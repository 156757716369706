/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import AuthNavbar from "components/Navbars/AuthNavbar";
import Footer from "components/Footer/Footer.jsx";

// dinamically create pages routes
import routes from "./../routes";

import "./Admin.scss";
import { REGISTER_SOURCES } from "constants/register-sources";

const Auth = ({ location, initialHash }) => {
  const [isNavbarInverted, setIsNavbarInverted] = useState(false);

  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    if (document.documentElement.className.indexOf("nav-open") !== -1) {
      document.documentElement.classList.toggle("nav-open");
    }
  }, []);

  useEffect(() => {
    switch (searchParams.get("source")) {
      case REGISTER_SOURCES.SQUARE:
      case REGISTER_SOURCES.SQUARE_WAITLY:
      case REGISTER_SOURCES.SQUARE_SUBSCRIPTION:
        setIsNavbarInverted(false);
        break;
      case REGISTER_SOURCES.PJ:
      case REGISTER_SOURCES.RESTAURANT:
        setIsNavbarInverted(true);
        break;
      default:
        setIsNavbarInverted(false);
        break;
    }
  }, [searchParams.get("source")]);

  const getPageClass = () => {
    let pageClass = "";
    switch (location.pathname) {
      case "/auth/login-page":
        pageClass = " login-page";
        break;
      case "/auth/register-page":
        pageClass = " register-page";
        break;
      case "/auth/lock-screen-page":
        pageClass = " lock-page";
        break;
      default:
        pageClass = "";
        break;
    }
    return pageClass;
  };

  const getRoutes = routes => {
    if (initialHash) {
      location.state = { initialHash };
    }
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return <Route path={prop.layout + prop.path} component={prop.component} key={key} />;
      } else {
        return null;
      }
    });
  };

  return (
    <div>
      <AuthNavbar location={location} isInverted={isNavbarInverted} />
      <div className={"full-page" + getPageClass()}>
        <div className="content">
          <Switch>{getRoutes(routes)}</Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Auth;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./FreeTrialTourModal.scss";
import CustomButton from "components/CustomButton/CustomButton";
import { checkout, checkoutWithoutCard } from "services/profile/profile.service";
import toastr from "toastr";
import { FIREBASE_EVENTS } from "constants/firebase";
import { logEvent } from "services/firebase/firebase.service";

const FreeTrialTourModal = ({ show, close, onFreeTrialSuccess }) => {
  const [loading, setLoading] = useState<null | undefined | "withcard" | "withoutcard">(null);

  const handleActivate = async () => {
    try {
      logEvent(FIREBASE_EVENTS.activate_with_card);
      setLoading("withcard");
      const checkoutResponse: any = await checkout("pro", "monthly", "tour");
      if (checkoutResponse) {
        const json: any = await checkoutResponse.json();
        setLoading(null);
        window.location.href = json.url;
      } else {
        toastr.error("Something went wrong");
      }
    } catch (e) {
      console.log(e);
      toastr.error((e as any)?.message || "Something went wrong");
    } finally {
      setLoading(null);
    }
  };

  const handleActivateFree = async () => {
    try {
      logEvent(FIREBASE_EVENTS.activate_without_card);
      setLoading("withoutcard");
      const checkoutResponse: any = await checkoutWithoutCard("pro", "monthly", "tour");
      if (checkoutResponse) {
        const json: any = await checkoutResponse?.json?.();
        setLoading(null);
        if (json?.success) {
          onFreeTrialSuccess();
        } else {
          toastr.error(json?.error || "Something went wrong");
        }
      } else {
        toastr.error("Something went wrong");
      }
    } catch (e) {
      console.log(e);
      toastr.error((e as any)?.message || "Something went wrong");
    } finally {
      setLoading(null);
    }
  };

  return (
    <Modal
      dialogClassName="free-trial-tour-modal"
      backdropClassName="dark-backdrop"
      show={show}
      onHide={() => {
        close();
      }}
    >
      <CustomButton
        className="free-trial-tour-modal__close-btn"
        simple
        onClick={() => {
          close();
        }}
      >
        <i className="fa fa-times" />
      </CustomButton>
      <div className="content">
        <div>
          <div>
            <div>1 month free trial</div>
            <span>no credit card required</span>
          </div>
          <CustomButton
            className="free-trial-tour-modal__activate-btn"
            onClick={() => handleActivateFree()}
            disabled={loading === "withoutcard"}
          >
            Get Free Trial
          </CustomButton>
        </div>
        <div>
          <div>
            <div>1 month free trial</div>
            <div>Plus 50% off for 3 months</div>
            <span>credit card required</span>
          </div>
          <CustomButton
            className="free-trial-tour-modal__activate-btn"
            onClick={() => handleActivate()}
            disabled={loading === "withcard"}
          >
            Get Free Trial + 50% off
          </CustomButton>
        </div>
      </div>
    </Modal>
  );
};

export default FreeTrialTourModal;

import React from "react";
import ProfileFrom from "./ProfileForm";
import ProfileChangePasswordForm from "./ProfileChangePasswordForm";
import SubscriptionDetails from "./SubscriptionDetails";
import { Grid, Row, Col } from "react-bootstrap";

function Profile(props) {
  return props.user ? (
    <Grid className="profile-wrappe main-content">
      <Row className="profile-row">
        <Col lg={6}>
          <ProfileFrom user={props.user}></ProfileFrom>
        </Col>
        <Col lg={6}>
          <SubscriptionDetails user={props.user}></SubscriptionDetails>
        </Col>
      </Row>
      <Row>
        <Col lg={6}>
          <ProfileChangePasswordForm user={props.user}></ProfileChangePasswordForm>
        </Col>
      </Row>
    </Grid>
  ) : null;
}

export default Profile;

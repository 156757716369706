import React, { useState } from "react";
import { ProgressBar, Row, Col, Form, FormGroup, Button } from "react-bootstrap";
import BeatLoader from "react-spinners/BeatLoader";
import "./Profile.scss";
import { db } from "../../firebase";
import { DateTime } from "luxon";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import { stripePortal } from "../../services/profile/profile.service";
import { isProOrPremium } from "services/plans/plans.helper";
import { useHistory } from "react-router-dom";
import { userIsAdmin } from "configs/access";
import { isBillingUser } from "configs/access";
import { environment } from "configs/environment";

function SubscriptionDetails(props) {
  const history = useHistory();
  const { user } = props;
  const stripeBillingProblemStatuses = ["incomplete", "incomplete_expired", "past_due", "unpaid"];
  const [account] = useDocumentDataOnce(db.collection("accounts").doc(user.accountId));
  const [startingSession, setStartingSession] = useState(false);

  const dateMessage = (accountData, verb) => {
    var dt;
    if (accountData && accountData.expires) {
      dt = accountData.expires.toDate();
    } else {
      dt = accountData.renewalDate.toDate();
    }
    return `Your subscription ${verb} on ${DateTime.fromJSDate(dt).toLocaleString()}`;
  };

  const getPlanDetails = accountData => {
    if (!accountData) {
      return `Guest totals resets on ${DateTime.fromJSDate(accountData.renewalDate.toDate()).toLocaleString()}`;
    }
    if (accountData.plan === "enterprise") {
      return "Unlimited guest parties per month";
    } else {
      return `${accountData.notificationLimit} guest parties per month`;
    }
  };

  const getDateDetails = accountData => {
    console.log(accountData);
    if (!accountData) {
      return "";
    }
    if (accountData.plan === "free") {
      return `Free plan resets on ${DateTime.fromJSDate(accountData.renewalDate.toDate()).toLocaleString()}`;
    }
    console.log(accountData);
    if (isProOrPremium(accountData.plan) && !accountData.autoPay) {
      return dateMessage(accountData, "expires");
    } else {
      return dateMessage(accountData, "renews");
    }
  };

  const getButtonName = account => {
    if (isProOrPremium(account.plan)) {
      return "Change Subscription";
    }
    return "Upgrade Plan";
  };

  const subscriptionButtonClicked = async () => {
    if (!account) {
      return;
    }

    if (account.plan === "free") {
      history.replace("/admin/upgrade");
    } else if (account?.customerSource === "squareSubscription") {
      window.open(`https://squareup.com/app-subscriptions/plans?client_id=${environment?.squareClientId}`, "_blank");
    } else {
      setStartingSession(true);
      const response = await stripePortal();
      if (response) {
        const json = await response.json();
        window.location.href = json.url;
      }
    }
  };

  const hasBillingIssue = account => {
    if (!account) {
      return false;
    }
    if (
      isProOrPremium(account.plan) &&
      account.origin === "stripe" &&
      stripeBillingProblemStatuses.includes(account.status)
    ) {
      return true;
    }
    return false;
  };

  const shouldShowManageSubscription = () => {
    console.log(user, account);
    return isBillingUser(user, account) && userIsAdmin(user);
  };

  const isInStripeTrialPeriod = account => {
    if (!account) {
      return false;
    }
    if (isProOrPremium(account.plan) && account.origin === "stripe" && account.status === "trialing") {
      return true;
    } else {
      return false;
    }
  };

  const dateFormat = account => {
    if (account && account.renewalDate) {
      return DateTime.fromJSDate(account.renewalDate.toDate()).toLocaleString(DateTime.DATE_SHORT);
    }
    return "";
  };

  const getPlanName = plan => {
    if (plan) {
      if (plan === "pro") {
        return "Professional";
      }
      if (plan === "premium") {
        return "Premium";
      }
    }
    return "";
  };

  return account ? (
    <Form className="profile-form">
      <Row>
        <Col md={12} lg={12}>
          <h3>Subscription Details</h3>
        </Col>
      </Row>
      <Row>
        <Col md={12} lg={12}>
          <h5>Plan: Waitly {getPlanName(account.plan)}</h5>
          {account.nextPlan && (
            <p>
              You recently changed your plan to <strong>Waitly {getPlanName(account.nextPlan)}</strong>. This change
              will take affect at the beginning of your next billing cycle.
            </p>
          )}
          <p>{getPlanDetails(account)}</p>
          {account.plan !== "enterprise" && (
            <ProgressBar
              className="guest-progress"
              bsStyle="success"
              now={((account.notificationLimit - account.visitCount) / account.notificationLimit) * 100}
            ></ProgressBar>
          )}
        </Col>
      </Row>
      {account.plan !== "enterprise" && (
        <Row>
          <Col md={12} lg={12}>
            <p style={{ textAlign: "center" }}>
              {account.notificationLimit - (account.visitCount || 0)} of {account.notificationLimit} remaining (resets
              on {dateFormat(account)})
            </p>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={12} lg={12}>
          <p>{getDateDetails(account)}</p>
          {isInStripeTrialPeriod(account) && (
            <p>You are currently in the trial period. You will automatically be billed on the renewal date.</p>
          )}
          {hasBillingIssue(account) && (
            <p style={{ color: "red" }}>
              There was a problem with your payment, please click manage subscription to fix the issue.
            </p>
          )}
        </Col>
      </Row>
      {account.plan !== "enterprise" && (
        <FormGroup>
          {isProOrPremium(account.plan) && account.origin === "ios" ? (
            <p>
              This plan was started with Apple In App purchases and must be managed via Apple Subscriptions{" "}
              <a href="https://support.apple.com/en-us/HT204939" target="new">
                (learn more)
              </a>
            </p>
          ) : (
            <div className="profile-form__button-wrapper">
              {shouldShowManageSubscription() && (
                <Button
                  bsStyle="info"
                  style={{ width: "175px" }}
                  className="btn-fill"
                  type="button"
                  onClick={subscriptionButtonClicked}
                >
                  {startingSession ? (
                    <BeatLoader margin={2.5} size={10} color={"#447df7"} />
                  ) : (
                    <span>{getButtonName(account)}</span>
                  )}
                </Button>
              )}
            </div>
          )}
        </FormGroup>
      )}
    </Form>
  ) : (
    " "
  );
}

export default SubscriptionDetails;

import React from "react";
import WaitlyFeatures from "components/WaitlyFeatures/WaitlyFeatures";
import { getRedirectToSquareUrls } from "services/square/square.service";
import SquareConnectButton from "components/Square/SquareConnectButton";
import { logEvent } from "../../services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "../../constants/firebase";

const ConnectToSquareSetting = () => {
  const connectToSquareClick = async () => {
    logEvent(FIREBASE_EVENTS.square_connect);
    const urls = await getRedirectToSquareUrls();
    window.location.href = urls.link;
  };

  return (
    <div className="connect-to-square">
      <div className="connect-to-square__title">Connect your Square account for added benefits.</div>
      <div className="connect-to-square__body">
        <div className="connect-to-square__actions">
          <SquareConnectButton connectToSquareClick={connectToSquareClick}></SquareConnectButton>
        </div>
        <div className="connect-to-square__features">
          <WaitlyFeatures type="addCustomers"></WaitlyFeatures>
          <WaitlyFeatures type="squareOnline"></WaitlyFeatures>
          <WaitlyFeatures type="squareLocations"></WaitlyFeatures>
        </div>
      </div>
    </div>
  );
};

export default ConnectToSquareSetting;

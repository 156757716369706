import SettingsWrapper from "common/ScrollableWrapper/SettingsWrapper";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import SettingsInput from "./SettingsInput";
import toastr from "toastr";
import { getAccountById } from "services/account/account.service";
import { updateAccountName } from "services/account/account.service";
const MAX_NAME_LENGTH = 50;

const AccountSettings = ({ user }) => {
  const { register, handleSubmit, errors } = useForm();
  const [form, setForm] = useState({
    name: ""
  });
  const [loading, setLoading] = useState(false);

  const fetchAccount = async () => {
    if (!user) return;

    setLoading(true);
    const res = await (await getAccountById(user.accountId)).data();
    setForm({
      name: res.name
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchAccount();
  }, [user.accountId]);

  const onSubmit = async data => {
    if (loading || !user || !data || !data.name) return;
    try {
      setLoading(true);
      await updateAccountName({ name: data.name, accountId: user.accountId });
      setForm({
        name: data.name
      });
      setLoading(false);
      toastr.success(`Settings updated`);
    } catch (e) {
      setLoading(false);
      toastr.error(`Something went wrong`);
    }
  };

  const onChangeFormField = event => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const validateBusinessName = value => {
    return value.length !== 0 && value.length <= MAX_NAME_LENGTH;
  };

  return (
    <SettingsWrapper
      title={"General"}
      handleSubmit={handleSubmit(onSubmit)}
      buttonStyles={{ maxWidth: 600 }}
      submitBtnDisabled={loading}
    >
      <Row>
        <Col md={9}>
          <SettingsInput
            placeholder={"Enter name"}
            form={form}
            value={form.name}
            subtitle={"Legal business name"}
            enabled={true}
            allowEdit={user ? true : false}
            name={"name"}
            type={"text"}
            errors={errors}
            label={"Account Name"}
            onChangeValue={onChangeFormField}
            register={register({ required: true, maxLength: 50 })}
            validation={validateBusinessName}
          />
        </Col>
      </Row>
    </SettingsWrapper>
  );
};

export default AccountSettings;

import React from "react";
// import { USER_INFO_TYPES } from "../../../constants/user-info";
import { Col, Row } from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import CalendarIcon from "@material-ui/icons/InsertInvitation";
import ListIcon from "@material-ui/icons/FormatListNumbered";
import { Modal } from "react-bootstrap";
import Card from "../Card/Card";
import { SNIPPET_TYPES } from "constants/square";

const SquareSnipppetTypeModal = ({ isActive, setActiveType, error, showModal, closeModal, onSubmit }) => {
  return (
    <Modal dialogClassName="add-guest-modal add-guest-modal__user" show={showModal} onHide={closeModal}>
      <Card
        content={
          <div className="add-guest">
            <Row>
              <Col className="add-guest__title" md={12}>
                Add Website Snippet
              </Col>
            </Row>
            <Row className="add-guest__responsive-container">
              <Col
                className={`add-guest__box ${isActive(SNIPPET_TYPES.WAITLIST) && "active"}`}
                onClick={() => setActiveType(SNIPPET_TYPES.WAITLIST)}
              >
                <div className="add-guest__title">Waitlist</div>
                <span>Allow guest to check in to your waitlist from your website</span>
                <ListIcon />
              </Col>
              <Col
                className={`add-guest__box ${isActive(SNIPPET_TYPES.RESERVATION) && "active"}`}
                onClick={() => setActiveType(SNIPPET_TYPES.RESERVATION)}
              >
                <div className="add-guest__title">Reservations</div>
                <span>Allow guest to book reservations on my website</span>
                <CalendarIcon />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="add-guest__actions">
                  {error && <p className="add-guest__error">{error}</p>}
                  <Button onClick={closeModal} className="add-guest__cancel" bsStyle="info" pullRight type="button">
                    Cancel
                  </Button>
                  <Button bsStyle="info" pullRight fill type="button" onClick={onSubmit}>
                    <span>Next</span>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        }
      />
    </Modal>
  );
};

export default SquareSnipppetTypeModal;

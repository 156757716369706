import React from "react";
import "components/Settings/Settings.scss";
import Button from "components/CustomButton/CustomButton";
import "./Square.scss";

function SquareLinkedConfirmation(props) {
  const { siteDomain, goToNextStep } = props;

  return (
    <div className="square-modal">
      <div className="square-modal__header">Your Square account is now linked</div>
      <div className="square-modal__body">
        {siteDomain
          ? "We linked your Square account to Waitly and added a self check-in button to your website."
          : "We linked your Square account to Waitly"}
        <br />
        <br />
        You can always make changes in the Waitly settings menu.
      </div>
      {siteDomain && (
        <div className="square-modal__external-link">
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a href={`//${siteDomain}`} target="_blank">
            <i className="square-modal__external-link-icon mdi mdi-open-in-app" />
            Preview My Site
          </a>
        </div>
      )}

      <div className="square-modal__footer square-modal__footer--right">
        <Button
          onClick={goToNextStep}
          bsStyle="info"
          className="square-welcome__button btn-fill"
          pullRight
          type="button"
        >
          OK
        </Button>
      </div>
    </div>
  );
}

export default SquareLinkedConfirmation;

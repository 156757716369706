export const handleEnterKeyNextField = e => {
  if (e.key === "Enter" || e.keyCode === 13) {
    if (
      (e.target.nodeName === "INPUT" ||
        e.target.nodeName === "SELECT" ||
        (e.target.nodeName === "TEXTAREA" && !e.shiftKey)) &&
      e.target.type !== "submit"
    ) {
      const form = e.target.form;
      const index = Array.prototype.indexOf.call(form, e.target);
      const nextInput = form.elements[index + 1];
      if (
        (nextInput.nodeName === "INPUT" || nextInput.nodeName === "SELECT" || nextInput.nodeName === "TEXTAREA") &&
        nextInput.type !== "submit"
      ) {
        nextInput.focus();
        e.preventDefault();
      }
    }
  }
};

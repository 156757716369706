import React, { useState } from "react";
import "./Square.scss";
import Button from "components/CustomButton/CustomButton";
import { ControlLabel, FormGroup } from "react-bootstrap";
import SettingsInput from "components/Settings/SettingsInput";
import { updateSquareInfo } from "services/square/square.service";
import { Plan } from "constants/plan";

function SquareSyncLocations(props) {
  const { goToPreviousStep, goToNextStep, squareInfo } = props;
  const [form, setForm] = useState({
    squareSyncLocations: squareInfo.plan !== Plan.free
  });
  const toggleChecked = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };
  const nextStep = () => {
    const configs = {
      squareSyncLocations: form.squareSyncLocations,
      squareLinked: true
    };
    updateSquareInfo(configs);
    goToNextStep(configs);
  };
  return (
    <div className="square-modal">
      <div className="square-modal__header">Sync your locations?</div>
      <div className="square-modal__body">
        <p>
          We see you have {squareInfo.squareLocations.length} Square locations. We can create a Waitly location for
          each. Do this if you'd like to have separate waitlist and reservations for each location.
        </p>
        <div className="square-modal__toggle">
          <FormGroup style={{ margin: 0 }}>
            <ControlLabel style={{ float: "left", marginTop: 17, fontSize: 15 }}>Sync Locations</ControlLabel>
            <SettingsInput
              form={form}
              name={"squareSyncLocations"}
              type={"switch"}
              enabled={true}
              style={{ marginBottom: 0 }}
              value={form.squareSyncLocations}
              register={null}
              onChangeValue={toggleChecked}
            />
          </FormGroup>
        </div>
      </div>
      <div className="square-modal__footer square-modal__footer--right">
        <Button
          onClick={goToPreviousStep}
          bsStyle="info"
          className="square-modal__button square-modal__button--left"
          pullRight
          type="button"
        >
          Back
        </Button>
        <Button onClick={nextStep} bsStyle="info" className="square-modal__button btn-fill" pullRight type="button">
          Next
        </Button>
      </div>
    </div>
  );
}

export default SquareSyncLocations;

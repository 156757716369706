import { DateTime } from "luxon";

export const VERIFICATION_MODES = {
  OFF: "off",
  ON: "on"
};

export const PHONE_CONFIG_STATUSES = {
  VERIFIED: "verified",
  UNVERIFIED: "unverified",
  SUBMITTED: "submitted",
  FAILED: "failed",
  PENDING: "pending"
};

export const isGracePeriodAfterNow = phoneConfig => {
  return DateTime.fromJSDate(phoneConfig?.gracePeriod?.toDate()).diffNow("minute").minutes > 0;
};

import React, { useState, useEffect } from "react";
import { getTimeDifferenceInSeconds, getTimeDifferenceInMinutes } from "services/helpers/waitlist";

function TimerTick(props) {
  const [lastUpdated, setLastUpdated] = useState(false);
  const { date, date2, increments } = props;

  useEffect(() => {
    let interval = setInterval(() => setLastUpdated(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastUpdated]);
  if (increments === "minutes") {
    return <span>{getTimeDifferenceInMinutes(date, date2)}</span>;
  } else {
    return <div>{getTimeDifferenceInSeconds(date, date2)}</div>;
  }
}

export default TimerTick;

import { DateTime } from "luxon";
import { DEFAULT_RESERVATION_DURATION } from "constants/reservation";

export function getHours(hoursByDays, resources, businessSettings) {
  const result = [];
  for (let data of hoursByDays) {
    const hours = [];
    const start = data.begin || "00:00";
    const end = data.end || "23:45";

    let indexDt = DateTime.fromFormat(start, "HH:mm", { zone: businessSettings.timezone });
    let endDt = DateTime.fromFormat(end, "HH:mm", { zone: businessSettings.timezone });

    const duration = getDuration(resources, indexDt, endDt);
    if (indexDt < endDt) {
      if (duration === -1) {
        result.push(hours);
      }
      while (indexDt < endDt) {
        hours.push(indexDt.toFormat("h:mm a"));
        indexDt = indexDt.plus({ minutes: 15 });
      }
      result.push(hours);
    } else {
      result.push([]);
    }
  }
  return result;
}

export function getDefaultDuration(resources, currentDate, businessSettings) {
  if (resources && resources.length > 0) {
    if (resources.some(r => r.minDuration === -1)) {
      if (businessSettings && businessSettings.reservations && businessSettings.reservations.hours) {
        const zone = businessSettings.timezone;
        const date = DateTime.fromJSDate(currentDate.toDate());
        const s = DateTime.fromFormat(businessSettings.reservations.hours[getWeekdayIndex(date)].begin, "HH:mm", {
          zone: zone
        });
        const e = DateTime.fromFormat(businessSettings.reservations.hours[getWeekdayIndex(date)].end, "HH:mm", {
          zone: zone
        });
        return e.diff(s, "minutes").as("minutes");
      } else {
        return DEFAULT_RESERVATION_DURATION;
      }
    } else {
      return DEFAULT_RESERVATION_DURATION;
    }
  } else {
    return DEFAULT_RESERVATION_DURATION;
  }
}

function getWeekdayIndex(date) {
  if (date.weekday === 7) {
    return 0;
  } else {
    return date.weekday;
  }
}

function getDuration(resources, start, end) {
  if (resources.length > 0 && resources.some(r => r.minDuration === -1)) {
    return end.diff(start, "minutes").minutes;
  } else return DEFAULT_RESERVATION_DURATION;
}

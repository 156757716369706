import React, { useState, useEffect } from "react";
import moment from "moment";

function ChatMessageAge(props) {
  const [lastUpdated, setLastUpdated] = useState(false);
  let interval;
  useEffect(() => {
    // eslint-disable-next-line
    interval = setInterval(() => setLastUpdated(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastUpdated]);

  const getMessageAge = date => {
    const secondsDiff = moment().diff(date, "seconds");
    const minutesDiff = moment().diff(date, "minutes");
    const hoursDiff = moment().diff(date, "hours");
    const daysDiff = moment().diff(date, "days");
    if (secondsDiff < 60) {
      return `less than minute ago`;
    } else if (minutesDiff < 60) {
      return `${minutesDiff} ${minutesDiff !== 1 ? "minutes ago" : "minute ago"}`;
    } else if (hoursDiff < 24) {
      return `${hoursDiff} ${hoursDiff !== 1 ? "hours ago" : "hour ago"}`;
    } else {
      return `${daysDiff} ${daysDiff !== 1 ? "days ago" : "day ago"}`;
    }
  };

  return <div className="chat__message-status">{getMessageAge(props.date)}</div>;
}

export default ChatMessageAge;

import { db } from "../../firebase";
import { Observable } from "rxjs";

export function getDashboardStats(user) {
  return new Observable(subscriber => {
    db.collection("aggregates")
      .where("accountId", "==", user.accountId)
      .onSnapshot(result => {
        subscriber.next(result.docs);
      });
  });
}

export function getBusinessStats(user) {
  return new Observable(subscriber => {
    db.collection("aggregates")
      .where("businessId", "==", user.businessId)
      .onSnapshot(result => {
        subscriber.next(result.docs);
      });
  });
}

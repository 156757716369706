/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Navbar } from "react-bootstrap";
// links that appear in navbar - they are separated from this component (navbar) so that we can redner them on responsive in sidebar as well

import AdminNavbarLinks from "./AdminNavbarLinks.jsx";

// we import here the routes for dashboard pages (links that appear in sidebar) to set navbar's name

import routes from "../../routes";
import "./AdminNavbar.scss";
import { connect, useSelector } from "react-redux";
import Select from "react-select";
import { setBusiness, setBusinesses } from "../../store/actions/business.actions";
import AddLocation from "../AddLocation/AddLocation";
import { canAddLocations } from "../../configs/access";
import { getRedirectToSquareUrls } from "services/square/square.service.js";
import AddUser from "components/AddUser/AddUser.jsx";
import AddPersonIcon from "@material-ui/icons/PersonAdd";
import CreditCard from "@material-ui/icons/CreditCard.js";
import DomainIcon from "@material-ui/icons/Domain";
import SweetAlert from "react-bootstrap-sweetalert";
import { DateTime } from "luxon";
import { stripePaymentSetup } from "services/profile/profile.service.js";

function AdminNavbar(props) {
  let [newBusinessId, setNewBusinessId] = useState(null);
  let [showAddLocationModal, setShowAddLocationModal] = useState(false);
  let [showAddUserModal, setShowAddUserModal] = useState(false);
  let [online, setOnline] = useState(true);
  let [squareDisconnected, setSquareDisconnected] = useState(false);
  const [showPromptAddUserModal, setShowPromptAddUserModal] = useState(false);
  const accountSelector = useSelector(state => state.account);
  const { account } = accountSelector;
  const { businesses, setBusiness, history } = props;

  useEffect(() => {
    if (newBusinessId && businesses.find(business => business.businessId === newBusinessId)) {
      setBusiness(businesses.find(business => business.businessId === newBusinessId));
      setNewBusinessId(null);
      history.push("settings");
    }
  }, [newBusinessId, history, businesses, setBusiness]);

  const missingPaymentDetails = account && account.missingPaymentDetails;
  const trialDaysLeft = Math.round(DateTime.fromJSDate(account?.expires?.toDate?.()).diffNow("days").days);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setOnline(navigator.onLine);
    };
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);
    return () => {
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (
      account &&
      !account.squareLinked &&
      account.squareError === "disconnected" &&
      props.user.claims.role === "admin"
    ) {
      setSquareDisconnected(true);
    } else {
      setSquareDisconnected(false);
    }
  }, [account]);

  const onChangeBusiness = option => {
    const value = props.businesses.find(business => business.businessId === option.value);
    if (value) {
      props.setBusiness(value);
      props.onResetSubscriptions(value.businessId);
      onCheckForRedirect(value.settings);
    }
  };

  const onCheckForRedirect = settings => {
    if (props.history.location.pathname.includes("reservations") && !settings.enableReservations) {
      props.history.push("waitlist");
    }
  };

  const onGetSelectValue = () => {
    const business = props.business
      ? props.businesses.find(business => business.businessId === props.business.businessId)
      : undefined;
    if (business) {
      return { value: business.businessId, label: business.name };
    }
    return undefined;
  };

  const onCheckIfToShowBusinessesSelect = () => {
    const roles = ["admin", "area-manager", "regional-manager", "analyst"];
    return (
      props.businesses &&
      roles.includes(props.user?.claims?.role) &&
      props.businesses.length > 1 &&
      onCheckForAvailableRoute()
    );
  };

  const isMobile = window.matchMedia("(max-width: 767px)").matches;

  const onCheckIfToShowAddBusinessLocation = () => {
    return canAddLocations(account, props.user, props.businesses) && onCheckForAvailableRoute() && !isMobile && online;
  };

  const onCheckIfToShowAddUser = () => {
    return (
      (props.plan === "enterprise" || props.plan === "pro" || props.plan === "premium") &&
      props.user.claims.role === "admin" &&
      !isMobile &&
      online
    );
  };

  const onCheckForAvailableRoute = () => {
    let currentLocation = props.history.location.pathname;
    const routesToDisplayBusinessSelector = ["reservations", "waitlist", "settings", "history"];
    return routesToDisplayBusinessSelector.some(route => currentLocation.includes(route));
  };

  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // function for responsive that hides/shows the sidebar
  const mobileSidebarToggle = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  const onShowAddLocationModal = () => {
    setShowAddLocationModal(true);
  };
  const onShowAddUserModal = () => {
    setShowAddUserModal(true);
  };

  const onCloseAddLocationModal = businessId => {
    if (businessId && !businessId.target) {
      if (onCheckIfToShowAddUser()) {
        setShowPromptAddUserModal(true);
      }
    }
    setShowAddLocationModal(false);
    setNewBusinessId(businessId);
  };

  const onCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  const reconnectSquare = async () => {
    const urls = await getRedirectToSquareUrls();
    window.location.href = urls.reconnect;
  };

  const hidePromptAddUserModal = () => {
    setShowPromptAddUserModal(false);
  };
  const confirmPromptAddUserModal = () => {
    setShowPromptAddUserModal(false);
    onShowAddUserModal();
  };

  const handlePaymentsDetailsMissingClick = async () => {
    const response = await stripePaymentSetup();
    if (response) {
      const json = await response.json();
      window.location.href = json.url;
    }
  };

  return (
    <Navbar fluid className={props.navbar ? "navbar-fixed" : ""}>
      {showPromptAddUserModal && (
        <SweetAlert
          showCancel
          title={"New location created"}
          cancelBtnText="Skip for now"
          confirmBtnText="Yes"
          onCancel={hidePromptAddUserModal}
          onConfirm={confirmPromptAddUserModal}
        >
          Do you want to create a login for this location?
        </SweetAlert>
      )}
      {showAddLocationModal && (
        <AddLocation
          businesses={props.businesses}
          showAddLocationModal={showAddLocationModal}
          closeModal={onCloseAddLocationModal}
        />
      )}
      {showAddUserModal && <AddUser showModal={showAddUserModal} closeModal={onCloseAddUserModal} />}
      <div className="navbar-minimize">
        <button
          id="minimizeSidebar"
          className="btn btn-default btn-fill btn-round btn-icon"
          onClick={props.handleMiniClick}
        >
          <i className="fa fa-ellipsis-v visible-on-sidebar-regular" />
          <i className="fa fa-navicon visible-on-sidebar-mini" />
        </button>
      </div>
      <Navbar.Header>
        <Navbar.Brand>
          {/* Here we create navbar brand, based on route name */}
          <a href="#pablo">{getActiveRoute(routes)}</a>
        </Navbar.Brand>
        <Navbar.Toggle onClick={mobileSidebarToggle} />
        <div className="headerContents">
          {onCheckIfToShowBusinessesSelect() && (
            <div className="select-wrapper">
              <Select
                onChange={onChangeBusiness}
                value={onGetSelectValue()}
                options={props.businesses.map(b => ({ value: b.businessId, label: b.name }))}
                className="business-select"
              />
            </div>
          )}
          {onCheckIfToShowAddBusinessLocation() && (
            <button className="add-location-button" onClick={onShowAddLocationModal}>
              <DomainIcon /> Add Location
            </button>
          )}
          {onCheckIfToShowAddUser() && (
            <button className="add-user-button" onClick={onShowAddUserModal}>
              <AddPersonIcon /> Add User
            </button>
          )}
          {missingPaymentDetails && (
            <div className="payment-details-missing">
              <button className="add-user-button" onClick={() => handlePaymentsDetailsMissingClick()}>
                {!!trialDaysLeft && (
                  <>
                    <CreditCard />
                    Add payment method
                    {!isMobile && (
                      <span style={{ color: "#fc7f03", marginLeft: "15px" }}>
                        {trialDaysLeft} days remaining on trial.
                      </span>
                    )}
                  </>
                )}{" "}
              </button>
            </div>
          )}
        </div>
      </Navbar.Header>

      {!online && (
        <div className="offline-mode-message">
          <i className="mdi mdi-lan-disconnect" />
          <span>Offline - Can't send texts or sync</span>
        </div>
      )}
      {squareDisconnected && (
        <div className="square-disconnected">
          <div className="offline-mode-message">
            <i className="mdi mdi-lan-disconnect" />
            <span>Your Square account was disconnected</span>
            <a className="square-disconnected__link" onClick={reconnectSquare}>
              Reconnect
            </a>
          </div>
        </div>
      )}
      {/* Here we import the links that appear in navbar */}
      {window.innerWidth > 992 ? (
        <Navbar.Collapse>
          <AdminNavbarLinks
            {...props}
            onCheckForAvailableRoute={onCheckForAvailableRoute}
            setNewBusinessId={setNewBusinessId}
          />
        </Navbar.Collapse>
      ) : null}
    </Navbar>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.account,
    business: state.business.business
  };
};

export default connect(mapStateToProps, {
  setBusiness,
  setBusinesses
})(AdminNavbar);

import React from "react";
import "./SquareConnectButton.scss";
import squareLogo from "../../assets/img/Square_Logo.png";

function SquareConnectButton({ connectToSquareClick }) {
  return (
    <div onClick={connectToSquareClick} className="square-connect-button">
      <span className="square-connect-button__connect-to">Connect to</span>
      <img src={squareLogo} alt="Waitly Logo" style={{ height: "34px" }} />
    </div>
  );
}

export default SquareConnectButton;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "./Square.scss";
import SquareWelcome from "./SquareWelcome";
import SquareSyncCustomers from "./SquareSyncCustomers";
import SquareSyncLocations from "./SquareSyncLocations";
import SquareAddCheckIns from "./SquareAddCheckIns";
import SquareActivate from "./SquareActivate";
import SquareAppearance from "./SquareAppearance";
import { updateSquareInfo } from "services/square/square.service";
import SquareError from "./SquareError";
import SquareLinkedConfirmation from "./SquareLinkedConfirmation";
import { logEvent } from "services/firebase/firebase.service";
import { FIREBASE_EVENTS } from "../../constants/firebase";
import SquareTakeReservations from "./SquareTakeReservations";
import SquareReservationSetup from "./SquareReservationSetup";
import { RESERVATIONS_TYPES } from "constants/reservation";

function SquareSetupModal(props) {
  const { business } = props;
  console.log("where is my business", business);
  const { handleSquareSetupModalClose, squareSetupModalConfig, squareInfo, exitPoint } = props;
  const [configs, setConfigs] = useState(squareSetupModalConfig);
  const steps = [
    { key: "welcome", display: () => true },
    {
      key: "syncCustomers",
      display: () => true
    },
    {
      key: "syncLocations",
      display: () => squareInfo?.squareLocations?.length > 1
    },
    {
      key: "takeReservations",
      display: () => squareInfo.squareOnlineSites?.length > 0
    },
    {
      key: "addCheckIns",
      display: payload => !payload.takeReservations && !payload.final && squareInfo.squareOnlineSites?.length > 0
    },
    {
      key: "activate",
      display: payload => {
        const params = new URLSearchParams(window.location.href.split("?")[1] || "");
        if (params.get("squareSubscription") === "true") {
          return false;
        }

        return (
          !payload.final && (squareInfo.plan === "free" || (squareInfo.plan === "premium" && payload.takeReservations))
        );
      }
    },
    {
      key: "reservationSetup",
      display: payload => {
        console.log("evaludate step: ", payload);
        return payload.takeReservations && squareInfo.squareOnlineSites?.length > 0 && !payload.skipActivate;
      }
    },
    {
      key: "appearance",
      display: payload =>
        squareInfo.squareOnlineSites?.length > 0 &&
        (payload.addCheckins || payload.takeReservations) &&
        !payload.skipActivate &&
        !payload.final
    },
    {
      key: "confirmation",
      display: () => true
    }
  ];

  const getInitalStep = () => {
    if (squareSetupModalConfig.reconnect) {
      return steps[8];
    } else if (squareSetupModalConfig.step == "activate") {
      return steps[5];
    } else if (squareSetupModalConfig.step == "paymentSuccess" && squareSetupModalConfig.plan == "pro") {
      return steps[6];
    } else if (squareSetupModalConfig.step == "paymentSuccess" && squareSetupModalConfig.plan == "premium") {
      return steps[7];
    } else {
      return steps[0];
    }
  };
  const [currentStep, setCurrentStep] = useState(getInitalStep());
  const closeModal = () => {
    handleSquareSetupModalClose(configs);
  };

  const goToPreviousStep = () => {
    const index = steps.findIndex(step => step.key === currentStep.key);
    let newIndex;
    for (let i = index - 1; i >= 0; i--) {
      if (steps[i].display()) {
        newIndex = i;
        break;
      }
    }
    if (newIndex !== -1) {
      setCurrentStep(steps[newIndex]);
    }
  };

  const finishLinkingFlow = async payload => {
    logEvent(FIREBASE_EVENTS.square_setup_complete);
    updateSquareInfo({ squareLinked: true, reservationsType: RESERVATIONS_TYPES.RESERVATIONS });
    handleSquareSetupModalClose(payload);
  };

  const goToNextStep = newConfigs => {
    const payload = { ...(configs || {}), ...(newConfigs || {}) };
    setConfigs(payload);
    const index = steps.findIndex(step => step.key === currentStep.key);
    let newIndex = steps.findIndex((step, i) => i > index && step.display(payload));
    console.log("goToNextStep currentstep", index, "newStep: ", newIndex, newConfigs);
    if (newIndex === 5 && newConfigs.addCheckins === false) {
      newIndex = 8; //to to end if check-ins skipped.
    }
    if (newIndex !== -1) {
      setCurrentStep(steps[newIndex]);
    } else {
      finishLinkingFlow(payload);
    }
  };

  const stepComponentMapper = {
    welcome: (
      <SquareWelcome newAccount={squareSetupModalConfig.newAccount} goToNextStep={() => goToNextStep()}></SquareWelcome>
    ),
    syncCustomers: <SquareSyncCustomers goToNextStep={goToNextStep}></SquareSyncCustomers>,
    syncLocations: (
      <SquareSyncLocations
        goToPreviousStep={goToPreviousStep}
        goToNextStep={goToNextStep}
        squareInfo={squareInfo}
      ></SquareSyncLocations>
    ),
    takeReservations: <SquareTakeReservations goToNextStep={goToNextStep} />,
    reservationSetup: <SquareReservationSetup businessId={business?.businessId} goToNextStep={goToNextStep} />,
    addCheckIns: <SquareAddCheckIns goToNextStep={goToNextStep}></SquareAddCheckIns>,
    activate: (
      <SquareActivate
        squareInfo={squareInfo}
        configs={configs}
        exitPoint={exitPoint}
        goToNextStep={goToNextStep}
      ></SquareActivate>
    ),
    appearance: (
      <SquareAppearance
        placement="setupFlow"
        squareInfo={squareInfo}
        configs={configs}
        saveSnippet={goToNextStep}
      ></SquareAppearance>
    ),
    confirmation: (
      <SquareLinkedConfirmation
        goToNextStep={goToNextStep}
        siteDomain={configs && configs.siteDomain}
      ></SquareLinkedConfirmation>
    )
  };

  return (
    <Modal backdrop="static" dialogClassName="square" show={!!squareSetupModalConfig} onHide={closeModal}>
      <div className="square__content">
        {squareSetupModalConfig.error ? (
          <SquareError
            squareInfo={squareInfo}
            squareSetupModalConfig={squareSetupModalConfig}
            handleModalClose={handleSquareSetupModalClose}
          />
        ) : (
          <div className="step-wrapper">{stepComponentMapper[currentStep.key]}</div>
        )}
      </div>
    </Modal>
  );
}

export default SquareSetupModal;

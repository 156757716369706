import React, { useState } from "react";
import Card from "components/Card/Card.jsx";
import { Modal, Row, Col } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton";
import WaitlistSettingsHour from "components/Settings/WaitlistSettingsHour";
import "./SpecificDateForm.scss";
import { collections } from "services";
import { db } from "firebase.js";

function SpecificDateForm(props) {
  const { onHide, showDateForm, date, selectedEvent, business } = props;

  const [form, setForm] = useState({
    closed: selectedEvent?.event?.closed || false,
    time: {
      begin: selectedEvent?.event?.begin || "00:00",
      end: selectedEvent?.event?.end || "11:45"
    }
  });

  const onValidChange = (day, values) => {
    let f = form;
    if (values.begin) {
      f.time.begin = values.begin;
    }
    if (values.end) {
      f.time.end = values.end;
    }
    if (values.closed !== undefined) {
      f.closed = values.closed;
    }
    setForm({ ...f });
  };

  const formatDate = date => {
    if (date) {
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit"
      });
    }
  };

  const onSave = async () => {
    if (selectedEvent) {
      let ref = db
        .collection(collections.businesses)
        .doc(business.businessId)
        .collection(collections.shifts)
        .doc(selectedEvent.event.id);

      await ref.update({ closed: form.closed, begin: form.time.begin, end: form.time.end });
    } else {
      let ref = db
        .collection(collections.businesses)
        .doc(business.businessId)
        .collection(collections.shifts)
        .doc();
      await ref.set({
        date: date,
        closed: form.closed,
        begin: form.time.begin,
        end: form.time.end,
        accountId: business.accountId
      });
    }
    onHide();
  };

  const onRemove = async () => {
    await db
      .collection(collections.businesses)
      .doc(business.businessId)
      .collection(collections.shifts)
      .doc(selectedEvent.event.id)
      .delete();
    onHide();
  };

  return (
    <Modal className="specificDateForm-modal" show={showDateForm} bsSize="lg" onHide={onHide}>
      <Card
        title={`Hours for ${formatDate(date)}`}
        content={
          <>
            <Row md={12}>
              <Col>
                <WaitlistSettingsHour
                  value={!form.closed}
                  disabled={false}
                  time={form.time}
                  onValidChange={onValidChange}
                  day={"Set hours"}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="specific-date-footer__cancel">
                  {selectedEvent ? (
                    <Button onClick={onRemove} bsStyle="danger" pullRight type="button" style={{ marginRight: "10px" }}>
                      Remove
                    </Button>
                  ) : (
                    <Button onClick={onHide} bsStyle="info" pullRight type="button" style={{ marginRight: "10px" }}>
                      Cancel
                    </Button>
                  )}
                  <Button onClick={onSave} bsStyle="info" pullRight fill type="button">
                    Save
                  </Button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <p>
                  <strong>NOTE:</strong> Changing booking hours will NOT affect existing reservations on this date.
                </p>
              </Col>
            </Row>
          </>
        }
      ></Card>
    </Modal>
  );
}

export default SpecificDateForm;

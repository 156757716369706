import { SET_PRICES } from "../actions/prices.actions";

export function prices(state = {}, action) {
  switch (action.type) {
    case SET_PRICES:
      return { ...state, prices: action.payload };
    default:
      return state;
  }
}

import React from "react";
import "components/Settings/Settings.scss";
import "./SquareHeader.scss";

function SquareHeader({ children, style = {} }) {
  return (
    <div className="square-header" style={style}>
      {children}
    </div>
  );
}

export default SquareHeader;

import React, { useRef, useState } from "react";
import { FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { hours, minutes } from "../../constants/time";
import toastr from "toastr";
import { DateTime } from "luxon";

const WailistSettingsHour = ({ onDisableDay, value, disabled, day, time, onValidChange }) => {
  const beginHoursInput = useRef(null);
  const beginMinutesInput = useRef(null);
  const beginPeriodInput = useRef(null);
  const endHoursInput = useRef(null);
  const endMinutesInput = useRef(null);
  const endPeriodInput = useRef(null);
  const [theValue, setTheValue] = useState(value);

  const useStyles = makeStyles(() => ({
    root: {
      position: "relative",
      marginLeft: 0,
      marginRight: 16,
      marginBottom: 0
    },
    labelPlacementStart: {
      marginLeft: 0,
      marginRight: 16
    },
    label: {
      position: "absolute",
      left: theValue ? 10 : 30,
      color: "white",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 10,
      top: 8
    }
  }));
  const classes = useStyles();

  const IOSSwitch = withStyles(theme => ({
    root: {
      width: 66,
      height: 28,
      padding: 0
    },
    switchBase: {
      padding: 1,
      top: 1,
      left: 2,
      "&$checked": {
        transform: "translateX(35px)",
        top: 1,
        left: 3,
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: disabled ? "#CAC9C9" : "rgb(79, 128, 239)",
          opacity: 1,
          border: "none"
        }
      },
      "&$focusVisible $thumb": {
        color: disabled ? "#CAC9C9" : "rgb(79, 128, 239)",
        border: "6px solid #fff"
      }
    },
    thumb: {
      width: 24,
      height: 24
    },
    track: {
      borderRadius: 60 / 2,
      border: "1px solid rgb(193, 193, 193)",
      backgroundColor: disabled ? "#CAC9C9" : "rgb(193, 193, 193)",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        checked={theValue}
        onChange={onSwitch}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
    );
  });

  const onSwitch = event => {
    setTheValue(event.target.checked);
    if (onDisableDay) {
      onDisableDay(day, event.target.checked);
    }
    let values = {
      closed: !event.target.checked
    };
    if (!event.target.checked) {
      let beginHour = convertHourValue(beginHoursInput.current.value, beginPeriodInput.current.value);
      let endHour = convertHourValue(endHoursInput.current.value, endPeriodInput.current.value);

      let startTime = DateTime.fromISO(`${beginHour}:${beginMinutesInput.current.value}`);
      let endTime = DateTime.fromISO(`${endHour}:${endMinutesInput.current.value}`);
      values["begin"] = startTime.toFormat("HH:mm");
      values["end"] = endTime.toFormat("HH:mm");
    }
    onValidChange(day, values);
  };

  const convertHourValue = (hour, period) => {
    if (hour === "12" && period === "AM") {
      return "00";
    } else if (period === "PM" && hour * 1 <= 11) {
      return "" + (hour * 1 + 12);
    } else {
      return "" + hour;
    }
  };

  const onChange = day => () => {
    let beginHour = convertHourValue(beginHoursInput.current.value, beginPeriodInput.current.value);
    let endHour = convertHourValue(endHoursInput.current.value, endPeriodInput.current.value);
    let startTime = DateTime.fromISO(`${beginHour}:${beginMinutesInput.current.value}`);
    let endTime = DateTime.fromISO(`${endHour}:${endMinutesInput.current.value}`);
    if (endHour === "00") {
      endTime = endTime.plus({ days: 1 });
    }
    if (!timesAreValid(startTime, endTime)) {
      toastr.error("Start time should be before end time");
      return;
    }
    onValidChange(day, {
      begin: startTime.toFormat("HH:mm"),
      end: endTime.toFormat("HH:mm"),
      closed: false
    });
  };

  const timesAreValid = (start, end) => {
    if (start.isValid && end.isValid && start <= end) {
      return true;
    }
    return false;
  };

  const onGetBeginHour = () => {
    let begin = time.begin || "00:00";
    let hour = begin.substr(0, begin.indexOf(":"));
    if (hour * 1 > 12) {
      if (hour * 1 - 12 < 10) {
        return "0" + (hour * 1 - 12).toString();
      } else {
        return (hour * 1 - 12).toString();
      }
    } else if (hour * 1 === 0) {
      return "12";
    } else {
      return hour.toString();
    }
  };

  const onGetBeginMinutes = () => {
    let begin = time.begin || "00:00";
    return begin.substr(begin.indexOf(":") + 1, begin.length);
  };

  const onGetBeginPeriod = () => {
    let begin = time.begin || "00:00";
    let hour = begin.substr(0, begin.indexOf(":"));
    if (hour * 1 > 11) {
      return "PM";
    } else {
      return "AM";
    }
  };

  const onGetEndHour = () => {
    let end = time.end || "23:45";
    let hour = end.substr(0, end.indexOf(":"));
    if (hour * 1 > 12) {
      if (hour * 1 - 12 < 10) {
        return "0" + (hour * 1 - 12).toString();
      } else {
        return (hour * 1 - 12).toString();
      }
    } else if (hour * 1 === 0) {
      return "12";
    } else {
      return hour.toString();
    }
  };

  const onGetEndMinutes = () => {
    let end = time.end || "23:45";
    return end.substr(end.indexOf(":") + 1, end.length);
  };

  const onGetEndPeriod = () => {
    let end = time.end || "23:45";
    let hour = end.substr(0, end.indexOf(":"));
    if (hour * 1 > 11) {
      return "PM";
    } else {
      return "AM";
    }
  };

  return (
    <div className="hours-wrapper">
      <div style={{ color: disabled && "#CAC9C9" }} className="settings-day-name">
        {day}
      </div>
      <FormControlLabel
        classes={classes}
        disabled={disabled}
        control={<IOSSwitch checked={value} />}
        labelPlacement={theValue ? "start" : "end"}
        label={theValue ? "Open" : "Closed"}
      />
      {value && (
        <>
          <select
            className="form-control hours-input"
            style={{
              marginRight: 8,
              color: disabled && "#CAC9C9"
            }}
            disabled={disabled}
            ref={beginHoursInput}
            onChange={onChange(day)}
            value={onGetBeginHour()}
          >
            {hours.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          :
          <select
            className="form-control hours-input"
            style={{
              marginLeft: 8,
              marginRight: 18,
              color: disabled && "#CAC9C9"
            }}
            disabled={disabled}
            ref={beginMinutesInput}
            onChange={onChange(day)}
            value={onGetBeginMinutes()}
          >
            {minutes.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            className="form-control hours-input"
            style={{
              marginRight: 18,
              color: disabled && "#CAC9C9"
            }}
            disabled={disabled}
            ref={beginPeriodInput}
            onChange={onChange(day)}
            value={onGetBeginPeriod()}
          >
            <option value={"AM"}>AM</option>
            <option value={"PM"}>PM</option>
          </select>
          <span style={{ color: disabled && "#CAC9C9" }}>to</span>
          <select
            className="form-control hours-input"
            style={{
              marginLeft: 18,
              marginRight: 8,
              color: disabled && "#CAC9C9"
            }}
            disabled={disabled}
            ref={endHoursInput}
            onChange={onChange(day)}
            value={onGetEndHour()}
          >
            {hours.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          :
          <select
            className="form-control hours-input"
            style={{
              marginLeft: 8,
              marginRight: 18,
              color: disabled && "#CAC9C9"
            }}
            disabled={disabled}
            ref={endMinutesInput}
            onChange={onChange(day)}
            value={onGetEndMinutes()}
          >
            {minutes.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
          <select
            className="form-control hours-input"
            onChange={onChange(day)}
            style={{
              color: disabled && "#CAC9C9"
            }}
            disabled={disabled}
            ref={endPeriodInput}
            value={onGetEndPeriod()}
          >
            <option value={"AM"}>AM</option>
            <option value={"PM"}>PM</option>
          </select>
        </>
      )}
    </div>
  );
};

export default WailistSettingsHour;

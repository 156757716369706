import { firebase } from "../../firebase";

export async function getChartsData(data) {
  const { tz, start, end, interval, locations, signal } = data;
  const currentUser = firebase.auth().currentUser;
  const token = await currentUser.getIdToken(true);

  const url = `app/charts/aggregate?tz=${tz}&start=${start}&end=${end}&interval=${interval}`;

  return fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify({
      locations
    }),
    signal
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getChartsData function", error);
      throw error;
    });
}

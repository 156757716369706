import React, { useState } from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { MoreIcon } from "common/MoreIcon/MoreIcon";
import { VisitStatus } from "services/visits/visits.service";

export const WaitlistAdditionalActionsButton = ({
  disabled,
  isDeleteButtonVisible,
  isLobbyVisible,
  isOMSVisible,
  item,
  addNoteClick,
  lobbyClick,
  resetClick,
  omsClick,
  editVisitClick,
  walkoutVisitClick,
  removeVisitClick,
  isMobile
}: {
  disabled: boolean;
  isDeleteButtonVisible: boolean;
  isLobbyVisible: boolean;
  isOMSVisible: boolean;
  item: any;
  lobbyClick: (item: any) => void;
  omsClick: (item: any) => void;
  addNoteClick: (item: any) => void;
  editVisitClick: (item: any) => void;
  resetClick: (item: any) => void;
  walkoutVisitClick: (item: any) => void;
  removeVisitClick: (item: any) => void;
  isMobile?: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const useStyles = ({ isMobile }) =>
    makeStyles(theme => {
      return {
        root: {
          padding: 0,
          backgroundColor: "transparent",
          marginRight: 8,
          marginTop: isMobile ? 6 : 10,
          width: isMobile ? 25 : 35,
          "&:hover": {
            backgroundColor: "transparent"
          },
          "&:focus": {
            backgroundColor: "transparent"
          },
          "&.Mui-focusVisible": { backgroundColor: "transparent" }
        }
      };
    });

  const classes = useStyles({ isMobile })();

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        disabled={disabled}
        onClick={handleMenuClick}
        classes={{
          root: classes.root
        }}
      >
        <MoreIcon disabled={disabled} isMobile={isMobile} />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: 50 * 4.5,
            width: "20ch"
          }
        }}
      >
        <MenuItem
          onClick={() => {
            handleMenuClose();
            addNoteClick.call(this, item);
          }}
        >
          <div>Add Note</div>
        </MenuItem>
        {isLobbyVisible && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              lobbyClick.call(this, item);
            }}
          >
            <div>{item.data().lobbyStatus ? "Remove" : "Add"} Lobby</div>
          </MenuItem>
        )}
        {item.data().callAhead && item.data().status === VisitStatus.waiting && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              resetClick.call(this, item);
            }}
          >
            <div>Not here yet</div>
          </MenuItem>
        )}
        {isOMSVisible && (
          <MenuItem
            disabled={!item.data().customerNumber}
            onClick={() => {
              handleMenuClose();
              omsClick.call(this, item);
            }}
          >
            <div>OMS+ Profile</div>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            handleMenuClose();
            editVisitClick.call(this, item);
          }}
        >
          <div>Edit</div>
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleMenuClose();
            walkoutVisitClick.call(this, item);
          }}
        >
          <div>No Show</div>
        </MenuItem>
        {isDeleteButtonVisible && (
          <MenuItem
            onClick={() => {
              handleMenuClose();
              removeVisitClick.call(this, item);
            }}
          >
            <div>Delete</div>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

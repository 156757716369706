import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Card from "../Card/Card";
import { Col, ControlLabel, Form, FormGroup, HelpBlock, Modal, Row } from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import { addRegion, editRegion } from "../../services/account/account.service";
import BeatLoader from "react-spinners/BeatLoader";
import toastr from "toastr";
import Select from "react-select";
import { useEffect } from "react";
import { useCallback } from "react";

const AreaForm = ({ closeModal, show, regionForEdit, businesses }) => {
  const [form] = useState({
    name: regionForEdit ? regionForEdit.name : "",
    id: regionForEdit ? regionForEdit.id : ""
  });

  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(false);
  const [locations, setLocations] = useState();

  const getDefaultLocations = useCallback(() => {
    if (regionForEdit && regionForEdit.locations) {
      return regionForEdit.locations.map(l => {
        const bus = businesses.find(b => b.businessId === l);
        return { value: l, label: bus.name };
      });
    }
  }, [regionForEdit, businesses]);

  useEffect(() => {
    if (regionForEdit) {
      setLocations(getDefaultLocations());
    }
  }, [regionForEdit, getDefaultLocations]);

  const onSubmit = async data => {
    setLoading(true);
    data.locations = formatLocations();
    data.id = form.id;
    data.isArea = true;
    try {
      if (regionForEdit) {
        await editRegion(data);
        toastr.success("Area Updated.");
      } else {
        await addRegion(data);
        toastr.success("Area Added.");
      }
    } catch (error) {
      toastr.error("There was a problem.");
    }
    closeModal(true);
    setLoading(false);
  };

  const formatLocations = () => {
    if (locations) {
      return locations.map(l => {
        return l.value;
      });
    }
    return [];
  };

  const updateLocations = value => {
    setLocations(value);
  };

  const busisnessOptions = () => {
    return businesses.map(b => {
      return { value: b.businessId, label: b.name };
    });
  };

  return (
    <Modal enforceFocus={false} dialogClassName="add-reservation-modal" show={show} onHide={closeModal}>
      <Card
        content={
          <Form noValidate className="add-reservation" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="add-reservation__title" md={12}>
                {regionForEdit ? "Edit Area" : "Add Area"}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.name && "error") || null}>
                  <ControlLabel>NAME</ControlLabel>
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    defaultValue={form.name}
                    placeholder="Area Name"
                    ref={register({ required: true, maxLength: 40 })}
                  />
                  {errors.name && errors.name.type === "required" && <HelpBlock>Area name is required</HelpBlock>}
                  {errors.name && errors.name.type === "maxLength" && (
                    <HelpBlock>Area Name length should be less than 40 characters</HelpBlock>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.businessId && "error") || null}>
                  <ControlLabel className="edit-user-label">LOCATIONS</ControlLabel>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    options={busisnessOptions()}
                    placeholder="Locations"
                    defaultValue={getDefaultLocations()}
                    onChange={value => updateLocations(value)}
                    disabled={loading}
                    name="locations"
                    isMulti
                  ></Select>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="add-reservation__actions">
                  <Button
                    onClick={closeModal}
                    className="add-reservation__cancel"
                    bsStyle="info"
                    pullRight
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button bsStyle="info" pullRight fill type="submit">
                    {loading ? (
                      <BeatLoader margin={2.5} size={10} color={"#fff"} />
                    ) : (
                      <span>{regionForEdit ? "Save Area" : "Add Area"}</span>
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      />
    </Modal>
  );
};

export default AreaForm;

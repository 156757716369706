import {
  SET_WAITING_VISITORS,
  SET_ALL_VISITORS,
  SET_HISTORY_VISITORS,
  SET_MESSAGES
} from "store/actions/visit.actions";

export function visit(state = {}, action) {
  switch (action.type) {
    case SET_WAITING_VISITORS:
      return { ...state, waitingVisitors: action.payload };
    case SET_ALL_VISITORS:
      return { ...state, allVisitors: action.payload };
    case SET_HISTORY_VISITORS:
      return { ...state, historyVisitors: action.payload };
    case SET_MESSAGES:
      return { ...state, messages: action.payload };
    default:
      return state;
  }
}

import { SET_SHOULD_UPDATE_USERS, SET_SHOULD_UPDATE_INVITES } from "../actions/users.actions";

const initialState = {
  shouldUpdateUsers: false,
  shouldUpdateInvites: false
};

export function users(state = initialState, action) {
  switch (action.type) {
    case SET_SHOULD_UPDATE_USERS:
      return { ...state, shouldUpdateUsers: action.shouldUpdateUsers };
    case SET_SHOULD_UPDATE_INVITES:
      return { ...state, shouldUpdateInvites: action.shouldUpdateInvites };
    default:
      return state;
  }
}

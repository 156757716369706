import React, { useEffect, useState } from "react";
import { HelpBlock, Badge, FormGroup } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { Tooltip } from "@material-ui/core";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
const MAX_MESSAGE_LENGTH = 160;
const MAX_WELCOME_MESSAGE_LENGTH = 139;

function TextMessagesBlock(props) {
  const [textareaLength, setTextareaLength] = useState(0);
  const [infoAlert, setInfoAlert] = useState(null);

  const onGetMessageLength = () => {
    return props.name === "welcomeText" || props.name === "callAheadText"
      ? MAX_WELCOME_MESSAGE_LENGTH
      : MAX_MESSAGE_LENGTH;
  };

  const getStringLength = value => {
    if (!value) {
      return 0;
    }
    if (value.indexOf(`{link}`) !== -1) {
      const remainingLinkLength = 21 - `{link}`.length;
      return value.length + remainingLinkLength;
    }
    return value.length;
  };
  const theme = createMuiTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "10px"
        }
      }
    }
  });

  useEffect(() => {
    setTextareaLength(getStringLength(props.textareaValue));
  }, [props.textareaValue]);

  const validateTextarea = value => {
    const length = getStringLength(value);
    return length <= onGetMessageLength();
  };

  const insertLink = () => {
    const linkExists = (props.textareaValue || "").indexOf("{link}") !== -1;
    if (linkExists && !(onGetMessageLength() - props.textareaValue.length < 21)) {
      setInfoAlert({
        title: "Oops",
        message: "You an only insert one waitlist link in a text message."
      });
    } else if (onGetMessageLength() - props.textareaValue.length < 21) {
      setInfoAlert({
        title: "Message Too Long",
        message: "Adding a link to the waitlist requires 21 characters. Please shorten your text message."
      });
    } else {
      const value = (props.textareaValue || "") + "{link}";
      props.handleTextareaChange(props.name)(value);
    }
  };

  const hideInfoAlert = () => {
    setInfoAlert(null);
  };

  return (
    <div
      onClick={props.onClick}
      className="text-messages-block"
      style={{ marginLeft: props.hideLabel && 0, width: props.hideLabel && "100%" }}
    >
      {infoAlert ? (
        <SweetAlert title={infoAlert.title} onConfirm={hideInfoAlert}>
          {infoAlert.message}
        </SweetAlert>
      ) : null}
      <div className="text-messages-block__title">{props.title}</div>
      <div className="text-messages-block__subtitle">{props.subtitle}</div>
      <FormGroup validationState={(!!props.errors[props.name] && "error") || null}>
        <textarea
          style={{ width: props.hideLabel && "100%" }}
          disabled={!props.enabled}
          onChange={props.handleTextareaChange(props.name)}
          name={props.name}
          className="form-control text-messages-block__textarea"
          value={props.textareaValue}
          ref={props.register({
            validate: value => validateTextarea(value)
          })}
        ></textarea>
        {props.errors && props.errors[props.name] && (
          <HelpBlock>Message too long. Please shorten your text message</HelpBlock>
        )}
      </FormGroup>
      <div className="text-messages-block__footer flex">
        <div className="text-messages-block__footer--left flex">
          {props.link ? (
            <div className="text-messages-block__badge" onClick={insertLink}>
              <Badge>{"{link}"}</Badge>
            </div>
          ) : null}
          {props.link ? (
            <ThemeProvider theme={theme}>
              <Tooltip title="Link Placeholder can be placed in your text message. We'll replace {link} with a custom link for each guest.">
                <div className="text-messages-block__badge">
                  <Badge className="question-circle">?</Badge>
                </div>
              </Tooltip>
            </ThemeProvider>
          ) : null}
        </div>
        <div
          className="text-messages-block__footer--right"
          style={{ color: textareaLength > onGetMessageLength() ? "#fb404b" : "#777" }}
        >
          {textareaLength} / {onGetMessageLength()} characters
        </div>
      </div>
    </div>
  );
}

export default TextMessagesBlock;

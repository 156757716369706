import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SettingsTitle from "./SettingsTitle";
import RoundedTag from "../../views/RoundedTag";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import { ACTIONS } from "../../constants/tables/regions-settings-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { makeStyles } from "@material-ui/core/styles";
import { deleteMessage } from "../../constants/messages";
import toastr from "toastr";
import { getRegions, deleteRegion } from "../../services/account/account.service";
import BeatLoader from "react-spinners/BeatLoader";
import Button from "../CustomButton/CustomButton";
import AreaForm from "../AreaForm/AreaForm";
import * as _ from "lodash";

const AreasSettings = () => {
  const businessSelector = useSelector(state => state.business);
  const { businesses } = businessSelector;
  const [regions, setRegions] = useState([]);
  const [regionsLoading, setRegionsLoading] = useState(false);

  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showRegionFormModal, setShowRegionFormModal] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState();

  const [firstTime, setFirstTime] = useState(true);

  const [orderBy, setOrderBy] = useState({ column: "name", direction: "asc" });

  const deleteUserMessage = deleteMessage("area");

  const createSortHandler = column => event => {
    onRequestSort(event, column);
  };

  const useStyles = makeStyles({
    head: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: 14,
      color: "#9A9A9A",
      textTransform: "uppercase",
      padding: 20,
      paddingBottom: 5
    },
    body: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: 18,
      flexFlow: "column wrap",
      color: "#323232"
    },
    root: {
      fontSize: 35
    }
  });

  const classes = useStyles();

  const hideInfoAlert = () => {
    setShowDeleteMessage(false);
  };

  useEffect(() => {
    fetchRegions(firstTime);
    setFirstTime(false);
  }, [businesses]);

  const fetchRegions = async loading => {
    setRegionsLoading(loading);
    let regions = await getRegions(true);

    regions.sort((a, b) =>
      a.name.toLowerCase() > b.name.toLowerCase() ? 1 : b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 0
    );
    matchBusinessesToRegions(regions);
    setRegions(regions);
    setRegionsLoading(false);
  };

  const getRegionLocations = locations => {
    if (locations) {
      return locations.map(l => {
        return getLocationName(l);
      });
    } else {
      return "";
    }
  };

  const onSelectRegionForEdit = async region => {
    let r = regions.find(r => r.name === region.name);
    setSelectedRegion(r);
    setShowRegionFormModal(true);
  };

  const showDeleteRegionMessage = region => {
    setSelectedRegion(regions.find(r => r.name === region.name));
    setShowDeleteMessage(true);
  };

  const onDeleteRegion = async () => {
    await deleteRegion(selectedRegion.id);
    // await onGetUsers(business.accountId);
    setShowDeleteMessage(false);
    fetchRegions(false);
    setSelectedRegion(undefined);
    toastr.success("Area deleted!");
  };

  const onCloseModal = () => {
    setSelectedRegion(undefined);
    fetchRegions(false);
    setShowRegionFormModal(false);
  };

  const matchBusinessesToRegions = regions => {
    for (const b of businesses) {
      for (const r of regions) {
        if (b.regionId === r.id) {
          r.locations.push(b.businessId);
        }
      }
    }
  };

  const onGetAction = (actionName, item) => () => {
    switch (actionName) {
      case "edit":
        onSelectRegionForEdit(item);
        return;
      case "delete":
        showDeleteRegionMessage(item);
        return;
      default:
        return;
    }
  };

  const getRegionsForTable = () => {
    let sortBy = orderBy.column.split(" ");
    if (regions.length > 0) {
      const values = _.chain(
        regions.map(region => {
          return {
            name: region.name,
            locations: getRegionLocations(region.locations)
          };
        })
      )
        .sortBy(user => user[sortBy[1] || sortBy[0]])
        .value();
      return orderBy.direction === "desc" ? values.reverse() : values;
    } else {
      return [];
    }
  };

  const getLocationName = id => {
    let location = businesses.find(l => l.businessId === id);
    if (location) {
      return location.name;
    } else {
      return "";
    }
  };

  const onHideDeleteAction = () => {
    return false;
  };

  const showRegionModal = () => {
    setShowRegionFormModal(true);
  };

  const onRequestSort = (event, column) => {
    if (column === orderBy.column) {
      setOrderBy({ column, direction: orderBy.direction === "asc" ? "desc" : "asc" });
    } else {
      setOrderBy({ column, direction: "desc" });
    }
  };

  return (
    <>
      {showDeleteMessage && (
        <SweetAlert
          showCancel
          title={deleteUserMessage.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideInfoAlert}
          onConfirm={onDeleteRegion}
        >
          {deleteUserMessage.message}
        </SweetAlert>
      )}
      {showRegionFormModal && (
        <AreaForm
          closeModal={onCloseModal}
          show={showRegionFormModal}
          regionForEdit={selectedRegion}
          businesses={businesses}
        />
      )}
      <SettingsTitle title={"Areas"} />
      <p className="settings-title-description">
        Group locations into areas. Area managers will have access to the entire area his or her primary location is
        part of.
      </p>
      <Button className="invite-guest-button" bsStyle="info" onClick={showRegionModal} pullRight fill type="button">
        Add Area
      </Button>
      {!regionsLoading ? (
        <TableContainer>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.head} align="left" key="name-header" style={{ width: "20%" }}>
                  <TableSortLabel
                    active={orderBy.column === "name"}
                    direction={orderBy.direction}
                    onClick={createSortHandler("name")}
                  >
                    name
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.head} align="left" key="locations-header" style={{ width: "60%" }}>
                  <TableSortLabel
                    active={orderBy.column === "locations"}
                    direction={orderBy.direction}
                    onClick={createSortHandler("locations")}
                  >
                    locations
                  </TableSortLabel>
                </TableCell>
                <TableCell className={classes.head} align="center" key="action-header" style={{ width: "20%" }}>
                  <span>actions</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ maxHeight: "calc(100vh - 310px)" }}>
              {getRegionsForTable().map((row, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.body} key={`name-+ ${index}`} align="left" style={{ width: "20%" }}>
                    {row.name}
                  </TableCell>
                  <TableCell className={classes.body} key={index} align="left" style={{ width: "60%" }}>
                    {row.locations.map(i => (
                      <RoundedTag key={`rounded-${i}-${index}`} value={i}></RoundedTag>
                    ))}
                  </TableCell>
                  <TableCell className={classes.root} align="center" style={{ width: "20%" }}>
                    {ACTIONS.map(
                      (action, index) =>
                        !onHideDeleteAction(action.name, row) && (
                          <i
                            onClick={onGetAction(action.name, row)}
                            className={action.className}
                            key={index}
                            style={{ color: action.color }}
                          />
                        )
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <div className="loader-wrapper">
          <BeatLoader margin={2.5} size={10} color={"#016BFF"} />
        </div>
      )}
    </>
  );
};

export default AreasSettings;

import React, { useEffect, useState } from "react";
import SettingsTitle from "./SettingsTitle";
import RegularTable from "../../views/RegularTable";
import { HEADERS, ACTIONS } from "../../constants/tables/users-settings-table";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteMessage } from "../../constants/messages";
import toastr from "toastr";
import { getUsers, deleteUser, getUserRole, updateUserStatus } from "../../services/users/users.service";
import BeatLoader from "react-spinners/BeatLoader";
import Button from "../CustomButton/CustomButton";
import * as _ from "lodash";
import UserInfo from "../UserInfo/UserInfo";
import { USER_INFO_TYPES } from "../../constants/user-info";
import { MenuItem, Modal, Nav, NavDropdown } from "react-bootstrap";
import Card from "../Card/Card";
import AddUser from "components/AddUser/AddUser";
import { useDispatch, useSelector } from "react-redux";
import { setShouldUpdateUsers } from "store/actions/users.actions";
import { ROlES, ROLE_KEYS } from "../../constants/settings";
import { isCurrentUser } from "helpers/user.helper";
import ChangePasswordModal from "./ChangePasswordModal";

let userForDelete = null;

const UsersSettings = ({ business, user }) => {
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showEditUserModal, setShowEditUserModal] = useState(false);
  const [showInviteUserModal, setShowInviteUserModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [orderBy, setOrderBy] = useState({ column: "name", direction: "asc" });
  const shouldUpdateUsers = useSelector(state => state.users.shouldUpdateUsers);
  const dispatch = useDispatch();

  const deleteUserMessage = deleteMessage("user");

  const hideInfoAlert = () => {
    setShowDeleteMessage(false);
  };

  useEffect(() => {
    onGetUsers(business.accountId);
  }, [business]);

  useEffect(() => {
    if (shouldUpdateUsers) {
      onGetUsers(business.accountId);
      dispatch(setShouldUpdateUsers(false));
    }
  }, [dispatch, shouldUpdateUsers]);

  const onGetUsers = async accountId => {
    setUserLoading(true);
    let users = await getUsers(accountId);
    users.sort((a, b) =>
      a.lastName.toLowerCase() > b.lastName.toLowerCase()
        ? 1
        : b.lastName.toLowerCase() > a.lastName.toLowerCase()
        ? -1
        : 0
    );
    setUsers(users);
    setUserLoading(false);
  };

  const onSelectUserForEdit = async user => {
    let u = users.find(u => u.email === user.email);
    const role = await getUserRole(u.userId);
    u.role = role || ROLE_KEYS.USER;
    setSelectedUser(u);
    setShowEditUserModal(true);
  };

  const onShowDeleteUserMessage = user => {
    userForDelete = users.find(u => u.email === user.email);
    setShowDeleteMessage(true);
  };

  const onDeleteUser = async () => {
    await deleteUser(userForDelete.userId);
    await onGetUsers(business.accountId);
    setShowDeleteMessage(false);
    toastr.success("User deleted!");
  };

  const onCloseModal = () => {
    setShowEditUserModal(false);
    setShowInviteUserModal(false);
    setShowChangePasswordModal(false);
  };

  const onGetUsersForTable = () => {
    let sortBy = orderBy.column.split(" ");
    if (users.length > 0) {
      const values = _.chain(
        users.map(user => {
          return {
            name: `${user.firstName} ${user.lastName}`,
            email: user.email,
            role: ROlES.find(role => role.role === user.role)?.name || "User",
            location: user.business
          };
        })
      )
        .sortBy(user => user[sortBy[1] || sortBy[0]])
        .value();
      return orderBy.direction === "desc" ? values.reverse() : values;
    } else {
      return [];
    }
  };

  const onHideDeleteAction = (action, tableUser) => {
    return action === "delete" && onCheckIfUserIsCurrent(tableUser);
  };

  const onCheckIfUserIsCurrent = selectedUser => {
    return user.email === selectedUser.email;
  };

  const onHideRoleDropdown = () => {
    if (selectedUser) {
      return onCheckIfUserIsCurrent(selectedUser);
    }
  };

  const onShowInviteUserModal = () => {
    setShowInviteUserModal(true);
  };

  const onRequestSort = (event, column) => {
    if (column === orderBy.column) {
      setOrderBy({ column, direction: orderBy.direction === "asc" ? "desc" : "asc" });
    } else {
      setOrderBy({ column, direction: "desc" });
    }
  };

  const additionalActions = user => {
    const userToEdit = users.find(usr => usr.email === user.email);
    const isUserEnabled = userToEdit.disabled;

    const updateStatus = async status => {
      const res = await updateUserStatus(userToEdit.userId, status);
      if (res.success) {
        const usersCopy = [...users];
        const userIndex = users.findIndex(x => x.userId == userToEdit.userId);
        usersCopy[userIndex] = { ...userToEdit, disabled: status };
        setUsers(usersCopy);
        toastr.success(`User ${status ? "disabled" : "enabled"} successfully!`);
        return;
      }
      toastr.error(`User isn't ${status ? "disabled" : "enabled"}!`);
    };

    const enableUser = () => {
      updateStatus(false);
    };

    const disableUser = async () => {
      updateStatus(true);
    };

    const handleChangePasswordClick = () => {
      setSelectedUser(userToEdit);
      setShowChangePasswordModal(true);
    };

    return (
      <>
        <Nav pullRight>
          <NavDropdown
            title={
              <div>
                <i className="mdi mdi-dots-vertical users-additional-actions-icon"></i>
              </div>
            }
            noCaret
            id="users-item-menu-options"
          >
            {!isCurrentUser(userToEdit) &&
              (isUserEnabled ? (
                <MenuItem>
                  <div onClick={enableUser}>Enable</div>
                </MenuItem>
              ) : (
                <MenuItem>
                  <div onClick={disableUser}>Disable</div>
                </MenuItem>
              ))}
            <MenuItem>
              <div onClick={handleChangePasswordClick}>Change password</div>
            </MenuItem>
          </NavDropdown>
        </Nav>
      </>
    );
  };

  const isUserDisabled = user => {
    const usr = users.find(usr => usr.email === user.email);
    return Boolean(usr.disabled);
  };

  return (
    <>
      {showDeleteMessage && (
        <SweetAlert
          showCancel
          title={deleteUserMessage.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideInfoAlert}
          onConfirm={onDeleteUser}
        >
          {deleteUserMessage.message}
        </SweetAlert>
      )}
      {showChangePasswordModal && (
        <ChangePasswordModal show={showChangePasswordModal} onClose={onCloseModal} userId={selectedUser?.userId} />
      )}
      {showEditUserModal && (
        <Modal dialogClassName="add-guest-modal add-guest-modal__user" show={showEditUserModal} onHide={onCloseModal}>
          <Card
            content={
              <UserInfo
                hideRoleDropdown={onHideRoleDropdown()}
                type={USER_INFO_TYPES.UPDATE_USER}
                closeModal={onCloseModal}
                user={selectedUser}
                onGetUsers={onGetUsers}
                accountId={business.accountId}
              />
            }
          />
        </Modal>
      )}
      {showInviteUserModal && <AddUser showModal={showInviteUserModal} closeModal={onCloseModal} />}
      <SettingsTitle title={"Users"} />
      <Button
        className="invite-guest-button"
        bsStyle="info"
        onClick={onShowInviteUserModal}
        fill
        pullRight
        type="button"
      >
        Invite User
      </Button>
      {!userLoading ? (
        <RegularTable
          rows={onGetUsersForTable()}
          editAction={onSelectUserForEdit}
          deleteAction={onShowDeleteUserMessage}
          tableHeaders={HEADERS}
          actions={ACTIONS}
          showSorting={true}
          isRowDisabled={isUserDisabled}
          hideAction={onHideDeleteAction}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          additionalActions={additionalActions}
        />
      ) : (
        <div className="loader-wrapper">
          <BeatLoader margin={2.5} size={10} color={"#016BFF"} />
        </div>
      )}
    </>
  );
};

export default UsersSettings;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import SettingsWrapper from "../../common/ScrollableWrapper/SettingsWrapper";
import { setSquareInfo } from "store/actions/square.actions";
import toastr from "toastr";
import { Col, Row } from "react-bootstrap";
import SettingsInput from "./SettingsInput";
import { connect, useSelector } from "react-redux";
import ConnectToSquareSetting from "./ConnectToSquareSetting";
import { Subject } from "rxjs";
import SquareSetupModal from "components/Square/SquareSetupModal";
import { useHistory } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { updateSquareInfo } from "services/square/square.service";
import { disconnectSquare } from "services/square/square.service";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import SquareSnippetsSettings from "./SquareSnippetsSettings";
import { getSquareInfo } from "services/square/square.service";

const SquareSettings = ({ plan, location, business, setSquareInfo }) => {
  const { handleSubmit, errors } = useForm();
  const [sweetAlertConfig, setSweetAlertConfig] = useState(null);
  const history = useHistory();
  const squareSelector = useSelector(state => state.square);
  const { squareInfo } = squareSelector;
  const [loading, setLoading] = useState(true);
  const accountSelector = useSelector(state => state.account);
  useEffect(() => {
    getSquareInfo().then(info => {
      setSquareInfo(info);
      setLoading(false);
    });
  }, []);
  const [squareSetupModalConfig, setSquareSetupModalConfig] = useState(false);
  useEffect(() => {
    setForm({
      squareSyncCustomers: squareInfo?.settings?.squareSyncCustomers || false,
      squareSyncLocations: plan === "free" ? false : squareInfo?.settings?.squareSyncLocations || false
    });
    // eslint-disable-next-line
  }, [squareInfo]);

  const handleSquareSetupModalClose = configs => {
    history.push("/admin/settings?page=connect-to-square");
    setForm({
      squareSyncCustomers: configs.squareSyncCustomers || false,
      squareSyncLocations: configs.squareSyncLocations || false
    });
    setSquareSetupModalConfig(null);
  };

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    if (params.get("source") === "square") {
      setSquareSetupModalConfig({
        newAccount: false,
        error: params.get("squareError"),
        step: params.get("step"),
        plan: params.get("plan")
      });
    }
    const destroy$ = new Subject();
    return () => {
      destroy$.next(true);
      destroy$.complete();
    };
  }, [location.search]);

  const onSubmit = async data => {
    await updateSquareInfo({ ...form, ...data });
    const settings = { ...form, ...data };
    setSquareInfo({ ...squareInfo, settings: settings });
    toastr.success(`Square settings updated`);
  };

  const toggleChecked = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const [form, setForm] = useState({
    squareSyncCustomers: false,
    squareSyncLocations: false
  });

  const closeModal = () => {
    setSweetAlertConfig(null);
  };

  const handleSquareDisconnect = async () => {
    try {
      closeModal();
      toastr.success("Square disconnect in progress...");
      await disconnectSquare();
      setSquareInfo({ linked: false });
      toastr.success("Square has been successfully disconnected");
    } catch (e) {
      toastr.error("Cannot disconnect from Square. Please contact support");
    }
  };

  const showDisconnectModal = () => {
    setSweetAlertConfig({
      title: "Are you sure?",
      message: `Are you sure you want to disconnect from Square? All configs will be lost`
    });
  };

  return (
    <>
      {" "}
      {sweetAlertConfig && (
        <SweetAlert
          showCancel={true}
          title={sweetAlertConfig.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={closeModal}
          onConfirm={handleSquareDisconnect}
        >
          {sweetAlertConfig.message}
        </SweetAlert>
      )}
      <SettingsWrapper
        title={"Connect to Square"}
        hideSubmit={!squareInfo || !squareInfo.linked}
        handleSubmit={handleSubmit(onSubmit)}
        deleteButtonText="Disconnect Square"
        showDelete={
          squareInfo &&
          squareInfo.linked &&
          accountSelector.account &&
          !(accountSelector.account.plan !== "free" && accountSelector.account.origin === "square")
        }
        handleDelete={showDisconnectModal}
        buttonStyles={{ maxWidth: 600 }}
      >
        {squareSetupModalConfig && business && squareInfo && (
          <SquareSetupModal
            business={business.busi}
            squareInfo={squareInfo}
            squareSetupModalConfig={squareSetupModalConfig}
            handleSquareSetupModalClose={handleSquareSetupModalClose}
            exitPoint={"squareSettings"}
          ></SquareSetupModal>
        )}
        {!squareInfo || loading ? (
          <div className="loader-wrapper">
            <BeatLoader margin={2.5} size={20} color={"#447ef7"} />
          </div>
        ) : squareInfo.linked ? (
          <div>
            <Row>
              <Col md={6}>
                <SettingsInput
                  form={form}
                  name={"squareSyncCustomers"}
                  type={"switch"}
                  enabled={true}
                  errors={errors}
                  value={form.squareSyncCustomers}
                  label={"Sync Customers to Square"}
                  subtitle={`When guests are added in Waitly, we will create a square customer in your account.`}
                  register={null}
                  onChangeValue={toggleChecked}
                />
              </Col>
            </Row>
            {squareInfo.squareLocations?.length > 1 && (
              <Row>
                <Col md={6}>
                  <SettingsInput
                    form={form}
                    name={"squareSyncLocations"}
                    type={"switch"}
                    enabled={plan !== "free"}
                    errors={errors}
                    value={form.squareSyncLocations}
                    label={"Sync Locations with Square"}
                    subtitle={`If you add new locations to Square we will add matching location in Waitly`}
                    register={null}
                    onChangeValue={toggleChecked}
                  />
                </Col>
                {plan === "free" && (
                  <div className="settings-subtitle self-check-wrapper">
                    Multiple locations is a Premium feature
                    <a
                      style={{ fontWeight: "700" }}
                      href={document.URL.split("#")[0] + "#/admin/upgrade"}
                      rel="noopener noreferrer"
                    >
                      Upgrade now
                    </a>
                  </div>
                )}
              </Row>
            )}
            {squareInfo.squareOnlineSites?.length > 0 && (
              <SquareSnippetsSettings plan={plan} squareInfo={squareInfo} business={business} />
            )}
          </div>
        ) : (
          <ConnectToSquareSetting />
        )}
      </SettingsWrapper>
    </>
  );
};

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps, {
  setSquareInfo
})(SquareSettings);

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { PureComponent, useEffect, useState } from "react";
import { Grid, Row, Col, FormGroup } from "react-bootstrap";
// react component used to create charts
import { DateTime } from "luxon";
import Select from "react-select";
import Card from "components/Card/Card.jsx";
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  // Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Sector
} from "recharts";
import { BeatLoader } from "react-spinners";
import { getChartsData } from "../services/charts/charts.service";
import moment from "moment";
import "./Charts.scss";
import MultiSelectFilter from "components/MultiSelectFilter/MultiSelectFilter";
import { useSelector } from "react-redux";
import Button from "../components/CustomButton/CustomButton";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import StatsCard from "components/Card/StatsCard";

export const dateRangeOptions = [
  { value: "yesterday", label: "Yesterday" },
  { value: "thisWeek", label: "This week" },
  { value: "lastWeek", label: "Last week" },
  { value: "last7Days", label: "Last 7 days" },
  { value: "last30Days", label: "Last 30 Days" },
  { value: "last90Days", label: "Last 90 days" },
  { value: "last12Months", label: "Last 12 months" },
  { value: "thisYear", label: "This year (Jan - Today)" }
];
export const DATE_INTERVALS = {
  HOUR: { value: "hour", label: "Hourly" },
  DAY: { value: "day", label: "Daily" },
  WEEK: { value: "week", label: "Weekly" },
  MONTH: { value: "month", label: "Monthly" }
};
class CustomizedAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props as any;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} fontSize={11} textAnchor="end" fill="#333" transform="rotate(-60)">
          {payload.value}
        </text>
      </g>
    );
  }
}

const SharedChartsContainer = ({ data, type, keys, colors, onlyRate = false, isAnimationActive }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = (_, index) => {
    setActiveIndex(index);
  };

  const renderActiveShape = props => {
    const RADIAN = Math.PI / 180;
    const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
        <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
        {onlyRate ? (
          <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${(
            percent * 100
          ).toFixed(2)}%`}</text>
        ) : (
          <>
            <text
              x={ex + (cos >= 0 ? 1 : -1) * 12}
              y={ey}
              textAnchor={textAnchor}
              fill="#333"
            >{`${value.toLocaleString()}`}</text>
            <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
              {`(${(percent * 100).toFixed(2)}%)`}
            </text>
          </>
        )}
      </g>
    );
  };

  const chartData = () => {
    return (
      <>
        <CartesianGrid strokeDasharray={"2 2"} />
        <XAxis dataKey="time" height={100} tick={<CustomizedAxisTick />} />
        <YAxis
          tickFormatter={tick => {
            return tick.toLocaleString();
          }}
        />
        <Tooltip formatter={value => new Intl.NumberFormat("en-US").format(Math.round((value as any) * 100) / 100)} />

        {/* <Legend /> */}
      </>
    );
  };

  const Chart = props => {
    switch (type) {
      case "line":
        return (
          <LineChart {...props}>
            {chartData()}
            {keys.map((k, idx) => (
              <Line
                type="monotone"
                key={idx}
                dataKey={k}
                isAnimationActive={isAnimationActive}
                stroke={colors[idx]}
                dot={false}
              />
            ))}
          </LineChart>
        );
      case "bar":
        return (
          <BarChart {...props}>
            {chartData()}
            {keys.map((k, idx) => (
              <Bar key={idx} dataKey={k} isAnimationActive={isAnimationActive} fill={colors[idx]} />
            ))}
          </BarChart>
        );
      default:
        return null;
    }
  };

  if (type === "pie-with-sector") {
    return (
      <div style={{ width: "100%", height: 450 }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              dataKey={keys[0]}
              data={data}
              fill={colors[0]}
              activeIndex={activeIndex}
              activeShape={renderActiveShape}
              onMouseEnter={onPieEnter}
              innerRadius="50%"
              paddingAngle={2}
              isAnimationActive={isAnimationActive}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }

  return (
    <ResponsiveContainer width={"100%"} height={320}>
      <Chart data={data} margin={{ top: 5, right: 20, bottom: 5, left: 0 }} />
    </ResponsiveContainer>
  );
};

function Charts(props) {
  const isRegionalManager = props.user.claims.role === "regional-manager";
  const isAreaManager = props.user.claims.role === "area-manager";
  const isManager = props.user.claims.role === "manager";
  const isUser = props.user.claims.role === "user" || !props.user.claims.role;

  const [avgWaitStats, setAvgWaitStats] = useState(0);
  const [data, setData] = useState([]);
  const [pieData, setPieData] = useState<any>([]);
  const [dowData, setDowData] = useState([]);
  const [selectedRange, setSelectedRange] = useState(dateRangeOptions[0]);
  const [dateIntervalOptions, setDateIntervalOptions] = useState([DATE_INTERVALS.HOUR]);
  const [selectedInterval, setSelectedInterval] = useState(DATE_INTERVALS.HOUR);
  const [loading, setLoading] = useState(false);
  const [shouldFetch, setShouldFetch] = useState(true);
  const [hasData, setHasData] = useState({
    served: false,
    wait: false,
    waitStats: false,
    pieSelfCheckIn: false,
    pieByType: false,
    dowServed: false,
    dowWait: false
  });
  const [areaValues, setAreaValues] = useState([]);
  const [regionValues, setRegionValues] = useState([]);
  const businesses = useSelector(state => (state as any).business.businesses);
  const regions = useSelector(state => {
    return (state as any).regions.regions;
  });
  const [filterLoading, setFilterLoading] = useState(true);
  const [regionLoading, setRegionLoading] = useState(true);
  const [filterOptions, setFilterOptions] = useState([]);
  const [locationsToFetch, setLocationsToFetch] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [abortControllers, setAbortControllers] = useState([]);
  const [isChartAnimationActive, setIsChartAnimationActiove] = useState(false);

  const dateFunctions = {
    yesterday: () => {
      const day = DateTime.local().minus({ days: 1 });
      return {
        start: day
          .startOf("day")
          .toJSDate()
          .getTime(),
        end: day
          .endOf("day")
          .toJSDate()
          .getTime()
      };
    },
    thisWeek: () => {
      const start = DateTime.local()
        .startOf("week")
        .minus({ days: 1 });
      const end = DateTime.local()
        .endOf("week")
        .minus({ days: 1 })
        .endOf("day");
      return {
        start: start.toJSDate().getTime(),
        end: end.toJSDate().getTime()
      };
    },
    lastWeek: () => {
      const lastWeek = DateTime.local().minus({ days: 7 });
      return {
        start: lastWeek
          .startOf("week")
          .minus({ days: 1 })
          .toJSDate()
          .getTime(),
        end: lastWeek
          .endOf("week")
          .minus({ days: 1 })
          .toJSDate()
          .getTime()
      };
    },
    last7Days: () => {
      const lastWeek = DateTime.local()
        .minus({ days: 7 })
        .startOf("day");
      const yesterday = DateTime.local()
        .minus({ days: 1 })
        .endOf("day");
      return {
        start: lastWeek.toJSDate().getTime(),
        end: yesterday.toJSDate().getTime()
      };
    },
    last30Days: () => {
      const last30Days = DateTime.local().minus({ days: 30 });
      const yesterday = DateTime.local()
        .minus({ days: 1 })
        .endOf("day");
      return {
        start: last30Days
          .startOf("day")
          .toJSDate()
          .getTime(),
        end: yesterday.toJSDate().getTime()
      };
    },
    last90Days: () => {
      const last90Days = DateTime.local().minus({ days: 90 });
      const yesterday = DateTime.local()
        .minus({ days: 1 })
        .endOf("day");
      return {
        start: last90Days
          .startOf("day")
          .toJSDate()
          .getTime(),
        end: yesterday.toJSDate().getTime()
      };
    },
    last12Months: () => {
      const last12Months = DateTime.local().minus({ months: 11 });
      const yesterday = DateTime.local()
        .minus({ days: 1 })
        .endOf("day");
      return {
        start: last12Months
          .startOf("month")
          .toJSDate()
          .getTime(),
        end: yesterday.toJSDate().getTime()
      };
    },
    thisYear: () => {
      const thisYear = DateTime.local();
      const yesterday = DateTime.local()
        .minus({ days: 1 })
        .endOf("day");
      return {
        start: thisYear
          .startOf("year")
          .toJSDate()
          .getTime(),
        end: yesterday.toJSDate().getTime()
      };
    }
  };

  const calculateDates = (range, interval) => {
    let { start, end } = dateFunctions[range]();
    const newStart = adjustRangeForInterval(start, interval);
    return { start: newStart, end };
  };

  const adjustRangeForInterval = (start, interval) => {
    if (interval === DATE_INTERVALS.WEEK) {
      return DateTime.fromMillis(start)
        .startOf("week")
        .toJSDate()
        .getTime();
    } else if (interval === DATE_INTERVALS.MONTH) {
      return DateTime.fromMillis(start)
        .startOf("month")
        .toJSDate()
        .getTime();
    } else {
      return start;
    }
  };

  const getChartLabel = time => {
    let label = "";
    if (selectedInterval.value === "hour") {
      label = moment(time).format("MM/DD h:mma");
    } else if (selectedInterval.value === "day") {
      label = moment(time).format("MM/DD/YYYY");
    } else if (selectedInterval.value === "week") {
      label =
        moment(time).format("MM/DD") +
        " - " +
        moment(time)
          .add(6, "days")
          .format("MM/DD");
    } else {
      label = DateTime.fromISO(time)
        .plus({ hour: 1 })
        .toFormat("MMM"); // add 1 hour to get the correct month with DLS
    }
    return label;
  };

  const changeRange = range => {
    switch (range.value) {
      case "yesterday":
        setDateIntervalOptions([DATE_INTERVALS.HOUR]);
        setSelectedInterval(DATE_INTERVALS.HOUR);
        break;
      case "thisWeek":
        setDateIntervalOptions([DATE_INTERVALS.HOUR, DATE_INTERVALS.DAY]);
        setSelectedInterval(DATE_INTERVALS.HOUR);
        break;
      case "lastWeek":
        setDateIntervalOptions([DATE_INTERVALS.HOUR, DATE_INTERVALS.DAY]);
        setSelectedInterval(DATE_INTERVALS.HOUR);
        break;
      case "last7Days":
        setDateIntervalOptions([DATE_INTERVALS.HOUR, DATE_INTERVALS.DAY]);
        setSelectedInterval(DATE_INTERVALS.HOUR);
        break;
      default:
        setDateIntervalOptions([DATE_INTERVALS.DAY, DATE_INTERVALS.WEEK, DATE_INTERVALS.MONTH]);
        setSelectedInterval(DATE_INTERVALS.DAY);
        break;
    }
    setSelectedRange(range);
  };

  const changeInterval = interval => {
    setSelectedInterval(interval);
  };

  async function fetchData(signal) {
    setData([]);
    setPieData([]);
    setDowData([]);
    setShouldFetch(false);
    setHasData({
      served: false,
      wait: false,
      pieByType: false,
      pieSelfCheckIn: false,
      dowServed: false,
      dowWait: false,
      waitStats: false
    });
    if (!locationsToFetch || !locationsToFetch.length) {
      console.error("Something went wrong, no locations choosen");
      return;
    }
    setLoading(true);
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { start, end } = calculateDates(selectedRange.value, selectedInterval);

    try {
      const myJson = await getChartsData({
        tz,
        start,
        end,
        interval: selectedInterval.value,
        locations: locationsToFetch,
        signal
      });

      if (!myJson) throw new Error("Something went wront, try again later");

      const { counts, pieSelfCheckIn, pieByType, statsByDayOfWeek, stats } = myJson;

      if (stats?.success && stats.data?.avg_wait) {
        setAvgWaitStats(Number(stats.data.avg_wait));
      }

      if (counts && counts.success) {
        if (counts.data.some(d => d.served)) setHasData(prev => ({ ...prev, served: true }));
        if (counts.data.some(d => d.wait)) setHasData(prev => ({ ...prev, wait: true }));
        setData(
          counts.data.map(data => ({
            ...data,
            time: getChartLabel(data.time)
          }))
        );
      } else {
        setShowErrorAlert(true);
      }

      if (statsByDayOfWeek && statsByDayOfWeek.success) {
        if (statsByDayOfWeek.data.some(d => d.served)) setHasData(prev => ({ ...prev, served: true }));
        if (statsByDayOfWeek.data.some(d => d.wait)) setHasData(prev => ({ ...prev, wait: true }));
        setDowData(
          statsByDayOfWeek.data.map(data => ({
            ...data,
            time: data.dayOfWeek
          }))
        );
      } else {
        setShowErrorAlert(true);
      }
      if (pieSelfCheckIn && pieSelfCheckIn.success) {
        if (pieSelfCheckIn.data.length) setHasData(prev => ({ ...prev, pieSelfCheckIn: true }));
        setPieData(prev => ({ ...prev, pieSelfCheckIn: pieSelfCheckIn.data }));
      } else {
        setShowErrorAlert(true);
      }

      if (pieByType && pieByType.success) {
        if (pieByType.data.length) setHasData(prev => ({ ...prev, pieByType: true }));
        setPieData(prev => ({ ...prev, pieByType: pieByType.data }));
      } else {
        setShowErrorAlert(true);
      }
      setLoading(false);
      // Turn on charts animation
      setIsChartAnimationActiove(true);
      // Turn off charts animation, when they displayed (give for this 1sec), and turn of animation
      setTimeout(() => {
        setIsChartAnimationActiove(false);
      }, 1000);
    } catch (error) {
      console.error(error);
      if ((error as any).name !== "AbortError") setLoading(false);
    }
  }

  const abortAllExistingFetches = (ac?: any) => {
    abortControllers.forEach(abc => (abc as any).abort());
    setAbortControllers(ac ? ([ac] as any) : []);
    setLoading(false);
  };

  useEffect(() => {
    const abortController = new AbortController();
    const { signal } = abortController;
    if (shouldFetch && !filterLoading) {
      abortAllExistingFetches(abortController);
      fetchData(signal);
    }
    return () => {
      if (!shouldFetch) {
        abortController.abort();
        abortAllExistingFetches();
      }
    };
  }, [shouldFetch, filterLoading]);

  // sweet alert didn't disabling scrolling, and user can scroll, in sweet alerts
  // i think it's not good, so i added this code
  // maybe we should do it all files, or think how to fix it better
  useEffect(() => {
    if (showErrorAlert) {
      (document.getElementById("root") as any).style.overflow = "hidden";
    } else (document.getElementById("root") as any).style.overflow = "auto";
  }, [showErrorAlert]);
  ///////////

  const noAvailableDataText = <div className="no-available-data">No available data for this period.</div>;

  const filterByRole = options => {
    const primaryLocation = businesses.find(b => props.user.businessId === b.businessId);
    if (!primaryLocation) return options;

    if (isRegionalManager) {
      return options.filter(opt => opt.value === primaryLocation.parentRegion);
    }

    if (isAreaManager) {
      return options
        .filter(opt => opt.value === primaryLocation.parentRegion)
        .map(opt => ({
          ...opt,
          children: opt.children.filter(child => child.value === primaryLocation.regionId)
        }));
    }

    if (isManager || isUser) {
      return [
        {
          value: "region",
          label: "Region",
          children: [
            {
              value: "area",
              label: "Area",
              subChildren: [
                {
                  value: primaryLocation.businessId,
                  label: primaryLocation.name,
                  checked: true
                }
              ]
            }
          ]
        }
      ];
    }

    return options;
  };

  const onFilter = options => {
    setLocationsToFetch(
      filterByRole(options)
        .map(p => p.children.map(c => c.subChildren))
        .flat(2)
        .filter(el => el.checked)
        .map(v => ({ id: v.value, name: v.label }))
    );
  };

  useEffect(() => {
    if (!regions || !businesses) return;
    setRegionLoading(true);
    const areas = regions
      .filter(r => r.isArea)
      .map(r => {
        return { value: r.id, label: r.name, parentRegion: r.parentRegion };
      });
    setAreaValues(areas);
    const allRegions = regions
      .filter(r => !r.isArea)
      .map(r => {
        return { value: r.id, label: r.name };
      });
    setRegionValues(allRegions);
    setRegionLoading(false);
  }, [regions, businesses]);

  React.useEffect(() => {
    if (regionLoading) return undefined;
    setFilterLoading(true);
    const filterOptions = [...regionValues.map((r: any) => ({ ...r, checked: true, children: [] }))];
    filterOptions.push({ value: "without-region", label: "Without Region", checked: true, children: [] });
    areaValues.forEach((a: any) => {
      let parentIndex = filterOptions.findIndex(o => o.value === a.parentRegion);
      const isWithParent = parentIndex > -1;
      parentIndex = isWithParent ? parentIndex : filterOptions.length - 1;
      filterOptions[parentIndex].children.push({
        ...a,
        checked: true,
        parentRegion: isWithParent ? a.parentRegion : "without-region",
        subChildren:
          businesses
            .filter(b => b.regionId === a.value)
            .map(b => ({
              value: b.businessId,
              label: b.name,
              parentValue: b.parentRegion,
              childValue: b.regionId,
              checked: true
            })) || []
      });
    });
    filterOptions[filterOptions.length - 1].children.push({
      checked: true,
      parentRegion: "without-region",
      value: "without-area",
      label: "Without Area",
      subChildren:
        businesses
          .filter(b => !b.regionId)
          .map(b => ({
            value: b.businessId,
            label: b.name,
            parentValue: "without-region",
            childValue: "without-area",
            checked: true
          })) || []
    });

    setFilterOptions(filterByRole(filterOptions));
    onFilter(filterOptions);
    setFilterLoading(false);
  }, [regionLoading]);

  return (
    <>
      <div className="main-content">
        {showErrorAlert && (
          <SweetAlert
            title={"Some of requests timed out"}
            confirmBtnText="OK"
            onConfirm={() => setShowErrorAlert(false)}
          >
            Try again later
          </SweetAlert>
        )}
        <div className="stats-container">
          <div className="stats-responsive-wrapper">
            <div>
              <StatsCard
                bigIcon={<i className="mdi mdi-format-list-numbered text-warning" />}
                statsText="Waitlist Parties"
                statsValue={
                  pieData.pieByType
                    ? pieData.pieByType.find(el => el.name === "Waitlist")?.value.toLocaleString("en-US")
                    : 0
                }
              />
            </div>
            <div>
              <StatsCard
                bigIcon={<i className="mdi mdi-calendar " style={{ color: "#8884d8" }} />}
                statsText="Reservations"
                statsValue={
                  pieData.pieByType
                    ? pieData.pieByType.find(el => el.name === "Reservations")?.value.toLocaleString("en-US")
                    : 0
                }
              />
            </div>
            <div>
              <StatsCard
                bigIcon={<i className="mdi mdi-clock text-info" />}
                statsText="Average wait"
                statsValue={avgWaitStats.toLocaleString("en-US")}
              />
            </div>
            <div>
              <StatsCard
                bigIcon={<i className="mdi mdi-account-multiple text-success" />}
                statsText="Served"
                statsValue={data.reduce((acc, curr) => acc + (curr as any).served, 0).toLocaleString("en-US")}
              />
            </div>
          </div>
        </div>
        <Grid fluid>
          <Row
            style={{
              display: "flex",
              justifyContent: "start",
              paddingTop: "15px"
            }}
            className="card cart-stats"
          >
            <i className="mdi mdi-filter db-filter__icon" style={{ paddingLeft: 12 }}></i>
            <div>
              {!filterLoading && !isManager && (
                <MultiSelectFilter
                  onFilter={onFilter}
                  options={filterOptions}
                  isDashboard={false}
                  hideMessages
                  fullWidth={false}
                  showOnlyChidren={isRegionalManager}
                  showOnlySubChidren={isAreaManager}
                  hideIcon={true}
                />
              )}
            </div>
            <div className="date-range">
              <FormGroup className="date-range__select">
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="dateRange"
                  onChange={changeRange}
                  options={dateRangeOptions}
                  value={selectedRange}
                />
              </FormGroup>
            </div>
            <div className="date-interval">
              <FormGroup className="date-interval__select">
                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  name="dateInterval"
                  onChange={changeInterval}
                  options={dateIntervalOptions}
                  value={selectedInterval}
                />
              </FormGroup>
            </div>
            <div className="update-chart-btn-wrapper">
              <Button type="button" onClick={() => setShouldFetch(true)}>
                Update Charts
              </Button>
            </div>
          </Row>
          {loading ? (
            <Row>
              <Col md={12}>
                <div
                  className="w-100"
                  style={{ height: "50vh", display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                  <BeatLoader margin={2.5} size={20} color={"#026FFF"} />
                </div>
              </Col>
            </Row>
          ) : (
            <>
              <Row>
                <Col md={6}>
                  <Card
                    title="Served"
                    category={<span>&nbsp;</span>}
                    content={
                      hasData.served ? (
                        <SharedChartsContainer
                          data={data}
                          type="bar"
                          keys={["served"]}
                          isAnimationActive={isChartAnimationActive}
                          colors={["#40981a"]}
                        />
                      ) : (
                        <>{noAvailableDataText}</>
                      )
                    }
                  />
                </Col>
                <Col md={6}>
                  <Card
                    title="Average Wait"
                    category="in minutes"
                    content={
                      hasData.wait ? (
                        <SharedChartsContainer
                          data={data}
                          type="bar"
                          keys={["wait"]}
                          isAnimationActive={isChartAnimationActive}
                          colors={["#447ef7"]}
                        />
                      ) : (
                        <>{noAvailableDataText}</>
                      )
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Card
                    title="Average Served by Day of Week"
                    category={<span>&nbsp;</span>}
                    content={
                      hasData.served ? (
                        <SharedChartsContainer
                          data={dowData}
                          type="bar"
                          keys={["served"]}
                          isAnimationActive={isChartAnimationActive}
                          colors={["#40981a"]}
                        />
                      ) : (
                        <>{noAvailableDataText}</>
                      )
                    }
                  />
                </Col>
                <Col md={6}>
                  <Card
                    title="Average Wait by Day of Week"
                    category="in minutes"
                    content={
                      hasData.wait ? (
                        <SharedChartsContainer
                          data={dowData}
                          type="bar"
                          keys={["wait"]}
                          isAnimationActive={isChartAnimationActive}
                          colors={["#447ef7"]}
                        />
                      ) : (
                        <>{noAvailableDataText}</>
                      )
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Card
                    title="Waitlist vs. Reservations"
                    content={
                      hasData.pieByType ? (
                        <SharedChartsContainer
                          data={pieData.pieByType}
                          type="pie-with-sector"
                          keys={["value"]}
                          colors={["#447ef7"]}
                          isAnimationActive={isChartAnimationActive}
                        />
                      ) : (
                        <>{noAvailableDataText}</>
                      )
                    }
                  />
                </Col>
                <Col md={6}>
                  <Card
                    title="Visits"
                    category="self check-in"
                    content={
                      hasData.pieSelfCheckIn ? (
                        <SharedChartsContainer
                          data={pieData.pieSelfCheckIn}
                          type="pie-with-sector"
                          keys={["value"]}
                          colors={["#8884d8"]}
                          isAnimationActive={isChartAnimationActive}
                        />
                      ) : (
                        <>{noAvailableDataText}</>
                      )
                    }
                  />
                </Col>
              </Row>
              {/* <Row>
                <Col md={12}>
                  <Card
                    title="Wait Times"
                    legend={
                      <div>
                        <i className="fa fa-circle text-info" /> Average
                        <i className="fa fa-circle text-danger" /> Longest
                        <i className="fa fa-circle text-warning" /> Shortest
                      </div>
                    }
                    category="in minutes"
                    content={
                      hasData.waitStats ? (
                        <SharedChartsContainer
                          data={data}
                          type="line"
                          keys={["average", "longest", "shortest"]}
                          colors={["#447ef7", "#fb404b", "#ffa534"]}
                        />
                      ) : (
                        <>{noAvailableDataText}</>
                      )
                    }
                  />
                </Col>
              </Row> */}
            </>
          )}
        </Grid>
      </div>
      <div className="sticky-footer">
        <strong>Disclaimer:</strong> To improve performance and readability, charts use sampled data that excludes
        outliers. Actual values may vary slightly.
      </div>
    </>
  );
}

// function trimDataSet(data) {
//   var result = [];
//   for (const i in data) {
//     if (prevNotZero(data, i) && nextNotZero(data, i)) {
//       result.push(data[i]);
//     }
//   }
//   return result;
// }

// function prevNotZero(data, i) {
//   return i > 0 && data[i - 1].x != 0;
// }

// function nextNotZero(data, i) {
//   return i + 1 < data.length && data[i + 1].x != 0;
// }

export default Charts;

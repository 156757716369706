import React, { useState } from "react";
import { Form, Row, Col, Modal, FormGroup, ControlLabel } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Switch } from "@material-ui/core";
import Card from "components/Card/Card.jsx";
import Button from "components/CustomButton/CustomButton";
import "./AddGuests.scss";
import { useForm } from "react-hook-form";
import { getAverageWaitForTimePeriod } from "services/visits/visits.service";
import { Business } from "types/business";
import { formatPhone } from "helpers/phone.helper";
import { BuildCusmomizableInputsForm } from "components/Settings/CustomizableInputFiels/BuildCustomizableInputFields";
import { useMediaQuery } from "hooks/useMediaQuery";
import CustomButton from "components/CustomButton/CustomButton";
import { handleEnterKeyNextField } from "helpers/form.helper";
import SweetAlert from "react-bootstrap-sweetalert";

export type AddGuestForm = {
  name: string;
  partySize?: number;
  quotedTime?: string;
  phone?: string;
  service?: string;
  orderNumber?: string;
  email?: string;
  callAhead?: boolean;
  note?: string;
};

type IForm = AddGuestForm;

type AddGuestModalProps = {
  handleGuestFormClose: (form?: any) => Promise<void>;
  showGuestForm: boolean;
  guestForm: any;
  allVisitors: any;
  business: Business;
};

function AddGuestModal(props: AddGuestModalProps) {
  const { showGuestForm, handleGuestFormClose, guestForm, allVisitors, business } = props;
  const { register, handleSubmit, errors } = useForm<IForm>();
  const isMobile = useMediaQuery("(max-width: 768px)");
  const account = useSelector<any>(state => state.account?.account || {}) as any;
  const [showUpgrade, setShowUpgrade] = useState(false);

  const getDefaultPartySize = () => {
    return business.settings.defaultPartySize || 1;
  };

  const emptyForm = {
    name: "",
    partySize: getDefaultPartySize() || 1,
    quotedTime: "",
    phone: "",
    service: "",
    orderNumber: "",
    callAhead: false,
    email: "",
    note: ""
  };

  const getCategoryInitialValue = () => {
    if (guestForm && guestForm.data() && guestForm.data().service) {
      return guestForm.data().service;
    }
    return "";
  };

  const [form, setForm] = useState<AddGuestForm>({
    name: guestForm && guestForm.data() ? guestForm.data().name : "",
    partySize: guestForm && guestForm.data() ? parseInt(guestForm.data().partySize) : getDefaultPartySize(),
    quotedTime: guestForm && guestForm.data() ? guestForm.data().quotedTime.toString() : "",
    phone: guestForm && guestForm.data() ? guestForm.data().phone : "",
    orderNumber: guestForm && guestForm.data() ? guestForm.data().orderNumber : "",
    email: guestForm && guestForm.data() ? guestForm.data().email : "",
    note: guestForm && guestForm.data() ? guestForm.data().note : "",
    callAhead: guestForm && guestForm.data() ? guestForm.data().callAhead : false,
    service: getCategoryInitialValue()
  });

  const onChangeCallAhead = () => {
    if (account.plan === "free") {
      setShowUpgrade(true);
    } else {
      setForm({ ...form, callAhead: !form.callAhead });
    }
  };

  const onSubmit = data => {
    console.log("data", data);
    handleGuestFormClose({ ...data, phone: formatPhone(data.phone), callAhead: form.callAhead });
    setForm(emptyForm);
  };

  const closeModal = () => {
    handleGuestFormClose();
    setForm(emptyForm);
  };

  const goToUpgrade = () => {
    setShowUpgrade(false);
    let url = document.URL.split("#")[0] + "#/admin/upgrade";
    window.location.replace(url);
  };

  const hideUpgrade = () => {
    setShowUpgrade(false);
  };

  return (
    <>
      {showUpgrade && (
        <SweetAlert
          title="Upgrade"
          onCancel={hideUpgrade}
          onConfirm={goToUpgrade}
          showCancel
          cancelBtnText="No"
          confirmBtnText="Yes"
        >
          The call ahead feature is only available on paid plans. Would you like to upgrade?
        </SweetAlert>
      )}
      <Modal dialogClassName="add-guest-modal" show={showGuestForm} onHide={closeModal}>
        {isMobile && (
          <CustomButton className="sign-up-modal-wrapper__close-btn" simple onClick={closeModal}>
            <i className="fa fa-times" />
          </CustomButton>
        )}
        <Card
          textCenter={true}
          title={form.name === "" ? "Add Guest" : "Edit Guest"}
          content={
            <Form
              noValidate
              className="add-guest"
              onSubmit={handleSubmit(onSubmit)}
              onKeyDown={handleEnterKeyNextField}
            >
              <div className="flex">
                <div className="add-guest__stats">
                  <div className="add-guest__stats-label">Average Wait</div>
                  <div className="add-guest__stats-value">{getAverageWaitForTimePeriod(allVisitors)}</div>
                </div>
                <div className="add-guest__stats">
                  <div className="add-guest__stats-label">Last Estimate</div>
                  <div className="add-guest__stats-value">
                    {allVisitors && allVisitors.length ? allVisitors[allVisitors.length - 1].data().quotedTime : 0} mins
                  </div>
                </div>
              </div>
              <BuildCusmomizableInputsForm<AddGuestForm>
                form={form}
                register={register}
                errors={errors}
                business={business}
                setForm={setForm}
                type="waitlist"
              />
              {account?.settings?.callAhead && (
                <Row>
                  <Col md={12}>
                    <FormGroup validationState={(!!errors.name && "error") || null}>
                      <ControlLabel>CALL AHEAD</ControlLabel>
                      <Switch
                        ref={register}
                        name="callAhead"
                        disableRipple={true}
                        checked={form.callAhead}
                        color="primary"
                        onChange={onChangeCallAhead}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={12}>
                  <div className="add-guest__actions">
                    <Button onClick={closeModal} className="add-guest__cancel" bsStyle="info" pullRight type="button">
                      Cancel
                    </Button>
                    <Button bsStyle="info" pullRight fill type="submit">
                      {guestForm && guestForm.data() ? "Save Guest" : "Add Guest"}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          }
        ></Card>
      </Modal>
    </>
  );
}

export default AddGuestModal;

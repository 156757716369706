export const hours = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

export const minutes = ["00", "15", "30", "45"];

export const week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const reservationPossibleValues = [15, 30, 45, 60, 75, 90, 105, 120, 135, 150, 165, 180, 195, 210, 225, 240];

export const partySizeSuitableDuration = {
  1: 45,
  2: 60,
  3: 60,
  4: 75,
  5: 75,
  6: 90,
  7: 90,
  8: 105,
  9: 120,
  10: 120,
  11: 120,
  12: 150
};

import React from "react";

const SettingTab = ({ setting, isActive, setActive }) => {
  return (
    <div className="flex">
      <div onClick={setActive(setting)} className={`settings-tab-name ${isActive(setting) && "active-tab"}`}>
        {setting.name}
      </div>
    </div>
  );
};

export default SettingTab;

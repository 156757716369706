import CustomInput from "../../common/CustomInput/CustomInput";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import "./VerifyMobileNumberModal.scss";
import Button from "../CustomButton/CustomButton";
import { BeatLoader } from "react-spinners";
import _ from "lodash";
import { formatPhone } from "helpers/phone.helper";
// import { useSelector } from "react-redux";
import { verifySmsPIN, sendSmsPIN } from "services/account/account.service";

const VerifyBusinessModal = ({ close, show, mode }) => {
  // const { phoneConfig } = useSelector(state => state.account?.account || {});
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState(mode);
  // eslint-disable-next-line
  const [verifyError, setVerifyError] = useState("");
  const form = useForm();
  const { handleSubmit, errors } = form;

  const onSubmit = async data => {
    setLoading(true);

    try {
      if (viewMode === "prompt") {
        const values = _.omit({ ...data, phone: formatPhone(data.phone) }, ["verifyBussinessRule"]);
        await sendSmsPIN(values);
        setViewMode("verify");
      } else {
        const values = _.omit({ ...data, pin: data.pin }, ["verifyBussinessRule"]);
        await verifySmsPIN(values);
        close(true);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      if (err.message === "552") {
        setVerifyError("Sorry, the Pin you entered was not correct.");
      } else if (err.message === "557") {
        setVerifyError("Sorry, too many attempts to send a code to this phone number. Please try again tomorrow.");
      } else {
        setVerifyError("Sorry we are unable to verify your business at this time. Please try again later.");
      }
      setLoading(false);
    }
  };
  const cancel = () => {
    close(false);
  };

  // Also prevent form submiting when press "Enter", because pressing also trigger onAutocomplete
  // and form submitting 2 times
  // it doesn't affect on other components
  const checkKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Modal show={show} onHide={close}>
      <div className="verify-business">
        <div className="verify-business__header">Let's Verify your mobile number</div>
        <div className="verify-business__text">
          {viewMode === "prompt"
            ? "We need to verify you mobile number to stay up to date with new text message regulations."
            : "Please enter the one time PIN you received via text message."}
        </div>
        {verifyError && <p className="verify-business__error">{verifyError}</p>}

        <div className="verify-business__content">
          <form className="verify-business__form" onKeyDown={checkKeyDown}>
            {viewMode === "prompt" && (
              <CustomInput
                form={form}
                type="phone"
                name="phone"
                label="Mobile Phone"
                placeholder="Mobile Phone"
                disabled={loading}
                required
              />
            )}
            {viewMode === "verify" && (
              <CustomInput
                form={form}
                type="pin"
                name="pin"
                label="One time PIN"
                placeholder="999999"
                disabled={loading}
                required
              />
            )}
          </form>
        </div>
        <div className="verify-business__actions">
          {viewMode === "prompt" && (
            <>
              <Button
                onClick={cancel}
                className="verify-business__actions__cancel"
                bsStyle="info"
                pullRight
                type="button"
              >
                Skip for now
              </Button>
              <Button
                bsStyle="info"
                pullRight
                fill
                type="button"
                disabled={!!errors?.verifyBussinessRule || loading}
                onClick={handleSubmit(onSubmit)}
              >
                {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Text me a PIN</span>}
              </Button>
            </>
          )}
          {viewMode === "verify" && (
            <Button
              bsStyle="info"
              pullRight
              fill
              type="button"
              disabled={!!errors?.verifyBussinessRule || loading}
              onClick={handleSubmit(onSubmit)}
            >
              {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Submit</span>}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VerifyBusinessModal;

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/database";
import "firebase/compat/analytics";
import "firebase/compat/app-check";
import "firebase/compat/storage";
import "firebase/compat/auth";
import { environment, env } from "./configs/environment";

firebase.initializeApp(environment.config);
firebase.analytics();

const appCheck = firebase.appCheck();

if (env === "local" || env === "localProd") {
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
}
appCheck.activate(new firebase.appCheck.ReCaptchaEnterpriseProvider(environment.recaptchaKey));
const db = firebase.firestore();
const rtdb = firebase.database();

export function setupPresence(user) {
  const isOfflineForRTDB = {
    state: "offline",
    lastChanged: firebase.database.ServerValue.TIMESTAMP
  };

  const isOnlineForRTDB = {
    state: "online",
    lastChanged: firebase.database.ServerValue.TIMESTAMP
  };

  const isOfflineForFirestore = {
    state: "offline",
    lastChanged: firebase.firestore.FieldValue.serverTimestamp()
  };

  const isOnlineForFirestore = {
    state: "online",
    lastChanged: firebase.firestore.FieldValue.serverTimestamp()
  };

  const rtdbRef = rtdb.ref(`/status/${user.uid}`);
  const userDoc = db.doc(`users/${user.uid}`);
  rtdb.ref(".info/connected").on("value", async snapshot => {
    if (snapshot.val() === false) {
      userDoc.update({
        status: isOfflineForFirestore
      });
      return;
    }

    await rtdbRef.onDisconnect().set(isOfflineForRTDB);
    //online
    userDoc.update({
      status: isOnlineForFirestore
    });
    rtdbRef.set(isOnlineForRTDB);
  });
}

export { db, firebase };

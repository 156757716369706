import React, { useEffect, useState } from "react";
import moment from "moment-timezone";

const NotificationIcon = ({ handleNotify, reservation, businessSettings, account, disabled = false }) => {
  const [lastUpdated, setLastUpdated] = useState(false);

  useEffect(() => {
    let interval = setInterval(() => setLastUpdated(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, [lastUpdated]);

  const onCheckIfNotificationIsAvailable = () => {
    return (
      ((getNotificationType() !== "email" && reservation.data().phone) ||
        (getNotificationType() === "email" && reservation.data().email)) &&
      !reservation.data().errorCode &&
      onCheckReservation(reservation) &&
      !disabled
    );
  };

  const onCheckReservation = reservation => {
    const reservationDate = moment(reservation.data().reservationTime.toDate()).toDate();
    const dateCurrent = moment().toDate();
    return moment(dateCurrent).diff(reservationDate, "seconds") > -1800;
  };

  const getNotificationType = () => {
    if (businessSettings && businessSettings.notificationType) {
      return businessSettings.notificationType;
    } else if (account && account.settings && account.settings.notificationType) {
      return account.settings.notificationType;
    } else {
      return "sms";
    }
  };

  return (
    <span>
      {onCheckIfNotificationIsAvailable() ? (
        <i
          onClick={handleNotify(reservation)}
          className="hour-reservation__icon
          mdi mdi-message-text-outline
          wl-table__action-icon
          wl-table__action-icon--chat-enabled"
        >
          <span className="badge-label">Notify</span>
        </i>
      ) : (
        <i
          className="hour-reservation__icon
            mdi mdi-message-text-outline
            wl-table__action-icon
            wl-table__action-icon--disabled
            wl-table__action-icon--chat-disabled"
        >
          <span className="badge-label">Notify</span>
        </i>
      )}
    </span>
  );
};

export default NotificationIcon;

import { Plan } from "constants/plan";

export const isPro = plan => plan === Plan.pro;

export const isPremium = plan => plan === Plan.premium;

export const isFree = plan => plan === Plan.free;

export const isProOrPremium = plan => plan === Plan.pro || plan === Plan.premium;

export const isNotEnterprise = plan => plan !== Plan.enterprise;

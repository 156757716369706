export const timezones = [
  {
    text: "Pacific/Midway GMT-10:00",
    value: "Pacific/Midway"
  },
  {
    text: "America/Adak GMT-10:00",
    value: "America/Adak"
  },
  {
    text: "America/Anchorage GMT-09:00",
    value: "America/Anchorage"
  },
  {
    text: "Pacific/Gambier GMT-09:00",
    value: "Pacific/Gambier"
  },
  {
    text: "America/Dawson Creek GMT-08:00",
    value: "America/Dawson_Creek"
  },
  {
    text: "America/Ensenada GMT-08:00",
    value: "America/Ensenada"
  },
  {
    text: "America/Los Angeles GMT-08:00",
    value: "America/Los_Angeles"
  },
  {
    text: "Canada/Vancouver GMT-08:00",
    value: "America/Vancouver"
  },
  {
    text: "America/Chihuahua GMT-07:00",
    value: "America/Chihuahua"
  },
  {
    text: "America/Denver GMT-07:00",
    value: "America/Denver"
  },
  {
    text: "Canada/Edmonton GMT-07:00",
    value: "America/Edmonton"
  },
  {
    text: "America/Phoenix GMT-07:00",
    value: "America/Phoenix"
  },
  {
    text: "America/Belize GMT-06:00",
    value: "America/Belize"
  },
  {
    text: "America/Cancun GMT-06:00",
    value: "America/Cancun"
  },
  {
    text: "America/Chicago GMT-06:00",
    value: "America/Chicago"
  },
  {
    text: "Canada/Regina GMT-06:00",
    value: "America/Regina"
  },
  {
    text: "Canada/Winnipeg GMT-06:00",
    value: "America/Winnipeg"
  },
  {
    text: "America/Bogota GMT-05:00",
    value: "America/Bogota"
  },
  {
    text: "America/Havana GMT-05:00",
    value: "America/Havana"
  },
  {
    text: "America/New York GMT-05:00",
    value: "America/New_York"
  },
  {
    text: "Canada/Toronto GMT-05:00",
    value: "America/Toronto"
  },
  {
    text: "America/Caracas GMT-04:30",
    value: "America/Caracas"
  },
  {
    text: "America/Campo Grande GMT-04:00",
    value: "America/Campo_Grande"
  },
  {
    text: "America/Glace Bay GMT-04:00",
    value: "America/Glace_Bay"
  },
  {
    text: "America/Goose Bay GMT-04:00",
    value: "America/Goose_Bay"
  },
  {
    text: "America/Santiago GMT-04:00",
    value: "America/Santiago"
  },
  {
    text: "America/La Paz GMT-04:00",
    value: "America/La_Paz"
  },
  {
    text: "Canada/Halifax GMT-04:00",
    value: "America/Halifax"
  },
  {
    text: "America/St Johns GMT-03:30",
    value: "America/St_Johns"
  },
  {
    text: "Canada/St Johns GMT-03:30",
    value: "America/St_Johns"
  },
  {
    text: "America/Argentina/Buenos Aires GMT-03:00",
    value: "America/Argentina/Buenos_Aires"
  },
  {
    text: "America/Montevideo GMT-03:00",
    value: "America/Montevideo"
  },
  {
    text: "America/Araguaina GMT-03:00",
    value: "America/Araguaina"
  },
  {
    text: "America/Godthab GMT-03:00",
    value: "America/Godthab"
  },
  {
    text: "America/Miquelon GMT-03:00",
    value: "America/Miquelon"
  },
  {
    text: "America/Sao Paulo GMT-03:00",
    value: "America/Sao_Paulo"
  },
  {
    text: "America/Noronha GMT-02:00",
    value: "America/Noronha"
  },
  {
    text: "Atlantic/Cape Verde GMT-01:00",
    value: "Atlantic/Cape_Verde"
  }
];

import { combineReducers } from "redux";
import { visit } from "./visit.reducer";
import { aggregate } from "./aggregate.reducer";
import { business } from "./business.reducer";
import { account } from "./account.reducers";
import { regions } from "./regions.reducer";
import { square } from "./square.reducer";
import { users } from "./users.reducers";
import { config } from "./config.reducers";
import { prices } from "./prices.reducers";

export default combineReducers({ aggregate, visit, business, account, regions, square, users, config, prices });

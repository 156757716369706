import { db, firebase } from "../../firebase";
import { environment } from "../../configs/environment";

export async function updateProfile(data) {
  return firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(async function(idToken) {
      return fetch(`${environment.apiEndpoint}updateProfile`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${idToken}`,
          Accepts: "application/json",
          "Content-Type": "application/json"
        }
      })
        .then(response => {
          if (!response.ok || (response.status > 200 && response.status < 600)) {
            throw new Error(response.statusText);
          }
          return response;
        })
        .catch(error => {
          console.error("error triggering updateProfile function", error);
          return { ok: false };
        });
    })
    .catch(function(error) {
      console.error("error getting auth token", error);
      return { ok: false };
    });
}

export async function changePassword(email, oldPassword, newPassword) {
  const currentUser = firebase.auth().currentUser;
  var credential = firebase.auth.EmailAuthProvider.credential(email, oldPassword);
  return currentUser
    .reauthenticateWithCredential(credential)
    .then(() => {
      return currentUser.updatePassword(newPassword);
    })
    .catch(error => {
      throw error;
    });
}

function safeIntOrDelete(value) {
  const result = parseInt(value, 10);
  if (isNaN(result)) {
    return firebase.firestore.FieldValue.delete();
  }
  return result;
}

export async function updateBusinessSettings(businessId, settings) {
  settings.coverFlowLimit = safeIntOrDelete(settings.coverFlowLimit);
  await db
    .collection("businesses")
    .doc(businessId)
    .set({ settings: settings }, { merge: true });
}

export async function updateBusiness(businessId, business) {
  await db
    .collection("businesses")
    .doc(businessId)
    .set(business, { merge: true });
}

export async function checkout(plan, interval, exitPoint) {
  const tokenId = await firebase.auth().currentUser.getIdToken(true);
  try {
    const response = await fetch("/stripe/checkout/session", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenId}`,
        Accepts: "application/json"
      },
      body: JSON.stringify({ plan, interval, exitPoint })
    });
    if (!response.ok || (response.status > 200 && response.status < 600)) {
      throw new Error(response.statusText);
    }
    return response;
  } catch (error) {
    console.log(error);
    return { ok: false };
  }
}

export async function checkoutWithoutCard(plan, interval, exitPoint) {
  const tokenId = await firebase.auth().currentUser.getIdToken(true);
  try {
    const response = await fetch("/stripe/without-card/subscription", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenId}`,
        Accepts: "application/json"
      },
      body: JSON.stringify({ plan, interval, exitPoint })
    });
    if (!response.ok || (response.status > 200 && response.status < 600)) {
      throw new Error(response.statusText);
    }
    return response;
  } catch (error) {
    console.log(error);
    return { ok: false };
  }
}

export async function getPrices() {
  const tokenId = await firebase.auth().currentUser.getIdToken(true);
  return fetch("/stripe/prices", {
    method: "GET",
    headers: {
      Authorization: `Bearer ${tokenId}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        if (response.status === 401 || response.status === 403) {
          return {};
        }
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error getting prices", error);
      throw error;
    });
}

export async function stripePortal() {
  const tokenId = await firebase.auth().currentUser.getIdToken(true);
  try {
    const response = await fetch("/stripe/portal", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenId}`,
        Accepts: "application/json"
      }
    });
    if (!response.ok || (response.status > 200 && response.status < 600)) {
      throw new Error(response.statusText);
    }
    return response;
  } catch (error) {
    console.log(error);
    return { ok: false };
  }
}

export async function stripePaymentSetup() {
  const tokenId = await firebase.auth().currentUser.getIdToken(true);
  try {
    const response = await fetch("/stripe/payment-setup", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenId}`,
        Accepts: "application/json"
      }
    });
    if (!response.ok || (response.status > 200 && response.status < 600)) {
      throw new Error(response.statusText);
    }
    return response;
  } catch (error) {
    console.log(error);
    return { ok: false };
  }
}

export async function upgradePremiumToPro(line_items) {
  const tokenId = await firebase.auth().currentUser.getIdToken(true);
  try {
    const response = await fetch("/stripe/upgrade", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${tokenId}`,
        Accepts: "application/json"
      },
      body: JSON.stringify(line_items)
    });
    if (!response.ok || (response.status > 200 && response.status < 600)) {
      const result = await response.json();
      if (result && result.error) {
        return { error: result.error, code: response.status };
      }
      throw new Error();
    }
    return await response.json();
  } catch (error) {
    console.log(error);
    return { error: error.message || "Something went wrong" };
  }
}

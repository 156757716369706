/* eslint-disable @typescript-eslint/ban-types */
import React, { CSSProperties } from "react";
import { makeStyles } from "@material-ui/core/styles";
import RoundedTag from "./RoundedTag";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import * as _ from "lodash";

const useStyles = overrides =>
  makeStyles({
    head: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 14,
      color: "#565656",
      textTransform: "uppercase",
      paddingTop: 0,
      paddingBottom: 5,
      ...(overrides?.head || {})
    },
    body: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 300,
      fontSize: 18,
      flexFlow: "column wrap",
      color: "#323232",
      overflow: "hidden",
      textOverflow: "ellipsis",
      ...(overrides?.body || {})
    },
    disabled: {
      textDecoration: "line-through",
      color: "#777777"
    },
    root: {
      fontSize: 35,
      ...(overrides?.root || {})
    }
  });

type OverridesKeys = "head" | "body" | "root";

const RegularTable = ({
  tableHeaders,
  rows,
  actions,
  editAction,
  deleteAction,
  resendAction,
  hideAction,
  hideColumn,
  orderBy,
  onRequestSort,
  showSorting,
  isRowDisabled,
  additionalActions,
  hideActions = false,
  emptyRowsMessage = "Table is empty.",
  overrides = {}
}: {
  tableHeaders: any;
  rows: any;
  actions: any;
  editAction?: (item: any) => void;
  deleteAction?: any;
  resendAction?: any;
  hideAction?: (name: string, item: any) => boolean;
  hideColumn?: (item: any, key: string) => boolean;
  orderBy?: any;
  onRequestSort?: any;
  showSorting?: boolean | Function;
  isRowDisabled?: Function;
  additionalActions?: Function;
  hideActions?: boolean;
  emptyRowsMessage: string;
  overrides?: { [key in OverridesKeys]?: CSSProperties };
}) => {
  const classes = useStyles(overrides)();

  const createSortHandler = column => event => {
    onRequestSort(event, column);
  };

  const onGetAction = (actionName, item) => () => {
    switch (actionName) {
      case "edit":
        editAction?.(item);
        return;
      case "delete":
        deleteAction(item);
        return;
      case "resend":
        resendAction(item);
        return;
      default:
        return;
    }
  };

  const alignTo = index => {
    return index === 0 ? "left" : "right";
  };

  const renderTableCell = (index, item, row) => {
    const isDisabled = isRowDisabled && isRowDisabled(row);

    if (Array.isArray(item)) {
      return (
        <TableCell className={`${classes.body} ${isDisabled && classes.disabled} `} key={index} align={alignTo(index)}>
          {item.map(i => (
            <RoundedTag key={`rounded-${i}-${index}`} value={i}></RoundedTag>
          ))}
        </TableCell>
      );
    } else if (_.isFunction(item)) {
      return (
        <TableCell className={classes.body} key={index} align={alignTo(index)}>
          {item()}
        </TableCell>
      );
    } else {
      return (
        <TableCell className={`${classes.body} ${isDisabled && classes.disabled} `} key={index} align={alignTo(index)}>
          {item}
        </TableCell>
      );
    }
  };

  return (
    <TableContainer>
      {!rows.length ? (
        <p className="w-100 text-center">{emptyRowsMessage}</p>
      ) : (
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header, index) => (
                <TableCell className={classes.head} align={alignTo(index)} key={index}>
                  {showSorting &&
                  header !== "actions" &&
                  (typeof showSorting === "function" ? showSorting(header) : true) ? (
                    <TableSortLabel
                      active={orderBy?.column === header}
                      direction={orderBy?.direction}
                      onClick={createSortHandler?.(header)}
                    >
                      {header}
                    </TableSortLabel>
                  ) : (
                    <span>{header}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody style={{ maxHeight: "calc(100vh - 310px)" }}>
            {rows.map((row, index) => (
              <TableRow key={index}>
                {Object.keys(row)
                  .filter(key => (hideColumn ? !hideColumn(row, key) : true))
                  .map((key, index) => renderTableCell(index, row[key], row))}
                {!hideActions && (
                  <TableCell className={classes.root} align="right">
                    {actions &&
                      actions.map(
                        (action, index) =>
                          !hideAction?.(action.name, row) && (
                            <i
                              onClick={onGetAction(action.name, row)}
                              className={action.className}
                              key={index}
                              style={{ color: action.color }}
                            />
                          )
                      )}
                    {additionalActions && additionalActions(row)}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default RegularTable;

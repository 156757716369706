import CustomInput from "../../common/CustomInput/CustomInput";
import React, { useState } from "react";
import { ControlLabel, HelpBlock, Modal, FormGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import "./VerifyBusinessModal.scss";
import Button from "../CustomButton/CustomButton";
import { BeatLoader } from "react-spinners";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { environment } from "configs/environment";
import _ from "lodash";
import { formatPhone } from "helpers/phone.helper";
import { isGracePeriodAfterNow, VERIFICATION_MODES } from "../../constants/verification";
import { verifyAccount } from "services/account/account.service";

const VerifyBusinessModal = ({ close, show, user }) => {
  const { business } = useSelector(state => state.business);
  const { verificationMode } = useSelector(state => state.config || {});
  const { phoneConfig } = useSelector(state => state.account?.account || {});
  const [loading, setLoading] = useState(false);
  const [businessType, setBusinessType] = useState("EIN");

  // eslint-disable-next-line
  const [verifyError, setVerifyError] = useState("");
  const form = useForm();
  const { handleSubmit, register, errors, setError, clearError } = form;

  const onSubmit = async data => {
    setLoading(true);
    if (
      [data.legalBusinessName.toLowerCase().trim(), business.name.toLowerCase().trim()].includes(
        data.fullName.toLowerCase().trim()
      )
    ) {
      setError("fullName", "validation", "Business contact name cannot be your business name, must be a person.");
      setLoading(false);
      return;
    }
    const values = _.omit({ ...data, ...address, postalCode: address.postal, phone: formatPhone(data.phone) }, [
      "address",
      "verifyBussinessRule",
      "termsAndPolicy",
      "postal"
    ]);
    try {
      await verifyAccount(values);
      close(true);
    } catch (err) {
      console.error(err);

      // website url error coded
      if (Number(err.message) === 566) {
        setError("websiteURL", "validation", "The url you have entered is not a valid website");
      } else {
        setVerifyError("Sorry we are unable to verify your business at this time. Please try again later.");
      }
    }
    setLoading(false);
  };
  const cancel = () => {
    close(false);
  };
  const [isAddressValid, setIsAddressValid] = useState(false);

  const [address, setAddress] = useState(null);
  const [autoCompleteValue, setAutoCompleteValue] = useState("");
  const onAutoComplete = place => {
    // "street_address" - address1
    // "locality" - city
    // "administrative_area_level_1" - state
    // "postal_code" - postal
    const { address_components, formatted_address } = place;
    if (!address_components) return;
    if (formatted_address) setAutoCompleteValue(formatted_address);
    const address1 = address_components.find(el => el.types.includes("street_address"));
    const street_number = address_components.find(el => el.types.includes("street_number"));
    const route = address_components.find(el => el.types.includes("route"));
    const city = address_components.find(el => el.types.includes("locality"));
    const state = address_components.find(el => el.types.includes("administrative_area_level_1"));
    const postal = address_components.find(el => el.types.includes("postal_code"));
    const country = address_components.find(el => el.types.includes("country"));
    const data = {
      address1:
        address1?.long_name || ((street_number?.long_name || "") + " " + (route?.short_name || "")).trim() || "",
      address2: form.address2 || "",
      city: city?.long_name || "",
      state: state?.short_name || state?.long_name || "",
      postal: postal?.long_name || "",
      country: country.short_name || "US"
    };

    if (!data || !data.address1 || !data.city || !data.state || !data.postal) {
      setError("address", "required");
      setIsAddressValid(false);
      setAddress(null);
      return;
    } else {
      clearError("address");
      setAddress(data);
      setIsAddressValid(true);
    }
  };

  // Also prevent form submiting when press "Enter", because pressing also trigger onAutocomplete
  // and form submitting 2 times
  // it doesn't affect on other components
  const checkKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  const changeBusinessType = e => {
    setBusinessType(e.target.value);
  };

  const isVerifyRequired = VERIFICATION_MODES.ON === verificationMode && !isGracePeriodAfterNow(phoneConfig);
  const someFieldIsNotFilled =
    !form.watch("legalBusinessName") ||
    !form.watch("fullName") ||
    !form.watch("phone") ||
    !form.watch("websiteURL") ||
    !isAddressValid ||
    !form.watch("verifyBussinessRule") ||
    !form.watch("termsAndPolicy");

  return (
    <Modal show={show} onHide={close} dialogClassName="verify-business-wrapper" backdropClassName="dark-backdrop">
      <div className="verify-business">
        <div className="verify-business__header">Let's verify your business</div>
        <div className="verify-business__text">
          {isVerifyRequired
            ? "You'll need to verify your business before you can send text messages. "
            : "We will issue you a dedicated phone number after verifying your business."}
        </div>
        {verifyError && <p className="verify-business__error">{verifyError}</p>}

        <div className="verify-business__content">
          <form className="verify-business__form" onKeyDown={checkKeyDown}>
            <CustomInput
              form={form}
              name="legalBusinessName"
              label={businessType == "SP" ? "Brand Name" : "Legal Business Name"}
              placeholder={businessType == "SP" ? "Brand Name" : "Legal Business Name"}
              disabled={loading}
              required
              maxLength={80}
            />
            <CustomInput
              form={form}
              name="fullName"
              type="fullName"
              label="First and Last Name"
              placeholder="First and Last Name"
              disabled={loading}
              required
              maxLength={80}
            />
            <CustomInput
              form={form}
              type="phone"
              name="phone"
              label={businessType == "SP" ? "Mobile Phone" : "Business Phone"}
              placeholder={businessType == "SP" ? "Mobile Phone" : "Business Phone"}
              disabled={loading}
              required
            />
            <input name="email" type="hidden" value={user.email} ref={form && form.register()} />
            <CustomInput
              form={form}
              name="websiteURL"
              label={"Website URL"}
              placeholder={"https://www.yourwebsite.com"}
              pattern={{
                value: /^(https:\/\/)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
                message: "Invalid URL"
              }}
              disabled={loading}
              required
            />
            <FormGroup validationState={(!!errors.address && "error") || null}>
              <ControlLabel>Business Address</ControlLabel>
              <ReactGoogleAutocomplete
                apiKey={environment.googleMapsKey}
                onPlaceSelected={onAutoComplete}
                className="form-control"
                language="en"
                options={{
                  strictBounds: true,
                  componentRestrictions: {
                    country: ["us", "ca"]
                  },
                  types: ["address"],
                  fields: ["address_components", "formatted_address"]
                }}
                onChange={e => setAutoCompleteValue(e.target.value)}
                value={autoCompleteValue}
                disabled={loading}
              />
              {/* need this input without display for correct react-hook-form validation */}
              <input
                name="address"
                type="text"
                onChange={() => null}
                value={isAddressValid ? "address" : ""}
                style={{ display: "none" }}
                ref={register({ required: true })}
              />
              {errors.address && errors.address.type === "required" && (
                <HelpBlock>Business Address is required</HelpBlock>
              )}
            </FormGroup>
          </form>
          <div className="verify-business__info-wrapper">
            <div className="verify-business__info">
              <div className="verify-business__info_header">Why Verify?</div>
              <div>
                <div className="verify-business__info_text"> - Required by all major mobile carriers</div>
                <div className="verify-business__info_text"> - Unlock your higher messages limits</div>
                <div className="verify-business__info_text"> - Receive a dedicated phone number</div>
                <div className="verify-business__info_text"> - Better deliverability of messages</div>
              </div>
              <div className="verify-business__info_learn_more">
                <a href="https://www.campaignregistry.com/faq/" target="_blank" rel="noreferrer">
                  Learn more
                </a>
              </div>
            </div>
            {address && (
              <div className="verify-business__address">
                {address.address1 && <div>{address.address1}</div>}
                {address.address2 && <div>{address.address2}</div>}
                {address.city && address.state && address.postal && (
                  <div>
                    {address.city}, {address.state} {address.postal}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <FormGroup className="verify-business__rules">
          <input
            id="verifyBussinessRule"
            className="verify-business__rules_checkbox"
            type="checkbox"
            name="verifyBussinessRule"
            required={true}
            ref={register({ required: true })}
          />
          <label htmlFor="verifyBussinessRule" className="verify-business__rules_checkbox_description">
            I aknowledge that my business information may be shared with third party carriers in order to verify my
            business.
          </label>
        </FormGroup>
        <FormGroup className="verify-business__terms">
          <input
            id="termsAndPolicy"
            className="verify-business__terms_checkbox"
            type="checkbox"
            name="termsAndPolicy"
            required={true}
            ref={register({ required: true })}
          />
          <label htmlFor="termsAndPolicy" className="verify-business__terms_checkbox_description">
            I agree to
            <a className="terms-link" href="https://www.waitly.com/terms/" target="_blank" rel="noopener noreferrer">
              {" "}
              Waitly's Terms of service
            </a>{" "}
            and{" "}
            <a className="terms-link" href="https://www.waitly.com/privacy" target="_blank" rel="noopener noreferrer">
              privacy policy
            </a>
            .
          </label>
        </FormGroup>
        <div className="verify-business__actions">
          <Button onClick={cancel} className="verify-business__actions__cancel" bsStyle="info" pullRight type="button">
            Cancel
          </Button>
          <Button
            bsStyle="info"
            pullRight
            fill
            type="button"
            disabled={loading || someFieldIsNotFilled}
            onClick={handleSubmit(onSubmit)}
          >
            {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Register my business</span>}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VerifyBusinessModal;

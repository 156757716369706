export enum CustomizableInputFieldType {
  NAME = "name",
  PHONE_NUMBER = "phoneNumber",
  EMAIL = "email",
  PARTY_SIZE = "partySize",
  SERVICE = "service",
  ORDER_NUMBER = "orderNumber",
  QUOTED_TIME = "quotedTime"
}

export enum CustomizableInputFieldOption {
  REQUIRED = "required",
  OPTIONAL = "optional",
  HIDDEN = "hidden"
}

export type CustomizableInputFieldsKeys = keyof Record<CustomizableInputFieldType, CustomizableInputFieldOption>;

export type CustomizableInputFields = {
  [key in CustomizableInputFieldsKeys]?: {
    enabled: boolean;
    reservations?: CustomizableInputFieldOption;
    selfCheckIn?: CustomizableInputFieldOption;
    waitlist?: CustomizableInputFieldOption;
  };
};

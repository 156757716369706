import "date-fns";
import React from "react";
import "./DailyReservations.scss";
import moment from "moment-timezone";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { DatePicker } from "@material-ui/pickers";

function DailyReservationsCalendar(props) {
  const { currentDate, changeDate } = props;
  const handleDateChange = date => {
    changeDate(date);
  };

  const changeDay = next => {
    if (next) {
      changeDate(
        moment(currentDate)
          .add(1, "day")
          .toDate()
      );
    } else {
      changeDate(
        moment(currentDate)
          .subtract(1, "day")
          .toDate()
      );
    }
  };

  return (
    <div className="week-view">
      <div className="week-view__arrow" onClick={changeDay.bind(this, false)}>
        <i
          className="
        mdi
        mdi-menu-left
      "
        ></i>
      </div>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          format="MM/dd/yyyy"
          autoOk
          disableToolbar
          variant="inline"
          value={currentDate}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
      <div className="week-view__arrow" onClick={changeDay.bind(this, true)}>
        <i
          className="
        mdi
        mdi-menu-right
      "
        ></i>
      </div>
    </div>
  );
}

export default DailyReservationsCalendar;

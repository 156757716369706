import React from "react";
import "./DailyReservations.scss";
import Badge from "@material-ui/core/Badge";
import moment from "moment-timezone";
import { VisitStatus } from "services/visits/visits.service";
import TimerTick from "common/TimerTick/TimerTick";
import StayPrimaryPortraitIcon from "@material-ui/icons/StayPrimaryPortrait";
import { MESSAGE_TYPES } from "../../constants/text-messages";
import WarningIcon from "@material-ui/icons/Warning";
import NotificationIcon from "./NotificationIcon/NotificationIcon";
import { IconButton, makeStyles } from "@material-ui/core";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import cx from "classnames";
import { MoreIcon } from "common/MoreIcon/MoreIcon";
import { getPreferenceColorByIndex } from "helpers/preference.helper";

const ReservationRowButtons = ({
  isChatEnabledForAccount,
  reservation,
  getMessagesCount,
  disabled,
  openChatModal,
  account,
  businessSettings,
  handleNotify,
  handleCheckIn,
  handleNotifyAgain,
  handleServe,
  handleMenuClick,
  handleMenuClose,
  addNote,
  handleEdit,
  handleWalkout,
  handleReset,
  handleDelete,
  anchorEl,
  menuOpen,
  isMobile = false
}) => {
  const useStyles = ({ isMobile, withMarginTop }) =>
    makeStyles(theme => ({
      root: {
        padding: 0,
        backgroundColor: "transparent",
        marginRight: 8,
        marginTop: isMobile && withMarginTop ? 0 : -22,
        width: isMobile ? 25 : 35,
        "&:hover": {
          backgroundColor: "transparent"
        },
        "&:focus": {
          backgroundColor: "transparent"
        },
        "&.Mui-focusVisible": { backgroundColor: "transparent" }
      }
    }));

  const classes = useStyles({
    isMobile,
    withMarginTop: [VisitStatus.seated, VisitStatus.walkout].includes(reservation.data().status)
  })();

  return (
    <div className="hour-reservation__col hour-reservation__col--actions end" style={!isMobile ? { width: "23%" } : {}}>
      {isChatEnabledForAccount() &&
      reservation.data().status !== VisitStatus.seated &&
      reservation.data().status !== VisitStatus.walkout ? (
        <span className="badge-wrapper">
          {reservation.data().phone ? (
            <Badge badgeContent={getMessagesCount(reservation.id)} color="error">
              {/* @ts-ignore */}
              <StayPrimaryPortraitIcon
                onClick={disabled ? () => null : openChatModal.bind(this, reservation)}
                disabled={disabled}
                style={{
                  fontSize: isMobile ? "34px" : "50px",
                  color: disabled ? "#9b9a9a" : "#000",
                  cursor: disabled ? "not-allowed" : "pointer"
                }}
              />
              {reservation.data().errorCode && <WarningIcon className="warning-icon" />}
            </Badge>
          ) : (
            <>
              {/* @ts-ignore */}
              <StayPrimaryPortraitIcon
                disabled={disabled}
                style={{
                  fontSize: isMobile ? "34px" : "50px",
                  color: "#9b9a9a",
                  cursor: "not-allowed"
                }}
              />
            </>
          )}
          <span
            className="badge-label"
            onClick={() => {
              if (reservation.data().phone && !disabled) {
                return openChatModal(reservation);
              }
            }}
            style={{
              cursor: reservation.data().phone && !disabled ? "pointer" : "not-allowed",
              color: reservation.data().phone && !disabled ? "#000" : "#9b9a9a"
            }}
          >
            Chat
          </span>
        </span>
      ) : null}
      {reservation.data().status !== VisitStatus.seated && reservation.data().status !== VisitStatus.walkout ? (
        <div>
          {reservation.data().firstNotifiedTime && !reservation.data().errorCode ? (
            <span
              className="wl-table__outline-msg-box"
              onClick={disabled ? () => null : handleNotifyAgain.bind(this, reservation)}
            >
              <span className="chat-badge-wrapper">
                <Badge badgeContent={reservation.data().numNotifications} color="primary">
                  <i
                    className={cx({
                      "mdi mdi-message-outline wl-table__action-icon wl-table__action-icon--chat-enabled": true,
                      "wl-table__action-icon--disabled": disabled
                    })}
                  >
                    <span className="badge-label">Notify</span>
                  </i>
                  <span className="wl-table__outlined-msg">
                    <TimerTick date={reservation.data().firstNotifiedTime.toDate()} />
                  </span>
                </Badge>
              </span>
            </span>
          ) : (
            <NotificationIcon
              disabled={disabled}
              account={account}
              businessSettings={businessSettings}
              reservation={reservation}
              handleNotify={handleNotify}
            />
          )}
        </div>
      ) : null}

      {reservation.data().status === VisitStatus.reserved ? (
        <i
          onClick={disabled ? () => null : handleCheckIn.bind(this, reservation)}
          className={cx({
            "hour-reservation__icon mdi mdi-account-check wl-table__action-icon wl-table__action-icon--check": true,
            "wl-table__action-icon--disabled": disabled
          })}
        >
          <span className="badge-label">Check-In</span>
        </i>
      ) : null}

      {reservation.data().status === VisitStatus.waiting ? (
        <i
          onClick={disabled ? () => null : handleServe.bind(this, reservation)}
          className={cx({
            "hour-reservation__icon mdi  mdi-check-box-outline wl-table__action-icon wl-table__action-icon--check": true,
            "wl-table__action-icon--disabled": disabled
          })}
        >
          <span className="badge-label">Done</span>
        </i>
      ) : null}
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          disabled={disabled}
          onClick={handleMenuClick}
          classes={{
            root: classes.root
          }}
          disableRipple
        >
          <MoreIcon disabled={disabled} isMobile={isMobile} />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={menuOpen}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: 45 * 4.5,
              width: "20ch"
            }
          }}
        >
          <MenuItem
            onClick={() => {
              handleMenuClose();
              addNote.call(this, reservation);
            }}
          >
            <div>Add Note</div>
          </MenuItem>

          <MenuItem
            disabled={reservation.data().status === VisitStatus.seated}
            onClick={() => {
              handleMenuClose();
              handleEdit.call(this, reservation);
            }}
          >
            <div>Edit</div>
          </MenuItem>
          <MenuItem
            disabled={reservation.data().status === VisitStatus.walkout}
            onClick={() => {
              handleMenuClose();
              handleWalkout.call(this, reservation);
            }}
          >
            <div>No Show</div>
          </MenuItem>
          <MenuItem
            disabled={reservation.data().status === VisitStatus.reserved}
            onClick={() => {
              handleMenuClose();
              handleReset.call(this, reservation);
            }}
          >
            <div>Reset</div>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleMenuClose();
              handleDelete.call(this, reservation);
            }}
          >
            <div>Delete</div>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

function ReservationRow(this: any, props) {
  const {
    disabled,
    account,
    reservation,
    businessSettings,
    handleEdit,
    handleCheckIn,
    handleServe,
    handleNotify,
    handleNotifyAgain,
    handleDelete,
    handleReset,
    handleWalkout,
    openChatModal,
    isMobile = false,
    index,
    addNote
  } = props;
  const isChatEnabledForAccount = () => {
    if (businessSettings && businessSettings.twoWayMessaging !== undefined) {
      return businessSettings.twoWayMessaging;
    }
    if (account && account.settings && account.settings.twoWayMessaging !== undefined) {
      return account.settings.twoWayMessaging;
    }
    return true;
  };

  const getMessagesCount = id => {
    if (props.messages && props.messages.length) {
      let count = 0;
      for (let message of props.messages) {
        const m = message.data();
        if (
          m.referenceId === id &&
          m.collection === MESSAGE_TYPES.RESERVATION.collection &&
          m.direction === "inbound" &&
          !m.read
        ) {
          count++;
        }
      }
      return count > 0 ? count : null;
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getPreferenceColor = service => {
    const index = businessSettings?.services?.findIndex?.(s => s.name === service);
    if (index === undefined || index === -1) {
      return "";
    }
    return getPreferenceColorByIndex(index);
  };

  const hasNote = item => {
    return item.data().note || item.data().orderNumber;
  };

  const noteFormatted = item => {
    if (hasNote(item)) {
      let result = `${item.data().note}` || "";
      if (item.data().orderNumber && !result.toLowerCase().includes(`order number:${item.data().orderNumber}`)) {
        return `Order number:${item.data().orderNumber} ${item.data().note || ""}`;
      } else {
        return result;
      }
    } else {
      return "";
    }
  };

  const displayStatus = [VisitStatus.waiting, VisitStatus.walkout, VisitStatus.seated].includes(
    reservation.data().status
  );

  if (isMobile) {
    const preferenceColor = getPreferenceColor(reservation.data().service);
    return (
      <div
        className={cx({
          "reservation-item-mobile": true,
          "reservation-item-mobile__served":
            reservation.data().status === VisitStatus.seated || reservation.data().status === VisitStatus.walkout
        })}
      >
        <div className="reservation-item-mobile__content">
          <div className="reservation-item-mobile__order">
            <span>{index + 1}.</span>
          </div>
          <div className="reservation-item-mobile__info">
            <div className="reservation-item-mobile__info__name">{reservation.data().name}</div>
            <div className="reservation-item-mobile__info__party-size">
              <i className="fa fa-user" aria-hidden="true" />
              <span>{reservation.data().partySize}</span>
              <span>{reservation.data().partySize > 1 ? " people" : " person"}</span>
            </div>
            <div className="reservation-item-mobile__info__time">
              <i className="fa fa-clock-o" aria-hidden="true" />
              {moment(reservation.data().reservationTime.toDate()).format("h:mm a")}
            </div>
            {businessSettings?.services?.length ? (
              <div
                className={cx({
                  "reservation-item-mobile__info__category-value": true,
                  colored: !!reservation.data().service && preferenceColor
                })}
                style={{
                  backgroundColor: preferenceColor
                }}
              >
                {reservation.data().service || "None"}
              </div>
            ) : null}
            {displayStatus && (
              <div className="reservation-item-mobile__info__status">
                {reservation.data().status === VisitStatus.waiting && <>Arrived</>}
                {reservation.data().status === VisitStatus.walkout && <>No Show</>}
                {reservation.data().status === VisitStatus.seated && <>Served</>}
              </div>
            )}
          </div>
          <div className="reservation-item-mobile__buttons">
            <ReservationRowButtons
              account={account}
              addNote={addNote}
              anchorEl={anchorEl}
              businessSettings={businessSettings}
              disabled={disabled}
              getMessagesCount={getMessagesCount}
              handleCheckIn={handleCheckIn}
              handleDelete={handleDelete}
              handleEdit={handleEdit}
              handleMenuClick={handleMenuClick}
              handleMenuClose={handleMenuClose}
              handleNotify={handleNotify}
              handleNotifyAgain={handleNotifyAgain}
              handleReset={handleReset}
              handleServe={handleServe}
              handleWalkout={handleWalkout}
              isChatEnabledForAccount={isChatEnabledForAccount}
              menuOpen={menuOpen}
              openChatModal={openChatModal}
              reservation={reservation}
              isMobile
            />
          </div>
        </div>
        {hasNote(reservation) && (
          <div className="reservation-item-mobile__note">NOTE: {noteFormatted(reservation)}</div>
        )}
      </div>
    );
  }

  return (
    <div
      className={`hour-reservation ${
        reservation.data().status === VisitStatus.seated || reservation.data().status === VisitStatus.walkout
          ? "hour-reservation__served"
          : ""
      }`}
    >
      <div className="hour-reservation__top">
        <div className="hour-reservation__col" style={{ width: "12%" }}>
          {moment(reservation.data().reservationTime.toDate()).format("h:mm a")}
        </div>
        <div className="hour-reservation__col" style={{ width: "30%", overflow: "hidden", textOverflow: "ellipsis" }}>
          {reservation.data().name}
        </div>
        <div className="hour-reservation__col--center" style={{ width: "30%" }}>
          {reservation.data().status === VisitStatus.waiting ? (
            <div className="hour-reservation__category">Arrived</div>
          ) : null}
          {reservation.data().status === VisitStatus.walkout ? (
            <div className="hour-reservation__category">No Show</div>
          ) : null}
          {reservation.data().status === VisitStatus.seated ? (
            <div className="hour-reservation__category">Served</div>
          ) : null}
          {reservation.data().resourceName ? (
            <div className="hour-reservation__category">{reservation.data().resourceName}</div>
          ) : null}
        </div>
        <div className="wl-table__stats wl-table__text-lg" style={{ width: "5%" }}>
          <span className="wl-table__party-size-info">
            <i className="fa fa-user" aria-hidden="true"></i>
            <span>{reservation.data().partySize}</span>
          </span>
        </div>
        <ReservationRowButtons
          account={account}
          addNote={addNote}
          anchorEl={anchorEl}
          businessSettings={businessSettings}
          disabled={disabled}
          getMessagesCount={getMessagesCount}
          handleCheckIn={handleCheckIn}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          handleMenuClick={handleMenuClick}
          handleMenuClose={handleMenuClose}
          handleNotify={handleNotify}
          handleNotifyAgain={handleNotifyAgain}
          handleReset={handleReset}
          handleServe={handleServe}
          handleWalkout={handleWalkout}
          isChatEnabledForAccount={isChatEnabledForAccount}
          menuOpen={menuOpen}
          openChatModal={openChatModal}
          reservation={reservation}
        />
      </div>
      <div className="hour-reservation__bottom">
        {reservation.data().note ? `NOTE: ${reservation.data().note}` : null}
      </div>
    </div>
  );
}

export default ReservationRow;

/*!

=========================================================
* Light Bootstrap Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useEffect } from "react";
import { Navbar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import waitlyWhiteLogo from "../../assets/img/waitly_white_logo.svg";
import waitlyBlueLogo from "../../assets/img/waitly_blue_logo.svg";
import classNames from "classnames";

const AuthNavbar = ({ isInverted, location }) => {
  const [, setWidth] = useState(window.innerWidth);

  const activeRoute = routeName => (window.location.pathname.indexOf(routeName) > -1 ? "active" : "");

  const mobileSidebarToggle = () => {
    document.documentElement.classList.toggle("nav-open");
  };

  const updateWidth = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  return (
    <Navbar
      collapseOnSelect
      inverse
      className={classNames({
        "navbar-primary navbar-transparent navbar-absolute": true,
        "navbar-inverted": isInverted
      })}
    >
      <Navbar.Header>
        <Navbar.Brand>
          <img src={isInverted ? waitlyBlueLogo : waitlyWhiteLogo} alt="Waitly Logo" style={{ height: "75px" }} />
        </Navbar.Brand>
        <Navbar.Toggle
          onClick={mobileSidebarToggle}
          className={classNames({
            "navbar-toggle-inverted": isInverted
          })}
        />
      </Navbar.Header>
      <Navbar.Collapse
        className={classNames({
          "navbar-collapse-inverted": isInverted
        })}
      >
        <ul className="nav navbar-nav navbar-right">
          <li className={activeRoute("login-page")}>
            <NavLink
              to={{
                pathname: "/auth/login-page",
                search: location ? location.search : ""
              }}
              className="nav-link"
            >
              <div
                className={classNames({
                  "nav-link-inverted": isInverted
                })}
              >
                <i className="fa fa-user-circle-o" />
                <p>Sign In</p>
              </div>
            </NavLink>
          </li>
          <li className={activeRoute("register-page")}>
            <NavLink
              to={{
                pathname: "/auth/register-page",
                search: location ? location.search : ""
              }}
              className="nav-link"
            >
              <div
                className={classNames({
                  "nav-link-inverted": isInverted
                })}
              >
                <i className="fa fa-drivers-license-o" />
                <p>Sign Up</p>
              </div>
            </NavLink>
          </li>
        </ul>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default AuthNavbar;

export const HEADERS = ["name", "email", "role", "primary location", "actions"];

export const ACTIONS = [
  {
    name: "edit",
    className: "mdi mdi-account-edit-outline",
    color: "#016BFF"
  },
  {
    name: "delete",
    className: "mdi mdi-delete-outline",
    color: "#6F6F6F"
  }
];

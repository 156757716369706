import React from "react";
import "./Square.scss";
import Button from "components/CustomButton/CustomButton";

function SquareAddCheckIns(props) {
  const { goToNextStep } = props;
  const nextStep = () => {
    goToNextStep({ addCheckins: true });
  };

  const skipStep = () => {
    goToNextStep({ addCheckins: false });
  };
  return (
    <div className="square-modal">
      <div className="square-modal__header">Add Check-ins to your website?</div>
      <div className="square-modal__body">
        <p>
          We see you have a square online site. We can add a button to your website to allow cutomers to check-in to
          your waistlist.
        </p>
      </div>
      <div className="square-modal__footer--center-column ">
        <div>
          <Button
            onClick={skipStep}
            bsStyle="info"
            className="square-modal__button square-modal__button--left"
            pullRight
            type="button"
          >
            Skip
          </Button>
          <Button onClick={nextStep} bsStyle="info" className="square-modal__button btn-fill" pullRight type="button">
            Yes
          </Button>
        </div>
        <div className="square-modal__footer-text">(Requires Waitly Premium, free trial available)</div>
      </div>
    </div>
  );
}

export default SquareAddCheckIns;

import Card from "../Card/Card";
import CustomInput from "../../common/CustomInput/CustomInput";
import React, { useRef, useState } from "react";
import { Col, Modal, Row, Form } from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import { useForm } from "react-hook-form";
import { BeatLoader } from "react-spinners";
import { changeUserPassword } from "services/users/users.service";
import toastr from "toastr";

const ChangePasswordModal = ({ show, onClose, userId }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const form = useForm();

  const onSubmit = async values => {
    if (!userId) return setError("No choosen user, try again later");
    setLoading(true);
    try {
      await changeUserPassword(userId, {
        password: values.password
      });
    } catch (error) {
      setError(error.message);
      setLoading(false);
      return;
    }
    setLoading(false);
    onClose();
    toastr.success("Password successfully changed!");
  };

  const { handleSubmit, watch } = form;

  const password = useRef({});
  password.current = watch("password", "");

  return (
    <Modal dialogClassName="add-guest-modal add-guest-modal__change-pass" show={show} onHide={onClose}>
      <Card
        content={
          <Form noValidate className="add-reservation" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="add-reservation__title" md={12}>
                Change Password
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <CustomInput
                  form={form}
                  name="password"
                  label="Password"
                  disabled={loading}
                  type={"password"}
                  required
                  minLength={8}
                  placeholder="Password"
                />
                <CustomInput
                  form={form}
                  name="repeatPassword"
                  label="Re-enter password"
                  disabled={loading}
                  type={"password"}
                  required
                  validate={value => value === password.current || "The passwords do not match"}
                  placeholder="Re-enter password"
                />
                <p className="password-warning">Warning: Changing password will sign this user out of all devices.</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="add-reservation__actions">
                  {error && <p className="add-guest__error">{error}</p>}
                  <Button onClick={onClose} className="add-reservation__cancel" bsStyle="info" pullRight type="button">
                    Cancel
                  </Button>
                  <Button bsStyle="info" pullRight fill type="submit">
                    {loading ? <BeatLoader margin={2.5} size={10} color={"#fff"} /> : <span>Change Password</span>}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      />
    </Modal>
  );
};

export default ChangePasswordModal;

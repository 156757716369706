import { firebase } from "../../firebase";

export async function getUsers() {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/users/get`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getUsers function", error);
      throw error;
    });
}

export async function deleteUser(userId) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/users/delete?id=${userId}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering deleteUser function", error);
      throw error;
    });
}

export async function getUserRole(userId) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/users/role?id=${userId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getUserRole function", error);
      throw error;
    });
}

export async function editUser(userId, user) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/users/edit?id=${userId}`, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(user)
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering editUser function", error);
      throw error;
    });
}

export async function inviteUser(userInvite) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/users/invite`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(userInvite)
  })
    .then(async response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error((await response.json()).error);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering inviteUser function", error);
      throw error;
    });
}

export async function getUserInvitation(invitationId) {
  return fetch(`app/users/invite/get?id=${invitationId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getUserInvitation function", error);
    });
}

export async function getUsersByBusinessId(id) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/users/business?id=${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    }
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering getUsers function", error);
      throw error;
    });
}

export async function updateUserStatus(uid, disabled) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/users/update-status?id=${uid}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify({ disabled })
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering updateUserStatus function", error);
    });
}

export async function changeUserPassword(uid, data) {
  const currentUser = firebase.auth().currentUser;

  const token = await currentUser.getIdToken(true);
  return fetch(`app/users/change-password?id=${uid}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      Accepts: "application/json"
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (!response.ok || (response.status > 200 && response.status < 600)) {
        throw new Error(response.status);
      }
      return response.json();
    })
    .catch(error => {
      console.error("error triggering changePassword function", error);
    });
}

import React from "react";
import { ControlLabel, FormGroup, HelpBlock } from "react-bootstrap";
import { FormControlLabel, Switch } from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import InputMask from "react-input-mask";
import { validatePhoneNumber } from "helpers/phone.helper";
import { validateIdentifier } from "helpers/businessIdentifier.helper";
import { validatePin } from "helpers/pin.helper";
import { validateFullName } from "helpers/fullName.helper";

const CustomInput = ({
  label,
  disabled,
  name,
  placeholder,
  required,
  maxLength,
  minLength,
  form,
  businessType,
  onChange,
  options,
  pattern,
  defaultValue,
  value,
  validate,
  type = "text"
}) => {
  const validationRules = {};
  const validationMessages = [];
  const repeatNameStr = "repeat" + name[0].toUpperCase() + name.slice(1);

  if (required) {
    validationRules.required = true;
    validationMessages.push({
      type: "required",
      message: typeof required === "string" ? required : label + " is required"
    });
  }
  if (maxLength) {
    validationRules.maxLength = maxLength;
    validationMessages.push({
      type: "maxLength",
      message: label + ` length should be less than ${maxLength} characters`
    });
  }
  if (minLength) {
    validationRules.minLength = minLength;
    validationMessages.push({
      type: "minLength",
      message: label + ` is must be at least ${minLength} characters`
    });
  }
  if (pattern) {
    validationRules.pattern = {
      value: pattern.value
    };
    validationMessages.push({
      type: "pattern",
      message: pattern.message || `${label} is not valid`
    });
  }
  if (type === "email" && name.includes("repeat")) {
    validationMessages.push({
      type: "emailsNotEqual",
      message: "Emails is not equalss"
    });
  }
  if (validate) {
    validationRules.validate = validate;
  }
  const useStyles = makeStyles(() => ({
    root: {
      position: "relative",
      marginLeft: 0,
      marginRight: 0
    },
    labelPlacementStart: {
      marginLeft: 0,
      marginRight: 0
    },
    label: {
      position: "absolute",
      left: value ? 13 : 37,
      color: "white",
      fontSize: 12,
      top: 17
    }
  }));
  const classes = useStyles();

  const IOSSwitch = withStyles(theme => ({
    root: {
      width: 67,
      height: 32,
      padding: 0,
      margin: "8px 0"
    },
    switchBase: {
      padding: 1,
      top: 1,
      left: 2,
      "&$checked": {
        transform: "translateX(35px)",
        top: 1,
        left: 0,
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "rgb(79, 128, 239)",
          opacity: 1,
          border: "none"
        }
      },
      "&$focusVisible $thumb": {
        color: "rgb(79, 128, 239)",
        border: "6px solid #fff"
      }
    },
    thumb: {
      width: 28,
      height: 28
    },
    track: {
      borderRadius: 60 / 2,
      border: "1px solid rgb(193, 193, 193)",
      backgroundColor: "rgb(193, 193, 193)",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"])
    },
    checked: {},
    focusVisible: {}
  }))(({ classes, ...props }) => {
    return (
      <Switch
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        checked={value}
        onChange={onChange}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked
        }}
        {...props}
      />
    );
  });

  let input = (
    <input
      name={name}
      className="form-control"
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      ref={form && form.register(validationRules)}
      defaultValue={defaultValue ?? ""}
    />
  );

  if (type === "fullName") {
    input = (
      <input
        name={name}
        className="form-control"
        type="text"
        disabled={disabled}
        placeholder={placeholder}
        ref={form && form.register({ ...validationRules, validate: value => validateFullName(value) })}
        defaultValue={defaultValue ?? ""}
      />
    );
  }

  if (type === "select") {
    input = (
      <select
        className="form-control"
        disabled={disabled}
        name={name}
        ref={form && form.register(validationRules)}
        onChange={onChange}
        value={value}
      >
        {options.map((opt, index) => (
          <option key={index} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </select>
    );
  }

  if (type === "email") {
    input = (
      <input
        name={name}
        className="form-control"
        type="email"
        disabled={disabled}
        placeholder={placeholder}
        defaultValue={defaultValue ?? ""}
        onChange={e => {
          onChange
            ? onChange(e)
            : form.formState.touched[repeatNameStr]
            ? form.triggerValidation([name, repeatNameStr])
            : form.triggerValidation(name);
        }}
        ref={form && form.register(validationRules)}
      />
    );
  }

  if (type === "switch") {
    input = (
      <div>
        <FormControlLabel
          classes={classes}
          disabled={disabled}
          control={<IOSSwitch checked={value} name={name} />}
          labelPlacement="start"
          label={value ? "ON" : "OFF"}
        />
      </div>
    );
  }
  if (type === "businessIdentifier") {
    input = (
      <InputMask
        name={name}
        className="form-control"
        type="text"
        placeholder={placeholder}
        defaultValue={defaultValue ?? ""}
        ref={form && form.register({ ...validationRules, validate: value => validateIdentifier(value) })}
        mask={businessType === "CA" ? "999999-9" : "99-9999999"}
      />
    );
  }

  if (type === "pin") {
    input = (
      <InputMask
        name={name}
        className="form-control"
        type="text"
        placeholder={placeholder}
        defaultValue={defaultValue ?? ""}
        ref={form && form.register({ ...validationRules, validate: value => validatePin(value) })}
        mask={"999999"}
      />
    );
  }
  if (type === "phone") {
    input = (
      <InputMask
        name={name}
        className="form-control"
        type="text"
        placeholder={placeholder}
        defaultValue={defaultValue ?? ""}
        ref={form && form.register({ ...validationRules, validate: value => validatePhoneNumber(value) })}
        mask="+1 (999) 999-9999"
      />
    );
  }

  return (
    <FormGroup validationState={(!!(form && form.errors[name]) && "error") || null}>
      <ControlLabel>{label}</ControlLabel>

      {input}

      {validationMessages.map(
        (vm, idx) =>
          form &&
          form.errors[name] &&
          form.errors[name].type === vm.type && <HelpBlock key={"vm" + idx}>{vm.message}</HelpBlock>
      )}
      {form && form.errors[name] && form.errors[name].message && <HelpBlock>{form.errors[name].message}</HelpBlock>}
      {form && form.errors[name] && form.errors[name].type === "validate" && (
        <HelpBlock>{"Invalid " + label || name || ""}</HelpBlock>
      )}
    </FormGroup>
  );
};

export default CustomInput;

export const INVITE_DATE_HEADER = "invite date";

export const INVITE_DATE_FORMAT = "MM/DD/YYYY";

export const HEADERS = ["name", "email", INVITE_DATE_HEADER, "role", "actions"];

export const ACTIONS = [
  {
    name: "resend",
    className: "mdi mdi-email-outline",
    color: "#016BFF"
  },
  {
    name: "delete",
    className: "mdi mdi-delete-outline",
    color: "#6F6F6F"
  }
];

import React, { useState, useEffect } from "react";
import { firebase } from "../../firebase";
import "./DailyReservations.scss";
import DailyReservationsHeader from "./DailyReservationsHeader";
import DailyReservationsSchedule from "./DailyReservationsSchedule";
import DailyReservationsTable from "./DailyReservationsTable";
import { getBusinessResources } from "services/businesses/businesses.service";
import { getReservations } from "services/reservations/reservations.service";
import AddReservationModal from "components/AddReservation/AddReservation";
import { VisitStatus, sendMessage } from "services/visits/visits.service";
import {
  updateReservation,
  createReservation,
  deleteReservation,
  serveReservation,
  resetReservation,
  walkoutReservation,
  addOrUpdateReservationNote
} from "services/reservations/reservations.service";
import moment from "moment-timezone";
import { checkInReservation } from "services/reservations/reservations.service";
import SweetAlert from "react-bootstrap-sweetalert/lib/dist/SweetAlert";
import { notifyGuest } from "services/visits/visits.service";
import { invalidNumber, noEmailAlert, notifyErrorAlert, notifyGuestEmailAlert, optOut } from "constants/messages";
import { notifyGuestAlert } from "constants/messages";
import { NotifyGuestType } from "constants/visits";
import { spamAlert } from "constants/messages";
import { DEFAULT_RESERVATION_DURATION, DEFAUL_TIMEZONE } from "constants/reservation";
import AddNoteModal from "components/AddNote/AddNote";
import { noPhoneNumAlert } from "constants/messages";
import { getAccountById } from "../../services/account/account.service";
import { useSelector } from "react-redux";
import useLocalStorage from "../../hooks/useLocalStorage";
import CustomIconButton from "../../components/CustomButton/CustomIconButton";
import { getHours, getDefaultDuration } from "./timeFunctions";
import { deleteMessage } from "constants/messages";
import { BeatLoader } from "react-spinners";
import DailyReservationsTimeline from "./DailyReservationsTimeline";
import toastr from "toastr";
import { DateTime } from "luxon";
import { makeResourcesWithResertions } from "helpers/reservation.helper";
import { PHONE_CONFIG_STATUSES } from "constants/verification";
import { VERIFICATION_MODES } from "constants/verification";
import { isGracePeriodAfterNow } from "constants/verification";
import { useMediaQuery } from "hooks/useMediaQuery";

function DailyReservations(props) {
  const { user } = props;
  const isAnalyst = Boolean(user.claims.role === "analyst");
  const disabled = isAnalyst;
  const [account, setAccount] = useState(null);
  const visitSelector = useSelector(state => state.visit);
  const businessSelector = useSelector(state => state.business);
  const { messages } = visitSelector;
  const [reservationToEdit, setReservationToEdit] = useState(null);
  const [filter, setFilter] = useState({ currentDate: new Date(), resources: [] });
  const [businessSettings, setBusinessSettings] = useState(null);
  const [noteForm, setNoteForm] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [infoAlert, setInfoAlert] = useState(null);
  const [confirmAlert, setConfirmAlert] = useState(null);
  const [resources, setResources] = useState([]);
  const [reservations, setReservations] = useState([]);
  const [limitMessage, setLimitMessage] = useState(null);
  const [showReservationModal, setShowReservationModal] = useState(false);
  const [viewStyle, setViewStyle] = useLocalStorage("viewPref.reservation.viewStyle", "calendar");
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [reservationToDelete, setReservationToDelete] = useState(null);
  const [deleteReservationMessage, setDeleteReservationMessage] = useState(null);
  const [filterLoading, setFilterLoading] = useState(true);
  const [formDate, setFormDate] = useState(null);
  const [reservationResource, setReservationResource] = useState(null);
  const [allReservations, setAllReservations] = useState([]);
  const [timelineScrollTime, setTimelineScrollTime] = useState(null);
  const [resourcesWithReservations, setResourcesWithReservations] = useState(null);
  const { verificationMode } = useSelector(state => state.config || {});
  const [verifyAlert, setVerifyAlert] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");

  moment.tz.setDefault(getTimezone());

  useEffect(() => {
    if (reservationToDelete) setDeleteReservationMessage(deleteMessage("Reservation", reservationToDelete.data().name));
  }, [reservationToDelete]);

  useEffect(() => {
    if (
      businessSelector.business &&
      businessSelector.business.settings &&
      businessSelector.business.settings.reservations
    ) {
      setBusinessSettings(businessSelector.business.settings);
    }
  }, [businessSelector.business]);

  useEffect(() => {
    const loadAccount = async () => {
      const account = await getAccountById(user.accountId);
      setAccount(account.data());
    };
    loadAccount();
  }, [user.accountId]);

  useEffect(() => {
    if (filter.currentDate && businessSelector.business) {
      const unsubscribe = loadReservations(filter.currentDate);
      return () => {
        unsubscribe();
      };
    }
  }, [filter, businessSelector.business]);

  useEffect(() => {
    if (formDate && businessSelector.business) {
      const f = date => {
        const payload = {
          businessId: businessSelector?.business?.businessId,
          accountId: user.accountId,
          date,
          timezone: getTimezone()
        };
        return getReservations(payload).onSnapshot(result => {
          setAllReservations(result.docs);
        });
      };
      const unsubscribe = f(formDate);
      return () => {
        unsubscribe();
      };
    }
  }, [formDate, businessSelector.business]);

  useEffect(() => {
    setFormDate(filter.currentDate);
  }, [filter.currentDate]);

  useEffect(() => {
    loadBusinessResources();
  }, [businessSelector.business]);

  useEffect(() => {
    if (isMobile) {
      setViewStyle("list");
    }
  }, [isMobile]);

  function updateFilter(payload) {
    setFilter({ ...filter, ...payload });
  }

  function getCurrentHour() {
    const resrcs = resources && filter.resources && filter.resources.length > 0 ? filter.resources : [];
    let hours = getHours(businessSettings.reservations.hours, resrcs, businessSettings);
    const theHour = hours[filter.currentDate.getDay()].find(hour => {
      const time = moment(hour, "h:mm a").toDate();
      const timeDiff = moment().diff(time, "minutes");
      return timeDiff > 0 && timeDiff < DEFAULT_RESERVATION_DURATION;
    });
    return theHour || hours[filter.currentDate.getDay()][0];
  }

  function getTimezone() {
    return businessSettings ? businessSettings.timezone : DEFAUL_TIMEZONE;
  }

  function changeViewStyle(event, newValue) {
    setViewStyle(newValue);
  }

  async function loadBusinessResources() {
    if (businessSelector.business) {
      const resources = await getBusinessResources(businessSelector.business.businessId);
      if (resources && resources.docs.length) {
        const mappedResources = resources.docs
          .map(br => {
            return { id: br.id, ...br.data() };
          })
          .filter(r => {
            if (!businessSelector.business.settings.enableReservations) {
              return false;
            }
            if (businessSelector.business.settings.reservationsType === "reservations") {
              return r.reservationsType === "reservations";
            }
            return !r.reservationsType || r.reservationsType === "appointments";
          });
        if (mappedResources.length) {
          setResources(mappedResources);
          updateFilter({ ...filter, resources: mappedResources });
        } else {
          setResources([]);
          setFilterLoading(false);
        }
      } else {
        setResources([]);
        setFilterLoading(false);
      }
    }
  }

  function loadReservations(date) {
    const payload = {
      businessId: businessSelector?.business?.businessId,
      accountId: user.accountId,
      date,
      timezone: getTimezone()
    };
    return getReservations(payload).onSnapshot(result => {
      let docs = applyResourceFilter(result.docs);
      docs = sortReservations(docs);
      setReservations(docs);
    });
  }

  const sortReservations = docs => {
    return docs.sort((a, b) => {
      if (a.data().reservationTime > b.data().reservationTime) {
        return 1;
      } else if (a.data().reservationTime < b.data().reservationTime) {
        return -1;
      } else {
        return a
          .data()
          .name.toLowerCase()
          .localeCompare(b.data().name.toLowerCase());
      }
    });
  };

  const applyResourceFilter = docs => {
    if (filter.resources && filter.resources.length > 0) {
      // resouces exists and has filters
      return docs.filter(doc => {
        return (
          filter.resources.some(r => r.name === doc.data().resourceName) ||
          !resources.some(r => r.name === doc.data().resourceName) // for reservation without resource
        );
      });
    } else if (resources && resources.length) {
      // resources exists and no filter choosen
      return (
        docs.filter(doc => {
          return !resources.some(r => r.name === doc.data().resourceName); // for reservation without resource
        }) || []
      );
    } else {
      // no resources
      return docs;
    }
  };

  const handleReservationModalClose = async form => {
    setShowReservationModal(false);
    setReservationResource(null);
    if (form) {
      var partySize = parseInt(form.partySize);
      if (isNaN(partySize)) {
        partySize = 1;
      }
      const payload = {
        businessId: businessSelector.business.businessId,
        accountId: user.accountId,
        createdDate: new Date(),
        duration: Number(form.duration) || getDefaultDuration(filter.resources, form.date, businessSettings),
        status: VisitStatus.reserved,
        userId: props.user.uid,
        name: form.name,
        reminderSent: false,
        reservationTime: moment(form.date).toDate(),
        partySize: partySize || 1
      };
      if (form.resourceName) {
        payload.resourceName = form.resourceName;
        payload.resourceId = resources.find(r => r.name === form.resourceName)?.id;
      } else {
        if (reservationToEdit) {
          payload.resourceName = firebase.firestore.FieldValue.delete();
          payload.resourceId = firebase.firestore.FieldValue.delete();
        }
      }
      if (form.orderNumber) {
        payload.orderNumber = form.orderNumber;
      } else {
        if (reservationToEdit) {
          payload.orderNumber = firebase.firestore.FieldValue.delete();
        }
      }
      if (form.service) {
        payload.service = form.service;
      } else {
        if (reservationToEdit) {
          payload.service = firebase.firestore.FieldValue.delete();
        }
      }
      if (form.phone) {
        payload.phone = form.phone;
      } else {
        if (reservationToEdit) {
          payload.phone = firebase.firestore.FieldValue.delete();
        }
      }
      if (form.email) {
        payload.email = form.email;
      } else {
        if (reservationToEdit) {
          payload.email = firebase.firestore.FieldValue.delete();
        }
      }
      if (form.note) {
        payload.note = form.note;
      } else {
        if (reservationToEdit) {
          payload.note = firebase.firestore.FieldValue.delete();
        }
      }
      if (reservationToEdit) {
        try {
          await updateReservation(reservationToEdit.id, payload);
          toastr.success("Reservation updated!");
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          await createReservation(payload);
          toastr.success("Reservation created!");
        } catch (e) {
          console.error(e);
        }
      }
    }
    if (formDate && formDate.toDate) {
      setTimelineScrollTime(DateTime.fromJSDate(formDate.toDate()).setZone(getTimezone()));
      updateFilter({ currentDate: formDate.toDate() });
    }
    setReservationToEdit(null);
  };

  const handleReservationModalShow = hour => {
    const minutesDiff = moment(hour, "h:mm A").diff(moment().startOf("day"), "minutes");
    const currentDate = moment(filter.currentDate)
      .startOf("day")
      .add(minutesDiff, "minutes")
      .toDate();
    updateFilter({ currentDate });
    setShowReservationModal(true);
  };

  const handleReservationFormShow = () => {
    handleReservationModalShow(getCurrentHour());
    // console.log("current Hour", getCurrentHour());
  };

  const handleEdit = reservation => {
    setReservationToEdit(reservation);
    setShowReservationModal(true);
  };

  const handleCheckIn = reservation => {
    checkInReservation(reservation);
  };

  const handleDelete = reservation => {
    setShowDeleteAlert(true);
    setReservationToDelete(reservation);
  };

  const onDeleteReservation = () => {
    if (reservationToDelete) {
      deleteReservation(reservationToDelete);
    }
    hideDeleteAlert();
  };

  const handleServe = reservation => {
    serveReservation(reservation);
  };

  const handleWalkout = reservation => {
    walkoutReservation(reservation);
  };

  const handleReset = reservation => {
    resetReservation(reservation);
  };

  const handleNotify = reservation => () => {
    if (
      account?.plan &&
      account.plan !== "free" &&
      !(
        account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.VERIFIED ||
        account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.SUBMITTED ||
        account?.phoneConfig?.status === PHONE_CONFIG_STATUSES.PENDING
      ) &&
      verificationMode === VERIFICATION_MODES.ON &&
      !isGracePeriodAfterNow(account?.phoneConfig)
    ) {
      setVerifyAlert(true);
      return;
    }

    if (reservation.data().errorCode) {
      let message =
        reservation.data().errorCode === "spam" || reservation.data().errorCode === "invalid-number"
          ? invalidNumber
          : reservation.data().errorCode === "opt-out"
          ? optOut
          : {};
      setInfoAlert(message);
      return;
    }
    const notificationType = getNotificationType(account, businessSettings);
    if (notificationType === "sms" && !reservation.data().phone) {
      setInfoAlert(noPhoneNumAlert);
    } else if (notificationType === "email" && !reservation.data().email) {
      setInfoAlert(noEmailAlert);
    } else {
      setSelectedReservation(reservation);
      if (notificationType === "email") {
        setConfirmAlert(notifyGuestEmailAlert);
      } else {
        setConfirmAlert(notifyGuestAlert);
      }
    }
  };

  const getNotificationType = (account, businessSettings) => {
    if (businessSettings && businessSettings.notificationType) {
      return businessSettings.notificationType;
    } else if (account && account.settings && account.settings.notificationType) {
      return account.settings.notificationType;
    } else {
      return "sms";
    }
  };

  const handleNotifyAgain = reservation => {
    const notifiedMoment = moment(reservation.data().notifiedTime.toDate());
    const diffSeconds = moment().diff(notifiedMoment, "seconds");
    if (diffSeconds <= 60) {
      setInfoAlert(spamAlert);
    } else {
      triggerConfirmationAlert(reservation);
    }
  };

  const triggerConfirmationAlert = reservation => {
    setSelectedReservation(reservation);
    setConfirmAlert(notifyGuestAlert);
  };

  const notifyUser = () => {
    setConfirmAlert(null);
    triggerNotification(selectedReservation);
  };

  const triggerNotification = async reservation => {
    const response = await notifyGuest(reservation, NotifyGuestType.reservations);
    if (!response.ok) {
      setInfoAlert(notifyErrorAlert);
    }
  };

  const addNoteForm = visit => {
    setNoteForm(visit);
  };

  const handleAddNoteFormClose = async form => {
    setNoteForm(false);
    if (form) {
      addOrUpdateReservationNote(noteForm, form.note);
    }
  };

  const onGoToUpgrade = () => {
    hideUpgradeAlert();
    let url = document.URL.split("#")[0] + "#/admin/upgrade";
    window.location.replace(url);
  };

  const hideUpgradeAlert = () => {
    setLimitMessage(null);
  };

  const hideDeleteAlert = () => {
    setShowDeleteAlert(false);
    setReservationToDelete(null);
  };

  const onGetSelectedCategory = () => {
    if (reservationResource) {
      return reservationResource.name;
    }
    if (filter && filter.resources && filter.resources.length > 0) {
      return filter.resources[0].name;
    } else if (resources && resources.length > 0) {
      return resources[0].name;
    }
  };

  const handleTimelineAddReservation = (date, resourceId) => {
    const hour = DateTime.fromJSDate(date)
      .setZone(getTimezone())
      .toFormat("h:mm a");
    handleReservationModalShow(hour);
    setReservationResource(resources.find(r => r.id === resourceId));
  };

  useEffect(() => {
    setResourcesWithReservations(makeResourcesWithResertions(resources, reservations, getTimezone()));
  }, [reservations]);

  const hideVerifyAlert = () => {
    setVerifyAlert(false);
  };

  const showVerifyBusinessModal = () => {
    hideVerifyAlert();
    window.location.replace("#" + document.URL.split("#")[1] + "?action=verify");
  };

  return (
    <div className="reservations-wrapper">
      {showDeleteAlert && deleteReservationMessage && (
        <SweetAlert
          showCancel
          title={deleteReservationMessage.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={hideDeleteAlert}
          onConfirm={onDeleteReservation}
        >
          {deleteReservationMessage.message}
        </SweetAlert>
      )}
      {verifyAlert && (
        <SweetAlert
          showCancel
          title={"Business Verification Required"}
          cancelBtnText="Cancel"
          confirmBtnText="Verify Now"
          onCancel={hideVerifyAlert}
          onConfirm={showVerifyBusinessModal}
        >
          In order to send sms messages, you'll need to verify your business.
        </SweetAlert>
      )}
      {limitMessage && (
        <SweetAlert
          showCancel
          title={limitMessage.title}
          cancelBtnText="No"
          confirmBtnText="Upgrade"
          onCancel={hideUpgradeAlert}
          onConfirm={onGoToUpgrade}
        >
          {limitMessage.message}
        </SweetAlert>
      )}
      {infoAlert && (
        <SweetAlert title={infoAlert.title} onConfirm={() => setInfoAlert(null)}>
          {infoAlert.message}
        </SweetAlert>
      )}
      {confirmAlert && (
        <SweetAlert
          showCancel
          title={confirmAlert.title}
          cancelBtnText="No"
          confirmBtnText="Yes"
          onCancel={() => setConfirmAlert(null)}
          onConfirm={notifyUser}
        >
          {confirmAlert.message}
        </SweetAlert>
      )}
      {noteForm && <AddNoteModal noteForm={noteForm} showModal={!!noteForm} handleFormClose={handleAddNoteFormClose} />}
      {businessSettings && businessSettings.reservations ? (
        <div className="reservations">
          {showReservationModal ? (
            <AddReservationModal
              timezone={getTimezone()}
              resourcesWithReservations={resourcesWithReservations}
              resources={resources}
              reservations={allReservations}
              selectedDate={formDate}
              handleDateChange={setFormDate}
              resource={onGetSelectedCategory()}
              reservation={reservationToEdit}
              showReservationModal={showReservationModal}
              handleReservationModalClose={handleReservationModalClose}
              business={businessSelector.business}
            />
          ) : (
            ""
          )}
          <DailyReservationsHeader
            timezone={getTimezone()}
            filter={filter}
            resources={resources}
            setFilter={updateFilter}
            changeViewStyle={changeViewStyle}
            viewStyle={viewStyle}
            loading={filterLoading}
            setLoading={setFilterLoading}
          />
          {filterLoading ? (
            <div className="loading-wrapper">
              <BeatLoader margin={2.5} size={20} color={"#026FFF"} />
            </div>
          ) : (
            <>
              {viewStyle === "calendar" && (
                <DailyReservationsSchedule
                  disabled={disabled}
                  accountId={user.accountId}
                  account={account}
                  messages={messages}
                  timezone={getTimezone()}
                  handleReservationModalShow={handleReservationModalShow}
                  reservations={reservations}
                  filter={filter}
                  resources={resources}
                  businessSettings={businessSettings}
                  hours={businessSettings.reservations.hours}
                  handleEdit={handleEdit}
                  handleCheckIn={handleCheckIn}
                  handleDelete={handleDelete}
                  handleServe={handleServe}
                  handleNotify={handleNotify}
                  handleNotifyAgain={handleNotifyAgain}
                  handleReset={handleReset}
                  handleWalkout={handleWalkout}
                  sendMessage={sendMessage}
                  addNote={addNoteForm}
                  // reachLimit={reachLimit}
                />
              )}
              {viewStyle === "list" && (
                <DailyReservationsTable
                  disabled={disabled}
                  accountId={user.accountId}
                  account={account}
                  messages={messages}
                  timezone={getTimezone()}
                  handleReservationModalShow={handleReservationModalShow}
                  reservations={reservations}
                  filter={filter}
                  resources={resources}
                  businessSettings={businessSettings}
                  hours={businessSettings.reservations.hours}
                  handleEdit={handleEdit}
                  handleCheckIn={handleCheckIn}
                  handleDelete={handleDelete}
                  handleServe={handleServe}
                  handleNotify={handleNotify}
                  handleNotifyAgain={handleNotifyAgain}
                  handleReset={handleReset}
                  handleWalkout={handleWalkout}
                  sendMessage={sendMessage}
                  addNote={addNoteForm}
                  // reachLimit={reachLimit}
                />
              )}
              {viewStyle === "timeline" && (
                <DailyReservationsTimeline
                  disabled={disabled}
                  resourcesWithReservations={resourcesWithReservations}
                  accountId={user.accountId}
                  account={account}
                  messages={messages}
                  timezone={getTimezone()}
                  handleReservationModalShow={handleReservationModalShow}
                  reservations={reservations}
                  filter={filter}
                  resources={resources}
                  businessSettings={businessSettings}
                  hours={businessSettings.reservations.hours}
                  handleEdit={handleEdit}
                  handleCheckIn={handleCheckIn}
                  handleDelete={handleDelete}
                  handleServe={handleServe}
                  handleNotify={handleNotify}
                  handleNotifyAgain={handleNotifyAgain}
                  handleReset={handleReset}
                  handleWalkout={handleWalkout}
                  sendMessage={sendMessage}
                  addNote={addNoteForm}
                  date={formDate}
                  addReservation={handleTimelineAddReservation}
                  scrollTime={timelineScrollTime}
                  setScrollTime={setTimelineScrollTime}
                  {...props}
                />
              )}
            </>
          )}
        </div>
      ) : null}
      {!disabled && (
        <div className="reservations__add-guest">
          <CustomIconButton faIcon="plus" handleClick={handleReservationFormShow}></CustomIconButton>
        </div>
      )}
    </div>
  );
}

export default DailyReservations;

export const env = (import.meta.env.MODE === "locally" ? "local" : import.meta.env.MODE) || "local";

const environments = {
  local: {
    apiEndpoint: "http://localhost:5001/waitly-dev/us-central1/",
    recaptchaKey: "6Lc5jmQcAAAAAM5A-C-63sWrQ_ApTdFzu856J0gn",
    baseUrl: "https://app-waitlydev.web.app/",
    waitlistUrl: "https://wl.waitlydev.com/",
    metaPixel: "636463144943632",
    db: "waitly-dev",
    prices: ["price_HKgi1fIunNwgXQ"],
    plans: {
      premium: {
        monthly: "price_HKgi1fIunNwgXQ",
        annually: "price_1JPWstBwISy4TmptCsKmnXSB"
      },
      pro: {
        monthly: "price_1JTDkCBwISy4TmptVpnJh3A5",
        annually: "price_1JTDkPBwISy4TmptHDir64Gz"
      }
    },
    stripeKey: "pk_test_o9FgE1sYY0OE5b79TSpZIy8P00ssMEjXOD",
    squareClientId: "sq0idp-Ff3j0cWVGdCvlJIU5bon1g",
    googleMapsKey: "AIzaSyCA15UnFEC81pSWKWTeiONweQ6bIIR26EQ",
    config: {
      projectId: "waitly-dev",
      appId: "1:964149436713:web:7f0a780634d42d2bf0d738",
      databaseURL: "https://waitly-dev.firebaseio.com",
      storageBucket: "waitly-dev.appspot.com",
      locationId: "us-central",
      apiKey: "AIzaSyCA15UnFEC81pSWKWTeiONweQ6bIIR26EQ",
      authDomain: "waitly-dev.firebaseapp.com",
      messagingSenderId: "964149436713",
      measurementId: "G-EJZWGFDJKD"
    }
  },
  development: {
    apiEndpoint: "https://us-central1-waitly-dev.cloudfunctions.net/",
    recaptchaKey: "6Lc5jmQcAAAAAM5A-C-63sWrQ_ApTdFzu856J0gn",
    baseUrl: "https://app-waitlydev.web.app/",
    waitlistUrl: "https://wl.waitlydev.com/",
    metaPixel: "636463144943632",
    db: "waitly-dev",
    prices: ["price_HKgi1fIunNwgXQ"],
    plans: {
      premium: {
        monthly: "price_HKgi1fIunNwgXQ",
        annually: "price_1JPWstBwISy4TmptCsKmnXSB"
      },
      pro: {
        monthly: "price_1JTDkCBwISy4TmptVpnJh3A5",
        annually: "price_1JTDkPBwISy4TmptHDir64Gz"
      }
    },
    stripeKey: "pk_test_o9FgE1sYY0OE5b79TSpZIy8P00ssMEjXOD",
    squareClientId: "sq0idp-Ff3j0cWVGdCvlJIU5bon1g",
    googleMapsKey: "AIzaSyCA15UnFEC81pSWKWTeiONweQ6bIIR26EQ",
    config: {
      projectId: "waitly-dev",
      appId: "1:964149436713:web:7f0a780634d42d2bf0d738",
      databaseURL: "https://waitly-dev.firebaseio.com",
      storageBucket: "waitly-dev.appspot.com",
      locationId: "us-central",
      apiKey: "AIzaSyCA15UnFEC81pSWKWTeiONweQ6bIIR26EQ",
      authDomain: "waitly-dev.firebaseapp.com",
      messagingSenderId: "964149436713",
      measurementId: "G-EJZWGFDJKD"
    }
  },
  localProd: {
    apiEndpoint: "http://localhost:5001/zentable-60946/us-central1/",
    recaptchaKey: "6Lcoj04gAAAAAMGtk0WSQ2U-JbcmQQ3HHRJb7u7L",
    baseUrl: "https://app.waitly.com",
    waitlistUrl: "https://wl.waitly.com/",
    metaPixel: "271165924980836",
    db: "waitly",
    prices: ["price_1H6qXsBwISy4TmptSiUm3Ooq"],
    stripeKey: "pk_live_Dln6A1GpPVT7P3PmBi21ite400JuEyNFOa",
    squareClientId: "sq0idp-C5rAfCHaVnwINjdfoIxkIA",
    googleMapsKey: "AIzaSyBDiP1uUiCDOE7eL8aW7SQJixEBkItrO0I",
    plans: {
      premium: {
        monthly: "price_1H6qXsBwISy4TmptSiUm3Ooq",
        annually: "price_1H6qXsBwISy4TmptDHOFtkY6"
      },
      pro: {
        monthly: "price_1IhJTeBwISy4TmptUitJWS0K",
        annually: "price_1IhJTeBwISy4TmptcsZrMlnt"
      }
    },
    config: {
      projectId: "zentable-60946",
      appId: "1:72343684537:web:4ea1c578fd0251d062a268",
      databaseURL: "https://zentable-60946.firebaseio.com",
      storageBucket: "zentable-60946.appspot.com",
      locationId: "us-central",
      apiKey: "AIzaSyAVPfilHLEj4KxpQ8LWHBPff8ZyDxBq2UU",
      authDomain: "zentable-60946.firebaseapp.com",
      messagingSenderId: "72343684537",
      measurementId: "G-8P82LBLPQX"
    }
  },
  production: {
    apiEndpoint: "https://us-central1-zentable-60946.cloudfunctions.net/",
    recaptchaKey: "6Lcoj04gAAAAAMGtk0WSQ2U-JbcmQQ3HHRJb7u7L",
    baseUrl: "https://app.waitly.com",
    waitlistUrl: "https://wl.waitly.com/",
    metaPixel: "271165924980836",
    db: "waitly",
    prices: ["price_1H6qXsBwISy4TmptSiUm3Ooq"],
    plans: {
      premium: {
        monthly: "price_1H6qXsBwISy4TmptSiUm3Ooq",
        annually: "price_1H6qXsBwISy4TmptDHOFtkY6"
      },
      pro: {
        monthly: "price_1IhJTeBwISy4TmptUitJWS0K",
        annually: "price_1IhJTeBwISy4TmptcsZrMlnt"
      }
    },
    stripeKey: "pk_live_Dln6A1GpPVT7P3PmBi21ite400JuEyNFOa",
    squareClientId: "sq0idp-C5rAfCHaVnwINjdfoIxkIA",
    googleMapsKey: "AIzaSyBDiP1uUiCDOE7eL8aW7SQJixEBkItrO0I",
    config: {
      projectId: "zentable-60946",
      appId: "1:72343684537:web:4ea1c578fd0251d062a268",
      databaseURL: "https://zentable-60946.firebaseio.com",
      storageBucket: "zentable-60946.appspot.com",
      locationId: "us-central",
      apiKey: "AIzaSyAVPfilHLEj4KxpQ8LWHBPff8ZyDxBq2UU",
      authDomain: "zentable-60946.firebaseapp.com",
      messagingSenderId: "72343684537",
      measurementId: "G-8P82LBLPQX"
    }
  }
};

export const environment = environments[env] || environments.development;

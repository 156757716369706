import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import toastr from "toastr";
import "./UserInfo.scss";
import { USER_INFO_TYPES } from "../../constants/user-info";
import { ROlES } from "../../constants/settings";
import CustomInput from "../../common/CustomInput/CustomInput";
import { EMAIL_PATTERN } from "constants/patterns";
import { editUser, inviteUser } from "../../services/users/users.service";
import { createUser } from "../../services/auth/signup";
import { useDispatch } from "react-redux";
import { setShouldUpdateUsers } from "store/actions/users.actions";
import { isCurrentUser } from "helpers/user.helper";
import { USER_INFO_TEXT_VARIABLES } from "../../constants/user-info";
import { setShouldUpdateInvites } from "store/actions/users.actions";

const UserInfo = ({ type, goBack, closeModal, hideRoleDropdown = false, user, onGetUsers }) => {
  const businessSelector = useSelector(state => state.business);
  const { businesses, business } = businessSelector;
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    firstName: user?.firstName || "",
    lastName: user?.lastName || "",
    businessId: user?.businessId || business.businessId || "",
    role: user?.role || ""
  });

  const form = useForm();
  const { handleSubmit, triggerValidation, formState } = form;
  const [loading, setLoading] = useState(false);
  const [roleValue, setRoleValue] = useState(user?.role || ROlES[0].role);
  const [role, setRole] = useState(null);

  const onSubmit = async values => {
    setLoading(true);
    setError("");
    const data = {
      ...values,
      email: values?.email?.toLowerCase().trim()
    };
    const fullName = data.fullName.split(" ");
    data.firstName = fullName.shift();
    data.lastName = fullName.join(" ");
    delete data.fullName;

    const accountId = businesses.find(b => b.businessId === values.businessId).accountId;

    switch (type) {
      case USER_INFO_TYPES.CREATE_USER:
        try {
          await createUser({ accountId, ...data });
          await dispatch(setShouldUpdateUsers(true));
          toastr.success("User created.");
        } catch (error) {
          setError(error.message);
          setLoading(false);
          return;
        }
        break;
      case USER_INFO_TYPES.INVITE_USER:
        try {
          await inviteUser(data);
          await dispatch(setShouldUpdateInvites(true));
          toastr.success("User invited.");
        } catch (error) {
          setError(error.message);
          setLoading(false);
          return;
        }
        break;
      case USER_INFO_TYPES.UPDATE_USER:
        try {
          const editData = {
            firstName: data.firstName,
            lastName: data.lastName,
            businessId: data.businessId
          };
          // check if it's current user, than formValues.role is "", and no need to edit role to ""
          if (formValues.role) {
            editData.role = formValues.role;
          }
          await editUser(user.userId, { user: editData, accountId, isCurrentUser: isCurrentUser(user) });
          await onGetUsers(accountId);
          toastr.success("User updated!");
        } catch (error) {
          console.error("User didn't update, something went wront.");
        }
        break;
      default:
        toastr.error("Something went wrong, try again later.");
        break;
    }
    closeModal();
    setLoading(false);
  };

  useEffect(() => {
    setRole(ROlES.find(role => role.role === roleValue));
  }, [roleValue]);

  const onCancel = () => {
    if (type === USER_INFO_TYPES.UPDATE_USER) return closeModal();
    return goBack();
  };

  const handleChangeField = event => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleChangeRole = event => {
    handleChangeField(event);
    setRoleValue(event.target.value);
  };

  return (
    <Form noValidate className="add-reservation" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col className="add-reservation__title" md={12}>
          {USER_INFO_TEXT_VARIABLES.HEADER[type]}
        </Col>
      </Row>
      <Row className="add-user-info__container">
        <Col md={6} className="add-user-info__col">
          <CustomInput
            form={form}
            name="fullName"
            label="Full name"
            disabled={loading}
            required
            maxLength={80}
            placeholder="Full name"
            defaultValue={(formValues.firstName + " " + formValues.lastName).trim()}
          />
          {type !== USER_INFO_TYPES.UPDATE_USER && (
            <CustomInput
              form={form}
              name="email"
              label="Email"
              placeholder="Email"
              disabled={loading}
              type="email"
              required
              pattern={{
                value: EMAIL_PATTERN,
                message: "Please enter a valid email"
              }}
              onChange={() => {
                formState.touched.repeatEmail
                  ? triggerValidation(["email", "repeatEmail"])
                  : triggerValidation("email");
              }}
            />
          )}
          <CustomInput
            form={form}
            name="businessId"
            label="Location"
            disabled={loading}
            type="select"
            required
            options={businesses.map(b => ({ label: b.name, value: b.businessId }))}
            value={formValues.businessId}
            onChange={handleChangeField}
          />
          {!hideRoleDropdown && (
            <CustomInput
              form={form}
              name="role"
              label="Role"
              disabled={loading}
              type="select"
              required
              onChange={handleChangeRole}
              options={ROlES.map(r => ({ label: r.name, value: r.role }))}
              value={formValues.role}
            />
          )}
          {type === USER_INFO_TYPES.CREATE_USER && (
            <CustomInput
              form={form}
              name="password"
              label="Password"
              disabled={loading}
              type={"password"}
              required
              minLength={8}
              placeholder="Password"
            />
          )}
        </Col>
        <Col md={6} className="add-user-info__col">
          {role && (
            <div className="role-permisions">
              <p className="role-permissions-title"> {role.name.toUpperCase() + " PERMISSIONS:"}</p>
              <ul className="role-permissions">
                {role.permissions.map((bp, idx) => {
                  return <li key={idx}>{bp}</li>;
                })}
              </ul>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <div className="add-reservation__actions">
            {error && <p className="add-guest__error">{error}</p>}
            <Button onClick={onCancel} className="add-reservation__cancel" bsStyle="info" pullRight type="button">
              {USER_INFO_TEXT_VARIABLES.CANCEL_BTN_TEXT[type]}
            </Button>
            <Button bsStyle="info" pullRight fill type="submit">
              {loading ? (
                <BeatLoader margin={2.5} size={10} color={"#fff"} />
              ) : (
                <span>{USER_INFO_TEXT_VARIABLES.SUCCESS_BTN_TEXT[type]}</span>
              )}
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default UserInfo;

import React from "react";
import "../../layouts/Admin.scss";
import "./Upgrade.scss";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import PriceBox from "components/PriceBox/Pricebox";

function UpgradeSquare({ exitPoint, isProfessional, skipUpgrade }) {
  const plan = isProfessional ? "pro" : "premium";
  const pricesSelector = useSelector(state => state.prices);
  const accountSelector = useSelector(state => state.account);
  const { prices } = pricesSelector;
  const { account } = accountSelector;

  return (
    <div id="pricing" className="pricing-section">
      <div className="text-center pricing-section__container">
        <div className="pricing-section__boxes pricing-section__boxes--upgrade">
          <PriceBox plan={plan} price={prices} interval="monthly" account={account} exitPoint={exitPoint} />
          <Button bsStyle="info" onClick={skipUpgrade} type="button">
            Skip For Now
          </Button>
        </div>
      </div>
    </div>
  );
}

export default UpgradeSquare;

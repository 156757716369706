import { SET_SQUARE_INFO } from "../actions/square.actions";

export function square(state = {}, action) {
  switch (action.type) {
    case SET_SQUARE_INFO:
      return { ...state, squareInfo: action.payload };
    default:
      return state;
  }
}

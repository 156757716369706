import parsePhoneNumberFromString from "libphonenumber-js";

export const formatPhone = phone => {
  if (!phone) {
    return "";
  }
  if (isOnlyMaskPresent(phone)) {
    return "";
  }

  return phone.replace(/[-_()\s]/gi, "").slice(0, 12);
};

export const isOnlyMaskPresent = phone => {
  return phone === "+1 (___) ___-____";
};

export const validatePhoneNumber = value => {
  if (!value || isOnlyMaskPresent(value)) {
    return true;
  }

  let phoneValue = formatPhone(value);
  const phone = parsePhoneNumberFromString(phoneValue || "");
  return !!phone && phone.isValid() && (phone.country === "US" || phone.country === "CA");
};

export const formatPhonePretty = phone => {
  return parsePhoneNumberFromString(formatPhone(phone))?.formatNational() || phone || "";
};

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Card from "../Card/Card";
import { Col, ControlLabel, Form, FormGroup, HelpBlock, Modal, Row } from "react-bootstrap";
import Button from "../CustomButton/CustomButton";
import BeatLoader from "react-spinners/BeatLoader";
import SettingsInput from "../Settings/SettingsInput";
import { reservationPossibleValues } from "../../constants/time";

const AddResource = ({ showResourceModal, closeModal, resource, updateResource, loading }) => {
  const { register, handleSubmit, errors } = useForm();

  const [form, setForm] = useState({
    name: resource?.name || "",
    maxReservations: resource?.maxReservations * 1 || 1,
    maxPartySize: resource?.maxPartySize || 1,
    minDuration: (resource && resource.minDuration) || 15,
    maxDuration: (resource && resource.maxDuration) || 15,
    addNotificationText: resource && !!resource.notificationText,
    notificationText: (resource && resource.notificationText) || ""
  });

  const onSubmit = data => {
    if (!form.addNotificationText) {
      delete data.notificationText;
    }
    updateResource(data);
  };

  const toggleChecked = event => {
    setForm({ ...form, [event.target.name]: event.target.checked });
  };

  const handleTextareaChange = key => event => {
    let value;
    if (typeof event === "string") {
      value = event;
    } else {
      value = event.target.value;
    }
    setForm({ ...form, [key]: value });
  };

  return (
    <Modal enforceFocus={false} dialogClassName="add-guest-modal" show={showResourceModal} onHide={closeModal}>
      <Card
        content={
          <Form noValidate className="edit-user" onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col className="edit-user__title" md={12}>
                {`${resource?.id ? "Edit" : "Add"} Resource`}
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.name && "error") || null}>
                  <ControlLabel className="edit-user-label">NAME</ControlLabel>
                  <input
                    name="name"
                    className="form-control"
                    type="text"
                    disabled={loading}
                    placeholder="Enter name"
                    defaultValue={form.name}
                    ref={register({ required: true })}
                  />
                  {errors.name && errors.name.type === "required" && <HelpBlock>Name is required</HelpBlock>}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.maxReservations && "error") || null}>
                  <ControlLabel className="edit-user-label">SIMULTANEOUS BOOKINGS</ControlLabel>
                  <input
                    name="maxReservations"
                    className="form-control"
                    type="number"
                    disabled={loading}
                    placeholder="Simulataneous Bookings"
                    defaultValue={form.maxReservations}
                    ref={register({ required: true, max: 200, min: 1 })}
                  />
                  {errors.maxReservations && errors.maxReservations.type === "required" && (
                    <HelpBlock>This field is required</HelpBlock>
                  )}
                  {errors.maxReservations && errors.maxReservations.type === "max" && (
                    <HelpBlock>Simultaneous bookings should be less than 200</HelpBlock>
                  )}
                  {errors.maxReservations && errors.maxReservations.type === "min" && (
                    <HelpBlock>Min simultaneous bookings should be at least 1</HelpBlock>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.maxPartySize && "error") || null}>
                  <ControlLabel className="edit-user-label">PARTY SIZE ACCOMMODATION</ControlLabel>
                  <input
                    name="maxPartySize"
                    className="form-control"
                    type="number"
                    disabled={loading}
                    placeholder="Max party size"
                    defaultValue={form.maxPartySize}
                    ref={register({ required: true, max: 200, min: 1 })}
                  />
                  {errors.maxPartySize && errors.maxPartySize.type === "required" && (
                    <HelpBlock>This field is required</HelpBlock>
                  )}
                  {errors.maxPartySize && errors.maxPartySize.type === "max" && (
                    <HelpBlock>Party size should be less than 200</HelpBlock>
                  )}
                  {errors.maxPartySize && errors.maxPartySize.type === "min" && (
                    <HelpBlock>Min party size should be at least 1</HelpBlock>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup validationState={(!!errors.minDuration && "error") || null}>
                  <ControlLabel className="edit-user-label">DEFAULT DURATION</ControlLabel>
                  <select
                    className="form-control"
                    placeholder="Default duration"
                    disabled={loading}
                    name="minDuration"
                    ref={register({ required: true })}
                    defaultValue={form.minDuration || ""}
                  >
                    {reservationPossibleValues.map((time, index) => (
                      <option key={index} value={time}>
                        {time}
                      </option>
                    ))}
                    <option key="allday" value="-1">
                      All day time slot
                    </option>
                  </select>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup style={{ margin: 0 }}>
                  <ControlLabel style={{ float: "left", marginTop: 17, fontSize: 14 }}>
                    CUSTOM NOTIFCATION TEXT
                  </ControlLabel>
                  <SettingsInput
                    form={form}
                    name={"addNotificationText"}
                    type={"switch"}
                    enabled={true}
                    style={{ marginBottom: 0 }}
                    value={form.addNotificationText}
                    register={null}
                    onChangeValue={toggleChecked}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <ControlLabel style={{ marginTop: 17, fontSize: 14, color: !form.addWelcomeText && "#CAC9C9" }}>
                    NOTIFICATION TEXT
                  </ControlLabel>
                  <SettingsInput
                    form={form}
                    name={"notificationText"}
                    type={"textarea"}
                    errors={errors}
                    hideLabel={true}
                    enabled={form.addNotificationText}
                    value={form.notificationText}
                    link={false}
                    register={register}
                    onChangeValue={handleTextareaChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div className="edit-user__actions">
                  <Button
                    onClick={closeModal}
                    className="add-reservation__cancel"
                    bsStyle="info"
                    pullRight
                    disabled={loading}
                    type="button"
                  >
                    Cancel
                  </Button>
                  <Button disabled={loading} bsStyle="info" pullRight fill type="submit">
                    {loading ? (
                      <BeatLoader margin={2.5} size={10} color={"#fff"} />
                    ) : (
                      <span>{resource?.id ? "UPDATE" : "ADD"}</span>
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        }
      />
    </Modal>
  );
};

export default AddResource;

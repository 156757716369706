
export const SET_BUSINESS = "SET_BUSINESS";
export const SET_BUSINESSES = "SET_BUSINESSES";

export function setBusiness(payload) {
  return {
    type: SET_BUSINESS,
    payload
  };
}

export const setBusinesses = (businesses) => {
  return {
    type: SET_BUSINESSES,
    businesses: businesses
  };
}

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Row, Col, FormGroup, ControlLabel, HelpBlock, Form, Button } from "react-bootstrap";
import "./Profile.scss";
import { changePassword } from "services/profile/profile.service";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useRef } from "react";
import BeatLoader from "react-spinners/BeatLoader";

function ProfileChangePasswordForm(props) {
  const { user } = props;
  const { register, handleSubmit, errors, watch, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const password = useRef({});
  password.current = watch("newPassword", "");
  const form = {
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: ""
  };

  const onSubmit = async data => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await changePassword(user.email, data.oldPassword, data.newPassword);
      setLoading(false);
      reset({
        oldPassword: "",
        newPassword: "",
        repeatNewPassword: ""
      });
      toastr.success(`Password successfully changed`);
    } catch (err) {
      setLoading(false);
      const msg =
        err.code === "auth/wrong-password"
          ? "Old password was incorrect."
          : "There was an issue. Make sure you are connected to the internet and try again.";
      toastr.error(msg);
    }
  };
  return (
    <Form noValidate className="profile-form" onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col md={12} lg={6}>
          <FormGroup>
            <h3>Change Password</h3>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup validationState={(!!errors.oldPassword && "error") || null}>
            <ControlLabel>Old Password</ControlLabel>
            <input
              name="oldPassword"
              className="form-control"
              type="password"
              placeholder="Enter old password"
              defaultValue={form.oldPassword}
              ref={register({ required: true })}
            />
            {errors.oldPassword && errors.oldPassword.type === "required" && (
              <HelpBlock>Password is required</HelpBlock>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup validationState={(!!errors.newPassword && "error") || null}>
            <ControlLabel>New Password</ControlLabel>
            <input
              name="newPassword"
              className="form-control"
              type="password"
              placeholder="Enter new password"
              defaultValue={form.newPassword}
              ref={register({ required: true, minLength: 8 })}
            />
            {errors.newPassword && errors.newPassword.type === "required" && (
              <HelpBlock>New password is required</HelpBlock>
            )}
            {errors.newPassword && errors.newPassword.type === "minLength" && (
              <HelpBlock>New password length should have at least 8 characters</HelpBlock>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup validationState={(!!errors.repeatNewPassword && "error") || null}>
            <ControlLabel>Re-enter Password</ControlLabel>
            <input
              name="repeatNewPassword"
              className="form-control"
              type="password"
              placeholder="Repeat new password"
              defaultValue={form.repeatNewPassword}
              ref={register({
                required: true,
                validate: value => value === password.current || "The passwords do not match"
              })}
            />
            {errors.repeatNewPassword && errors.repeatNewPassword.type === "required" && (
              <HelpBlock>New password is required</HelpBlock>
            )}
            {errors.repeatNewPassword && errors.repeatNewPassword.message && (
              <HelpBlock>{errors.repeatNewPassword.message}</HelpBlock>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup>
            <div className="profile-form__button-wrapper">
              <Button className="profile-form__button-password btn-fill" bsStyle="primary" fill type="submit">
                {loading ? <BeatLoader margin={2.5} size={10} color={"#447df7"} /> : <span>Change Password</span>}
              </Button>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
}

export default ProfileChangePasswordForm;
